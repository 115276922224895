import React from 'react';
import classnames from 'classnames';

import Radio from '../../../components/form/Radio';
import text from '../../../text';

const MembershipOption = ({
  priceId,
  onChange,
  noMembership,
  isSelected,
  label,
  extraText,
}) => {
  return (
    <div
      className={classnames('MembershipOption position-relative', {
        isSelected,
        noMembership,
      })}
    >
      <Radio
        id={priceId}
        onChange={onChange}
        value={priceId}
        checked={isSelected}
      />
      <label htmlFor={priceId} className='label'>
        <div className='label'>{label}</div>
        <div className='extraText'>{extraText}</div>
      </label>
    </div>
  );
};

export default MembershipOption;
