import React from 'react';
import { TOUR_DONE_MODAL } from '../../../../../components/modal/TourDoneModal';
import { showModal } from '../../../../../redux/actions';
import text from '../../../../../text';
import { store } from '../../../../../utilities/store';
import { StepContent } from './TourContent';
import { hightlightedAreaStyling, popoverCustomDefaultStyling } from './config';

export const TOUR_FILE_MANAGER = [
  {
    selector: '.Activity',
    content: () => (
      <StepContent
        title={text('tourFileManagerStep1Title')}
        description={text('tourFileManagerStep1')}
      />
    ),
    position: 'bottom',
    styles: {
      popover: (base) => ({
        ...popoverCustomDefaultStyling(base),
        marginTop: '16px',
      }),
      highlightedArea: (base, state) => hightlightedAreaStyling(base, state),
    },
    customProps: {
      caret: 'top',
    },
  },
  {
    selector: '#ActivityFilterControls-tour',
    content: () => (
      <StepContent
        title={text('tourFileManagerStep2Title')}
        description={text('tourFileManagerStep2')}
      />
    ),
    position: 'bottom',
    styles: {
      popover: (base) => ({
        ...popoverCustomDefaultStyling(base),
        marginTop: '16px',
      }),
      highlightedArea: (base, state) => hightlightedAreaStyling(base, state),
    },
    customProps: {
      caret: 'top',
    },
  },
  {
    selector: '.Controls',
    content: () => (
      <StepContent
        title={text('tourFileManagerStep3Title')}
        description={text('tourFileManagerStep3')}
      />
    ),
    position: 'bottom',
    styles: {
      popover: (base) => ({
        ...popoverCustomDefaultStyling(base),
        marginTop: '16px',
      }),
      highlightedArea: (base, state) => hightlightedAreaStyling(base, state),
    },
    customProps: {
      caret: 'top',
      callback: {
        next: () => {
          store.dispatch(showModal(TOUR_DONE_MODAL));
        },
      },
    },
  },
];
