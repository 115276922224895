import cn from 'classnames';
import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';

import Titlebar from '../../pages/dashboard/components/navigation/navbar/Titlebar';

const Page = forwardRef((props, ref) => {
  const {
    id,
    children,
    className,
    title,
    noPadding,
    showTitlebar = true,
    showShareLink = false,
  } = props;
  return (
    <div
      id={id}
      className={cn('Page', { [className]: className, noPadding })}
      {...(ref && { ref })}
    >
      <Helmet>
        <title>{title ? `LARKI - ${title}` : `LARKI`}</title>
      </Helmet>
      {showTitlebar ? <Titlebar showShareLink={showShareLink} /> : null}
      {children}
    </div>
  );
});

const PageControl = (props) => {
  const { children, className } = props;
  return (
    <div className={cn('PageControl', { [className]: className })}>
      {children}
    </div>
  );
};

const PageTop = (props) => {
  const { children } = props;
  return <div className='PageTop'>{children}</div>;
};
const PageTopControl = (props) => {
  const { children } = props;
  return <div className='PageTopControl'>{children}</div>;
};

export const PageBody = (props) => {
  const { children, className } = props;
  return (
    <div className={cn('PageBody', { [className]: className })}>
      {children || null}
    </div>
  );
};

Page.Top = PageTop;
Page.Top.Control = PageTopControl;
Page.Body = PageBody;
Page.Control = PageControl;
Page.displayName = 'Page';

export default Page;
