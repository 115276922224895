import React, { useState } from 'react';
import { NavItem, Collapse } from 'react-bootstrap';
import T from 'prop-types';
import NavLink from './NavLink';

const CollapsibleNavItem = (props) => {
  const {
    header,
    items,
    isBorderHighlighted = true,
    isCaretHighlighted = true,
  } = props;
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <div className='CollapsibleNavItem'>
      <NavLink
        isCollapsed={isCollapsed}
        isBorderHighlighted={isBorderHighlighted}
        isCaretHighlighted={isCaretHighlighted}
        name={header}
        onClick={() => setIsCollapsed(!isCollapsed)}
      />
      <Collapse in={isCollapsed}>
        <div>
          {items.map((itemProps, i) => (
            <NavLink key={i} className='sublink' {...itemProps} />
          ))}
        </div>
      </Collapse>
    </div>
  );
};

CollapsibleNavItem.propTypes = {
  items: T.arrayOf(T.object),
  header: T.string.isRequired,
};

export default CollapsibleNavItem;
