import React, { useState, useCallback, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { mergeProject, hideModal } from '../../redux/actions';
import { isEmpty, noop } from 'lodash/fp';

import text from '../../text';
import { Button } from '../button';
import ProjectSelect from '../select/ProjectSelect/ProjectSelect';
import IconWithTooltip from '../icon/IconWithTooltip';
import { isAdministrator } from '../../utilities/user';
import { getProjectName } from '../../utilities/project';

export const MERGE_PROJECT_MODAL = 'MERGE_PROJECT_MODAL';

const MergeProjectModal = ({ show, currentJob, onSuccess = noop }) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.profileReducer.userProfile,
  }));
  const [targetProject, setTargetProject] = useState();
  const onHide = () => dispatch(hideModal(MERGE_PROJECT_MODAL));

  const onOk = useCallback(
    async (event) => {
      event.preventDefault();
      if (!isEmpty(currentJob)) {
        const isMerged = await dispatch(
          mergeProject(currentJob.project, targetProject)
        );
        onHide();
        if (isMerged) {
          onSuccess(targetProject);
        }
      }
    },
    [currentJob, targetProject]
  );

  const projectFilter = (job) => (option) =>
    option && job?.project && option.id !== job.project.id;

  const isMergeEnabled = useMemo(() => {
    return targetProject && targetProject.id && currentJob?.project.id;
  }, [targetProject, currentJob]);

  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Header className='justify-content-start' closeButton>
        <h3 className='mr-1 green-1'>{text('mergeProjects')}</h3>
        <IconWithTooltip
          icon='information'
          tooltipText={text('mergeProjectsInfo')}
        />
      </Modal.Header>
      <Modal.Body className='p-0 pt-3 pb-3'>
        <p className='mb-3'>
          {text('selectProjectToMerge', {
            projectName: getProjectName(currentJob?.project),
          })}
        </p>
        <ProjectSelect
          className='mb-3'
          filterPredicate={(option) => projectFilter(currentJob)(option)}
          onChange={(option) => setTargetProject(option)}
          isCreateOptionEnabled={false}
          isAdmin={isAdministrator(currentUser.role)}
        />
        <p className='mb-0'>
          {text('mergeProjectNote', {
            projectName: getProjectName(currentJob?.project),
          })}
        </p>
      </Modal.Body>
      <Modal.Footer className='p-0 pb-3'>
        <div className='w-100 d-flex flex-column'>
          <Button
            className='full-width mb-3'
            disabled={!isMergeEnabled}
            onClick={onOk}
          >
            {text('merge')}
          </Button>
          <Button
            className='full-width'
            variant='outline-primary'
            onClick={onHide}
          >
            {text('cancel')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default MergeProjectModal;
