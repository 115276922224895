import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AcceptInvite from '../pages/acceptInvite';
import AcceptQuote from '../pages/acceptQuote/AcceptQuote';
import AccessDenied from '../pages/accessDenied';
import AssignedPaymentStatus from '../pages/billing/AssignedPaymentStatus';
import MembershipManagement from '../pages/billing/Membership/MembershipManagement';
import MembershipStatusSuccess from '../pages/billing/Membership/MembershipStatusSuccess';
import Payment from '../pages/billing/Payment';
import PaymentStatus from '../pages/billing/PaymentStatus';
import CreatePassword from '../pages/createPassword';
import Dashboard from '../pages/dashboard';
import FileManager from '../pages/fileManager/FileManager';
import ForgotPassword from '../pages/forgotPassword';
import Logout from '../pages/logout';
import MapView from '../pages/mapView';
import UserProfile from '../pages/profile/UserProfile';
import ApproveBookmarkPage from '../pages/project/approve';
import DenyBookmarkPage from '../pages/project/deny';
import ResetPassword from '../pages/resetPassword';
import Signup from '../pages/signup';

import OpenBookmarkPage from '../pages/openBookmark';
import RequestAccessPage from '../pages/requestAccess';
import ViewerRedirect from '../pages/viewerRedirect';
import LoggedOutRoute from '../utilities/loggedOutRoute';
import PrivateRoute from '../utilities/privateRoute';
import routes from './constants';
import StatusPage from '../pages/statusPage';

const Routes = () => {
  return (
    <div className='App-Container'>
      <Switch>
        {/* always active */}
        <Route exact path={routes.logout} component={Logout} />
        {/* must be logged OUT */}
        <LoggedOutRoute
          exact
          path={[routes.login, routes.signUp]}
          component={Signup}
        />
        <LoggedOutRoute
          exact
          path={routes.password.create}
          component={CreatePassword}
        />
        <LoggedOutRoute
          exact
          path={routes.password.reset}
          component={ResetPassword}
        />
        <LoggedOutRoute
          exact
          path={routes.password.forgot}
          component={ForgotPassword}
        />
        <Route exact path={routes.quote.accept()} component={AcceptQuote} />
        {/* authentication required */}
        <Route
          exact
          path={routes.home}
          render={({ location }) => (
            <Redirect
              from={routes.home}
              to={{ pathname: routes.dashboard, search: location.search }}
            />
          )}
        />
        <PrivateRoute exact path={routes.dashboard} component={Dashboard} />
        <PrivateRoute
          exact
          path={[routes.view3D.project(), routes.view3D.root]}
          component={ViewerRedirect}
        />
        <PrivateRoute
          exact
          allowAnonymous
          path={routes.order.root}
          component={MapView}
        />
        <PrivateRoute
          exact
          allowAnonymous
          path={routes.order.job()}
          component={MapView}
        />
        <PrivateRoute exact path={routes.payment.job()} component={Payment} />
        <PrivateRoute
          exact
          path={routes.payment.status()}
          component={PaymentStatus}
        />
        <PrivateRoute
          exact
          path={[routes.fileManager.viewProject(), routes.fileManager.root]}
          component={FileManager}
        />
        <Route
          exact
          path={routes.project.acceptInvite}
          component={AcceptInvite}
        />
        <PrivateRoute
          exact
          path={routes.user.profile}
          component={UserProfile}
        />
        <PrivateRoute
          exact
          path={routes.user.membershipManagement}
          component={MembershipManagement}
        />
        <PrivateRoute
          exact
          path={routes.user.membershipChangeSuccess}
          component={MembershipStatusSuccess}
        />
        <PrivateRoute
          exact
          path={routes.payment.assignedStatus()}
          component={AssignedPaymentStatus}
        />
        <PrivateRoute
          exact
          path={routes.requestAccess.path}
          component={RequestAccessPage}
        />
        <PrivateRoute
          exact
          path={routes.openBookmark.path}
          component={OpenBookmarkPage}
        />
        <PrivateRoute
          exact
          path={routes.projectUser.approve.path}
          component={ApproveBookmarkPage}
        />
        <PrivateRoute
          exact
          path={routes.projectUser.deny.path}
          component={DenyBookmarkPage}
        />
        <PrivateRoute
          exact
          path={routes.statusPage.path}
          component={StatusPage}
        />
        {/* fallback */}
        <Route exact path={routes.accessDenied} component={AccessDenied} />
      </Switch>
    </div>
  );
};

export default Routes;
