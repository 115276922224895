const { createIntl, createIntlCache } = require('@formatjs/intl');

const messages = require('./locale_en');

const cache = createIntlCache();
const intl = createIntl(
  {
    locale: 'en',
    defaultLocale: 'en',
    messages,
  },
  cache
);

const text = (id, values) =>
  intl.formatMessage({ id, defaultMessage: id }, values);

// Number should be
export const formatCurrency = (
  amount,
  currency,
  scale_factor,
  options,
  spaced = false
) => {
  if (!isNaN(amount) && currency && scale_factor) {
    const result = intl.formatNumber(amount / scale_factor, {
      style: 'currency',
      currency,
      ...options,
    });
    return !spaced
      ? result
      : result.replace(/^(\D+)/, '$1 ').replace(/\s+/, ' ');
  } else {
    return '';
  }
};

export const formatArea = (number) =>
  intl.formatNumber(number, { style: 'unit', unit: 'meter' });

export const formatSqmArea = (area) => `${formatArea(area)}²`;

export default text;
