import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { TOOLTIP } from '../../pages/constants';

import Icon from './Icon';

const IconWithTooltip = ({ tooltipText, icon }) => {
  return (
    <OverlayTrigger
      placement='right'
      delay={{ show: TOOLTIP.SHOW_DELAY, hide: TOOLTIP.HIDE_DELAY }}
      overlay={<Tooltip>{tooltipText}</Tooltip>}
    >
      <Icon className='IconWithTooltip' icon={icon} />
    </OverlayTrigger>
  );
};

export default IconWithTooltip;
