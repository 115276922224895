/**
 * Why? Sometimes we need custom tooltips, and react-bootstrap OverlayTrigger is unstable
 *  given that it relies on onMouseOver/onMouseLeave event handlers that are not called consistently.
 * Floating UI has been found to work better in practice.
 *
 * Usage (controlled):
 *  <Tooltip.Provider open={open} setOpen={setOpen}>
 *    <Tooltip.Trigger onClick={() => setOpen((v) => !v)}>
 *      ...
 *    </Tooltip.Trigger>
 *    <Tooltip.Content>
 *      ...
 *    </Tooltip.Content>
 *  </Tooltip.Provider>
 *
 */

import {
  FloatingArrow,
  FloatingFocusManager,
  FloatingPortal,
  useMergeRefs,
} from '@floating-ui/react';
import T from 'prop-types';
import React, { createContext, forwardRef, useContext } from 'react';

import useTooltip from '../../hooks/useTooltip';

const TooltipContext = createContext(null);

const useTooltipContext = () => {
  const context = useContext(TooltipContext);
  if (!context) {
    throw new Error('not wrapped in TooltipContext.Provider');
  }
  return context;
};

const TooltipProvider = ({ children, ...options }) => {
  const tooltip = useTooltip(options);
  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  );
};
TooltipProvider.propTypes = {
  children: T.node.isRequired,
};

const TooltipContent = forwardRef(
  ({ style, withArrow = false, arrowProps = {}, ...props }, propRef) => {
    const context = useTooltipContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);
    if (!context.open) return null;
    const { children, ...floatingProps } = context.getFloatingProps(props);
    return (
      <FloatingPortal>
        <FloatingFocusManager context={context.context} modal={false}>
          <div
            ref={ref}
            style={{ ...context.floatingStyles, ...style, zIndex: 1001 }}
            {...floatingProps}
          >
            {children}
            {withArrow ? (
              <FloatingArrow
                ref={context.arrowRef}
                context={context.context}
                {...arrowProps}
              />
            ) : null}
          </div>
        </FloatingFocusManager>
      </FloatingPortal>
    );
  }
);
TooltipContent.displayName = 'TooltipContent';
TooltipContent.propTypes = {
  rootRef: T.object,
  style: T.object,
  withArrow: T.bool,
  arrowProps: T.object,
};

const TooltipTrigger = forwardRef(({ children, ...props }, propRef) => {
  const context = useTooltipContext();
  const childrenRef = children.ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  return React.cloneElement(
    children,
    context.getReferenceProps({
      ref,
      ...props,
      ...children.props,
      'data-state': context.open ? 'open' : 'closed',
    })
  );
});
TooltipTrigger.displayName = 'TooltipTrigger';
TooltipTrigger.propTypes = {
  children: T.node.isRequired,
};

const Tooltip = {
  Provider: TooltipProvider,
  Content: TooltipContent,
  Trigger: TooltipTrigger,
};

export default Tooltip;
