import React from 'react';
import cn from 'classnames';
import T from 'prop-types';
import { ReactSVG } from 'react-svg';

const AddOnIcon = (props) => {
  const { icon, isCornerRound, className, ...rest } = props;
  return (
    icon && (
      <ReactSVG
        className={cn('AddOnIcon', icon, {
          [className]: className !== null,
          'rounded-corners': isCornerRound,
        })}
        src={`/public/img/${icon}.svg`}
        {...rest}
      />
    )
  );
};

AddOnIcon.propTypes = {
  icon: T.string.isRequired,
  className: T.string,
  isCornerRound: T.bool,
};
AddOnIcon.defaultProps = {
  className: null,
};

export default AddOnIcon;
