import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../redux/actions';
import text from '../../text';

export const ORDER_FAILURE_MODAL = 'ORDER_FAILURE_MODAL';

const OrderFailureModal = (props) => {
  const dispatch = useDispatch();

  const onHide = () => {
    dispatch(hideModal(ORDER_FAILURE_MODAL));
    props.onHide && props.onHide();
  };

  return (
    <Modal centered {...props} onHide={onHide}>
      <Modal.Body>
        <div>
          <h2>{text('orderCouldNotPlaced')}</h2>
          <p className='text-center'>{text('orderCouldNotPlacedAtTime')}</p>
          <p className='text-center'>{text('savedAsDraftTryAgain')}</p>
          <div className='form-footer'>
            <Button
              type='button'
              className='metromap-modal-button'
              onClick={onHide}
            >
              {text('returnToMap')}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OrderFailureModal;
