import isEmpty from 'lodash/fp/isEmpty';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import WhiteContainer from '../../../components/containers/WhiteContainer';
import List from '../../../components/list/List';
import Loading from '../../../components/loading/Loading';
import { MapViewContext } from '../../../components/mapView/mapViewContext';
import { getJob } from '../../../redux/actions';
import text, { formatCurrency } from '../../../text';
import { isMembershipPriceId } from '../../../utilities/membership';
import { userHasActiveMembership } from '../../../utilities/user';
import ChargeItem from './ChargeItem';
import { PaymentContext } from '../PaymentContext';

const ProductInvoice = ({ jobId, numOrder }) => {
  const dispatch = useDispatch();
  const {
    state: { buyNowData },
  } = useContext(MapViewContext);
  const { job, membershipPriceId, currentUser, isGettingJob, isQuotingJob } =
    useSelector((state) => ({
      job: state.jobsReducer.job,
      membershipPriceId: state.order.membershipPriceId,
      currentUser: state.profileReducer.userProfile,
      isGettingJob: state.jobsReducer.isGettingJob,
      isQuotingJob: state.jobsReducer.isQuotingJob,
    }));
  const {
    states: { cardHeading },
  } = useContext(PaymentContext);

  useEffect(() => {
    (async () => {
      if (jobId && !isGettingJob && isEmpty(job)) {
        dispatch(getJob(jobId));
      }
    })();
  }, [dispatch, isGettingJob, job, jobId]);

  if (!job) {
    return null;
  }

  const quote = job.quote;

  if (buyNowData.length <= 0) {
    return null;
  }

  return (
    <WhiteContainer className='Invoice position-relative'>
      {isQuotingJob ? (
        <div className='position-absolute overlay-loader-container'>
          <Loading />
        </div>
      ) : null}
      {!quote ? null : (
        <>
          <h6 className='green-1 font-weight--600 mb-0'>
            <span className='mr-1'>{numOrder}.</span>{' '}
            <span>
              {text('_invoiceType', {
                prefix: cardHeading,
                type: text('Data in the Database'),
              })}
            </span>
          </h6>
          <div style={{ paddingLeft: '20px', paddingRight: '16px' }}>
            <List>
              {buyNowData.map(
                (
                  { discounts, details, price, fees, member_discounts_preview },
                  i
                ) => (
                  <ChargeItem
                    key={i}
                    displayName={text(`${details.category_name}3D`)}
                    discounts={
                      isMembershipPriceId(membershipPriceId)
                        ? member_discounts_preview
                        : discounts
                    }
                    details={details}
                    price={price}
                    fees={fees}
                    quote={quote}
                    isIndividualDiscountVisible={
                      !userHasActiveMembership(currentUser) &&
                      !isMembershipPriceId(membershipPriceId)
                    }
                  />
                )
              )}

              {!isEmpty(quote.discounts) &&
                quote.discounts.map((discount, i) => (
                  <List.Item
                    className='discount font-pink pl-3 pt-1 font-italic'
                    key={i}
                    description={discount.display_name}
                    value={`—${formatCurrency(
                      discount.price?.subtotal,
                      quote.currency.name,
                      quote.currency.scale_factor,
                      {},
                      true
                    )}`}
                  />
                ))}
            </List>
          </div>
        </>
      )}
    </WhiteContainer>
  );
};

ProductInvoice.propTypes = {
  jobId: PropTypes.number,
  numOrder: PropTypes.number,
};

export default ProductInvoice;
