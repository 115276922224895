import React from 'react';
import cn from 'classnames';
import T from 'prop-types';
import { ReactSVG } from 'react-svg';

const Icon = (props) => {
  const { icon, isCornerRound, className, ...rest } = props;
  return icon ? (
    <ReactSVG
      className={cn('Icon', icon, {
        [className]: className !== null,
        'rounded-corners': isCornerRound,
      })}
      src={`/public/img/${icon}.svg`}
      {...rest}
    />
  ) : null;
};

Icon.propTypes = {
  icon: T.string.isRequired,
  className: T.string,
  isCornerRound: T.bool,
};
Icon.defaultProps = {
  className: null,
};

export default Icon;
