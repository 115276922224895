import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Wizard } from 'react-use-wizard';
import { START_TRIAL_MODAL } from '../../../../components/modal/StartTrialModal/StartTrialModal';
import { getUserProfile, showModal } from '../../../../redux/actions';
import { client } from '../../../../utilities/api';
import { isImpersonating } from '../../../../utilities/storage';
import {
  isUserAnonymous,
  userHasValidMembership,
} from '../../../../utilities/user';
import { onboardingConstants } from './constants';
import OtherProfessionForm from './OtherProfessionForm';
import Profession from './Profession';
import { OnboardingProvider, useOnboarding } from './reducer';
import Welcome from './Welcome';

const Onboarding = () => {
  const [, onboardingDispatch] = useOnboarding();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const { userProfile, userProfileState } = useSelector((state) => ({
    userProfile: state.profileReducer.userProfile,
    userProfileState: state.profileReducer.userProfileState,
  }));

  const isUserLoggedIn = useSelector(
    (state) => state.profileReducer.isUserLoggedIn
  );

  const hasOnboarded = userProfile.full_name && userProfile.profession;

  useEffect(() => {
    if (isUserLoggedIn && !hasOnboarded && !isUserAnonymous(userProfile)) {
      if (userProfile?.full_name) {
        onboardingDispatch({
          type: onboardingConstants.SET_FULL_NAME,
          payload: userProfile.full_name,
        });
      }

      if (!isImpersonating()) {
        setOpen(true);
      }
    }
  }, [
    userProfile,
    isUserLoggedIn,
    hasOnboarded,
    userProfileState,
    onboardingDispatch,
  ]);

  const onSubmit = async (userDetails) => {
    try {
      await client.onboardUser(
        {
          fullName: userDetails.fullName,
          phoneNumber: userDetails.phoneNumber,
          profession: userDetails.profession,
          isMarketingEnabled: userDetails.isMarketingEnabled,
        },
        process.env.STRIPE_ONBOARD_COUPON_ID // always grant New Customer Discount
      );

      await dispatch(getUserProfile());

      setOpen(false);
      if (!userHasValidMembership(userProfile)) {
        dispatch(showModal(START_TRIAL_MODAL));
      }
    } catch {
      setOpen(false);
    }
  };

  const onHide = () => {
    setOpen(false);
  };

  return (
    <Modal
      id='onboarding-modal'
      show={open}
      onHide={onHide}
      backdrop='static'
      keyboard={false}
      centered
    >
      <Wizard>
        <Welcome />
        <Profession onSubmit={onSubmit} />
        <OtherProfessionForm onSubmit={onSubmit} />
      </Wizard>
    </Modal>
  );
};

const WithOnboardingProvider = () => (
  <OnboardingProvider>
    <Onboarding />
  </OnboardingProvider>
);

export default WithOnboardingProvider;
