import { captureError } from './error';

export const downloadFile = async (dataUrl, name = null) => {
  return new Promise((resolve, reject) => {
    try {
      const link = document.createElement('a');
      if (name) {
        link.download = name;
      } else {
        link.download = true;
      }
      link.href = dataUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      resolve();
    } catch (error) {
      captureError(`Error on downloadFile: ${error}`);
      reject(error);
    }
  });
};
