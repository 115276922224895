import { useFormik } from 'formik';
import _ from 'lodash/fp';
import T from 'prop-types';
import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { MEMBERSHIP } from '../../constants/membership';
import usePromiseWrapper from '../../hooks/usePromiseWrapper';
import { alertError } from '../../redux/actions';
import text from '../../text';
import { client } from '../../utilities/api';
import assert from '../../utilities/assert';
import { captureError } from '../../utilities/error';
import {
  calculateRemainderDays,
  calculateTrialLengthDays,
} from '../../utilities/membership';
import { getUserMembership } from '../../utilities/user';
import { Button } from '../button';
import { Control } from '../form';

export const GIVE_USER_TRIAL_MODAL = 'GIVE_USER_TRIAL_MODAL';

const GiveUserTrialModal = ({ user, show, onHide, ...rest }) => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: { days: '' },
    validate: ({ days }) => {
      let errors = {};
      days = days.trim();
      if (days === '') {
        errors.days = 'Required';
      } else if (!/^\d+$/.test(days)) {
        errors.days = 'Not a valid positive integer';
      } else {
        const asInt = parseInt(days, 10);
        if (isNaN(asInt)) {
          throw new Error(`Could not parseInt: ${days}`);
        }
        // coordinate with backend
        if (asInt < 1 || asInt > 100) {
          errors.days = 'Please enter a value between 1 and 100';
        }
      }
      return errors;
    },
    onSubmit: async ({ days }, { setSubmitting }) => {
      try {
        await addDaysToTrial(days);
        onHide && onHide();
      } catch (err) {
        captureError(err);
        dispatch(alertError('Failed to add days to trial'));
      } finally {
        setSubmitting(false);
      }
    },
  });
  const { run: addDaysToTrial } = usePromiseWrapper(
    async (days) => {
      assert(!_.isNil(user?.id));
      const { data } = await client.giveUserTrial(user.id, {
        priceId: MEMBERSHIP.MONTHLY.PRICE_ID,
        days,
      });
      return data;
    },
    [user?.id] // keep the ?: modal props can be undefined
  );
  const daysLeftOfTrial = useMemo(() => {
    const membership = getUserMembership(user);
    if (!membership || membership.status !== 'trialing') return null;
    return calculateRemainderDays(membership);
  }, [user]);
  const trialLengthInDays = useMemo(() => {
    const membership = getUserMembership(user);
    if (!membership || membership.status !== 'trialing') return null;
    return calculateTrialLengthDays(membership);
  }, [user]);
  return (
    <Modal centered scrollable show={show} onHide={onHide} {...rest}>
      <Modal.Header className='align-end' closeButton>
        <div>
          <h3 className='secondary'>{text('addTrial')}</h3>
        </div>
      </Modal.Header>
      <p>
        {user &&
          (!_.isNil(daysLeftOfTrial)
            ? `${user.email} has ${daysLeftOfTrial} days left of ${trialLengthInDays}-day trial.`
            : `${user.email} not yet on a trial.`)}
      </p>
      <form onSubmit={formik.handleSubmit}>
        <Control
          label={text('days')}
          as='input'
          type='text'
          name='days'
          isFailed={formik.touched.days && !!formik.errors.days}
          value={formik.values.days}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          validationError={formik.touched.days && formik.errors.days}
          autoComplete='off'
        />
        <Button type='submit' loading={formik.isSubmitting}>
          {text('addTrial')}
        </Button>
      </form>
    </Modal>
  );
};

GiveUserTrialModal.propTypes = {
  user: T.object,
  show: T.bool.isRequired,
  onHide: T.func,
};

export default GiveUserTrialModal;
