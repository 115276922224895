import React from 'react';
import { useDispatch } from 'react-redux';
import { listProjectActivities } from '../../../redux/actions/activity';
import { hideModal } from '../../../redux/actions/modal';
import text from '../../../text';
import { Modal } from 'react-bootstrap';
import { UploadAssetForm } from './form';

export const UPLOAD_ASSET_MODAL = 'UPLOAD_ASSET_MODAL';

const UploadAssetModal = ({ show, projectId }) => {
  const dispatch = useDispatch();

  const onHide = () => dispatch(hideModal(UPLOAD_ASSET_MODAL));

  const onSubmit = () => {
    dispatch(listProjectActivities(projectId));
    onHide();
  };

  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <h3 className='secondary'>{text('uploadFiles')}</h3>
      </Modal.Header>
      <UploadAssetForm
        projectId={projectId}
        onSubmit={onSubmit}
        onCancel={onHide}
      />
    </Modal>
  );
};

export default UploadAssetModal;
