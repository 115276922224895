import React from 'react';
import { Tabs as BSTabs, Tab as BSTab } from 'react-bootstrap';

const Tabs = ({ defaultActiveKey, onSelect, children }) => (
  <div className='Tabs'>
    <BSTabs
      defaultActiveKey={defaultActiveKey}
      {...(!!onSelect && { onSelect })}
    >
      {children}
    </BSTabs>
  </div>
);

// This is a wrapper just in case we need to do changes later down the line
export const Tab = ({ children, ...restProps }) => (
  <BSTab {...restProps}>{children}</BSTab>
);

export default Tabs;
