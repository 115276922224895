import React from 'react';
import { Modal } from 'react-bootstrap';
import { useBoolean } from 'react-use';
import Button from '../../../../components/button/Button';
import Control from '../../../../components/form/Control';
import text from '../../../../text';
import { onboardingConstants } from './constants';
import { useOnboarding } from './reducer';

const OtherProfessionForm = ({ onSubmit }) => {
  const [{ fullName, phoneNumber, profession, isMarketingEnabled }, dispatch] =
    useOnboarding();
  const [loading, setLoading] = useBoolean(false);

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit({ profession, fullName, phoneNumber, isMarketingEnabled });
    setLoading(false);
  };

  const handleChange = (e) =>
    dispatch({
      type: onboardingConstants.TOGGLE_PROFESSION,
      payload: e.target.value,
    });

  return (
    <>
      <Modal.Header>
        <h2 className='text-center w-100'>{text('great')}!</h2>
      </Modal.Header>
      <Modal.Body>
        <p className='text-center'>{text('askOccupation')}</p>
        <Control
          as='input'
          type='text'
          name='profession'
          value={profession || ''}
          onChange={(e) => handleChange(e)}
          placeholder={text('enterOccupation')}
          autoComplete='off'
        />
        <Button
          className='full-width'
          onClick={handleSubmit}
          disabled={!profession || loading}
          loading={loading}
        >
          {text('goToMyDashboard')}
        </Button>
      </Modal.Body>
    </>
  );
};

export default OtherProfessionForm;
