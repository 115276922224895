function clamp(num, min, max) {
  return Math.min(Math.max(num, min), max);
}

const roundToNearest = (num, amount = 100) => Math.round(num / amount) * amount;

const stripOffDiscount = (amount, percentage) => {
  const subtotal = amount / (1 + percentage / 100);
  const gst = amount - subtotal;
  return {
    gst,
    subtotal,
  };
};

export { clamp, roundToNearest, stripOffDiscount };
