import { useFormikContext } from 'formik';
import React from 'react';
import text from '../../text';
import FormContainer from '../../components/containers/FormContainer';

const ForgotPasswordSuccess = () => {
  const { values } = useFormikContext();

  return (
    <FormContainer>
        <FormContainer.SecondaryHeader>
          <h2>{text('passwordResetSent')}</h2>
        </FormContainer.SecondaryHeader>
      <FormContainer.Body>
        <FormContainer.PrimaryHeader>
          <h2>{text('passwordResetSent')}</h2>
        </FormContainer.PrimaryHeader>
        <p className='text-center mb-2'>{text('checkInbox')}</p>
        <p className='text-center font-weight-bold'>{values.email}</p>
      </FormContainer.Body>
    </FormContainer>
  );
};

export default ForgotPasswordSuccess;
