import React from 'react';
import { TOUR_DONE_MODAL } from '../../../../../components/modal/TourDoneModal';
import { showModal } from '../../../../../redux/actions';
import { store } from '../../../../../utilities/store';
import { StepContent } from './TourContent';
import { hightlightedAreaStyling, popoverCustomDefaultStyling } from './config';
import text from '../../../../../text';

export const TOUR_3D_VIEWER = [
  {
    selector: '.p__sidebar-list',
    content: () => (
      <StepContent
        title={text('tour3dViewerStep1Title')}
        description={text('tour3dViewerStep1')}
      />
    ),
    position: 'right',
    styles: {
      popover: (base) => ({
        ...popoverCustomDefaultStyling(base),
        marginLeft: '24px',
        top: '-34px',
      }),
      highlightedArea: (base, state) => hightlightedAreaStyling(base, state),
    },
    customProps: {
      caret: 'left',
    },
  },
  {
    selector: '.button-list li:nth-child(2)',
    content: () => (
      <StepContent
        title={text('tour3dViewerStep2Title')}
        description={text('tour3dViewerStep2')}
        externalLinkHelp={
          'https://help.pointerra.io/articles/30882-using-the-cropping-tools'
        }
      />
    ),
    position: 'right',
    styles: {
      popover: (base) => ({
        ...popoverCustomDefaultStyling(base),
        marginLeft: '16px',
        top: '-62px',
      }),
      highlightedArea: (base, state) => hightlightedAreaStyling(base, state),
    },
    customProps: {
      caret: 'left',
    },
  },
  {
    selector: '.button-list li:nth-child(3)',
    content: () => (
      <StepContent
        title={text('tour3dViewerStep3Title')}
        description={text('tour3dViewerStep3')}
        externalLinkHelp={
          'https://help.pointerra.io/articles/40390-cropping-points-with-the-vertical-cropping-tool'
        }
      />
    ),
    position: 'right',
    styles: {
      popover: (base) => ({
        ...popoverCustomDefaultStyling(base),
        marginLeft: '16px',
        top: '-72px',
      }),
      highlightedArea: (base, state) => hightlightedAreaStyling(base, state),
    },
    customProps: {
      caret: 'left',
    },
  },
  {
    selector: '.button-list li:nth-child(4)',
    content: () => (
      <StepContent
        title={text('tour3dViewerStep4Title')}
        description={text('tour3dViewerStep4')}
        externalLinkHelp={
          'https://help.pointerra.io/articles/40391-using-the-cross-section-tool'
        }
      />
    ),
    position: 'right',
    styles: {
      popover: (base) => ({
        ...popoverCustomDefaultStyling(base),
        marginLeft: '16px',
        top: '-62px',
      }),
      highlightedArea: (base, state) => hightlightedAreaStyling(base, state),
    },
    customProps: {
      caret: 'left',
    },
  },
  {
    selector: '.button-list li:nth-child(5)',
    content: () => (
      <StepContent title={text('tour3dViewerStep5Title')} description={''}>
        <p className='mb-3'>{text('tour3dViewerStep5_1')}</p>
        <p className='mb-0'>{text('tour3dViewerStep5_2')}</p>(
        <a
          href='https://help.pointerra.io/articles/40139-using-the-distance-measurement-tool'
          target='_blank'
          rel='noopener noreferrer'
        >
          {text('learnMore')}
        </a>
        )
      </StepContent>
    ),
    position: 'right',
    styles: {
      popover: (base) => ({
        ...popoverCustomDefaultStyling(base),
        marginLeft: '16px',
        top: '-100px',
      }),
      highlightedArea: (base, state) => hightlightedAreaStyling(base, state),
    },
    customProps: {
      caret: 'left',
      callback: {
        next: () => {
          store.dispatch(showModal(TOUR_DONE_MODAL));
        },
      },
    },
  },
];
