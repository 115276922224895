import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { START_TRIAL_MODAL } from '../components/modal/StartTrialModal/StartTrialModal';
import { showModal } from '../redux/actions';
import { profileSelectors } from '../redux/selectors/profile';
import routes from '../routes/constants';

const useMembershipButton = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const canUserStartTrial = useSelector(profileSelectors.getCanUserStartTrial);
  const canUserUpgrade = useSelector(profileSelectors.getCanUserUpgrade);

  const handleMembershipClick = useCallback(
    (state) =>
      canUserStartTrial
        ? dispatch(showModal(START_TRIAL_MODAL))
        : canUserUpgrade
        ? history.push({
            pathname: `${routes.user.membershipManagement}`,
            ...(state && {
              state: { ...state },
            }),
          })
        : () => {},
    [canUserStartTrial, canUserUpgrade, dispatch, history]
  );

  return { handleMembershipClick };
};

export default useMembershipButton;
