import classNames from 'classnames';
import map from 'lodash/map';
import React, { useCallback, useRef, useState } from 'react';
import {
  PRODUCT_DELIVERY_METHOD,
  PRODUCT_TYPE,
} from '../../../constants/product';
import useEventListener from '../../../hooks/useEventListener';
import text from '../../../text';
import OutlinedText from '../../text/OutlinedText';
import { getAddOnConfig } from '../drawingManager/utilities';
import ProductControlsButton from './ProductControlsButton';

export const PRODUCT_CONTROLS_ID = 'ProductControls-wrapper';

const productsByDeliveryMethod = {
  draft: [
    {
      productType: PRODUCT_TYPE.UNKNOWN,
    },
  ],
  database: [
    {
      productType: PRODUCT_TYPE.AERIAL,
    },
    {
      productType: PRODUCT_TYPE.STREETSCAPE,
    },
  ],
  capture: [
    {
      productType: PRODUCT_TYPE.DRONE,
    },
    {
      productType: PRODUCT_TYPE.EXTERIOR,
    },
    {
      productType: PRODUCT_TYPE.INTERIOR,
    },
  ],
  addOns: [
    {
      productType: PRODUCT_TYPE.BIM_EXTERIOR,
    },
    {
      productType: PRODUCT_TYPE.BIM_INTERIOR,
    },
    {
      productType: PRODUCT_TYPE.TWOD_PLAN_EXTERIOR,
    },
    {
      productType: PRODUCT_TYPE.TWOD_PLAN_INTERIOR,
    },
  ],
};

const ProductControls = () => {
  const [isScrollable, setIsScrollable] = useState(false);
  const productControlsRef = useRef(null);

  const isElementHeightScrollable = (element) => {
    const { clientHeight, scrollHeight } = element;
    return setIsScrollable(scrollHeight > clientHeight);
  };

  const setScrollableOnResize = useCallback(() => {
    if (!productControlsRef.current) return;
    isElementHeightScrollable(productControlsRef.current);
  }, [productControlsRef]);

  useEventListener('resize', setScrollableOnResize);

  return (
    <div
      id={PRODUCT_CONTROLS_ID}
      className={classNames('ProductControls', {
        scrollable: isScrollable,
      })}
      ref={(element) => {
        // necessary to add callback to set ref on load of ProductControl component
        // especially on smaller screen
        if (!element) return;
        productControlsRef.current = element;
        isElementHeightScrollable(element);
      }}
    >
      {map(productsByDeliveryMethod, (products, deliveryMethod) => (
        <React.Fragment key={deliveryMethod}>
          <div className='position-relative'>
            <OutlinedText
              label={text(PRODUCT_DELIVERY_METHOD[deliveryMethod])}
              svgProps={{
                width: '60px', // best fit width for labels draft, database, ...
              }}
            />
            <div className='product-control-button-group'>
              {map(products, ({ productType }) => {
                const isAddOn = !!getAddOnConfig(productType);
                const withExplanation =
                  productType === PRODUCT_TYPE.UNKNOWN || isAddOn;

                return (
                  <ProductControlsButton
                    key={productType}
                    productType={productType}
                    variant={
                      productType === PRODUCT_TYPE.UNKNOWN
                        ? 'tertiary'
                        : 'default'
                    }
                    withExplanation={withExplanation}
                    deliveryMethod={deliveryMethod}
                  />
                );
              })}
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ProductControls;
