import { useContext, useEffect } from 'react';
import { MapViewContext } from './mapViewContext';

let markers = [];

const clearMarkers = () => markers.forEach((m) => m.setMap(null));

const createLarkiMarker = (place, map) => {
  const icon = {
    url: '/public/img/map-marker.svg',
    anchor: new google.maps.Point(17, 34),
    scaledSize: new google.maps.Size(50, 50),
  };
  const marker = new google.maps.Marker({
    position: place?.geometry.location,
    map,
    title: '',
    icon,
  });
  return marker;
};

const useLarkiMarker = () => {
  const { state } = useContext(MapViewContext);

  // Place LARKI marker
  useEffect(() => {
    if (
      state.mapPlace &&
      state.map &&
      state.googleMapsScriptStatus === 'ready'
    ) {
      clearMarkers();
      const marker = createLarkiMarker(state.mapPlace, state.map);
      markers.push(marker);
    }
  }, [state.googleMapsScriptStatus, state.mapPlace, state.map]);
};

export default useLarkiMarker;
