import { noop } from 'lodash/fp';
import React, { useCallback, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { hideModal } from '../../redux/actions';
import text from '../../text';
import { Button } from '../button';
import Icon from '../icon/Icon';
import { MapViewContext } from '../mapView/mapViewContext';
import { uniq } from 'lodash';

export const DELETE_SHAPES_MODAL = 'DELETE_SHAPES_MODAL';

/* eslint-disable react/prop-types */
const DeleteShapesModal = ({
  selectionsToDelete,
  onOk = noop,
  show,
  associatedAddOns = [],
}) => {
  const dispatch = useDispatch();
  const { actions, state } = useContext(MapViewContext);

  const handleHide = () => dispatch(hideModal(DELETE_SHAPES_MODAL));

  const handleDelete = useCallback(() => {
    actions.deleteSelections(selectionsToDelete);
    onOk();
    handleHide();
  }, [selectionsToDelete, state.selections]);

  const associatedAddOnDisplayNames = uniq([
    ...associatedAddOns.map(({ display_name }) => display_name),
  ]).join(', ');

  return (
    <Modal
      centered
      className='DeleteShapesModal'
      onHide={handleHide}
      show={show}
    >
      <Modal.Header className='centered' closeButton>
        <Icon icon='circled-exclamation' />
      </Modal.Header>
      <Modal.Body className='centered'>
        <h3 className='mb-4'>{text('deleteShapesWarning')}</h3>
        {associatedAddOnDisplayNames && (
          <p className='mb-4 font-16 font-weight-bold'>
            {text('deleteShapesWarningAddOns', {
              addOns: associatedAddOnDisplayNames,
            })}
          </p>
        )}
        <p className='m-0'>{text('deleteShapesMessage')}</p>
      </Modal.Body>
      <Modal.Footer className='flex-column justify-content-center'>
        <Button autoFocus onClick={handleDelete}>
          {text('yesDeleteShapes')}
        </Button>
        <Button onClick={handleHide} variant='link'>
          {text('noCancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteShapesModal;
