import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export const initializeSentry = () => {
  Sentry.init({
    dsn: process.env.SENTRY_FRONTEND_DSN,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: ['larki-frontend'],
        behaviour: 'apply-tag-if-contains-third-party-frames',
      }),
    ],
    tracePropagationTargets: ['*'],
    enabled: !process.env.DISABLE_SENTRY,
    tracesSampleRate: 1.0, // send all traces
    environment: process.env.SENTRY_ENVIRONMENT,
  });
};
