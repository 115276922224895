import classnames from 'classnames';
import T from 'prop-types';
import React from 'react';

import Img from '../../components/img/Img';

const UserAvatar = (props) => {
  const { profileImage, className } = props;
  return (
    <Img
      className={classnames('UserAvatar', { [className]: className })}
      img={profileImage || '/public/img/user-silhouette.png'}
      isUrl
      roundedCircle
      crossOrigin='true'
    />
  );
};

UserAvatar.propTypes = { profileImage: T.string };

export default UserAvatar;
