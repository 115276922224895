/* eslint-disable react/prop-types */

import React from 'react';
import { Badge } from 'react-bootstrap';
import toLower from 'lodash/toLower';

import Icon from '../../icon/Icon';
import text from '../../../text';

const IndicatorSeparator = ({ inputValue, ...props }) => {
  const existingOption = props.options.find(
    (option) =>
      option.name &&
      toLower(option.name) === toLower(inputValue) &&
      !option.__isNew__
  );
  return (
    <>
      {inputValue && (
        <Badge
          className='inverted center-content mr-2'
          variant={existingOption ? 'warning' : 'info'}
        >
          <Icon
            className='mr-1'
            icon={existingOption ? 'triangular-exclamation' : 'information'}
          />
          {text(existingOption ? 'similar' : 'new')}
        </Badge>
      )}
      |
    </>
  );
};

export default IndicatorSeparator;
