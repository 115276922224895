import React from 'react';
import text from '../../text';
import Img from '../../components/img/Img';
import Header from './Header';

const SideContent = () => {
  return (
    <section className='SideContent'>
        <Header />
      <div>
        <p>{text('signUpOrLoginToGetAccess')}</p>
      </div>
      <Img className='laptop-image' img='laptop-larki-app' />
      <div>
        <p>{text('howDoYouMeasureUp')}</p>
      </div>
    </section>
  );
};

export default SideContent;
