import React from 'react';
import { useSearchParam } from 'react-use';
import classnames from 'classnames';

import SearchAddressInput from '../../components/searchAddress/SearchAddressInput';
import routes from '../../routes/constants';
import { larkiApiNoAuthentication } from '../../utilities/api';

const SearchProducts = () => {
  const variant = useSearchParam('variant');

  const onPlaceChange = async (place) => {
    const { data } = await larkiApiNoAuthentication.post('/user/anonymous');
    const url = `${process.env.LARKI_APP_URL}${routes.order.root}?siteAddress=${place.formatted_address}&token=${data}`;

      window.parent.postMessage({ id: 'place_search', url }, '*');

      // Avoid opening new tab in the mean time
      // setTimeout(() => {
      //   const link = document.createElement('a');
      //   link.href = url;
      //   link.target = '_blank';
      //   document.body.appendChild(link);
      //   link.click();
      //   document.body.removeChild(link);
      // });

  };

  return (
    <div className={classnames('SearchProducts', { [variant]: variant })}>
      <div className='searchSection'>
        <SearchAddressInput onChange={onPlaceChange} />
      </div>
    </div>
  );
};

export default SearchProducts;
