import {
  autoUpdate,
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import T from 'prop-types';
import React, {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useAsyncFn, useCopyToClipboard } from 'react-use';

import classNames from 'classnames';
import _ from 'lodash/fp';
import { Badge } from 'react-bootstrap';
import Button from '../../../../../components/button/Button';
import Icon from '../../../../../components/icon/Icon';
import useBreakpoint from '../../../../../hooks/useBreakpoint';
import { alertError } from '../../../../../redux/actions';
import text from '../../../../../text';
import { client } from '../../../../../utilities/api';
import { captureError } from '../../../../../utilities/error';

const ShareLinkContext = createContext({});

const ShareLinkProvider = ({
  children,
  createBookmarkState,
  onOpen,
  listRequests,
  listRequestsState,
  getRequestAccessCount,
  getRequestAccessCountState,
  showHomeIcon,
  new3dBookmark,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: (value) => {
      if (value) {
        onOpen();
      }
      setIsOpen(value);
    },
    placement: 'bottom-end',
    middleware: [offset(4)],
    whileElementsMounted: autoUpdate,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);
  const isBottomCard = useBreakpoint(['xs', 'sm', 'md']);
  return (
    <ShareLinkContext.Provider
      value={{
        refs,
        isOpen,
        setIsOpen,
        floatingStyles,
        context,
        getReferenceProps,
        getFloatingProps,
        createBookmarkState,
        listRequests,
        listRequestsState,
        getRequestAccessCount,
        getRequestAccessCountState,
        showHomeIcon,
        isBottomCard,
        new3dBookmark,
      }}
    >
      {children}
    </ShareLinkContext.Provider>
  );
};
ShareLinkProvider.propTypes = {
  children: T.node.isRequired,
  createBookmarkState: T.shape({
    loading: T.bool.isRequired,
  }).isRequired,
  // called when the share link is clicked to **open** the content, not when it is closed
  onOpen: T.func.isRequired,
  listRequests: T.func.isRequired,
  listRequestsState: T.shape({
    loading: T.bool.isRequired,
    value: T.oneOfType([
      T.shape({
        data: T.array.isRequired,
      }),
      T.object,
    ]),
  }),
  getRequestAccessCount: T.func.isRequired,
  getRequestAccessCountState: T.shape({
    loading: T.bool.isRequired,
    value: T.oneOfType([T.number, T.object]),
  }),
  showHomeIcon: T.bool,
  new3dBookmark: T.func,
};

const updateRequestAccess = (status, request) => async () => {
  const resp = await client.updateRequestAccess(
    request.project.id,
    request.user.id,
    status
  );
  return resp.data;
};

const AccessRequestListItem = ({ key, request }) => {
  const { listRequests, getRequestAccessCount } = useContext(ShareLinkContext);
  const [rejectState, reject] = useAsyncFn(
    updateRequestAccess('rejected', request),
    [request]
  );
  const [approveState, approve] = useAsyncFn(
    updateRequestAccess('accepted', request),
    [request]
  );

  const [loading, setLoading] = useState(false);
  const handleUpdate = (updateFn) => async () => {
    try {
      setLoading(true);
      await updateFn();
      await Promise.all([listRequests(), getRequestAccessCount()]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <li key={key}>
      <h6>Access Request</h6>
      <p>
        {text('hasRequestedAccess', {
          fullName: request.user.full_name,
          email: request.user.email,
        })}
        <br />
        {`${text('projectName')}: ${request.project.name}`}
        <br />
        {`${text('projectAddress')}: ${request.project.jobs?.[0].full_address}`}
      </p>
      <div className='share-link__request-actions'>
        <Button
          variant='danger'
          loading={rejectState.loading}
          onClick={loading ? _.noop : handleUpdate(reject)}
          icon='deny'
        >
          {text('deny')}
        </Button>
        <Button
          variant='primary'
          loading={approveState.loading}
          onClick={loading ? _.noop : handleUpdate(approve)}
          icon='approve'
        >
          {text('grant')}
        </Button>
      </div>
    </li>
  );
};
AccessRequestListItem.propTypes = {
  key: T.string.isRequired,
  request: T.object.isRequired,
};

const ConditionalWrap = ({ condition, wrap, children }) =>
  condition ? wrap(children) : children;

const ShareLinkContent = forwardRef((__, ref) => {
  const {
    floatingStyles,
    getFloatingProps,
    createBookmarkState,
    listRequestsState,
    showHomeIcon,
    isBottomCard,
    new3dBookmark,
  } = useContext(ShareLinkContext);

  const data = listRequestsState.value?.data;

  return (
    <FloatingPortal>
      <ConditionalWrap
        condition={isBottomCard}
        wrap={(children) => (
          <FloatingOverlay
            className={classNames(
              'share-link__overlay',
              isBottomCard && 'enabled'
            )}
          >
            {children}
          </FloatingOverlay>
        )}
      >
        <div
          ref={ref}
          {...(!isBottomCard && { style: { ...floatingStyles, zIndex: 1001 } })}
          {...getFloatingProps()}
          className='share-link__content'
        >
          <section className='share-link__copy-confirmation'>
            <div className='group'>
              {showHomeIcon ? <Icon icon='home' /> : null}
              <span>
                {createBookmarkState.loading
                  ? text('copyingLinkToPage')
                  : createBookmarkState.value && !createBookmarkState.error
                  ? text('linkToPageCopied')
                  : null}
              </span>
            </div>
          </section>
          {new3dBookmark ? (
            <>
              <div className='share-link__divider' />
              <section className='share-link__new-3d-bookmark'>
                <div className='group'>
                  <Icon icon='bookmark' />
                  <span>{text('new3dBookmark')}</span>
                </div>
                <Button variant='primary' onClick={new3dBookmark}>
                  {text('openBookmarks')}
                </Button>
              </section>
            </>
          ) : null}
          {!listRequestsState.error && data?.length > 0 ? (
            <>
              <div className='share-link__divider' />
              <section className='share-link__requests'>
                <ul>
                  {data?.map((request, i) => (
                    <AccessRequestListItem
                      key={`AccessRequest${i}`}
                      request={request}
                    />
                  ))}
                </ul>
              </section>
            </>
          ) : null}
        </div>
      </ConditionalWrap>
    </FloatingPortal>
  );
});
ShareLinkContent.displayName = 'ShareLinkContent';

const ShareLinkButton = forwardRef((__, ref) => {
  const {
    getReferenceProps,
    getRequestAccessCount,
    getRequestAccessCountState,
  } = useContext(ShareLinkContext);

  const count = getRequestAccessCountState?.value;

  useEffect(() => {
    (async () => {
      await getRequestAccessCount();
    })();
  }, [getRequestAccessCount]);

  return (
    <button
      type='button'
      ref={ref}
      className='share-link'
      {...getReferenceProps()}
    >
      <img src='/public/img/share-link.svg' alt='share-link' />
      {count && count > 0 ? (
        <Badge pill variant='danger' className='share-link__badge-count'>
          {count}
        </Badge>
      ) : null}
    </button>
  );
});
ShareLinkButton.displayName = 'ShareLinkButton';

const ShareLinkConsumer = () => {
  const { isOpen, refs, context } = useContext(ShareLinkContext);

  return (
    <>
      <ShareLinkButton ref={refs.setReference} />
      {isOpen ? (
        <FloatingFocusManager context={context} modal={false}>
          <ShareLinkContent ref={refs.setFloating} />
        </FloatingFocusManager>
      ) : null}
    </>
  );
};

const ShareLink = ({
  projectId,
  redirectUrl,
  showHomeIcon = false,
  metadata,
  new3dBookmark,
}) => {
  const dispatch = useDispatch();
  const [, copyToClipboard] = useCopyToClipboard();

  const [createBookmarkState, createBookmark] = useAsyncFn(async () => {
    const resp = await client.createBookmark(projectId, redirectUrl, metadata);
    return resp.data;
  }, [projectId, redirectUrl, metadata]);

  const [listRequestsState, listRequests] = useAsyncFn(async () => {
    const resp = await client.listRequestAccess(projectId);
    return resp.data;
  }, [projectId]);

  const [getRequestAccessCountState, getRequestAccessCount] =
    useAsyncFn(async () => {
      const resp = await client.getRequestAccessCount(projectId);
      return resp.data;
    }, [projectId]);

  const handleShareLinkOpen = async () => {
    // Promise.all so these can happen concurrently
    await Promise.all([
      new Promise((ok, rej) =>
        createBookmark()
          .then(({ url }) => {
            copyToClipboard(url);
            ok();
          })
          .catch(rej)
      ),
      listRequests(),
      getRequestAccessCount(),
    ]);
  };

  useEffect(() => {
    if (createBookmarkState.error) {
      captureError(createBookmarkState.error);
      dispatch(alertError(createBookmarkState.error.message));
    }
  }, [createBookmarkState, dispatch]);

  useEffect(() => {
    if (listRequestsState.error) {
      captureError(listRequestsState.error);
      dispatch(alertError(listRequestsState.error.message));
    }
  }, [listRequestsState, dispatch]);

  useEffect(() => {
    if (getRequestAccessCountState.error) {
      captureError(getRequestAccessCountState.error);
      dispatch(alertError(getRequestAccessCountState.error.message));
    }
  }, [getRequestAccessCountState, dispatch]);

  return (
    <ShareLinkProvider
      createBookmarkState={createBookmarkState}
      listRequests={listRequests}
      listRequestsState={listRequestsState}
      getRequestAccessCount={getRequestAccessCount}
      getRequestAccessCountState={getRequestAccessCountState}
      onOpen={handleShareLinkOpen}
      showHomeIcon={showHomeIcon}
      new3dBookmark={new3dBookmark}
    >
      <ShareLinkConsumer />
    </ShareLinkProvider>
  );
};
ShareLink.propTypes = {
  projectId: T.number.isRequired,
  redirectUrl: T.string.isRequired,
  showHomeIcon: T.bool,
  metadata: T.object,
  new3dBookmark: T.func,
};

export default ShareLink;
