import { default as classNames, default as classnames } from 'classnames';
import { includes } from 'lodash';
import map from 'lodash/fp/map';
import T from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import useBreakpoint from '../../hooks/useBreakpoint';
import { setListJobStatus } from '../../redux/actions';
import text from '../../text';
import { ALL_STATUS, statusLight, statuses } from './consts';
import SearchControl from './filters/searchControl';
import { useProjectListing } from './projectListing';

const ProjectListingControl = ({ statusCounts }) => {
  const dispatch = useDispatch();
  const isBreakpointXL = useBreakpoint(['md', 'lg']);

  const [statusFilters, setStatusFilters] = useState([]);

  const handleAddStatusFilter = (key) => {
    let statusFiltersCopy = [...statusFilters];
    if (statusFiltersCopy.includes(key)) {
      statusFiltersCopy = statusFiltersCopy.filter((filter) => filter !== key);
    } else {
      statusFiltersCopy.push(key);
    }

    setStatusFilters(statusFiltersCopy);
  };

  useEffect(() => {
    dispatch(setListJobStatus(ALL_STATUS));
  }, []);

  useEffect(() => {
    dispatch(
      setListJobStatus(statusFilters.length ? statusFilters : ALL_STATUS)
    );
  }, [statusFilters]);

  return (
    <div className='mb-3' id='ProjectListingControlContainer'>
      <Row>
        <Col sm={12} xl={3} className='search-control-container'>
          <SearchControl />
        </Col>
        <Col
          sm={12}
          xl={9}
          className={classnames('d-flex align-items-center', {
            'px-0 justify-content-end': !isBreakpointXL,
            'justify-content-center': isBreakpointXL,
          })}
        >
          <div id='ProjectListingControl'>
            <ul>
              <li key='statusFilter' className='mx-0 status-filter-label'>
                <span>{text('statusFilter')}</span>
              </li>
              {map(({ name, key, isFilterable }) => {
                if (!isFilterable) return;
                return (
                  <li
                    aria-label={name}
                    key={key}
                    className={classNames('ml-2', key, {
                      'is-active': includes(statusFilters, key),
                    })}
                    onClick={() => {
                      handleAddStatusFilter(key);
                    }}
                  >
                    <span className='orderStatusControl'>
                      <span className={`status-circle ${statusLight[key]}`} />
                      {statusCounts[key] || 0} {name}
                    </span>
                  </li>
                );
              }, statuses)}
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};

ProjectListingControl.propTypes = {
  statusCounts: T.object.isRequired,
};

const ProjectListingControlWithRedux = () => {
  const { statusCounts } = useProjectListing();

  return <ProjectListingControl statusCounts={statusCounts} />;
};

export default ProjectListingControlWithRedux;
