import isEmpty from 'lodash/isEmpty';
import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import T from 'prop-types';
import { useParams } from 'react-router-dom';
import WhiteContainer from '../../../components/containers/WhiteContainer';
import List from '../../../components/list/List';
import Loading from '../../../components/loading/Loading';
import { MapViewContext } from '../../../components/mapView/mapViewContext';
import {
  getMembershipPrices,
  quoteOrder,
  setMembershipPriceId,
} from '../../../redux/actions';
import text, { formatCurrency } from '../../../text';
import { isMembershipPriceId } from '../../../utilities/membership';
import { PaymentContext } from '../PaymentContext';
import { profileSelectors } from '../../../redux/selectors/profile';

const MembershipInvoice = ({ numOrder = 1, isFromOrderWizard = false }) => {
  const { jobId } = useParams();
  const dispatch = useDispatch();
  const {
    state: { buyNowData },
  } = useContext(MapViewContext);
  const activeMembership = useSelector(profileSelectors.getActiveMembership);
  const { membershipPriceId, membershipPrices } = useSelector((state) => ({
    membershipPriceId: state.order.membershipPriceId,
    membershipPrices: state.profileReducer.membershipPrices,
  }));

  const selectedMembership = useMemo(() => {
    if (!membershipPrices.value) return {};

    const targetMembershipId = isMembershipPriceId(membershipPriceId)
      ? membershipPriceId
      : activeMembership?.price_id;

    // if in membership flow and page is refreshed membershipPriceId value is "noMembership" hence rendering an empty LHS card
    // without the current membership subscription and RHS current subscription details is not shown (showing card for "No thanks")
    // Fix: if membershipPriceId = "noMembershipId" and "activeMembership.price_id" then set to membershipPriceId via redux
    if (!isMembershipPriceId(membershipPriceId) && activeMembership?.price_id) {
      dispatch(setMembershipPriceId(activeMembership.price_id));
    }

    return (
      membershipPrices.value.find(
        (membership) => membership.id === targetMembershipId
      ) || {}
    );
  }, [activeMembership, dispatch, membershipPriceId, membershipPrices.value]);

  useEffect(() => {
    if (membershipPriceId) {
      dispatch(getMembershipPrices());
      if (jobId) {
        const includeFreeTierDiscount = !isMembershipPriceId(membershipPriceId);
        dispatch(
          quoteOrder(jobId, undefined, includeFreeTierDiscount, {}, false)
        );
      }
    }
  }, [activeMembership, dispatch, jobId, membershipPriceId]);

  return !isEmpty(selectedMembership) ? (
    <WhiteContainer
      className={classNames('Invoice MembershipInvoice', {
        'mt-4': buyNowData.length <= 0 && isFromOrderWizard,
      })}
    >
      {membershipPrices.isLoading ? (
        <Loading />
      ) : (
        selectedMembership &&
        (!isFromOrderWizard ? (
          <MembershipManagementTemplate
            selectedMembership={selectedMembership}
          />
        ) : (
          <OrderWizardTemplate
            selectedMembership={selectedMembership}
            numOrder={numOrder}
          />
        ))
      )}
    </WhiteContainer>
  ) : null;
};

MembershipInvoice.propTypes = {
  numOrder: T.number,
  isFromOrderWizard: T.bool,
};
export default MembershipInvoice;

// BREAKDOWN TEMPLATES
const MembershipManagementTemplate = ({ selectedMembership }) => {
  const {
    states: { cardHeading },
  } = useContext(PaymentContext);

  if (isEmpty(selectedMembership)) {
    return null;
  }

  return (
    <>
      <h6 className='green-1'>
        1.{' '}
        {text('_invoiceType', {
          prefix: cardHeading,
          type: text('membership'),
        })}
      </h6>
      <List>
        <tbody>
          <List.Item
            description={selectedMembership?.name}
            value={formatCurrency(
              selectedMembership.grand_total,
              selectedMembership.currency.name,
              selectedMembership.currency.scale_factor,
              {},
              true
            )}
          />
        </tbody>
      </List>
      <List>
        <tbody>
          <List.Item
            description={text('subtotal')}
            value={formatCurrency(
              selectedMembership.subtotal,
              selectedMembership.currency.name,
              selectedMembership.currency.scale_factor,
              {},
              true
            )}
          />
          {selectedMembership.taxes.map((tax, i) => (
            <List.Item
              key={i}
              description={tax.display_name}
              value={formatCurrency(
                tax.price.extra,
                selectedMembership.currency.name,
                selectedMembership.currency.scale_factor,
                {},
                true
              )}
            />
          ))}
          <List.Item
            isHighlighted
            description={text('total')}
            value={formatCurrency(
              selectedMembership.grand_total,
              selectedMembership.currency.name,
              selectedMembership.currency.scale_factor,
              {},
              true
            )}
          />
        </tbody>
      </List>
    </>
  );
};
MembershipManagementTemplate.propTypes = {
  selectedMembership: T.object,
};

const OrderWizardTemplate = ({ selectedMembership, numOrder }) => {
  const {
    states: { cardHeading },
  } = useContext(PaymentContext);

  if (isEmpty(selectedMembership)) {
    return null;
  }

  return (
    <>
      <h6 className='green-1 font-weight--600 mb-0'>
        <span className='mr-1'>{numOrder}.</span>{' '}
        <span>
          {text('_invoiceType', {
            prefix: cardHeading,
            type: text('membership'),
          })}
        </span>
      </h6>
      <div style={{ paddingLeft: '20px', paddingRight: '16px' }}>
        <List>
          <tbody>
            <List.Item
              description={selectedMembership?.name}
              value={formatCurrency(
                selectedMembership?.subtotal,
                selectedMembership?.currency.name,
                selectedMembership?.currency.scale_factor,
                {},
                true
              )}
            />
          </tbody>
        </List>
      </div>
    </>
  );
};
OrderWizardTemplate.propTypes = {
  selectedMembership: T.object,
  numOrder: T.number,
};
