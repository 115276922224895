import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loading from '../../components/loading/Loading';
import Timeline from '../../components/timeline/Timeline';
import { ACTIVITY_TYPES } from '../../constants/activity';
import {
  PRODUCT_TYPE,
  SMART_SELECTION_DELIVERY_METHOD,
} from '../../constants/product';
import { listProjectActivities } from '../../redux/actions';
import { generateDefaultActivity } from '../../utilities/activity';
import { isStaff } from '../../utilities/user';
import JobActivity from './JobActivity';

const JobTimeline = () => {
  const dispatch = useDispatch();
  // TODO: confusing, projectId vs currentProject?
  const { projects, currentUser, currentProject, isStaffActivityListVisible } =
    useSelector((state) => ({
      projects: state.activity.projects,
      isStaffActivityListVisible: state.activity.isStaffActivityListVisible,
      currentUser: state.profileReducer.userProfile,
      currentProject: state.project.currentProject,
    }));
  const { projectId } = useParams();
  
  const isLoading = useMemo(
    () => projects[projectId]?.ui?.isLoading,
    [projectId, projects]
  );

  const fetchProjectActivities = useCallback(() => {
    if (projectId) {
      dispatch(listProjectActivities(projectId));
    }
  }, [dispatch, projectId]);

  useEffect(() => {
    fetchProjectActivities();
  }, [fetchProjectActivities]);

  /**
   * Duplicate an order that has metadata && metadata.action === quote
   * Bcoz, RFQ Activity is not created and save, only one activity type="order" and title "Order received".
   */
  const projectActivities = useMemo(() => {
    if (projects[projectId]?.activities) {
      const activities = [...projects[projectId]?.activities];
      if (currentProject.jobs && activities) {
        const rfqActivities = activities
          .filter(
            ({ type, metadata }) =>
              type === ACTIVITY_TYPES.order &&
              !isEmpty(metadata) &&
              metadata.action === 'quote'
          )
          .map((activity) => {
            const job = currentProject.jobs.find(
              ({ id }) => id === activity.job_id
            );
            if (!job) {
              return [];
            }

            const rfqCharges = job.quote?.charges.filter(
              (charge) =>
                charge.type === 'data' &&
                [
                  PRODUCT_TYPE.UNKNOWN,
                  SMART_SELECTION_DELIVERY_METHOD.MANUAL,
                ].includes(charge.details.delivery_method)
            );
            return { ...activity, rfqCharges };
          })
          .filter(({ rfqCharges }) => rfqCharges && rfqCharges.length > 0);

        if (rfqActivities.length === 0) {
          return activities;
        }
        return [...activities, ...rfqActivities];
      }
    }
    return [];
  }, [currentProject.jobs, projectId, projects]);

  const rawActivities = projectActivities?.sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  const activities = isEmpty(rawActivities)
    ? [generateDefaultActivity()]
    : filter(rawActivities, (activity) =>
        !isStaffActivityListVisible ? !isStaff(activity?.visibility) : true
      );

  // TODO: refactor job to project timeline and activity
  return (
    <Timeline className='JobTimeline'>
      {isLoading ? (
        <Loading />
      ) : (
        activities &&
        activities.map((activity, i) => (
          <JobActivity
            key={i}
            currentProject={currentProject}
            jobActivity={activity}
            currentUser={currentUser}
          />
        ))
      )}
    </Timeline>
  );
};

export default JobTimeline;
