/* eslint-disable react/prop-types */

import JSZip from 'jszip';
import { map } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Dropdown } from 'react-bootstrap';

import { SVG } from '@svgdotjs/svg.js';
import { PulseLoader } from 'react-spinners';
import text from '../../../text';
import { client } from '../../../utilities/api';
import { downloadFile } from '../../../utilities/files';
import mapScreenshotUtils from '../../../utilities/mapScreenshot';
import { GEOSPATIAL_FILES } from '../constants';
import { MapViewContext } from '../mapViewContext';
import classNames from 'classnames';
import { captureError } from '../../../utilities/error';

const toFileNameSafe = (s) => {
  return s.replace(/[^a-z0-9]/gi, '_').toLowerCase();
};

const DOWNLOAD_NOTIF_TIMEOUT = 2000; // 2sec
const downloadStatuses = [text('downloaded'), 'Error'];

const DownloadsMenu = ({
  handleCloseMenu,
  selections,
  closeOnSelect = true,
}) => {
  let downloadClickedPool = useRef([]);
  const { state } = useContext(MapViewContext);

  const [isDownloadingScreenshot, setIsDownloadingScreenshot] = useState(false);
  const [donwloadScreenshotStatus, setDownloadScreenshotStatus] =
    useState(null);
  const [geoSpatialFiles, setGeoSpatialFiles] = useState(
    map(GEOSPATIAL_FILES, (row) => ({
      ...row,
      originalDisplayName: row.displayName,
      isLoading: false,
    }))
  );

  const handleIsMenuLoading = useCallback(
    (menuDisplayName, loadingValue, isError = false) => {
      const isMenuInGeoSpatial = !!geoSpatialFiles.find(
        (file) => file.displayName === menuDisplayName
      );

      const handleIsLoadingProp = (state) => {
        setGeoSpatialFiles((file) => {
          const itemToUpdate = file.find(
            (item) => item.displayName === menuDisplayName
          );

          if (itemToUpdate) {
            const _displayName = isError ? 'Error' : text('downloaded');
            return file.map((_file) =>
              _file.displayName === menuDisplayName
                ? {
                    ..._file,
                    isLoading: state,
                    displayName: !state
                      ? _displayName
                      : _file.originalDisplayName,
                  }
                : _file
            );
          }
          return file;
        });
      };

      if (loadingValue) {
        isMenuInGeoSpatial && handleIsLoadingProp(true);
        downloadClickedPool.current = [
          ...downloadClickedPool.current,
          menuDisplayName,
        ];
      } else {
        isMenuInGeoSpatial && handleIsLoadingProp(false);
        setTimeout(() => {
          setGeoSpatialFiles((file) =>
            file.map((_file) =>
              downloadStatuses.includes(_file.displayName)
                ? { ..._file, displayName: _file.originalDisplayName }
                : _file
            )
          );
        }, DOWNLOAD_NOTIF_TIMEOUT);
        downloadClickedPool.current = downloadClickedPool.current.filter(
          (p) => p !== menuDisplayName
        );
      }
    },
    [geoSpatialFiles]
  );

  // todo: run in worker thread
  const handleScreenshot = useCallback(async () => {
    if (closeOnSelect && handleCloseMenu) {
      handleCloseMenu();
    }
    setIsDownloadingScreenshot(true);
    try {
      for (const selection of selections) {
        const mapDim = {
          width: state.mapRef.current.offsetWidth,
          height: state.mapRef.current.offsetHeight,
        };
        const { layerPaths, bboxXy, mapTilesXy, labelsXy } =
          await mapScreenshotUtils.computeMapScreenshot(
            state.map,
            mapDim,
            selection,
            state.coverage
          );
        const draw = SVG();
        const group = draw.group();
        mapScreenshotUtils.createSvgPath(
          group,
          layerPaths,
          selection.category_name
        );
        draw.width(bboxXy.xMax - bboxXy.xMin);
        draw.height(bboxXy.yMax - bboxXy.yMin);
        const downloadUrl = await mapScreenshotUtils.drawMapScreenshot(
          draw,
          mapTilesXy,
          labelsXy
        );
        const fileName = `${mapScreenshotUtils.toValidFileStem(
          selection.name
        )}.jpg`;

        await downloadFile(downloadUrl, fileName);
      }
      setDownloadScreenshotStatus(text('downloaded'));
    } catch (error) {
      setDownloadScreenshotStatus('Error');
    }
    setIsDownloadingScreenshot(false);
  }, [
    selections,
    closeOnSelect,
    handleCloseMenu,
    state.mapRef,
    state.map,
    state.coverage,
  ]);

  const downloadGeospatialFile = useCallback(
    async (jobId, selections, fileType, menuDisplayName) => {
      handleIsMenuLoading(menuDisplayName, true);

      let _error = null;
      try {
        const promises = selections.map(async (selection) => {
          const { data } = await client.getSelectionFeaturesFile(
            jobId,
            [selection.id],
            fileType
          );
          const fileName = selection.name
            ? toFileNameSafe(selection.name)
            : selection.id;
          return { url: data, name: `${fileName}.${fileType}` };
        });

        const results = await Promise.all(promises);
        const urls = results.map((result) => result.url);
        const names = results.map((result) => result.name);

        if (urls.length === 1) {
          try {
            await downloadFile(urls[0]);
          } catch (error) {
            _error = error;
            captureError(
              new Error(
                `Error in downloadGeospatialFile-->downloadFile: ${error}`
              )
            );
          }
        } else if (urls.length > 1) {
          const zip = new JSZip();
          await Promise.all(
            urls.map(async (url, i) => {
              const res = await fetch(url);
              const blob = await res.blob();
              zip.file(names[i], blob, { blob: true });
            })
          );

          zip
            .generateAsync({ type: 'blob' })
            .then((content) => {
              downloadFile(URL.createObjectURL(content), 'shapes.zip');
            })
            .catch((error) => {
              _error = error;
            });
        }
      } catch (error) {
        _error = error;
        captureError(new Error(`Error in downloadGeospatialFile: ${error}`));
      }
      handleIsMenuLoading(menuDisplayName, false, !!_error);
    },
    [handleIsMenuLoading]
  );

  useEffect(() => {
    let timer;
    if (
      !isDownloadingScreenshot &&
      downloadStatuses.includes(donwloadScreenshotStatus)
    ) {
      timer = setTimeout(() => {
        setDownloadScreenshotStatus(null);
      }, DOWNLOAD_NOTIF_TIMEOUT);
    }

    return () => clearTimeout(timer);
  }, [isDownloadingScreenshot, donwloadScreenshotStatus]);

  return (
    <div className='geospatial-downloads-menu'>
      <Dropdown.Item
        onClick={handleScreenshot}
        disabled={isDownloadingScreenshot || donwloadScreenshotStatus}
      >
        {isDownloadingScreenshot ? (
          <div className='text-center w-100'>
            <PulseLoader size={8} />
          </div>
        ) : donwloadScreenshotStatus ? (
          <div
            className={classNames('text-center w-100 text-center font-italic', {
              'font-weight-light green-1':
                donwloadScreenshotStatus === text('downloaded'),
              'color-red-1': donwloadScreenshotStatus === 'Error',
            })}
          >
            {donwloadScreenshotStatus}
          </div>
        ) : (
          text('jpgScreenshot')
        )}
      </Dropdown.Item>
      {geoSpatialFiles.map((shapeFile, key) => {
        const isDisabled =
          shapeFile.isLoading || shapeFile.displayName === text('downloaded');
        return (
          <Dropdown.Item
            key={key}
            onClick={async () => {
              if (closeOnSelect && handleCloseMenu) {
                handleCloseMenu();
              }

              if (!selections || selections.length === 0) {
                return;
              }
              await downloadGeospatialFile(
                state.job?.id,
                selections,
                shapeFile.fileExtension,
                shapeFile.displayName
              );
            }}
            disabled={isDisabled}
          >
            {/* On/off visibility template to avoid shifting of context menu width */}
            <div
              className='position-relative w-100'
              style={{ pointerEvents: 'none' }}
            >
              <div
                className={classNames(
                  'text-center w-100 h-100 position-absolute',
                  {
                    invisible: !shapeFile.isLoading,
                  }
                )}
              >
                <PulseLoader size={8} />
              </div>
              {/* File type display name e.g. .dxf(xxx, m etc) */}
              <p
                className={classNames('mb-0', {
                  invisible:
                    shapeFile.isLoading ||
                    downloadStatuses.includes(text(shapeFile.displayName)),
                })}
              >
                {shapeFile.originalDisplayName}
              </p>
              {/* donwloaded or Error status */}
              <p
                style={{ top: 0 }}
                className={classNames(
                  'text-center w-100 h-100 position-absolute mb-0 font-italic',
                  {
                    invisible: !downloadStatuses.includes(
                      shapeFile.displayName
                    ),
                    'green-1 font-weight-light':
                      shapeFile.displayName === text('downloaded'),
                    'color-red-1': shapeFile.displayName === 'Error',
                  }
                )}
              >
                {shapeFile.displayName}
              </p>
            </div>
          </Dropdown.Item>
        );
      })}
    </div>
  );
};

export default DownloadsMenu;
