/* eslint-disable react/prop-types */
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Dropdown, Overlay, Tooltip } from 'react-bootstrap';

import useBreakpoint from '../../hooks/useBreakpoint';
import { useClickOutside } from '../../hooks/useClickOutside';
import Icon from '../icon/Icon';

const MeatballDropdown = (
  {
    children,
    className,
    disabled,
    btnClassName,
    toggleText,
    tooltipProps,
    onOpen,
    align = 'right',
    variant = 'outline-primary',
    closeDropdownOnSelect = true,
  },
  ref
) => {
  const target = useRef();
  const isEnabledForBreakpoint = useBreakpoint(['md', 'lg', 'xl']);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const enableTooltip = !isEmpty(tooltipProps) && isEnabledForBreakpoint;
  const handleToggle = (nextIsOpen, event) => {
    const isDownloadMenu = event.target.hasAttribute('download');
    // don't close on select of a dropdown menu
    if (!closeDropdownOnSelect && isDownloadMenu) {
      return;
    }

    const isDropdownItemClicked =
      event.currentTarget.classList &&
      event.currentTarget.classList.contains('dropdown-item');
    const isSubmenuDropClicked =
      isDropdownItemClicked &&
      event.currentTarget.classList.contains('SubmenuButton');
    // don't close dropdown when Submenu trigger is clicked
    if (isSubmenuDropClicked) {
      return;
    }

    if (isDropdownItemClicked && !closeDropdownOnSelect) {
      return;
    }

    setIsOpen(nextIsOpen);
  };

  useEffect(() => {
    if (isOpen && onOpen) {
      onOpen();
    }
  }, [isOpen]);

  useClickOutside(
    ref,
    (event) => {
      if (event.button === 2) {
        // right-click
        setIsOpen(false);
      }
    },
    [],
    !isOpen
  );

  return (
    <>
      <Dropdown
        show={isOpen}
        bsPrefix='MeatballDropdown'
        onToggle={handleToggle}
        ref={ref}
        className={className}
      >
        {/* Container for the overlay to pass ref */}
        <div
          ref={target}
          onBlur={() => enableTooltip && setIsTooltipVisible(false)}
          onMouseEnter={() => enableTooltip && setIsTooltipVisible(true)}
          onMouseLeave={() => enableTooltip && setIsTooltipVisible(false)}
          className='ToggleContainer'
          disabled={disabled}
        >
          <Dropdown.Toggle
            disabled={disabled}
            variant={variant}
            bsPrefix={classnames('Toggle', {
              [btnClassName]: btnClassName,
              simple: !btnClassName,
            })}
          >
            <Icon icon='more' />
            {toggleText ? <p className='m-0 ml-2'>{toggleText}</p> : null}
          </Dropdown.Toggle>
        </div>

        <Dropdown.Menu align={align}>{children}</Dropdown.Menu>
      </Dropdown>
      {enableTooltip ? (
        <Overlay
          placement={tooltipProps.placement ?? 'bottom'}
          target={target.current}
          show={isTooltipVisible}
        >
          {(props) => (
            <Tooltip id='meatballdropdown-tooltip' {...props}>
              {tooltipProps.text}
            </Tooltip>
          )}
        </Overlay>
      ) : null}
    </>
  );
};

export default forwardRef(MeatballDropdown);
