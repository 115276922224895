import { client } from '../../utilities/api';
import { captureError } from '../../utilities/error';
import { alertConstants } from '../constants';
import { DELETE_ASSET } from '../constants/asset';

export const deleteAsset =
  (projectId, jobId, activityId, assetId) => async (dispatch) => {
    try {
      await client.deleteAsset(jobId, assetId);

      dispatch({
        type: DELETE_ASSET,
        payload: {
          projectId,
          activityId,
          assetId,
        },
      });
    } catch (error) {
      captureError(error);
      dispatch({
        type: alertConstants.ALERT_ERROR,
        payload: { message: error.response?.data?.message },
      });
    }
  };
