import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParam } from 'react-use';
import * as yup from 'yup';

import Page from '../../components/page/Page';
import { alertConstants } from '../../redux/constants';
import { larkiApiNoAuthentication } from '../../utilities/api';
import { getUser } from '../../utilities/storage';
import { isUserAnonymous } from '../../utilities/user';
import { validateEmail } from '../../validation';
import ForgotPasswordForm from './form';
import ForgotPasswordSuccess from './success';

const forgotPasswordSchema = yup.object({
  email: validateEmail,
});

const initialValues = {
  email: '',
};

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [isSuccessful, setSuccess] = useState(false);
  const redirectUrlParam = useSearchParam('redirectUrl');

  const onSubmit = (redirectUrl) => async (values) => {
    try {
      const body = values;
      let headers = {};
      if (isUserAnonymous(getUser())) {
        headers['Authorization'] = `Bearer ${getUser().token}`;
      }
      if (redirectUrl) {
        body['redirectUrl'] = redirectUrl;
      }
      await larkiApiNoAuthentication.post('/user/reset-password', body, {
        headers,
      });

      sessionStorage.clear();
      setSuccess(true);
    } catch (error) {
      dispatch({
        type: alertConstants.ALERT_ERROR,
        payload: { message: error.response.data.message },
      });
    }
  };

  return (
    <Page>
      <Formik
        initialValues={initialValues}
        validationSchema={forgotPasswordSchema}
        onSubmit={onSubmit(redirectUrlParam)}
      >
        {isSuccessful ? <ForgotPasswordSuccess /> : <ForgotPasswordForm />}
      </Formik>
    </Page>
  );
};

export default ForgotPassword;
