import React from 'react';
import BodyItem from './BodyItem';
import text from '../../text';
import SocialMedia, { BRANDS } from '../socialMedia';

const SocialMediaSection = () => {
  return (
    <BodyItem className='SocialMediaSection' title={text('follow')}>
      <SocialMedia brand={BRANDS.INSTAGRAM} />
      <SocialMedia brand={BRANDS.LINKEDIN} />
      <SocialMedia brand={BRANDS.YOUTUBE} />
      <SocialMedia brand={BRANDS.FACEBOOK} />
      <SocialMedia brand={BRANDS.TWITTER} />
    </BodyItem>
  );
};

export default SocialMediaSection;
