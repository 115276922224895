import _ from 'lodash/fp';
import flowFP from 'lodash/fp/flow';
import setFP from 'lodash/fp/set';
import { FETCH_STATUS } from '../../constants/project';
import {
  DOWNLOAD_ALL_STATUS,
  DOWNLOAD_ALL_UPDATE_QUEUE_JOB_ID,
  DOWNLOAD_ALL_UPDATE_STATUS,
  DOWNLOAD_ALL_URL,
  DOWNLOAD_ALL_URL_FAILURE,
  DOWNLOAD_ALL_URL_SUCCESS,
  GET_CURRENT_PROJECT,
  GET_CURRENT_PROJECT_FAILURE,
  GET_CURRENT_PROJECT_SUCCESS,
  LIST_PROJECTS,
  LIST_PROJECTS_FAILURE,
  LIST_PROJECTS_SUCCESS,
  LIST_PROJECT_USERS,
  LIST_PROJECT_USERS_SUCCESS,
  REGISTER_PROJECT_TRIAL_MEMBERS,
  REGISTER_PROJECT_TRIAL_MEMBERS_SUCCESS,
  SET_CURRENT_PROJECT,
  UPDATE_USER_PROJECT_TRIAL,
  UPDATE_USER_PROJECT_TRIAL_SUCCESS,
} from '../constants/project';

const defaultState = {
  downloadAll: {},
  getCurrentProjectStatus: FETCH_STATUS.IDLE,
  currentProject: undefined,
  projectTrialMembers: {
    isLoading: false,
    members: undefined,
  },
  projectUsers: {},
  projects: {
    loading: false,
    error: null,
    data: [],
  },
};

const projectReducer = (state = defaultState, action) => {
  switch (action.type) {
    case DOWNLOAD_ALL_UPDATE_STATUS: {
      const { projectId, status } = action.payload;
      return flowFP([setFP(['downloadAll', projectId, 'status'], status)])(
        state
      );
    }
    case DOWNLOAD_ALL_UPDATE_QUEUE_JOB_ID: {
      const { projectId, queueJobId } = action.payload;
      return flowFP(
        setFP(['downloadAll', projectId, 'queueJobId'], queueJobId)
      )(state);
    }
    case DOWNLOAD_ALL_URL: {
      return flowFP([
        setFP(['downloadAll', action.payload.projectId, 'isLoading'], true),
      ])(state);
    }
    case DOWNLOAD_ALL_URL_SUCCESS: {
      return flowFP([
        setFP(['downloadAll', action.payload.projectId, 'isLoading'], false),
        setFP(
          ['downloadAll', action.payload.projectId, 'status'],
          DOWNLOAD_ALL_STATUS.IDLE
        ),
      ])(state);
    }
    case DOWNLOAD_ALL_URL_FAILURE: {
      return flowFP([
        setFP(['downloadAll', action.payload.projectId, 'isLoading'], false),
        setFP(
          ['downloadAll', action.payload.projectId, 'status'],
          DOWNLOAD_ALL_STATUS.IDLE
        ),
      ])(state);
    }

    case REGISTER_PROJECT_TRIAL_MEMBERS: {
      return flowFP([setFP(['projectTrialMembers', 'isLoading'], true)])(state);
    }
    case REGISTER_PROJECT_TRIAL_MEMBERS_SUCCESS: {
      return flowFP([
        setFP(['projectTrialMembers', 'isLoading'], false),
        setFP(['projectTrialMembers', 'members'], action.payload.members),
      ])(state);
    }
    case LIST_PROJECTS: {
      return _.set('projects.loading', true)(state);
    }
    case LIST_PROJECTS_SUCCESS: {
      const { data } = action.payload;
      return _.flow(
        _.set('projects.loading', false),
        _.set('projects.data', data)
      )(state);
    }
    case LIST_PROJECTS_FAILURE: {
      const { error } = action.payload;
      return _.flow(
        _.set('projects.loading', false),
        _.set('projects.error', error)
      )(state);
    }
    case LIST_PROJECT_USERS: {
      const { projectId } = action.payload;
      return flowFP([setFP(['projectUsers', projectId, 'isLoading'], true)])(
        state
      );
    }
    case LIST_PROJECT_USERS_SUCCESS: {
      const { projectId, users } = action.payload;
      return flowFP([
        setFP(['projectUsers', projectId, 'isLoading'], false),
        setFP(['projectUsers', projectId, 'users'], users),
      ])(state);
    }
    case UPDATE_USER_PROJECT_TRIAL: {
      const { projectId, userId } = action.payload;
      return flowFP([
        setFP(
          ['projectUsers', projectId, 'invitation', userId, 'isLoading'],
          true
        ),
      ])(state);
    }
    case UPDATE_USER_PROJECT_TRIAL_SUCCESS: {
      const { projectId, userId } = action.payload;
      return flowFP([
        setFP(
          ['projectUsers', projectId, 'invitation', userId, 'isLoading'],
          false
        ),
      ])(state);
    }

    case SET_CURRENT_PROJECT: {
      return flowFP([
        setFP(['currentProject'], action.payload.project),
        setFP(['getCurrentProjectStatus'], FETCH_STATUS.DONE),
      ])(state);
    }

    case GET_CURRENT_PROJECT: {
      return setFP(['getCurrentProjectStatus'], FETCH_STATUS.LOADING)(state);
    }
    case GET_CURRENT_PROJECT_SUCCESS: {
      return flowFP([
        setFP(['currentProject'], action.payload.project),
        setFP(['getCurrentProjectStatus'], FETCH_STATUS.DONE),
      ])(state);
    }
    case GET_CURRENT_PROJECT_FAILURE: {
      return flowFP([setFP(['getCurrentProjectStatus'], FETCH_STATUS.ERROR)])(
        state
      );
    }

    default:
      return state;
  }
};

export default projectReducer;
