import { Formik } from 'formik';
import T from 'prop-types';
import React from 'react';
import { object, string } from 'yup';
import getOr from 'lodash/fp/getOr';

import Control from '../Control';
import text from '../../../text';
import Button from '../../button/Button';

const schema = object().shape({
  pointerraId: string().matches(/^[a-zA-Z0-9]{22}$/, {
    message: text('invalidParam', { param: 'Pointerra ID' }),
    excludeEmptyString: true,
  }),
});

const PointerraIDForm = (props) => {
  const { onSubmit, onCancel, job, submitState } = props;
  return (
    <div className='PointerraIDForm'>
      <Formik
        initialValues={{
          pointerraId: getOr('', ['point_clouds', 0, 'pointerra_id'], job),
        }}
        validationSchema={schema}
      >
        {({ handleChange, isValid, values, errors, dirty }) => {
          return (
            <>
              <Control
                label={text('enterIDNumber')}
                as='input'
                type='text'
                name='pointerraId'
                isFailed={!!errors.pointerraId || submitState.error}
                value={values?.pointerraId || ''}
                onChange={(e) => handleChange(e)}
                placeholder={text('enterIDNumber')}
                validationError={errors.pointerraId || submitState.error}
                autoComplete='off'
              />
              <div className='form-footer'>
                <Button
                  loading={submitState.loading}
                  onClick={() => onSubmit(job?.project.id, values?.pointerraId)}
                  disabled={!isValid || !dirty}
                >
                  {text('update')}
                </Button>
                <a name='cancel' href='#' onClick={onCancel}>
                  {text('cancel')}
                </a>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

PointerraIDForm.propTypes = {
  onSubmit: T.func.isRequired,
};

export default PointerraIDForm;
