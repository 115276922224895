import { MAX_MGA_ZONE, MIN_MGA_ZONE } from '../constants/mga';

const ZONE_LETTERS = 'CDEFGHJKLMNPQRSTUVWXX';

const latToUtmZoneLetter = (lat) => {
  if (-80 <= lat && lat <= 84) {
    return ZONE_LETTERS.charAt(Math.floor((lat + 80) / 8));
  }
  return null;
};

const latLngToUtmZoneNumber = (lat, lon) => {
  if (56 <= lat && lat <= 64 && 3 <= lon && lon < 12) {
    return 32;
  }
  if (72 <= lat && lat <= 84 && lon >= 0) {
    if (lon < 9) {
      return 31;
    } else if (lon < 21) {
      return 33;
    } else if (lon < 33) {
      return 35;
    } else if (lon < 42) {
      return 37;
    }
  }
  return Math.floor((lon + 180) / 6) + 1;
};

const latLngToMgaZone = (lat, lon) => {
  const zoneNumber = latLngToUtmZoneNumber(lat, lon);
  return MIN_MGA_ZONE <= zoneNumber && zoneNumber <= MAX_MGA_ZONE
    ? zoneNumber
    : null;
};

const mgaUtils = {
  latLngToMgaZone,
};

export default mgaUtils;
