import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import Button from '../button/Button';
import { hideModal } from '../../redux/actions';
import text from '../../text';

export const DEMO_PROJECT_ACTION_MODAL = 'DEMO_PROJECT_ACTION_MODAL';

const DemoProjectActionsModal = ({ show }) => {
  const dispatch = useDispatch();
  const onHide = () => {
    dispatch(hideModal(DEMO_PROJECT_ACTION_MODAL));
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header className='centered' closeButton>
        <h3>{text('demoProjectOnly')}</h3>
      </Modal.Header>
      <Modal.Body className='centered'>
        <div>
          <p className='m-0'>{text('demoActionMessagePart1')}</p>
          <p className='mx-3'>{text('demoActionMessagePart2')}</p>
          <Button variant='link' className='w-100' onClick={() => onHide()}>
            <h5>{text('closeAndContinue')}</h5>
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DemoProjectActionsModal;
