import React from 'react';
import capitalize from 'lodash/capitalize';
import { Field } from 'formik';

import ProductSelect from './ProductSelect';
import StatusSelect from './StatusSelect';
import DatePicker from '../../datePicker/DatePicker';
import text from '../../../text';
import AssetIcon from '../../icon/common/AssetIcon';
import { ASSET_ICON_MAP } from '../../../constants/product';
import { get } from 'lodash';
import { DETAILED_STATUS } from '../../../constants/job';
import { formatDate, getNowDate } from '../../../utilities/date';

const ProductRow = (props) => {
  const { initialProducts, products, product, name } = props;
  const today = new Date();

  return (
    <tr className='ProductRow'>
      <td className='productCell'>
        <AssetIcon
          defaultIcon='question-mark'
          product={ASSET_ICON_MAP[product.productKey]}
        />
        <Field name={`${name}.productKey`}>
          {({ field, form: { setFieldValue } }) => {
            const existingProduct = initialProducts.find(
              (item) => item.productKey === field.value
            );
            return existingProduct ? (
              <span className='productLabel'>{field.value}</span>
            ) : (
              <ProductSelect
                existingProducts={products}
                product={field.value}
                placeholder={text('addNewProduct')}
                onChange={(option) =>
                  setFieldValue(field.name, option.productKey)
                }
              />
            );
          }}
        </Field>
      </td>
      <td className='statusCell'>
        <Field name={`${name}.status`}>
          {({ field, form: { setFieldValue } }) => (
            <StatusSelect
              status={field.value}
              placeholder={text('selectStatus')}
              onChange={(option) =>
                setFieldValue(field.name, capitalize(option.status))
              }
            />
          )}
        </Field>
      </td>
      <td className='etaCell'>
        <Field name={`${name}.expectedAt`}>
          {({ field, form: { setFieldValue, values } }) =>
            product.status === DETAILED_STATUS.COMPLETED ? (
              text('completedByDate', {
                completedDate: formatDate(product?.completedAt || getNowDate()),
              })
            ) : (
              <DatePicker
                placeholder={text('selectDate')}
                minDate={today}
                onChange={(date) =>
                  setFieldValue(field.name, date?.toISOString())
                }
                value={
                  field.value &&
                  ![DETAILED_STATUS.COMPLETED].includes(
                    get(values, `${name}.status`)
                  )
                    ? new Date(field.value)
                    : null
                }
              />
            )
          }
        </Field>
      </td>
    </tr>
  );
};

export default ProductRow;
