import React, { forwardRef } from 'react';

import { useDispatch } from 'react-redux';
import { AREA_TYPE } from '../../../constants/product';
import { getMapQueryData } from '../../../redux/actions';
import { convertToGeoJson } from '../../../utilities/map';
import MeatballDropdown from '../../dropdown/MeatballDropdown';
import ShapeMenu from '../../shapeMenu/ShapeMenu';
import { PRODUCT_DATA } from '../constants';

/* eslint-disable react/prop-types */
const LayerMeatballDropdown = forwardRef(
  (
    { data, closeDropdownOnSelect = true},
    ref
  ) => {
    const dispatch = useDispatch();

    const onProductMenuOpen = () => {
      dispatch(
        getMapQueryData({
          region: convertToGeoJson([{ region: data.region }]).features[0]
            .geometry,
          category_names: PRODUCT_DATA.result,
        })
      );
    };

    return (
      <ShapeMenu
        data={data}
        DropdownMenuComponent={MeatballDropdown}
        variant=''
        className='layer-meatball-dropdown-menu'
        btnClassName='meatball-dropdown'
        align='right'
        onOpen={onProductMenuOpen}
        ref={ref}
        showShapeName={true}
        areaType={AREA_TYPE.SHAPE_AREA}
        popupTrigger='LHS'
        closeDropdownOnSelect={closeDropdownOnSelect}
      />
    );
  }
);

LayerMeatballDropdown.displayName = 'LayerMeatballDropdown';

export default LayerMeatballDropdown;
