import classnames from 'classnames';
import { flow } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import T from 'prop-types';
import Button from '../../components/button/Button';
import MembershipButton from '../../components/button/MembershipButton';
import { ACTIVITY_TYPES } from '../../constants/activity';
import {
  disableFilter,
  enableFilter,
  filterActivities,
} from '../../redux/actions/activity';
import routes from '../../routes/constants';
import text from '../../text';
import { isActivityFileDownload } from '../../utilities/activity';
import { filterByMultipleConditions } from '../../utilities/filter';
import { isDemoJob } from '../../utilities/job';
import SearchActivitiesInput from './SearchActivitiesInput';

const FILTERS = {
  ORDERS: 'orders',
  USERS: 'users',
  NOTES: 'notes',
  FILES: 'files',
  STATUSES: 'statuses',
  ALERTS: 'alerts',
};

const ActivityFilterControls = ({ id, projectId, currentProject }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { projects, enabledFilters } = useSelector((state) => ({
    projects: state.activity.projects,
    enabledFilters: state.activity.enabledFilters,
  }));

  const activityFilters = useMemo(
    () => [
      {
        name: FILTERS.ORDERS,
        predicate: (activity) => activity.type === ACTIVITY_TYPES.order,
        label: text('orders'),
        icon: 'shopping-cart',
      },
      {
        name: FILTERS.USERS,
        predicate: (activity) => activity.type === ACTIVITY_TYPES.user,
        label: text('users'),
        icon: 'user-outline',
      },
      {
        name: FILTERS.NOTES,
        predicate: (activity) =>
          activity.type === ACTIVITY_TYPES.note &&
          !isActivityFileDownload(activity),
        label: text('notes'),
        icon: 'speech',
      },
      {
        name: FILTERS.FILES,
        predicate: (activity) => activity.type === ACTIVITY_TYPES.file,
        label: text('files'),
        icon: 'clip',
      },
      {
        name: FILTERS.STATUSES,
        predicate: (activity) => activity.type === ACTIVITY_TYPES.status,
        label: text('statuses'),
        icon: 'circle',
      },
      {
        name: FILTERS.ALERTS,
        predicate: (activity) =>
          /** need to check File downloaded from title for old activities prior
           *  saving File downlaoded activity as Alerts (larki type) */
          activity.title.includes('File downloaded') ||
          activity.type === ACTIVITY_TYPES.alert,
        label: text('alerts'),
        icon: 'larki-icon',
      },
    ],
    []
  );
  const isEnabled = useCallback(
    (enabledFilter) => enabledFilters.includes(enabledFilter),
    [enabledFilters]
  );

  const handleEnableFilter = (filterName) => dispatch(enableFilter(filterName));
  const handleDisableFilter = (filterName) =>
    dispatch(disableFilter(filterName));

  const nonFilteredActivities = useMemo(
    () => projects[projectId]?.nonFilteredActivities,
    [projectId, projects]
  );

  const handleFilter = useCallback(() => {
    dispatch(
      filterActivities(
        enabledFilters.length !== 0
          ? flow([
              filterByMultipleConditions(
                activityFilters
                  .filter((activityFilter) =>
                    enabledFilters.includes(activityFilter.name)
                  )
                  .map((activityFilter) => activityFilter.predicate)
              ),
            ])(nonFilteredActivities)
          : nonFilteredActivities,
        projectId
      )
    );
  }, [
    dispatch,
    enabledFilters,
    activityFilters,
    nonFilteredActivities,
    projectId,
  ]);

  useEffect(() => {
    handleFilter();
  }, [handleFilter]);

  return (
    <div id={id} className='ActivityFilterControls'>
      <SearchActivitiesInput projectId={projectId} />
      <div className='ButtonGroup'>
        <p className='m-0 mr-3'>{text('activityFilter')}:</p>
        {activityFilters.map((filter, i) => (
          <Button
            key={i}
            className={classnames({
              enabled: isEnabled(filter.name),
            })}
            onClick={() =>
              !isEnabled(filter.name)
                ? handleEnableFilter(filter.name)
                : handleDisableFilter(filter.name)
            }
            variant='chip'
            icon={filter.icon}
          >
            {filter.label}
          </Button>
        ))}
      </div>
      {!isDemoJob(currentProject) && (
        <div className='right'>
          <MembershipButton variant='outline-primary' />
          <Button
            id='orderButton'
            variant='primary'
            disabled={currentProject?.status === 'demo'}
            onClick={() => {
              event.preventDefault();
              history.push({
                pathname: routes.order.root,
                ...(!isDemoJob(currentProject)
                  ? { search: `?projectId=${currentProject?.projectId}` }
                  : {}),
              });
            }}
          >
            {text('order3DData')}
          </Button>
        </div>
      )}
    </div>
  );
};

ActivityFilterControls.propTypes = {
  id: T.oneOfType([T.string, T.number]),
  projectId: T.oneOfType([T.string, T.number]),
  currentProject: T.shape(),
};

export default ActivityFilterControls;
