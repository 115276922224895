import React, { useRef } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { PulseLoader } from 'react-spinners';
import classnames from 'classnames';
import Link from '../Link/Link';

const LinkButton = ({
  children,
  className,
  disabled,
  loading,
  tooltipProps: { placement = 'bottom', text = '' } = {},
  ...rest
}) => {
  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 250 }}
      overlay={<Tooltip>{text}</Tooltip>}
    >
      <Link
        className={classnames(className, { disabled })}
        {...rest}
        to={rest?.to || ''}
      >
        {loading ? <PulseLoader color='#fff' size={12} /> : children}
      </Link>
    </OverlayTrigger>
  );
};

export default LinkButton;
