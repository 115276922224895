import cn from 'classnames';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import T from 'prop-types';
import React from 'react';
import './metromapLogo.scss';

export const METROMAP_LOGO_ID = 'metromap-logo-wrapper';

const MetromapLogo = (props) => {
  const { isVisible, mapTypeId, tileDate } = props;

  return (
    <div id={METROMAP_LOGO_ID} className={cn({ is_visible: isVisible })}>
      {mapTypeId === 'highres' ? (
        <>
          <img
            className='metromap-logo'
            src='/public/img/metromap_logo.png'
            alt='metromap_logo_red'
          />
          {!isEmpty(tileDate) && (
            <div className='metromap-logo-datetime-container'>
              <div className='metromap-logo-datetime'>
                {format(new Date(tileDate), 'dd/MM/yyyy')}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className='metromap-logo-datetime-container'>
          <div className='metromap-logo-datetime'>
            © <a href='//www.linz.govt.nz/linz-copyright'>LINZ CC BY 4.0</a> | ©{' '}
            <a href='//www.linz.govt.nz/data/linz-data/linz-basemaps/data-attribution'>
              Imagery Basemap contributors
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

MetromapLogo.propTypes = {
  isVisible: T.bool.isRequired,
};

export default MetromapLogo;
