import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCompanyProfile,
  resetCompanyForm,
  updateCompanyProfile,
} from '../../../redux/actions';
import { isDefault, isSuccess } from '../../../redux/stateTools';
import { checkFieldsAreDiff } from '../../../utilities/object';
import { companyFormSchema } from '../../../validation';
import AutoSave from './AutoSave';
import Control from '../../../components/form/Control';
import text from '../../../text';
import FormSection from '../../../components/containers/FormSection';

const CompanyForm = () => {
  const dispatch = useDispatch();
  const { companyProfile, updateCompanyProfileState } = useSelector(
    (state) => state.profileReducer
  );

  const handleSubmit = (values, actions) => {
    const diffFields = checkFieldsAreDiff(companyProfile, values);
    if (Object.entries(diffFields).some(Boolean)) {
      dispatch(updateCompanyProfile(values));
      actions.resetForm({ values, touched: diffFields });
    }
  };

  useEffect(() => {
    if (isEmpty(companyProfile)) {
      dispatch(getCompanyProfile());
    }
  }, []);
  return (
    !isEmpty(companyProfile) && (
      <FormSection title={text('companyDetails')}>
        <Formik
          validationSchema={companyFormSchema}
          initialValues={companyProfile}
          onSubmit={handleSubmit}
        >
          {({
            values: company,
            handleBlur,
            handleChange,
            touched,
            isValid,
            errors,
          }) => {
            const handleChangeWithReset = (newValues) => {
              if (!isDefault(updateCompanyProfileState)) {
                dispatch(resetCompanyForm());
              }
              handleChange(newValues);
            };
            const isFormTouched =
              touched && Object.values(touched).some(Boolean);
            return (
              <div>
                <Row>
                  <Col md='6'>
                    <Control
                      isSuccessful={
                        touched.name && isSuccess(updateCompanyProfileState)
                      }
                      isFailed={isFormTouched && errors.name}
                      as='input'
                      type='text'
                      name='name'
                      value={company.name ?? ''}
                      label={text('company')}
                      onBlur={handleBlur}
                      onChange={handleChangeWithReset}
                      validationError={errors.name}
                    />
                  </Col>
                </Row>
                <AutoSave isValidForm={isValid} />
              </div>
            );
          }}
        </Formik>
      </FormSection>
    )
  );
};

export default CompanyForm;
