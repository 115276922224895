import _ from 'lodash/fp';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePreviousDistinct } from 'react-use';

import { addToCoverage } from '../../redux/actions/coverage';
import { getUnseenHashes } from '../../utilities/coverage';
import { isArrayEqual } from '../../utilities/lodash';
import {
  convertToGeoJson,
  convertToGoogleGeometry,
  geoEncodeBounds,
  getGMGeometryBounds,
  getRegionBounds,
} from '../../utilities/map';
import { MapViewContext } from './mapViewContext';

/**
 * `useCoverage` is responsible for adding coverage iteratively to the coverage cache upon specific user actions.
 * It works by awaiting the region selection to stabilize, then encoding the bounds of the region by obtaining a list of geohashes.
 * See https://en.wikipedia.org/wiki/Geohash for more info.
 * Why is this advantageous? It allows us to avoid querying coverage for the same region multiple times.
 * Instead, we can query the cache for coverage data and only request new data for regions that have not been seen before.
 *
 * Note: uses MapViewContext, so ensure that the <MapViewContext.Provider /> is a parent of the component using this hook.
 */
const useCoverage = () => {
  const { state } = useContext(MapViewContext);
  const { coverageState } = useSelector((reduxState) => ({
    coverageState: reduxState.coverageReducer,
  }));
  const dispatch = useDispatch();
  const previousSelections = usePreviousDistinct(state.selections);

  /**
   * Add new hashes to the coverage cache whenever the region selection changes.
   */
  useEffect(() => {
    const regionSelection = _.values(state.debouncedRegionSelection)?.[0];
    if (!regionSelection || state.activeSelection) {
      return;
    }
    const bounds = getGMGeometryBounds(regionSelection);
    const hashes = geoEncodeBounds(bounds);
    const unseenHashes = getUnseenHashes(hashes, coverageState.hashes);
    dispatch(addToCoverage(unseenHashes));
  }, [state.debouncedRegionSelection]);

  /**
   * Add new hashes to the coverage cache when selection regions are changed.
   */
  useEffect(() => {
    if (
      state.googleMapsScriptStatus !== 'ready' ||
      !state.selections ||
      isArrayEqual(state.selections, previousSelections, ['focused'])
    ) {
      return;
    }
    let unseenHashes = [];
    _.forEach((selection) => {
      const region = convertToGeoJson([selection]).features?.[0].geometry;
      if (!region) {
        return;
      }
      const { bounds } = convertToGoogleGeometry(region.coordinates[0]);
      const hashes = geoEncodeBounds(bounds);
      unseenHashes.push(...getUnseenHashes(hashes, coverageState.hashes));
    })(state.selections);
    unseenHashes = _.uniq(unseenHashes);
    dispatch(addToCoverage(unseenHashes));
  }, [state.selections, state.googleMapsScriptStatus]);

  /**
   * Add new hashes to the coverage cache when the default region changes.
   */
  useEffect(() => {
    if (!state.defaultRegion) {
      return;
    }
    const bounds = getRegionBounds(state.defaultRegion);
    const hashes = geoEncodeBounds(bounds);
    const unseenHashes = getUnseenHashes(hashes, coverageState.hashes);
    dispatch(addToCoverage(unseenHashes));
  }, [state.defaultRegion]);
};

export default useCoverage;
