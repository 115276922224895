import classNames from 'classnames';
import T from 'prop-types';
import React from 'react';

/**
 * Description placeholder
 *
 * @export
 * @param {string} label
 * @param {object} svgProps - object: should be <svg> tag attributes e.g. width, height, fill etc
 * @param {object} textProps - object: should be <text> tag attributes e.g. x, y etc
 * @param {string} textClass - additional/override class
 * @returns
 */
export default function OutlinedText({
  label,
  svgProps,
  textProps,
  textClass,
  svgClass,
}) {
  return (
    <svg className={classNames('svgOutlined', svgClass)} {...svgProps}>
      <text
        dominantBaseline='hanging'
        className={classNames('svgText', textClass)}
        {...textProps}
      >
        {label}
      </text>
    </svg>
  );
}

OutlinedText.propTypes = {
  label: T.string.isRequired,
  textClass: T.string,
  svgProps: T.object.isRequired,
  textProps: T.object,
  svgClass: T.string,
};

OutlinedText.defaultProps = {
  textClass: '',
  textProps: {
    x: 2,
    y: 2,
  },
  svgProps: {
    width: '48px',
  },
  svgClass: 'position-absolute',
};
