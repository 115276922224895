import React, { useState, useEffect, useRef } from 'react';
import ReactCalendar from 'react-calendar';
import Fit from 'react-fit';
import { format } from 'date-fns';
import { useClickAway } from 'react-use';
import cn from 'classnames';

import Icon from '../icon/Icon';
import { FORMAT } from '../../utilities/date';

const DatePicker = (props) => {
  const { onChange, placeholder, value, ...restProps } = props;
  const [isOpen, toggleIsOpen] = useState(false);
  const [inputDate, setInputDate] = useState('');
  const ref = useRef();

  const handleChange = (v) => {
    onChange(v);
    toggleIsOpen(false);
  };

  useEffect(() => {
    if (value) {
      setInputDate(format(value, FORMAT.DATE));
    }
  }, [value]);

  useClickAway(ref, () => toggleIsOpen(false));

  return (
    <div ref={ref} className={cn('DatePicker', { isOpen })}>
      <div className='DatePicker-Input' onClick={() => toggleIsOpen(true)}>
        <input readOnly value={inputDate} placeholder={placeholder} />
        <Icon className='button' icon='calendar' />
      </div>
      {isOpen && (
        <Fit>
          <ReactCalendar formatMonth={(loc,date) => format(date, 'MMM')} onChange={handleChange} value={value} {...restProps} />
        </Fit>
      )}
    </div>
  );
};

export default DatePicker;
