import text from '../../../../../text';

const TOURS = {
  TOUR_2D_MAP: 'TOUR_2D_MAP',
  TOUR_FILE_MANAGER: 'TOUR_FILE_MANAGER',
  TOUR_3D_VIEWER: 'TOUR_3D_VIEWER',
};

const MODAL_TOUR_NAMES = {
  TOUR_2D_MAP: '2d_map_tour',
  TOUR_FILE_MANAGER: 'file_manager_tour',
  TOUR_3D_VIEWER: '3d_viewer_tour',
};

const TOUR_MODAL_START_CONFIG = {
  [TOURS.TOUR_2D_MAP]: {
    gif: '/public/img/2D-map.gif',
    title: text('tourModal2dTitle'),
    description: text('tourModal2dDescription'),
    done: text('tourModal2dDone'),
  },
  [TOURS.TOUR_FILE_MANAGER]: {
    gif: '/public/img/filemanager.gif',
    title: text('tourModalFilemanagerTitle'),
    description: text('tourModalFilemanagerDescription'),
    done: text('tourModalFilemanagerDone'),
  },
  [TOURS.TOUR_3D_VIEWER]: {
    gif: '/public/img/3D_Viewer.gif',
    title: text('tourModal3dViewerTitle'),
    description: text('tourModal3dViewerDescription'),
    done: text('tourModal3dViewerDone'),
  },
};

const hightlightedAreaStyling = (base, { x, y, width, height }) => ({
  ...base,
  display: 'block',
  stroke: '#f00',
  strokeWidth: 3,
  width: width + 6,
  height: height + 6,
  rx: 3,
  x: x - 3,
  y: y - 3,
  pointerEvents: 'none',
});

const popoverCustomDefaultStyling = (props) => ({
  ...props,
  borderRadius: 6,
  padding: 0,
});

export {
  TOURS,
  TOUR_MODAL_START_CONFIG,
  hightlightedAreaStyling,
  popoverCustomDefaultStyling,
  MODAL_TOUR_NAMES,
};
