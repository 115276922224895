import React, { useState } from 'react';
import { components } from 'react-select';
import ReactCreateTable from 'react-select/creatable';
import cn from 'classnames';
import Control from '../form/Control';

const MenuList = ({ selectProps, ...props }) => {
  const { onInputChange, inputValue, onMenuInputFocus } = selectProps;
  return (
    <>
      <Control
        autoCorrect='off'
        autoComplete='off'
        type='text'
        value={inputValue}
        onChange={(e) =>
          onInputChange(e.currentTarget.value, {
            action: 'input-change',
          })
        }
        onMouseDown={(e) => {
          e.stopPropagation();
          e.target.focus();
        }}
        onTouchEnd={(e) => {
          e.stopPropagation();
          e.target.focus();
        }}
        onFocus={onMenuInputFocus}
        placeholder={selectProps.createInputPlaceholder}
      />
      <components.MenuList {...props} selectProps={selectProps} />
    </>
  );
};

const CreateTable = (props) => {
  const { className, onChange, createInputPlaceholder, ...restProps } = props;
  const [isFocused, toggleIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');

  return (
    <ReactCreateTable
      classNamePrefix='Select'
      createOptionPosition='first'
      createInputPlaceholder={createInputPlaceholder}
      selectProps={{ createInputPlaceholder }}
      className={cn('CreateTable', { [className]: className })}
      components={{ MenuList }}
      inputValue={inputValue}
      onMenuInputFocus={() => toggleIsFocused(true)}
      onChange={(v) => {
        onChange(v);
        toggleIsFocused(false);
      }}
      onInputChange={(val) => setInputValue(val)}
      menuIsOpen={isFocused || undefined}
      isFocused={isFocused || undefined}
      {...restProps}
    />
  );
};

export default CreateTable;
