import fp from 'lodash/fp';
import { PRODUCT_DATA } from '../../components/mapView/constants';
import {
  computeUnionMulti,
  geoDecode,
} from '../../components/mapView/geometry';
import { larkiApi } from '../../utilities/api';
import {
  ADD_MANY_TO_COVERAGE_CACHE,
  SET_COVERAGE_CACHE,
} from '../reducers/coverage';

export const addToCoverage = (hashes) => async (dispatch) => {
  if (hashes.length === 0) {
    return;
  }

  let totalCoverageArea = computeUnionMulti(hashes.map(geoDecode));
  const { data } = await larkiApi.post(`/engine/product/query`, {
    region: totalCoverageArea,
    category_names: PRODUCT_DATA.result,
  });
  const layersByProduct = fp.groupBy('product_id', data.coverage.layers);
  const coverage = fp.mapValues(
    (p) =>
      fp.set('layers', fp.keyBy('layer_id', layersByProduct[p.product_id]), p),
    data.coverage.products
  );

  dispatch({
    type: ADD_MANY_TO_COVERAGE_CACHE,
    payload: { hashes, coverage },
  });
};

export const setCoverageCache = (coverage) => (dispatch) => {
  dispatch({
    type: SET_COVERAGE_CACHE,
    payload: { coverage },
  });
};
