import React from 'react';

import BodyItem from './BodyItem';
import text from '../../text';
import routes from '../../routes/constants';

const ConnectSection = () => {
  return (
    <BodyItem className='ConnectSection' title={text('connect')}>
      <a
        href={routes.larkiWebsiteUrl.contactUs}
        rel='noreferrer'
        target='_blank'
      >
        {text('contactUs')}
      </a>
      <a href={routes.larkiWebsiteUrl.survey} rel='noreferrer' target='_blank'>
        {text('leaveReview')}
      </a>
      <a href={routes.larkiWebsiteUrl.blog} rel='noreferrer' target='_blank'>
        {text('blog')}
      </a>
    </BodyItem>
  );
};

export default ConnectSection;
