import { productConstants } from '../constants/products';

const defaultState = {
  products: [],
  status: 'idle',
};

const productsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case productConstants.FETCH_PRODUCTS_LOADING:
      return { ...state, status: 'loading' };
    case productConstants.FETCH_PRODUCTS_SUCCESS:
      return { ...state, products: action.payload, status: 'success' };
    case productConstants.FETCH_PRODUCTS_FAIL:
      return { ...state, status: 'failed' };

    default:
      return state;
  }
};

export default productsReducer;
