import React, { useContext } from 'react';
import {
  Accordion as BSAccordion,
  Card,
  Button,
  useAccordionToggle,
  AccordionContext,
} from 'react-bootstrap';
import noop from 'lodash/noop';

import Icon from '../icon/Icon';

// import Button from '../button/Button';

const AccordionToggle = (props) => {
  const { eventKey, toggleLabel, onToggle } = props;
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    onToggle(!isCurrentEventKey ? eventKey : null);
  });
  return (
    <div className='AccordionToggle'>
      <BSAccordion.Toggle
        id='label'
        as={Button}
        variant='link'
        eventKey={eventKey}
        onClick={decoratedOnClick}
      >
        {toggleLabel}
      <Icon icon={isCurrentEventKey ? 'caret-up' : 'caret-down'} />
      </BSAccordion.Toggle>
    </div>
  );
};

const Accordion = (props) => {
  const { children, ...rest } = props;
  return (
    <BSAccordion bsPrefix='Accordion' {...rest}>
      {children}
    </BSAccordion>
  );
};

const AccordionCard = (props) => {
  const { toggleLabel, children, eventKey, onToggle = noop } = props;
  return (
    <Card>
      <Card.Header>
        <AccordionToggle
          onToggle={onToggle}
          toggleLabel={toggleLabel}
          eventKey={eventKey}
        />
      </Card.Header>
      <BSAccordion.Collapse eventKey={eventKey}>
        <Card.Body>{children}</Card.Body>
      </BSAccordion.Collapse>
    </Card>
  );
};

Accordion.Card = AccordionCard;

export default Accordion;
