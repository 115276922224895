export const ANONYMOUS_DASHBOARD = 'anonymousDashboard';
export const ANONYMOUS_ORDER = 'anonymousOrder';
export const ANONYMOUS_HIGH_RES = 'anonymousHighRes';

export const ANONYMOUS_ALERT = {
  [ANONYMOUS_DASHBOARD]: {
    type: 'ALERT_INFO',
    message: 'goToDashboardPleaseSignUp',
  },
  [ANONYMOUS_ORDER]: {
    type: 'ALERT_INFO',
    message: 'goToOrderPleaseSignUp',
  },
  [ANONYMOUS_HIGH_RES]: {
    type: 'ALERT_INFO',
    message: 'toContinueWithHighResMap',
  },
};
