import text from '../text';

export const FREE = 'free';
export const TRIAL = 'trial';
export const ESSENTIALS = 'essentials';
export const LARKI_SUPPORT = 'larkiSupport';
export const GOVERNMENT = 'government';

export const USER_TYPE = {
  FREE,
  TRIAL,
  ESSENTIALS,
  LARKI_SUPPORT,
  GOVERNMENT,
};

export const USER_TYPE_TO_DISPLAY_NAME = {
  [USER_TYPE.FREE]: text('freeUser'),
  [USER_TYPE.TRIAL]: text('trialUser'),
  [USER_TYPE.ESSENTIALS]: text('essentials'),
  [USER_TYPE.LARKI_SUPPORT]: text('larkiSupport'),
  [USER_TYPE.GOVERNMENT]: text('governmentUser'),
};
