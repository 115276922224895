import { SHOW_MODAL, HIDE_MODAL } from '../constants/modal';

const defaultState = {
  modalId: '',
};

const modalReducer = (state = defaultState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SHOW_MODAL:
      return {
        ...state,
        id: payload.id,
        modalProps: { ...payload.modalProps, show: true },
      };
    case HIDE_MODAL:
      return { ...state, id: payload.id, modalProps: { show: false } };
    default:
      return state;
  }
};

export default modalReducer;
