import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import text from '../../text';

export const NO_DATA_MODAL = 'NO_DATA_MODAL';

const NoDataModal = (props) => {
  const onCancel = (event) => {
    event?.preventDefault();
    props.onHide();
  };

  return (
    <Modal centered {...props}>
      <Modal.Header className='centered'>
          <h2>3D Data not in database</h2>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p className='text-center'>
            We currently do not have any 3D Data available in the database for
            the location you specified.
          </p>
          <p className='text-center'>
            Please contact us to discuss your needs with a salesperson.
          </p>
          <div className='form-footer'>
            <Button
              href={`${process.env.LARKI_MARKETING_URL}/contact-us`}
              type='button'
              target='_blank'
              onClick={props.onHide}
            >
              {text('contactUs')}
            </Button>
            <a name='cancel' href='#' onClick={onCancel}>
              {text('cancel')}
            </a>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NoDataModal;
