import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';

import {
  ESSENTIALS_MEMBERSHIP_BENEFITS,
  TRIAL_MEMBERSHIP_BENEFITS,
} from '../../constants/membership';
import { hideModal } from '../../redux/actions';
import text from '../../text';
import { client } from '../../utilities/api';
import { getUserMembership, isUserUnderTrial } from '../../utilities/user';
import Button from '../button/Button';

export const CANCEL_MEMBERSHIP_MODAL = 'CANCEL_MEMBERSHIP_MODAL';

const CancelMembershipModal = ({ ...rest }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.profileReducer.userProfile);
  const handleHide = () => dispatch(hideModal(CANCEL_MEMBERSHIP_MODAL));

  const [cancelMembershipState, cancelMembership] = useAsyncFn(
    async (membership) => {
      await client.cancelMembership(membership.id);
      window.location.reload();
    }
  );
  return (
    <Modal centered show onHide={handleHide} {...rest}>
      <Modal.Header className='centered' closeButton>
        <h3>
          {text(
            isUserUnderTrial(currentUser)
              ? 'areYouSureCancelTrialMembership'
              : 'areYouSureCancelMembership'
          )}
        </h3>
      </Modal.Header>
      <Modal.Body>
        <p>
          {text(
            isUserUnderTrial(currentUser)
              ? 'cancelTrialMembershipBenefits'
              : 'cancelEssentialsMembershipBenefits'
          )}
        </p>
        <ul>
          {[
            ...(isUserUnderTrial(currentUser)
              ? TRIAL_MEMBERSHIP_BENEFITS
              : ESSENTIALS_MEMBERSHIP_BENEFITS),
          ].map((item, i) => {
            return (
              <li key={i} className='mb-2'>
                {item}
              </li>
            );
          })}
        </ul>
      </Modal.Body>
      <Modal.Footer className='d-flex flex-column centered'>
        <Button className='full-width' onClick={handleHide} autoFocus>
          {text('keepMembership')}
        </Button>
        <Button
          loading={cancelMembershipState.loading}
          variant='link'
          className='secondary mt-2'
          onClick={() => cancelMembership(getUserMembership(currentUser))}
        >
          {text('cancelMembership')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelMembershipModal;
