import { client } from '../../utilities/api';
import { captureError } from '../../utilities/error';
import {
  DELETE_ACTIVITY,
  LIST_ACTIVITIES,
  LIST_ACTIVITIES_FAILED,
  LIST_ACTIVITIES_SUCCESS,
  TOGGLE_STAFF_ACTIVITIES,
  FILTER_ACTIVITIES,
  ENABLE_ACTIVITY_FILTER,
  DISABLE_ACTIVITY_FILTER,
} from '../constants/activity';
import { alertConstants } from '../constants/alert';

export const listProjectActivities = (projectId, query) => async (dispatch) => {
  try {
    dispatch({
      type: LIST_ACTIVITIES,
      payload: { projectId },
    });

    const { data } = await client.listProjectActivities(projectId, query);

    dispatch({
      type: LIST_ACTIVITIES_SUCCESS,
      payload: { projectId, activities: data },
    });
  } catch (error) {
    dispatch({ type: LIST_ACTIVITIES_FAILED });
    captureError(error);
    dispatch({
      type: alertConstants.ALERT_ERROR,
      payload: { message: error.response?.data?.message },
    });
  }
};

export const deleteActivity = (projectId, activityId) => async (dispatch) => {
  try {
    await client.deleteActivity(projectId, activityId);

    dispatch({
      type: DELETE_ACTIVITY,
      payload: {
        projectId,
        activityId,
      },
    });
  } catch (error) {
    dispatch({
      type: alertConstants.ALERT_ERROR,
      payload: { message: error.response.data.message },
    });
  }
};

export const toggleStaffActivities = () => async (dispatch) =>
  dispatch({ type: TOGGLE_STAFF_ACTIVITIES });

export const filterActivities =
  (filteredActivities, projectId) => async (dispatch) =>
    dispatch({
      type: FILTER_ACTIVITIES,
      payload: {
        projectId,
        activities: filteredActivities,
      },
    });

export const enableFilter = (filterName) => async (dispatch) =>
  dispatch({
    type: ENABLE_ACTIVITY_FILTER,
    payload: {
      filterName,
    },
  });

export const disableFilter = (filterName) => async (dispatch) =>
  dispatch({
    type: DISABLE_ACTIVITY_FILTER,
    payload: {
      filterName,
    },
  });
