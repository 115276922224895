import cn from 'classnames';
import T from 'prop-types';
import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import NavProjectSelect from '../../../../../components/select/jobSelect/NavProjectSelect';
import useProjectsList from '../../../../../hooks/useProjectsList';
import { profileSelectors } from '../../../../../redux/selectors/profile';
import routes from '../../../../../routes/constants';
import text from '../../../../../text';
import ShareLinkNav from './ShareLinkNav';

const FileManagerNav = ({ className, showShareLink }) => {
  const history = useHistory();
  const isAdmin = useSelector(profileSelectors.getIsAdmin);
  const { projects, isLoading } = useProjectsList({ isAdmin });
  return (
    <Nav className={cn('Title', className)}>
      <Navbar>
        <span>{`${text(
          isAdmin ? 'administrationFileManager' : 'fileManager'
        )}:`}</span>
        <NavProjectSelect
          projects={projects}
          isLoading={isLoading}
          onProjectChange={(nextProject, prevProject) => {
            if (nextProject.id !== prevProject.id) {
              history.replace(routes.fileManager.viewProject(nextProject.id));
            }
          }}
        />

        <ShareLinkNav show={showShareLink} />
      </Navbar>
    </Nav>
  );
};

FileManagerNav.propTypes = {
  className: T.string,
};

export default FileManagerNav;
