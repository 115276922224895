import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import Icon from '../icon/Icon';
import DropdownItemLabel from './DropdownItemLabel';
import T from 'prop-types';

const Submenu = (props) => {
  const { children, menuIcon, label, onOpen, className, collapsed = true } = props;
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  useEffect(() => {
    if (!isCollapsed && onOpen) {
      onOpen();
    }
  }, [isCollapsed, onOpen]);

  return (
    <>
      <Dropdown.Item
        className='SubmenuButton'
        onClick={(event) => {
          setIsCollapsed(!isCollapsed);
          event.preventDefault();
        }}
      >
        <DropdownItemLabel label={label} icon={menuIcon} />
        <Icon icon={isCollapsed ? 'caret-down' : 'caret-up'} />
      </Dropdown.Item>
      <div
        className={classnames('Submenu', className, {
          collapsed: isCollapsed,
        })}
      >
        <div className='items'>{children}</div>
      </div>
    </>
  );
};

Submenu.propTypes = {
  children: T.node,
  menuIcon: T.string,
  label: T.string,
  onOpen: T.func,
  className: T.string,
  collapsed: T.bool,
};

export default Submenu;
