import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import FormSection from '../../components/containers/FormSection';
import Control from '../../components/form/Control';
import { resetUserForm, updateUserProfile } from '../../redux/actions';
import { isDefault, isLoading, isSuccess } from '../../redux/stateTools';
import text from '../../text';
import { checkFieldsAreDiff } from '../../utilities/object';
import { userFormSchema } from '../../validation';
import AutoSave from '../dashboard/components/AutoSave';

const UserForm = () => {
  const dispatch = useDispatch();
  const { userProfile, updateUserProfileState } = useSelector(
    (state) => state.profileReducer
  );

  const handleSubmit = async (values, actions) => {
    const diffFields = checkFieldsAreDiff(userProfile, values);
    if (Object.entries(diffFields).some(Boolean)) {
      dispatch(updateUserProfile(values));
      actions.resetForm({ touched: diffFields, values });
    }
  };

  return (
    !isEmpty(userProfile) && (
      <FormSection title={text('profile')}>
        <Formik
          validationSchema={userFormSchema}
          validateOnBlur
          initialValues={userProfile}
          onSubmit={handleSubmit}
        >
          {({
            values: user,
            handleBlur,
            handleChange,
            setTouched,
            touched,
            errors,
            isValid,
          }) => {
            const handleChangeWithReset = (newValues) => {
              if (!isDefault(updateUserProfileState)) {
                dispatch(resetUserForm());
              }
              handleChange(newValues);
            };
            const isFormTouched =
              touched && Object.values(touched).some(Boolean);
            return (
              <div>
                <Row>
                  <Col md='6'>
                    <Control
                      required
                      isSuccessful={
                        touched.full_name && isSuccess(updateUserProfileState)
                      }
                      isFailed={isFormTouched && errors.full_name}
                      as='input'
                      type='text'
                      name='full_name'
                      value={user.full_name ?? ''}
                      onInput={() => setTouched({ full_name: true })}
                      onChange={handleChangeWithReset}
                      onBlur={handleBlur}
                      label={text('fullName')}
                      validationError={errors.full_name}
                    />
                  </Col>
                  <Col md='6'>
                    <Control
                      as='input'
                      type='email'
                      name='email'
                      value={user.email ?? ''}
                      onBlur={handleBlur}
                      label={text('email')}
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md='6'>
                    <Control
                      isSuccessful={
                        touched.phone && isSuccess(updateUserProfileState)
                      }
                      isFailed={isFormTouched && errors.phone}
                      as='input'
                      type='tel'
                      name='phone'
                      value={user.phone ?? ''}
                      onInput={() => setTouched({ phone: true })}
                      onChange={handleChangeWithReset}
                      onBlur={handleBlur}
                      label={text('phone')}
                      validationError={errors.phone}
                    />
                  </Col>
                  <Col md='6'>
                    <Control
                      isSuccessful={
                        touched.profession && isSuccess(updateUserProfileState)
                      }
                      isFailed={isFormTouched && errors.profession}
                      as='input'
                      type='text'
                      name='profession'
                      onBlur={handleBlur}
                      label={text('occupation')}
                      value={user.profession ?? ''}
                      onChange={handleChangeWithReset}
                    />
                  </Col>
                </Row>
                <AutoSave
                  isValidForm={isValid}
                  isSubmitting={isLoading(updateUserProfileState)}
                  onSubmit={handleSubmit}
                />
              </div>
            );
          }}
        </Formik>
      </FormSection>
    )
  );
};

export default UserForm;
