import classnames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { SUBSCRIPTION_PRICE } from '../../constants/membership';
import useMembershipButton from '../../hooks/useMembershipButton';
import routes from '../../routes/constants';
import text from '../../text';
import { isUserStaff, userHasActiveMembership } from '../../utilities/user';
import Button from './Button';

const SubscribeButton = ({ className, variant = 'primary' }) => {
  const { currentUser } = useSelector((reduxState) => ({
    currentUser: reduxState.profileReducer.userProfile,
  }));
  const { handleMembershipClick } = useMembershipButton();

  return (
    !isUserStaff(currentUser) &&
    !userHasActiveMembership(currentUser) && (
      <Button
        id='SubscribeButton'
        className={classnames(className)}
        variant={variant}
        onClick={() =>
          handleMembershipClick({
            redirectTo: {
              path: `${routes.user.profile}?focusedTab=membership`,
              label: text('navigateToRoute', {
                navigation: 'Back',
                routeName: text('account'),
              }),
            },
          })
        }
      >
        {text('subscribeButtonLabel', {
          yearly: SUBSCRIPTION_PRICE.yearly,
          monthly: SUBSCRIPTION_PRICE.monthly,
        })}
      </Button>
    )
  );
};

export default SubscribeButton;
