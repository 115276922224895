import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import T from 'prop-types';
import CloseButton from './CloseButton';
import text from '../../../../text';
import Icon from '../../../icon/Icon';

export default function Header({
  isPreviewButtonHidden,
  isScreenshotButtonHidden,
}) {
  return (
    <Modal.Header>
      <div className='d-flex flex-row' style={{ gap: 16 }}>
        {!isPreviewButtonHidden && (
          <Button variant='light' className='row-action with-text icon-button'>
            <Icon icon='preview' />
            <span>{text('preview')}</span>
          </Button>
        )}

        {!isScreenshotButtonHidden && (
          <Button variant='light' className='row-action with-text icon-button'>
            <Icon icon='camera' />
            <span>{text('screenshot')}</span>
          </Button>
        )}
      </div>

      <CloseButton />
    </Modal.Header>
  );
}

Header.propTypes = {
  isPreviewButtonHidden: T.bool,
  isScreenshotButtonHidden: T.bool,
};
