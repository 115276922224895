import T from 'prop-types';
import React, { forwardRef } from 'react';
import { Button } from 'react-bootstrap';

import classNames from 'classnames';
import Icon from '../icon/Icon';

const IconButton = forwardRef((props, ref) => {
  const { icon, iconProps = {}, ...restProps } = props;
  return (
    <Button bsPrefix='btn-icon' {...restProps} ref={ref}>
      <Icon
        icon={icon}
        className={classNames({ original: iconProps.isOriginal })}
      />
    </Button>
  );
});

IconButton.propTypes = {
  icon: T.string,
};

export default IconButton;
