import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React from 'react';
import { Table } from 'react-bootstrap';

const DataTable = (props) => {
  const { children, className } = props;
  return (
    <Table
      className={classNames('DataTable', {
        [className]: !isEmpty(className),
      })}
    >
      {children}
    </Table>
  );
};

export default DataTable;
