import React from 'react';
import text from '../../text';

const Header = () => {
  return (
    <h2 className='green-1 text-center'>{text('joinTheBestInTheIndustry')}</h2>
  );
};

export default Header;
