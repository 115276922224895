import { useEffect, useCallback } from 'react';
import { useFormikContext } from 'formik';
import debounce from 'lodash/debounce';
import { AUTOSUBMIT_DELAY } from '../../constants';

const AutoSave = ({ isValidForm }) => {
  const { dirty, submitForm, values } = useFormikContext();
  const debouncedSubmit = useCallback(debounce(submitForm, AUTOSUBMIT_DELAY), [
    submitForm,
  ]);

  useEffect(() => {
    dirty && isValidForm && debouncedSubmit();
  }, [debouncedSubmit, dirty, isValidForm, values]);

  return null;
};

export default AutoSave;
