// UI constants
export const AUTOSUBMIT_DELAY = 2000;
export const AUTOSEARCH_DELAY = 600;
export const RESET_FORM_DELAY = 3000;
export const MODAL_DELAY = 10000;
export const MAP_IDLE_TIME = 10000;//ms
export const TOOLTIP = {
  SHOW_DELAY: 800,
  HIDE_DELAY: 500,
};

export const DOUBLE_CLICK_INTERVAL = 300; // miliseconds

// Sizes referencing the bootstrap guide
// https://github.com/twbs/bootstrap/blob/6ffb0b48e455430f8a5359ed689ad64c1143fac2/scss/_variables.scss#L195
export const BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};
