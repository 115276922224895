import classNames from 'classnames';
import { useField } from 'formik';
import { isEmpty } from 'lodash';
import React from 'react';
import { ToggleButtonGroup as BootstrapToggleButtonGroup } from 'react-bootstrap';

const ToggleButtonGroup = (props) => {
  const { className, name, type, children } = props;
  const [field, meta, helpers] = useField(name);

  const onChange = (value) => {
    helpers.setValue(value, true);
  };

  return (
    <div
      className={classNames('Control', {
        [className]: !isEmpty(className),
      })}
    >
      <div className='Control-Container'>
        <BootstrapToggleButtonGroup
          type={type}
          name={field.name}
          value={meta.value}
          onChange={onChange}
          onBlur={field.onBlur}
        >
          {children}
        </BootstrapToggleButtonGroup>
      </div>
      <p
        className={classNames('Control-Feedback', {
          isFailed: meta.touched && !!meta.error,
        })}
      >
        {meta.touched && meta.error}
      </p>
    </div>
  );
};

export default ToggleButtonGroup;
