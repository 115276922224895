import { Elements, useStripe } from '@stripe/react-stripe-js';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAsyncFn, useSearchParam } from 'react-use';

import WhiteContainer from '../../components/containers/WhiteContainer';
import Loading from '../../components/loading/Loading';
import Page from '../../components/page/Page';
import { stripePromise } from '../../utilities/api';
import PaymentSuccess from './PaymentSuccess';

const PaymentStatus = () => {
  const stripe = useStripe();

  const orderClientSecret = useSearchParam('orderClientSecret');
  const membershipClientSecret = useSearchParam('membershipClientSecret');
  const { jobId } = useParams();

  const [orderPaymentIntent, getOrderPaymentIntent] = useAsyncFn(
    async (secret) => {
      const { paymentIntent } = await stripe.retrievePaymentIntent(secret);
      return paymentIntent;
    },
    [stripe]
  );

  const [membershipPaymentIntent, getMembershipPaymentIntent] = useAsyncFn(
    async (secret) => {
      const { paymentIntent } = await stripe.retrievePaymentIntent(secret);
      return paymentIntent;
    },
    [stripe]
  );

  useEffect(() => {
    if (stripe && orderClientSecret) {
      getOrderPaymentIntent(orderClientSecret);
    }
  }, [stripe]);

  useEffect(() => {
    if (stripe && membershipClientSecret) {
      getMembershipPaymentIntent(membershipClientSecret);
    }
  }, [stripe]);

  const render = (paymentIntent) => {
    switch (paymentIntent?.value?.status) {
      case 'succeeded': {
        return (
          <PaymentSuccess
            jobId={jobId}
            orderPaymentIntent={paymentIntent}
            membershipPaymentIntent={membershipPaymentIntent}
          />
        );
      }
      default: {
        <Loading />;
      }
    }
  };

  return (
    <Page className='PaymentStatus PageCenter'>
      <WhiteContainer>{render(orderPaymentIntent)}</WhiteContainer>
    </Page>
  );
};

const PaymentStatusContainer = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentStatus />
    </Elements>
  );
};

export default PaymentStatusContainer;
