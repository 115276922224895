/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import Icon from '../icon/Icon';
import { IconWrapper } from '../RightMenu/Product';

const ProductIndicator = ({
  className,
  categoryName,
  isSecondary,
  isFocused = false,
  isDisabled = false,
}) => {
  return (
    <div
      className={classNames(
        'product-indicator',
        categoryName,
        className,
        isSecondary && 'secondary'
      )}
    >
      <IconWrapper
        icon={categoryName}
        isActive={isFocused}
        isSecondary={isSecondary}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default ProductIndicator;
