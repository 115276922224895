export const PROJECT_TRIAL_ROLE = 'project-trial';
export const FREE_ROLE = 'free';

export const PROJECT_ROLE = {
  PROJECT_TRIAL: PROJECT_TRIAL_ROLE,
  FREE: FREE_ROLE,
};

export const PROJECT_TEAM_MEMBER_LIMIT = 3;

export const FETCH_STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  DONE: 'done',
  ERROR: 'error',
};
