import _ from 'lodash/fp';
import { PRODUCT_DATA } from '../components/mapView/constants';
import {
  computeArea,
  computeIntersection,
  computeMinEnvelope,
  computeUnion,
  computeVisualCenter,
  computeWidthHeight,
  hasOverlap,
} from '../components/mapView/geometry';

// Promise.all but for objects
const promiseAllObj = async (obj) => {
  return _.zipObj(_.keys(obj), await Promise.all(_.values(obj)));
};

const COVERAGE_MIN_DIMENSION_IN_M = 10;

export const getUnseenHashes = (hashesToAdd, hashes) => {
  return _.difference(hashesToAdd, hashes);
};

const reorderCoverage = (coverage) => {
  return _.sortBy(
    (product) => PRODUCT_DATA.entities[product.category_name].order
  )(coverage);
};

// EDGE CASE: it is possible that coverage queries could yield different product IDs for the same product type
// This is rare, since products are generally defined for very large areas e.g. VIC, NSW, QLD
// so this should only happen when the coverage is queried across borders.
// In these cases, the products will not load properly.
// TODO: ^
export const mergeCoverage = (coverages) => {
  const merged = _.reduce((merged, products) => {
    return _.flow(
      _.map((product) => {
        const existingProduct = merged?.[product.category_name];
        return _.set(
          product.category_name,
          _.flow(
            _.map(([k, v]) => {
              if (existingProduct) {
                if (k === 'area_in_sqm') {
                  return _.set(k, v + existingProduct[k]);
                }
                if (k === 'geometry') {
                  return _.set(k, computeUnion(v, existingProduct[k]));
                }
                if (k === 'layers') {
                  return _.set(
                    k,
                    _.reduce((prevLayers, newLayer) => {
                      const existingLayer = prevLayers?.[newLayer.layer_id];
                      if (existingLayer) {
                        return _.set(
                          newLayer.layer_id,
                          _.flow(
                            _.map(([k, v]) => {
                              if (k === 'area_in_sqm') {
                                return _.set(k, v + existingLayer[k]);
                              }
                              if (k === 'geometry') {
                                const union = computeUnion(v, existingLayer[k]);
                                return _.set(k, union);
                              }
                              if (k === 'marker_locations') {
                                return _.set(k, _.concat(v, existingLayer[k]));
                              }
                              if (k === 'acquired_at') {
                                return _.set(k, v);
                              }
                              console.assert(
                                v === existingLayer[k],
                                `layer ${newLayer.layer_id} has different ${k} value to existing layer ${existingLayer.layer_id}: ${v} vs ${existingLayer[k]}`
                              );
                              return _.set(k, v);
                            })(_.toPairs(newLayer))
                          )(existingLayer)
                        )(prevLayers);
                      }
                      return _.set(newLayer.layer_id, newLayer)(prevLayers);
                    })(existingProduct.layers)(v)
                  );
                }
              }
              return _.set(k, v);
            })(_.toPairs(product))
          )(product)
        );
      })(products)
    )(merged);
  })({})(coverages);
  return reorderCoverage(merged);
};

/**
 * @param geometry - GeoJSON geometry of a polygon or multi-polygon
 * @returns true iff the geometry has an envelope with width and height greater than the minimum.
 */
const coverageFilter = (geometry) => {
  const envelope = computeMinEnvelope(geometry);
  const [width, height] = computeWidthHeight(envelope);
  return (
    width >= COVERAGE_MIN_DIMENSION_IN_M &&
    height >= COVERAGE_MIN_DIMENSION_IN_M
  );
};

export const filterProductCoverageLayersByIntersection = (
  productCoverage,
  geometry
) => {
  // TODO: issue with coverage, streetscape layers returns 'Polygon' as geometry type, when actually 'MultiPolygon'
  // this is not a bug in practice, since computeIntersection knows how to handle both
  const intersectingLayers = _.reduce((layers, layer) => {
    const intersection = computeIntersection(layer.geometry, geometry);
    let markerLocations = [];
    if (intersection) {
      if (intersection.type === 'Polygon') {
        if (coverageFilter(intersection)) {
          const centroid = computeVisualCenter(intersection);
          markerLocations.push(centroid.coordinates);
        }
      } else {
        console.assert(intersection.type === 'MultiPolygon');
        _.forEach((coords) => {
          const geom = { type: 'Polygon', coordinates: coords };
          if (coverageFilter(geom)) {
            const centroid = computeVisualCenter(geom);
            markerLocations.push(centroid.coordinates);
          }
        }, intersection.coordinates);
      }
      return _.set(
        layer.layer_id,
        _.flow(
          _.set('geometry', intersection),
          _.set('marker_locations', markerLocations),
          _.set('area_in_sqm', computeArea(intersection)) // TODO: fixme
        )(layer)
      )(layers);
    }
    return layers;
  })({})(productCoverage.layers);
  // TODO: set the area_in_sqm of the product to the sum of the areas of the intersecting layers
  return _.set('layers', intersectingLayers)(productCoverage);
};

export const filterCoverageLayersByIntersection = async (
  coverage,
  geometry
) => {
  let coverageWithIntersectingLayers = _.mapValues((product) => {
    return filterProductCoverageLayersByIntersection(product, geometry);
  }, coverage);
  return reorderCoverage(coverageWithIntersectingLayers);
};

/**
 * Filters out coverage layers that do not overlap with geometry.
 * Does not mutate the underlying geometry.
 * @param coverage
 * @param geometry
 */
export const filterCoverageLayersByOverlap = (coverage, geometry) => {
  return _.mapValues(
    (product) =>
      _.set(
        'layers',
        _.pickBy((layer) => {
          return hasOverlap(layer.geometry, geometry);
        }, product.layers),
        product
      ),
    coverage
  );
};
