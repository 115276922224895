import { useStripe } from '@stripe/react-stripe-js';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Wizard } from 'react-use-wizard';

import { MEMBERSHIP } from '../../../constants/membership';
import {
  alertWarning,
  assignPayment,
  refreshCustomer,
  sendLead,
  setMembershipPriceId,
  showModal,
  subscribe,
} from '../../../redux/actions';
import { isStaff, isUserUnderTrial } from '../../../utilities/user';
import MembershipManagementPaymentStep from './MembershipManagementPaymentStep';

import { isEmpty, noop } from 'lodash';
import { PAYMENT_FAILURE_MODAL } from '../../../components/modal/PaymentFailureModal';
import StripeElements from '../../../components/stripe/StripeElements';
import Breadcrumbs from '../../../components/wizard/Breadcrumbs';
import BreadcrumbStep from '../../../components/wizard/BreadcrumbStep';
import { LEAD_START_ESSENTIAL } from '../../../constants/job';
import { PAY_MODE } from '../../../constants/order';
import { useQuery } from '../../../hooks/useQuery';
import routes from '../../../routes/constants';
import text from '../../../text';
import ConfirmationStep from '../OrderWizard/ConfirmationStep';
import MembershipStep from '../OrderWizard/MembershipStep';
import { PaymentContext } from '../PaymentContext';

const isAssignedPayment = (selectedPayMode) =>
  selectedPayMode === PAY_MODE.ASSIGNED_PAYMENT;

const MembershipManagementWizard = () => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const returnPage = routes.user.membershipManagement;
  const returnUrl = `${process.env.LARKI_APP_URL}${returnPage}`;
  const queryParams = useQuery();
  const history = useHistory();
  const location = useLocation();
  const {
    states: { membershipStepper },
  } = useContext(PaymentContext);

  const {
    currentUser,
    membershipPriceId,
    selectedPayMode,
    paymentMethodId,
  } = useSelector((state) => ({
    membershipPriceId: state.order.membershipPriceId,
    paymentMethodId: state.order.paymentMethodId,
    selectedPayMode: state.order.selectedPayMode,
    currentUser: state.profileReducer.userProfile,
    membershipPrices: state.profileReducer.membershipPrices,
    upsertMembership: state.profileReducer.upsertMembership,
  }));

  const handleSendToPayer = async (
    payerName,
    payerEmail,
    message,
    nextStep = noop
  ) => {
    if (!isAssignedPayment(selectedPayMode)) {
      nextStep();
      return;
    }

    // 'Send to Payer' payment selected
    await dispatch(
      assignPayment(
        membershipPriceId,
        undefined,
        payerEmail,
        payerName,
        message
      )
    );

    history.push({
      pathname: routes.payment.assignedStatus(),
      state: { payerEmail, payerName, isMembership: true },
    });
  };

  const handleSubmit = async () => {
    let redirectTo = null;
    if (location.state?.redirectTo) {
      redirectTo = location.state?.redirectTo;
    } else if (queryParams.get('redirectTo')) {
      redirectTo = {
        path: queryParams.get('redirectTo'),
        label: queryParams.get('label'),
      };
    }

    const newMembership = await dispatch(
      subscribe({
        user: currentUser,
        priceId: membershipPriceId,
        paymentMethodId,
        isTrial: false,
      })
    );
    if (newMembership.paymentIntent?.status === 'requires_confirmation') {
      const membershipResult = await stripe.confirmCardPayment(
        newMembership.payment_intent_secret,
        { payment_method: paymentMethodId, return_url: returnUrl },
        { handleActions: false }
      );
      if (membershipResult?.error) {
        console.error(membershipResult.error);
        dispatch(
          showModal(PAYMENT_FAILURE_MODAL, {
            message: text('membershipPaymentFailed'),
          })
        );
      }
    }

    await dispatch(refreshCustomer());

    dispatch(sendLead(null, LEAD_START_ESSENTIAL));

    history.push({
      pathname: routes.user.membershipChangeSuccess,
      ...(!isEmpty(redirectTo) && {
        state: { redirectTo },
      }),
    });
  };

  useEffect(() => {
    if (isUserUnderTrial(currentUser)) {
      dispatch(setMembershipPriceId(MEMBERSHIP.MONTHLY.PRICE_ID));
    }
  }, [currentUser, dispatch]);

  return (
    <div className='MembershipManagementWizard'>
      <Wizard
        header={
          <Breadcrumbs>
            {membershipStepper.map((stepName, index) => (
              <BreadcrumbStep
                name={stepName}
                key={index}
                stepIndex={index}
                stepLabel={index + 1}
              />
            ))}
          </Breadcrumbs>
        }
      >
        <MembershipStep
          includeNoMembership={false}
          membershipPriceId={membershipPriceId}
          onMembershipChange={(membershipPriceIdValue) =>
            dispatch(
              setMembershipPriceId(
                membershipPriceIdValue.target?.value
                  ? membershipPriceIdValue.target?.value
                  : membershipPriceIdValue
              )
            )
          }
          nextButtonProps={{
            onClick: (nextStep) => {
              if (isStaff(currentUser.role)) {
                dispatch(
                  alertWarning(text('disabledPaymentScreenForSupportAlert'), {})
                );
              } else {
                nextStep();
              }
            },
          }}
        />
        <MembershipManagementPaymentStep
          isAssignedPayment={isAssignedPayment(selectedPayMode)}
          nextButtonProps={{
            label: isAssignedPayment(selectedPayMode)
              ? text('sendRequest')
              : text('continueToConfirm'),
          }}
          onSubmit={({ payerEmail, payerName, message }) =>
            handleSendToPayer(payerName, payerEmail, message)
          }
        />
        <ConfirmationStep onSubmit={handleSubmit} />
      </Wizard>
    </div>
  );
};

const MembershipManagementWizardWithStripe = (props) => {
  return (
    <StripeElements>
      <MembershipManagementWizard {...props} />
    </StripeElements>
  );
};
export default MembershipManagementWizardWithStripe;
