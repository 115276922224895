import React from 'react';
import text from '../../text';
import routes from '../../routes/constants';

const FooterBottom = () => {
  return (
    <div id='FooterBottom'>
      <div className='FooterBottom-Start'>{text('copyright')}</div>
      <div className='FooterBottom-End'>
        <a
          href={routes.larkiWebsiteUrl.privacy}
          target='_blank'
          rel='noreferrer'
        >
          {text('privacyPolicy')}
        </a>
        <span>|</span>
        <a
          href={routes.larkiWebsiteUrl.custTermsCond}
          target='_blank'
          rel='noreferrer'
        >
          {text('customerTermsConditions')}
        </a>
        <span>|</span>
        <a
          href={routes.larkiWebsiteUrl.supplierTermsCond}
          target='_blank'
          rel='noreferrer'
        >
          {text('supplierTermsConditions')}
        </a>
      </div>
    </div>
  );
};

export default FooterBottom;
