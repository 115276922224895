import React from 'react';
import map from 'lodash/map';
import flowFP from 'lodash/fp/flow';
import filterFP from 'lodash/fp/filter';
import mergeFP from 'lodash/fp/merge';
import keyByFP from 'lodash/fp/keyBy';

import text from '../../../text';
import CreateTable from '../../select/CreateTable';
import { ASSET_ICON_MAP } from '../../../constants/product';

const productOptions = map(ASSET_ICON_MAP, (_, productKey) => ({
  productKey,
  label: productKey,
}));

const getProductOption = (productKey) =>
  productKey
    ? {
        productKey,
        label: productKey,
      }
    : undefined;

const ProductSelect = (props) => {
  const {
    onChange,
    product,
    existingProducts,
    isProductSelectionDisabled,
    placeholder,
  } = props;

  const options = flowFP([
    filterFP((item) => item.productKey),
    (result) =>
      map(result, (item) => ({
        ...item,
        isDisabled: true,
        ...getProductOption(item.productKey),
      })),
    keyByFP('productKey'),
    mergeFP(keyByFP('productKey')(productOptions)),
    (result) => map(result, (item) => ({ ...item })),
  ])(existingProducts);

  return (
    <>
      <CreateTable
        placeholder={placeholder}
        options={options}
        isDisabled={isProductSelectionDisabled}
        onChange={(option) => {
          const value = option.productKey
            ? option
            : getProductOption(option.value);
          onChange(value);
        }}
        createInputPlaceholder={text('enterNewProduct')}
        getOptionValue={(option) => option.productKey}
        value={getProductOption(product)}
      />
    </>
  );
};

export default ProductSelect;
