import { HIDE_MODAL, SHOW_MODAL } from '../constants/modal';

export const showModal = (id, modalProps) => ({
  type: SHOW_MODAL,
  payload: { id, modalProps },
});

export const hideModal = (id) => ({
  type: HIDE_MODAL,
  payload: { id },
});
