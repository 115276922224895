import cn from 'classnames';
import T from 'prop-types';
import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';

import Img from '../../components/img/Img';
import text from '../../text';

export default function FileManagerFallbackArrow({ show, referenceElement }) {
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'right',
      modifiers: [{ name: 'offset', options: { offset: [0, 15] } }],
    }
  );

  useEffect(() => {
    if (show && update) {
      update();
    }
  }, [show]);

  return (
    <div
      id='file-manager-fallback-arrow'
      className={cn('file-manager-fallback-arrow', show && 'show')}
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
    >
      <Img img='file-manager-fallback-arrow' alt={text('startNewProject')} />
    </div>
  );
}

FileManagerFallbackArrow.propTypes = {
  show: T.bool.isRequired,
  referenceElement: T.any,
};
