import React from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import Control from '../form/Control';
import Loading from '../loading/Loading';

const NewCard = (props) => {
  const {
    name,
    value,
    label,
    error,
    isFailed,
    onChange,
    onPaymentElementChange,
  } = props;
  const stripe = useStripe();
  const elements = useElements();

  const isLoading = !stripe || !elements;
  return isLoading ? (
    <Loading />
  ) : (
    <div className='NewCard'>
      <Control
        onChange={onChange}
        label={label}
        as='input'
        type='text'
        name={name}
        value={value}
        isFailed={isFailed}
        validationError={error}
      />
      <PaymentElement
        onChange={(elementProps) =>
          onPaymentElementChange(elementProps.complete)
        }
        options={{
          fields: {
            billingDetails: {
              address: {
                country: 'never',
              },
            },
          },
        }}
      />
    </div>
  );
};

export default NewCard;
