import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useBoolean } from 'react-use';
import { COUPON_MODAL } from '../components/modal/CouponModal';
import { hideModal, showModal } from '../redux/actions';

const useCoupon = (
  modalProps = {
    title: null,
    onAcceptLabel: null,
    onAccept: null,
  },
  predicate = (args = true) => args // return true by default
) => {
  const [hasDiscountDataModalAppeared, setHasDiscountDataModalAppeared] =
    useBoolean(false);
  const dispatch = useDispatch();

  const dispatchModal = useCallback(() => {
    if (predicate(hasDiscountDataModalAppeared)) {
      dispatch(
        showModal(COUPON_MODAL, {
          title: modalProps.title,
          onAcceptLabel: modalProps.onAcceptLabel,
          onAccept: () => {
            modalProps.onAccept && modalProps.onAccept();
            dispatch(hideModal(COUPON_MODAL));
          },
        })
      );
      setHasDiscountDataModalAppeared(true);
    }
  }, [
    hasDiscountDataModalAppeared,
    setHasDiscountDataModalAppeared,
    dispatch,
    modalProps,
    predicate,
  ]);

  useEffect(() => {
    dispatchModal();
  }, [dispatchModal]);
};

export default useCoupon;
