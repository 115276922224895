import React from 'react';
import { Image } from 'react-bootstrap';
import T from 'prop-types';

const Img = (props) => {
  const { img, isUrl, id, ...rest } = props;
  const src = isUrl ? img : `/public/img/${img}.png`;
  return <Image id={id} src={src} {...rest} />;
};

Img.propTypes = {
  img: T.string.isRequired,
  isUrl: T.bool,
};

Img.defaultProps = {
  isUrl: false,
};

export default Img;
