import * as turf from '@turf/turf';
import axios from 'axios';
import _ from 'lodash/fp';

const multiPolygonToPolygons = (multiPolygon) => {
  return _.map((polygonCoords) => {
    return turf.polygon(polygonCoords).geometry;
  }, multiPolygon.coordinates);
};

export const getHighResCollections = async () => {
  const { data } = await axios.get(
    `${process.env.AEROMETREX_STAC_URL}/collections`
  );
  return data.collections;
};

export const getHighResCollectionsContainsBBox = (collections, bbox) => {
  const bboxGeom = turf.bboxPolygon(bbox);
  return _.filter((collection) => {
    const extentGeom = turf.bboxPolygon(collection.extent.spatial.bbox[0]);
    return turf.booleanContains(extentGeom, bboxGeom);
  }, collections);
};

export const EXCLUDED_LAYERNAMES = ['Melbourne_Centre_North_2021_2_GM'];

// bbox = [minx, miny, maxx, maxy] in epsg:4326
export const getHighResLayers = async (
  collectionId,
  bbox,
  offset = 0,
  limit = 50
) => {
  const initialParams = new URLSearchParams({
    bbox: bbox.join(','),
    offset,
    limit,
  });
  const features = [];
  let nextLink = `${
    process.env.AEROMETREX_STAC_URL
  }/collections/${collectionId}/items?${initialParams.toString()}`;

  // todo: do in parallel if the next link is predictable
  console.log(collectionId, nextLink);
  while (nextLink) {
    const { data } = await axios.get(nextLink);
    features.push(...data.features);

    // we need to omit 'datetime' from the query to get the avoid errors
    const rawNextLink = _.find({ rel: 'next' }, data.links)?.href;
    if (rawNextLink) {
      const [base, search] = rawNextLink.split('?');
      const rawParams = new URLSearchParams(search);
      const withDatetimeOmitted = new URLSearchParams(
        _.omit(['datetime'], Object.fromEntries(rawParams))
      );
      nextLink = `${base}?${withDatetimeOmitted.toString()}`;
    } else {
      nextLink = null;
    }
  }

  const bboxGeom = turf.bboxPolygon(bbox);
  const filtered = _.filter((feature) => {
    const { layername } = feature.properties;
    if (layername.includes('CIR')) {
      return false;
    }
    if (EXCLUDED_LAYERNAMES.includes(layername)) {
      return false;
    }
    const geom = turf.feature(feature.geometry).geometry;
    // turf.booleanContains does not work with MultiPolygon
    // so split into Polygons and check that one of them contains the bbox
    const geoms =
      geom.type === 'MultiPolygon' ? multiPolygonToPolygons(geom) : [geom];
    const contains = _.some((g) => turf.booleanContains(g, bboxGeom), geoms);
    return contains;
  }, features);

  console.log(
    `[${collectionId}] ${features.length} -> ${filtered.length} features`
  );

  return filtered;
};

export const groupHighResLayersByDate = (features) => {
  return _.groupBy(
    (feature) => feature.properties.datetime.split('T')[0],
    features
  );
};
