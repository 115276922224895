import _ from 'lodash/fp';

import { DRAW_LAYERS_MERGED } from '../components/mapView/constants';
import {
  computeDifference,
  computeIntersection,
  computeUnion,
} from '../components/mapView/geometry';
import { PRODUCT_TYPE } from '../constants/product';
import { selectionToGeoJsonNoGM } from './utilities';

const ADDON_TYPES = [
  PRODUCT_TYPE.BIM_EXTERIOR,
  PRODUCT_TYPE.BIM_INTERIOR,
  PRODUCT_TYPE.TWOD_PLAN_EXTERIOR,
  PRODUCT_TYPE.TWOD_PLAN_INTERIOR,
];

const newLayer = (geometry, productType, hovered, focused, selected) => ({
  geometry,
  productType,
  hovered,
  focused,
  selected,
});

// given coverage of a particular product (e.g. Streetscape, Aerial, etc...)
//  crop the coverage layers to be within the provided `drawRegion`
// new layers wil adopt the provided `hovered`, `focused`, `selected` as object properties
export const drawProductLayers = (
  productCoverage,
  drawRegion,
  hovered,
  focused,
  selected
) => {
  if (DRAW_LAYERS_MERGED[productCoverage.category_name]) {
    let mergedLayerGeometry;
    _.forEach((layer) => {
      const layerGeometry = computeIntersection(layer.geometry, drawRegion);
      if (!layerGeometry) {
        return;
      }
      mergedLayerGeometry = mergedLayerGeometry
        ? computeUnion(mergedLayerGeometry, layerGeometry)
        : layerGeometry;
    })(productCoverage.layers);
    if (!mergedLayerGeometry) {
      console.warn('no merged layer geometry found');
      return [];
    }
    return [
      newLayer(
        mergedLayerGeometry,
        productCoverage.category_name,
        hovered,
        focused,
        selected
      ),
    ];
  } else {
    return _.flow(
      _.map((layer) => {
        const layerGeometry = computeIntersection(layer.geometry, drawRegion);
        return { layer, layerGeometry };
      }),
      _.filter(({ layerGeometry }) => !_.isEmpty(layerGeometry)),
      _.map(({ layer, layerGeometry }) =>
        newLayer(layerGeometry, layer.category_name, hovered, focused, selected)
      )
    )(productCoverage.layers);
  }
};

const cachedDrawingLayers = {};

const drawSelectionProducts = ({
  coverage,
  selections,
  activeSelection,
  useCached = false,
}) => {
  const selectionsByCategoryName = _.groupBy('category_name')(selections);
  const layers = [];

  const getSelectionRegion = (selection) => {
    const isActiveSelectionAnAddon = ADDON_TYPES.includes(
      selection.category_name
    );

    if (isActiveSelectionAnAddon) {
      const addOnParentId = selection.parent_selection_id;
      const parent = selections.find(
        (selection) => selection.id === addOnParentId
      );

      // safe fallback
      if (!parent) {
        return selectionToGeoJsonNoGM(selection)?.geometry;
      }

      return computeIntersection(
        selectionToGeoJsonNoGM(parent)?.geometry,
        selectionToGeoJsonNoGM(selection)?.geometry
      );
    }

    return selectionToGeoJsonNoGM(selection)?.geometry;
  };

  _.forEach((productCoverage) => {
    const draw = (drawRegion, activeDrawRegion) => {
      if (drawRegion) {
        layers.push(
          ...drawProductLayers(productCoverage, drawRegion, false, false, true)
        );
      }
      if (activeDrawRegion) {
        layers.push(
          ...drawProductLayers(
            productCoverage,
            activeDrawRegion,
            false,
            true,
            true
          )
        );
      }
    };

    if (useCached) {
      const cached = cachedDrawingLayers[productCoverage.category_name];
      if (cached) {
        draw(cached.drawRegion, cached.activeDrawRegion);
        return;
      }
    }

    const selections = _.filter(
      'visible',
      selectionsByCategoryName[productCoverage.category_name]
    );
    if (!selections || selections.length === 0) {
      return;
    }

    let drawRegion;
    const activeDrawRegion =
      activeSelection &&
      activeSelection.category_name === productCoverage.category_name
        ? getSelectionRegion(activeSelection)
        : null;

    _.forEach((selection) => {
      const selectionRegion = getSelectionRegion(selection);

      if (!selectionRegion) {
        console.warn(`no selection region found for ${selection.name}`);
        return;
      }
      const isActive = !!activeSelection && selection.id === activeSelection.id;
      if (!isActive) {
        if (!drawRegion) {
          drawRegion = selectionRegion;
        } else {
          drawRegion = computeUnion(drawRegion, selectionRegion);
        }
      }
    })(selections);
    if (drawRegion && activeDrawRegion) {
      drawRegion = computeDifference(drawRegion, activeDrawRegion);
    }

    draw(drawRegion, activeDrawRegion);
    cachedDrawingLayers[productCoverage.category_name] = {
      drawRegion,
      activeDrawRegion,
    };
  })(coverage);

  return layers;
};

self.onmessage = (e) => {
  const { coverage, selections, activeSelection, useCached } = e.data;
  self.postMessage(
    drawSelectionProducts({ coverage, selections, activeSelection, useCached })
  );
};

export {};
