import { object, string } from 'yup';
import text from '../text';

export const PHONE_REGEX =
  /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;

export const userFormSchema = object().shape({
  full_name: string().required(text('required')),
  phone: string().matches(PHONE_REGEX, { message: text('invalidPhone') }),
});
