import { useCallback, useContext, useEffect } from 'react';
import { MapViewContext } from './mapViewContext';
import { checkAutomaticSmartSelectionsAvailability } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { noop } from 'lodash';

export default function useAutomaticSmartSelectionsChecker(
  geocode,
  geocodeReady,
  firstRender = null
) {
  const dispatch = useDispatch();
  const { state } = useContext(MapViewContext);
  const isUndefinedAddress = useSelector(
    (state) => state.jobsReducer.undefinedAddress
  );

  const checker = useCallback(async () => {
    const place = await geocode(state.job.full_address);
    dispatch(
      checkAutomaticSmartSelectionsAvailability(
        {
          address: state.job.address,
          city: state.job.city,
          state: state.job.state,
          postcode: state.job.postcode,
          location: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
        },
        isUndefinedAddress
      )
    );
    if (firstRender) {
      firstRender.current = false;
    }
  }, [state.job, geocodeReady]);

  useEffect(() => {
    if (!state.job?.full_address) {
      return;
    }
    if (!geocodeReady) {
      return;
    }

    // Skip checkAutomaticSmartSelectionsAvailability
    // if an order is existing - to stop trigger /api/engine/selections endpoint
    const { quote } = state.job;
    if (quote?.charges.length > 0 && firstRender && !firstRender.current) {
      return;
    }

    checker();
  }, [checker]);
}
