import React from 'react';
import { Badge, Button } from 'react-bootstrap';
import cn from 'classnames';

import text from '../../text';
import Icon from '../icon/Icon';

const CartButton = ({
  // if true, the icon will show an X;
  // if false, the icon will show a cart with a badge of the number of items in the cart
  open,
  className,
  count,
  ...rest
}) => {
  return (
    <Button
      variant='outline-primary'
      className={cn('cart-button', className, open && 'open')}
      {...rest}
    >
      <div className='wrapper'>
        <Icon icon={open ? 'close-box' : 'shopping-cart'} />
        <span className='text'>{text('cart')}</span>
        {!open && (
          <Badge pill variant='danger' className='badge-count'>
            {count}
          </Badge>
        )}
      </div>
    </Button>
  );
};

export default CartButton;
