import React from 'react';
import classnames from 'classnames';


const FormContainer = (props) => {
  const { children } = props;
  return <div className='FormContainer'>{children}</div>;
};

const PrimaryHeader = (props) => {
  const { children } = props;
  return <div className='PrimaryHeader'>{children}</div>;
};
const SecondaryHeader = (props) => {
  const { children, isSecondary } = props;
  return <div className={classnames('SecondaryHeader', {secondary: isSecondary})}>{children}</div>;
};

const FormContainerBody = (props) => {
  const { children } = props;
  return <div className='FormContainerBody'>{children}</div>;
};

FormContainer.PrimaryHeader = PrimaryHeader;
FormContainer.SecondaryHeader = SecondaryHeader;
FormContainer.Body = FormContainerBody;

export default FormContainer;
