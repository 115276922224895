import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import React, { useMemo } from 'react';

import { sumBy, upperCase } from 'lodash';
import PropTypes from 'prop-types';
import List from '../../../components/list/List';
import { SMART_SELECTION_DELIVERY_METHOD } from '../../../constants/product';
import text, { formatCurrency } from '../../../text';
import { isMembershipPriceId } from '../../../utilities/membership';
import { isStaff } from '../../../utilities/user';
import classNames from 'classnames';

export const InvoiceTotal = ({
  membershipPriceId,
  currentUser,
  quote,
  selectedMembership,
  subtotalText = `${upperCase(text('buyNow'))} ${text('subtotal')}`,
  grandTotalText = `${upperCase(text('buyNow'))} ${text('total')}`,
  isApproximate = false,
}) => {
  const membershipPrice = selectedMembership?.subtotal || 0;
  const hasMembershipOpted =
    isMembershipPriceId(membershipPriceId) && !isStaff(currentUser.role);

  const membershipGST = sumBy(
    selectedMembership?.taxes,
    (tax) => tax.price.extra
  );

  const otherDiscountsSubtotal = sumBy(
    quote?.discounts,
    (discount) => discount.price.subtotal
  );

  const otherDiscountsGSTtotal = sumBy(
    quote?.discounts,
    (discount) => discount.price.gst
  );

  const otherGST = membershipGST - otherDiscountsGSTtotal;

  const chargesTotalDiscount = useMemo(
    () =>
      // Compute discounts for w/ BUY NOW DATA only
      quote?.charges.reduce((totalChargesDiscount, charge) => {
        const discounts =
          (hasMembershipOpted
            ? charge.member_discounts_preview
            : charge.discounts) || [];
        return (
          totalChargesDiscount +
          discounts.reduce((totalDiscount, discount) => {
            if (
              charge.details.delivery_method ===
              SMART_SELECTION_DELIVERY_METHOD.MANUAL
            ) {
              return 0;
            }
            return totalDiscount + discount.price.discount;
          }, 0)
        );
      }, 0),
    [quote?.charges]
  );

  return !isEmpty(quote) && !isEmpty(currentUser) ? (
    <List isPaddingless className='InvoiceTotal'>
      <List.Item
        description={text('discounts')}
        value={`-${formatCurrency(
          chargesTotalDiscount + otherDiscountsSubtotal,
          quote.currency.name,
          quote.currency.scale_factor,
          {},
          true
        )}`}
        key={'invoice-discounts'}
        className='discounts'
      />

      <tbody>
        <List.Item
          className={classNames({
            isApproximate,
          })}
          description={subtotalText}
          value={formatCurrency(
            hasMembershipOpted
              ? quote.price.member_subtotal +
                  membershipPrice -
                  otherDiscountsSubtotal
              : quote.price.subtotal + membershipPrice - otherDiscountsSubtotal,
            quote.currency.name,
            quote.currency.scale_factor,
            {},
            true
          )}
          key={'invoice-subtotal'}
        />

        {/* GST */}
        {map((tax, i) => (
          <List.Item
            className={classNames({
              isApproximate,
            })}
            key={i}
            description={tax.display_name}
            value={formatCurrency(
              tax.price.extra + otherGST,
              quote.currency.name,
              quote.currency.scale_factor,
              {},
              true
            )}
          />
        ))(hasMembershipOpted ? quote.member_taxes : quote.taxes)}

        <List.Item
          className={classNames({
            isApproximate,
          })}
          isHighlighted
          description={grandTotalText}
          key={'invoice-total'}
          value={formatCurrency(
            hasMembershipOpted
              ? quote.price.member_grand_total +
                  (selectedMembership?.grand_total || 0)
              : quote.price.grand_total +
                  (selectedMembership?.grand_total || 0),
            quote.currency.name,
            quote.currency.scale_factor,
            {},
            true
          )}
        />
      </tbody>
    </List>
  ) : null;
};

InvoiceTotal.propTypes = {
  membershipPriceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentUser: PropTypes.object,
  quote: PropTypes.object,
  selectedMembership: PropTypes.object,
  subtotalText: PropTypes.string,
  grandTotalText: PropTypes.string,
  isApproximate: PropTypes.bool,
};
