import { Form, useFormikContext } from 'formik';
import React from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button } from '../../components/button';
import { PasswordFormControl } from '../../components/form';
import text from '../../text';
import FormContainer from '../../components/containers/FormContainer';

const ResetPasswordForm = () => {
  const { values, touched, errors, handleChange, isSubmitting } =
    useFormikContext();

  return (
    <FormContainer>
      <FormContainer.SecondaryHeader>
        <h2>{text('createNewPassword')}</h2>
      </FormContainer.SecondaryHeader>
      <FormContainer.Body>
        <FormContainer.PrimaryHeader>
          <h2>{text('createNewPassword')}</h2>
        </FormContainer.PrimaryHeader>
        <Form>
          <PasswordFormControl
            as='input'
            name='password'
            required
            value={values?.password || ''}
            onChange={handleChange}
            placeholder={text('enterPassword')}
            label={text('password')}
            autoComplete='off'
            isFailed={touched.password && Boolean(errors.password)}
            validationError={errors.password}
          />          
            <PasswordFormControl
              as='input'
              name='confirmPassword'
              required
              value={values?.confirmPassword || ''}
              onChange={handleChange}
              placeholder={text('confirmPassword')}
              label={text('confirmPassword')}
              autoComplete='off'
              isFailed={
                touched.confirmPassword && Boolean(errors.confirmPassword)
              }
              validationError={errors.confirmPassword}
            />
          <div className='text-center'>
            <div className='btns-group'>
              <Button
                type={!isSubmitting && 'submit'}
                className='full-width'
                loading={isSubmitting}
              >
                {text('submit')}
              </Button>
            </div>
            <p className='small-text'>
              <Link to='/'>{text('cancel')}</Link>
            </p>
          </div>
        </Form>
      </FormContainer.Body>
    </FormContainer>
  );
};

export default ResetPasswordForm;
