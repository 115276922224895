import React from 'react';
import LottieReact from 'lottie-react';
import classnames from 'classnames';

const Lottie = ({ lottie, className, ...rest }) => {
  return (
    <LottieReact
      className={classnames('Lottie', { [className]: className })}
      animationData={require(`/public/lottie/${lottie}.json`)}
      {...rest}
    />
  );
};

export default Lottie;
