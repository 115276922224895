import { object, string } from 'yup';
import identity from 'lodash/identity';

import text from '../text';
import { DETAILED_STATUS } from '../constants/job';

export const productDetailedStatusSchema = object().shape({
  productKey: string()
    .when('status', {
      is: identity,
      then: string().min(1),
      otherwise: string().optional(),
    })
    .when('expectedAt', {
      is: identity,
      then: string().required(),
      otherwise: string().optional(),
    }),
  status: string().required(text('required')),
  expectedAt: string().when('status', {
    is: (status) => status === DETAILED_STATUS.COMPLETED,
    then: string().optional(),
    otherwise: string().required(text('required')),
  }),
});

export const selectionNameSchema = string().required(text('required')).min(1);
