/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useAsyncFn, useMount, useSearchParam } from 'react-use';
import Creatable from 'react-select/creatable';
import cn from 'classnames';

import { client } from '../../../utilities/api';
import { getProjectName } from '../../../utilities/project';
import IndicatorSeparator from './IndicatorSeparator';
import Option from './Option';
import _ from 'lodash/fp';

const ProjectSelectV2 = ({
  value,
  onChange,
  className,
  hasError = false,
  ...props
}) => {
  const [currentOption, setCurrentOption] = useState(value);
  const [inputValue, setInputValue] = useState();

  const projectId = useSearchParam('projectId');
  const [options, setOptions] = React.useState([]);
  const [loadOptionsState, loadOptions] = useAsyncFn(async () => {
    const { data } = await client.listProjects({
      isAdmin: false,
      includeDemo: false,
      inviteStatus: ['accepted'],
    });
    setOptions(data.projects);
  });
  useMount(() => {
    loadOptions();
  });
  useEffect(() => {
    if (
      loadOptionsState.loading ||
      loadOptionsState.error ||
      _.isEmpty(options) ||
      !projectId
    ) {
      return;
    }
    const mergeProject = _.find(['id', parseInt(projectId, 10)], options);
    if (!mergeProject) {
      console.warn(
        `project with id ${projectId} not found`,
        options,
        projectId
      );
      return;
    }
    handleOnChange(mergeProject);
  }, [handleOnChange, loadOptionsState, options, projectId]);

  const handleOnChange = useCallback((option) => {
    onChange(option, { newProject: false });
    setCurrentOption(option);
  }, [onChange]);

  const handleOnCreateOption = (newValue) => {
    onChange({ name: newValue }, { newProject: true });
    setCurrentOption({ label: newValue });
  };

  return (
    <Creatable
      options={options}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      value={currentOption}
      onChange={handleOnChange}
      onCreateOption={handleOnCreateOption}
      onBlur={() => {
        if (
          inputValue &&
          (!currentOption || inputValue !== currentOption.name)
        ) {
          handleOnCreateOption(inputValue);
        }
      }}
      formatOptionLabel={(option) => option?.label ?? getProjectName(option)}
      isDisabled={loadOptionsState.loading}
      isLoading={loadOptionsState.loading}
      isClearable
      inputValue={inputValue}
      onInputChange={setInputValue}
      components={{
        IndicatorSeparator: (props) => (
          <IndicatorSeparator inputValue={inputValue} {...props} />
        ),
        Option,
      }}
      classNamePrefix='Select'
      createOptionPosition='first'
      className={cn('Select', { hasError, [className]: className })}
      {...props}
    />
  );
};

export default ProjectSelectV2;
