import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { getJob, getProjectById } from '../redux/actions';
import { isEmpty } from 'lodash/fp';

const useCurrentProject = ({ enableJob = false } = {}) => {
  const dispatch = useDispatch();
  const { jobId, projectId } = useParams();

  const { currentProject } = useSelector((state) => ({
    currentProject: state.project.currentProject,
  }));

  useEffect(async () => {
    if (isEmpty(currentProject) && jobId && enableJob) {
      const job = await dispatch(getJob(jobId));
      dispatch(getProjectById(job.projectId, { includePointCloud: true }));
    }
  }, [jobId]);

  useEffect(() => {
    if (isEmpty(currentProject) && projectId) {
      dispatch(getProjectById(projectId, { includePointCloud: true }));
    }
  }, [projectId]);
};

export default useCurrentProject;
