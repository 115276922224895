import T from 'prop-types';
import React from 'react';

import useShareLinkParams from '../../../../../hooks/useShareLinkParams';
import ShareLink from './ShareLink';

const ShareLinkNav = ({ show, metadata, new3dBookmark }) => {
  const { isAvailable: isShareLinkAvailable, ...shareLinkParams } =
    useShareLinkParams();

  return show && isShareLinkAvailable ? (
    <div className='share-link__container'>
      <ShareLink
        {...shareLinkParams}
        metadata={metadata}
        new3dBookmark={new3dBookmark}
      />
    </div>
  ) : null;
};
ShareLinkNav.propTypes = {
  show: T.bool,
  metadata: T.object,
  new3dBookmark: T.func,
};

export default ShareLinkNav;
