import React from 'react';
import classnames from 'classnames';

const WhiteContainer = (props) => {
  const { children, isSelected, className, isContentCentered = false } = props;
  return (
    <div
      className={classnames('WhiteContainer', {
        isSelected,
        [className]: className,
        isContentCentered,
      })}
    >
      {children}
    </div>
  );
};

const WhiteContainerHeader = (props) => {
  const { title, children } = props;
  return (
    <div className='WhiteContainerHeader'>
      {!title ? children : <h2 className='green-1'>{title}</h2>}
    </div>
  );
};

WhiteContainer.Header = WhiteContainerHeader;

export default WhiteContainer;
