import { createSelector } from '@reduxjs/toolkit';
import { first, flatten, uniq } from 'lodash';

import { ENTITLEMENTS } from '../../constants/membership';
import { USER_TYPE } from '../../constants/user';
import {
  isAdministrator,
  isStaff,
  isUserGovernment,
  isUserUnderTrial,
  userHasActiveMembership,
  userHasValidMembership,
} from '../../utilities/user';

const getUserProfile = (state) => state.profileReducer.userProfile;

const getPretendType = (state) => state.profileReducer.pretendType;

const getActiveMemberships = (state) =>
  state.profileReducer.userProfile.customer?.memberships.filter((membership) =>
    ['active'].includes(membership.status)
  );

const getActiveMembership = createSelector(
  getActiveMemberships,
  (memberships) => first(memberships)
);

const hasValidMembership = createSelector(
  getActiveMemberships,
  (memberships) => memberships?.length > 0
);

const getCustomer = (state) => state.profileReducer.userProfile.customer;

const getEntitlements = createSelector(getCustomer, (customer) =>
  uniq(
    flatten(customer?.memberships.map((membership) => membership.entitlements))
  )
);

const getHasHighResEntitlement = createSelector(
  getEntitlements,
  (entitlements) => entitlements.includes(ENTITLEMENTS.HIGH_RES_MAP)
);

const getHighResEnabled = createSelector(
  getHasHighResEntitlement,
  getUserProfile,
  getPretendType,
  (hasHighResEntitlement, userProfile, pretendType) =>
    pretendType
      ? [
          USER_TYPE.TRIAL,
          USER_TYPE.ESSENTIALS,
          USER_TYPE.GOVERNMENT,
          USER_TYPE.LARKI_SUPPORT,
        ].includes(pretendType)
      : hasHighResEntitlement ||
        ['superadministrator', 'administrator', 'staff', 'government'].includes(
          userProfile.role
        )
);

const getHas3dViewerEntitlement = createSelector(
  getEntitlements,
  (entitlements) => entitlements.includes(ENTITLEMENTS._3D_VIEWER)
);

const get3dViewerEnabled = createSelector(
  getHas3dViewerEntitlement,
  getUserProfile,
  getPretendType,
  (has3dViewerEntitlement, userProfile, pretendType) =>
    pretendType
      ? [
          USER_TYPE.TRIAL,
          USER_TYPE.ESSENTIALS,
          USER_TYPE.GOVERNMENT,
          USER_TYPE.LARKI_SUPPORT,
        ].includes(pretendType)
      : has3dViewerEntitlement ||
        ['superadministrator', 'administrator', 'staff', 'government'].includes(
          userProfile?.role
        )
);

const getCanDeleteAnyProject = createSelector(
  getUserProfile,
  getPretendType,
  (userProfile, pretendType) =>
    pretendType
      ? [USER_TYPE.LARKI_SUPPORT].includes(pretendType)
      : isAdministrator(userProfile.role)
);

const getIsAdmin = createSelector(
  getUserProfile,
  getPretendType,
  (userProfile, pretendType) =>
    userProfile &&
    isAdministrator(userProfile.role) &&
    (!pretendType || pretendType === USER_TYPE.LARKI_SUPPORT)
);

const getIsStaff = createSelector(
  getUserProfile,
  getPretendType,
  (userProfile, pretendType) =>
    userProfile &&
    isStaff(userProfile.role) &&
    (!pretendType || pretendType === USER_TYPE.LARKI_SUPPORT)
);

const getCanUserStartTrial = createSelector(
  getUserProfile,
  getPretendType,
  (userProfile, pretendType) =>
    userProfile &&
    !isUserGovernment(userProfile) &&
    !isUserUnderTrial(userProfile) &&
    !userHasValidMembership(userProfile) &&
    !userProfile.has_used_trial &&
    (!isStaff(userProfile.role) ||
      (pretendType && [USER_TYPE.FREE].includes(pretendType)))
);

const getIsUserUnderTrial = createSelector(
  getUserProfile,
  getPretendType,
  (userProfile, pretendType) =>
    userProfile &&
    isUserUnderTrial(userProfile) &&
    (!isStaff(userProfile.role) ||
      (pretendType && [USER_TYPE.FREE].includes(pretendType)))
);

const getCanUserSeeTrialExpired = createSelector(
  getUserProfile,
  getPretendType,
  (userProfile, pretendType) =>
    userProfile &&
    (!isStaff(userProfile.role) ||
      (pretendType && [USER_TYPE.FREE].includes(pretendType)))
);

const getCanUserUpgrade = createSelector(
  getUserProfile,
  getPretendType,
  (userProfile, pretendType) =>
    userProfile &&
    !isUserGovernment(userProfile) &&
    !userHasActiveMembership(userProfile) &&
    (userProfile.has_used_trial || isUserUnderTrial(userProfile)) &&
    (!isStaff(userProfile.role) ||
      (pretendType && [USER_TYPE.FREE].includes(pretendType)))
);

const getIsUpgradeRecommended = createSelector(
  getUserProfile,
  getCanUserUpgrade,
  (userProfile, canUserUpgrade) =>
    userProfile &&
    canUserUpgrade &&
    (isUserUnderTrial(userProfile) || userProfile.has_used_trial)
);

export const profileSelectors = {
  getUserProfile,
  getPretendType,
  getHighResEnabled,
  get3dViewerEnabled,
  hasValidMembership,
  getActiveMembership,
  getCanDeleteAnyProject,
  getIsAdmin,
  getIsStaff,
  getCanUserUpgrade,
  getCanUserSeeTrialExpired,
  getCanUserStartTrial,
  getIsUserUnderTrial,
  getIsUpgradeRecommended,
};
