import cn from 'classnames';
import T from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useAsyncFn } from 'react-use';

import _ from 'lodash/fp';
import NavProjectSelect from '../../../../../components/select/jobSelect/NavProjectSelect';
import useProjectsList from '../../../../../hooks/useProjectsList';
import { useShareLink2dMapMetadata } from '../../../../../hooks/useShareLinkParams';
import { alertError, setCurrentProject } from '../../../../../redux/actions';
import { profileSelectors } from '../../../../../redux/selectors/profile';
import routes from '../../../../../routes/constants';
import text from '../../../../../text';
import { client } from '../../../../../utilities/api';
import { getProjectLatestJob } from '../../../../../utilities/project';
import { isUserAnonymous } from '../../../../../utilities/user';
import ShareLinkNav from './ShareLinkNav';

const Map2DNav = ({ className, showShareLink }) => {
  const isAdmin = useSelector(profileSelectors.getIsAdmin);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const jobId = location.pathname.split(`${routes.order.root}/`)[1];
  const { userProfile } = useSelector((state) => state.profileReducer);
  const canGetJob =
    !_.isNil(jobId) && !_.isEmpty(userProfile) && !isUserAnonymous(userProfile);
  const [currentJob, setCurrentJob] = useState(null);

  const { projects, isLoading } = useProjectsList({
    isAdmin,
    filterPredicate: (project) => project?.jobs?.length > 0,
    deps: [canGetJob],
  });

  const [getJobState, getJob] = useAsyncFn(async () => {
    const { data } = await client.getJobById(jobId);
    setCurrentJob(data);
  }, [canGetJob]);

  useEffect(() => {
    if (!canGetJob) {
      return;
    }
    getJob();
  }, [canGetJob]);

  useEffect(() => {
    if (getJobState.error) {
      console.error(getJobState.error.message);
      dispatch(alertError(getJobState.error.message));
    }
  }, [getJobState.error]);

  useEffect(() => {
    if (!currentJob?.project_id) return;
    const project = _.find(['id', currentJob.project_id], projects);
    if (project) {
      dispatch(setCurrentProject(project));
    }
  }, [currentJob]);

  const map2DText = `${text(isAdmin ? 'administrationMap2D' : 'map2D')}${
    jobId ? ':' : ''
  }`;

  const metadata = useShareLink2dMapMetadata();

  return (
    <Nav className={cn('Title', className)}>
      <Navbar>
        <span>{map2DText}</span>
        {canGetJob && (
          <NavProjectSelect
            projects={projects}
            isLoading={isLoading}
            onProjectChange={(nextProject, prevProject) => {
              const nextJob = getProjectLatestJob(nextProject);
              if (nextProject.id !== prevProject.id) {
                history.replace({
                  pathname: routes.order.job(nextJob.id),
                  search: history.location.search,
                });
                // TODO: fix this once map state is able to reload on project change
                window.location.reload();
              }
            }}
          />
        )}
        <ShareLinkNav show={showShareLink} metadata={metadata} />
      </Navbar>
    </Nav>
  );
};

Map2DNav.propTypes = {
  className: T.string,
};

export default Map2DNav;
