import _ from 'lodash/fp';

export const mapWithKey = _.map.convert({ cap: false });

export const getObjectDiff = (x, y) => {
  // Note: only returns the keys in x with values different to those in y
  return _.filter((k) => !_.isEqual(x[k], y[k]), _.keys(x));
};

export const isObjectEqual = (x, y, excludeKeys = []) => {
  // Note: only returns the keys in x with values different to those in y
  const diff = getObjectDiff(x, y);
  if (_.isEmpty(diff)) {
    return true;
  }
  return _.every((k) => excludeKeys.includes(k), diff);
};

/**
 * Checks if arrays of objects xs and ys are equal.
 * Ignored keys can be passed as an optional 3rd argument.
 */
export const isArrayEqual = (xs, ys, excludeKeys = []) => {
  if (xs?.length !== ys?.length) {
    return false;
  }
  return _.every(([x, y]) => {
    return isObjectEqual(x, y, excludeKeys);
  }, _.zip(xs, ys));
};
