import React from 'react';

import Step from '../../../components/wizard/Step';
import StepFooter from '../../../components/wizard/StepFooter';
import routes from '../../../routes/constants';
import text from '../../../text';
import MembershipForm from '../Membership/MembershipForm';

const MembershipStep = ({
  membershipPriceId,
  onMembershipChange,
  includeNoMembership,
  nextButtonProps,
  previousButtonProps,
}) => {
  return (
    <>
      <Step
        className='MembershipStep'
        title={text('selectMembership')}
        extraText={
          <div>
            {text('membershipFeaturesSummary')}
            <div className='mt-3'>
              <a
                target='_blank'
                rel='noreferrer'
                href={routes.larkiWebsiteUrl.memberships}
              >
                {text('learnMore')}
              </a>
            </div>
          </div>
        }
      >
        <Step.Content>
          <MembershipForm
            includeNoMembership={includeNoMembership}
            membershipPriceId={membershipPriceId}
            onMembershipChange={onMembershipChange}
          />
        </Step.Content>
      </Step>
      <StepFooter
        previousButtonProps={previousButtonProps}
        nextButtonProps={nextButtonProps}
      />
    </>
  );
};

export default MembershipStep;
