import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useMemo } from 'react';
import { Badge, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  USER_TYPE,
  USER_TYPE_TO_DISPLAY_NAME,
} from '../../../../../constants/user';
import { getUserProfile, setPretendType } from '../../../../../redux/actions';
import { profileSelectors } from '../../../../../redux/selectors/profile';
import routes from '../../../../../routes/constants';
import text from '../../../../../text';
import { clearUser, getUser } from '../../../../../utilities/storage';
import {
  getUserType,
  isStaff,
  isUserAnonymous,
  isUserStaff,
} from '../../../../../utilities/user';
import UserAvatar from '../../../../profile/UserAvatar';
import { useRedirect } from '../../../../../hooks/useRedirect';

/* eslint-disable react/prop-types */
const UserNav = ({ className }) => {
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const userProfile = useSelector(profileSelectors.getUserProfile);
  const pretendType = useSelector(profileSelectors.getPretendType);

  const userBadge = useMemo(
    () => userProfile && (pretendType ?? getUserType(userProfile)),
    [pretendType, userProfile]
  );

  const canShowPretendUserTypes = useMemo(
    () => userProfile && isStaff(userProfile.role),
    [userProfile]
  );

  const pretendUserTypes = useMemo(
    () =>
      canShowPretendUserTypes
        ? [
            USER_TYPE.FREE,
            USER_TYPE.ESSENTIALS,
            USER_TYPE.LARKI_SUPPORT,
          ].filter((type) => {
            if (pretendType && type === pretendType) return false;
            if (
              !pretendType &&
              type === USER_TYPE.LARKI_SUPPORT &&
              isUserStaff(userProfile)
            )
              return false;
            return true;
          })
        : [],
    [canShowPretendUserTypes, pretendType, userProfile]
  );

  useEffect(() => {
    // todo: why check for full name here?
    if (getUser() && userProfile.full_name === undefined) {
      dispatch(getUserProfile());
    }
  }, [userProfile]);

  return (
    !isEmpty(userProfile) &&
    !isUserAnonymous(userProfile) && (
      <Nav className={cn('UserNav', { [className]: className })}>
        <Navbar.Collapse>
          <NavDropdown
            alignRight
            title={
              userBadge && (
                <div className='avatar-wrapper'>
                  <UserAvatar profileImage={userProfile.profile_image} />
                  <Badge className={userBadge}>{text(userBadge)}</Badge>
                </div>
              )
            }
          >
            <NavDropdown.Item onClick={() => redirect.push(routes.dashboard)}>
              {text('dashboard')}
            </NavDropdown.Item>

            <NavDropdown.Item
              onClick={() => redirect.push(routes.user.profile)}
            >
              {text('account')}
            </NavDropdown.Item>

            <NavDropdown.Item
              href={routes.larkiWebsiteUrl.home}
              target='_blank'
            >
              {text('larkiURL')}
            </NavDropdown.Item>

            {canShowPretendUserTypes && (
              <>
                <NavDropdown.Divider />

                {pretendUserTypes.map((type) => (
                  <NavDropdown.Item
                    key={type}
                    onClick={() => dispatch(setPretendType(type))}
                  >
                    {text('viewAs', { as: USER_TYPE_TO_DISPLAY_NAME[type] })}
                  </NavDropdown.Item>
                ))}
              </>
            )}

            <NavDropdown.Divider />

            <NavDropdown.Item
              onClick={() => {
                // also clear it here in case we're on an external domain
                clearUser();
                redirect.push(routes.logout);
              }}
            >
              {text('logout')}
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </Nav>
    )
  );
};

export default UserNav;
