import React from 'react';
import T from 'prop-types';
import cn from 'classnames';

const BodyItem = (props) => {
  const { className, title, children } = props;

  return (
    <div className={cn('BodyItem', { [className]: className })}>
      <h6 className='BodyItem-Title'>{title}</h6>
      <div className='BodyItem-Content'>{children}</div>
    </div>
  );
};

BodyItem.propTypes = {
  className: T.string,
  title: T.string,
  children: T.node,
};

export default BodyItem;
