import React from 'react';
import classNames from 'classnames';
import { Spinner } from 'react-bootstrap';
import text from '../../../../text';

const WithLoader = ({
  children,
  hasCharges = true,
  className,
  showLoaderText = true,
  fullWidth = true,
}) => {
  if (hasCharges) return children;
  return (
    <div
      className={classNames('d-flex flex-row align-items-center', className, {
        'flex-fill': fullWidth,
      })}
    >
      <Spinner animation='border' className='size-12 grey-3' />
      {showLoaderText && (
        <p className='mb-0 ml-1 font-12 grey-3'>{text('fetching')}</p>
      )}
    </div>
  );
};

export default WithLoader;
