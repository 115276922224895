import React, { useState } from 'react';
import text from '../../text';
import IconButton from '../../components/button/IconButton';
import LinkButton from '../../components/button/LinkButton';

const ReferralBar = () => {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <>
      {isVisible && (
        <div id='ReferralBar'>
          <div className='d-flex align-items-center'>
            <p className='mr-3 ml-1 my-0'>{text('referralBar')}</p>
            <LinkButton
              tooltipProps={{ text: text('referralBar') }}
              to='https://larki.com.au/referral/'
              target='_blank'
              className='learnmore-link-button'
            >
              {text('learnMore')}
            </LinkButton>
            <IconButton
              onClick={(e) => {
                setIsVisible(false);
                e.stopPropagation();
              }}
              icon='close-box'
              className='close-icon-button'
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ReferralBar;
