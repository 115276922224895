import { useFormikContext } from 'formik';
import React from 'react';
import FormContainer from '../../components/containers/FormContainer';
import text from '../../text';

const AccountCreated = () => {
  const { values } = useFormikContext();

  return (
    <FormContainer>
      <FormContainer.SecondaryHeader>
        <h2>{text('accountCreated')}</h2>
      </FormContainer.SecondaryHeader>
      <FormContainer.Body>
        <FormContainer.PrimaryHeader>
          <h2>{text('accountCreated')}</h2>
        </FormContainer.PrimaryHeader>
        <p className='text-center mb-2'>{text('checkInboxSignup')}</p>
        <p className='text-center font-weight-bold'>{values.email}</p>
      </FormContainer.Body>
    </FormContainer>
  );
};

export default AccountCreated;
