import { Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParam } from 'react-use';
import { Wizard } from 'react-use-wizard';
import * as yup from 'yup';
import { alertConstants } from '../../redux/constants';
import { larkiApiNoAuthentication } from '../../utilities/api';
import { validateEmail } from '../../validation';
import AccountCreated from './accountCreated';
import CreateNewAccount from './createNewAccount';
import SignupForm from './signupForm';
import { isUserAnonymous } from '../../utilities/user';
import { getUser } from '../../utilities/storage';
import routes from '../../routes/constants';

const signupSchema = yup.object({
  email: validateEmail,
});

const initialValues = {
  email: '',
};

const SignupWizard = ({ onGoToLogin }) => {
  const dispatch = useDispatch();
  const redirectUrl = useSearchParam('redirectUrl');

  const onGoogleRedirect = async () => {
    try {
      let state = `redirectUrl=${redirectUrl || routes.home}`;
      if (isUserAnonymous(getUser())) {
        state = state.concat(`&token=${getUser().token}`);
      }
      const { data } = await larkiApiNoAuthentication.get(
        '/user/google-auth-url',
        {
          params: {
            state,
          },
        }
      );

      window.location.href = data;
    } catch (error) {
      dispatch({
        type: alertConstants.ALERT_ERROR,
        payload: { message: error.response.data.message },
      });
    }
  };

  const onUserExists = async (values) => {
    const { data } = await larkiApiNoAuthentication.post(
      '/user/exists',
      values
    );

    return data;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={signupSchema}
      onSubmit={onUserExists}
    >
      <Wizard>
        <SignupForm
          onGoogleRedirect={onGoogleRedirect}
          onGoToLogin={onGoToLogin}
        />
        <CreateNewAccount onGoogleRedirect={onGoogleRedirect} />
        <AccountCreated />
      </Wizard>
    </Formik>
  );
};

export default SignupWizard;
