/**
 * The goal of this cache is to reduce the number of requests we send to the server by caching the coverage data.
 * To minimize the amount of cache misses, we'll cache the coverage in a grid of ~square tiles,
 * that way we can easily check if some geometry is already cached, by simply checking it's GeoHash.
 */

import _ from 'lodash/fp';

const defaultCoverageState = {
  hashes: [],
  caches: [],
};

export const ADD_TO_COVERAGE_CACHE = 'ADD_TO_CACHE';
export const ADD_MANY_TO_COVERAGE_CACHE = 'ADD_MANY_TO_CACHE';
export const SET_COVERAGE_CACHE = 'SET_COVERAGE_CACHE';

const coverageReducer = (state = defaultCoverageState, action) => {
  switch (action.type) {
    case ADD_TO_COVERAGE_CACHE: {
      const { hash, coverage } = action.payload;
      console.assert(!state.hashes.includes(hash));
      return _.flow(
        _.set('hashes', [...state.hashes, hash]),
        _.set('caches', [...state.caches, coverage])
      )(state);
    }
    case ADD_MANY_TO_COVERAGE_CACHE: {
      const { hashes, coverage } = action.payload;
      console.assert(hashes.length === _.uniq(hashes).length);
      // if all the hashes we want to add
      //  are already in state, skip
      if (_.difference(hashes, state.hashes).length == 0) {
        return state;
      }
      // hashes may contain duplicates
      //  so remove the duplicates
      const hashesAfter = _.uniq(_.concat(state.hashes, hashes));
      return _.flow(
        _.set('hashes', hashesAfter),
        _.set('caches', [...state.caches, coverage])
      )(state);
    }
    case SET_COVERAGE_CACHE: {
      const { coverage } = action.payload;
      return _.set('caches', [coverage], state);
    }
    default:
      return state;
  }
};

export default coverageReducer;
