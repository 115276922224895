import React from 'react';
import T from 'prop-types';

const FormSection = (props) => {
  const { children, title } = props;
  return (
    <div className='FormSection'>
      <h3 className='title'>{title}</h3>
      {children}
    </div>
  );
};

FormSection.propTypes = {
    children: T.node,
    title: T.string,
};

export default FormSection;