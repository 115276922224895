import React from 'react';
import { useHistory } from 'react-router';
import { useMount } from 'react-use';
import { clearUser } from '../utilities/storage';

const Logout = () => {
  const history = useHistory();

  useMount(() => {
    clearUser();
    history.replace('/login');
  });

  return <div />;
};

export default Logout;
