import { forEach, isEmpty } from 'lodash';
import _ from 'lodash/fp';

/**
 * @returns {Object} contains the compared object fields with value 'true' if the same keys have different values
 */
export const checkFieldsAreDiff = (baseObj, compareObj) => {
  const changedFields = {};
  if (!isEmpty(baseObj) && !isEmpty(compareObj)) {
    forEach(baseObj, (baseValue, baseKey) => {
      if (compareObj[baseKey] !== baseValue) {
        changedFields[baseKey] = true;
      }
    });
    return changedFields;
  }
  return changedFields;
};

const flatten = (obj, prefix = '') =>
  Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '.' : '';
    if (typeof obj[k] === 'object' && obj[k] !== null) {
      Object.assign(acc, flatten(obj[k], pre + k));
    } else {
      acc[pre + k] = obj[k];
    }
    return acc;
  }, {});

const unflatten = (flattedObject) => {
  let result = {};
  _.keys(flattedObject).forEach((key) => {
    result = _.set(key, flattedObject[key], result);
  });
  return result;
};

const objectUtilities = {
  flatten,
  unflatten,
};

export default objectUtilities;
