import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AccessDenied from '../../pages/accessDenied';
import SearchProducts from '../../pages/searchProducts/SearchProducts';
import routes from '../../routes/constants';

const Routes = () => {
  return (
    <Switch>
      <Route path={'/embed/instant-search'} component={SearchProducts} />

      {/* fallback */}
      <Route exact path={routes.accessDenied} component={AccessDenied} />
    </Switch>
  );
};

export default Routes;
