import _ from 'lodash/fp';
import { useLocation } from 'react-router-dom';

import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { MapViewContext } from '../components/mapView/mapViewContext';
import { alertError } from '../redux/actions';
import { profileSelectors } from '../redux/selectors/profile';
import projectSelectors from '../redux/selectors/project';
import { client } from '../utilities/api';
import { captureError } from '../utilities/error';
import { isDemoProject } from '../utilities/project';
import shareLinkUtilities from '../utilities/shareLink';
import { isUserAnonymous } from '../utilities/user';

const useShareLinkParams = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentProject = useSelector(projectSelectors.getCurrentProject);

  const user = useSelector(profileSelectors.getUserProfile);
  const isAnon = isUserAnonymous(user);

  const [isVisible, id, isOrderId, showHomeIcon] = useMemo(
    () => [
      shareLinkUtilities.isShareLinkVisible(location.pathname),
      shareLinkUtilities.extractRouteParam(location.pathname),
      shareLinkUtilities.doesPathHaveOrderId(location.pathname),
      shareLinkUtilities.shouldShowHomeIcon(location.pathname),
    ],
    [location.pathname]
  );
  const [projectIdFallback, setProjectIdFallback] = useState();
  const projectId = !isOrderId ? id : projectIdFallback;
  const redirectUrl = window.location.href;
  const isDemo = currentProject ? isDemoProject(currentProject) : false;
  const isAvailable =
    isVisible &&
    !_.isNil(projectId) &&
    redirectUrl &&
    !!user &&
    !isAnon &&
    !isDemo;

  const [getJobState, getJob] = useAsyncFn(async (id) => {
    const resp = await client.getJobById(id);
    return resp.data;
  });

  useEffect(() => {
    if (id && isOrderId) {
      (async () => {
        const job = await getJob(id);
        setProjectIdFallback(job.project_id);
      })();
    }
  }, [getJob, id, isOrderId]);

  useEffect(() => {
    if (getJobState.error) {
      captureError(getJobState.error);
      dispatch(alertError(getJobState.error.message));
    }
  }, [dispatch, getJobState.error]);

  return {
    isAvailable,
    projectId,
    redirectUrl,
    showHomeIcon,
  };
};

export const parse2dMapMetadata = ({ center: c, zoom: z, ...metadata }) => {
  return {
    center: c ? { lat: parseFloat(c.lat), lng: parseFloat(c.lng) } : null,
    zoom: z ? parseFloat(z) : null,
    ...metadata,
  };
};

export const useShareLink2dMapMetadata = () => {
  const { state } = useContext(MapViewContext);
  const mapState = useSelector((reduxState) => reduxState.mapReducer);
  const metadata = useMemo(() => {
    const center = state.debouncedViewportCenter;
    return {
      center: center ? { lat: center.lat(), lng: center.lng() } : null,
      zoom: state.zoom.zoom,
      currentMapTypeId: state.currentMapTypeId,
      highResDate: mapState.highRes.currentDate,
      highResLayerName: state.currentHighResLayerName,
    };
  }, [
    state.debouncedViewportCenter,
    state.zoom.zoom,
    state.currentMapTypeId,
    mapState.highRes.currentDate,
    state.currentHighResLayerName,
  ]);
  return metadata;
};

export const useShareLinkMetadata = () => {
  const location = useLocation();
  const metadata2dMap = useShareLink2dMapMetadata();
  return shareLinkUtilities.isRoute2dMap(location.pathname)
    ? metadata2dMap
    : undefined;
};

export const useNew3dBookmark = () => {
  const location = useLocation();
  return shareLinkUtilities.isRoute3dViewer(location.pathname)
    ? () => {
        const root = document.getElementById('cloud-viewer');
        const headers = [...root.getElementsByClassName('p__header__title')];
        const header = headers.find((header) =>
          header.innerHTML.includes('Bookmarks')
        );

        if (header) {
          const parent = header.parentElement?.parentElement;

          const isCollapsed = parent.classList.contains(
            'p__card--is-collapsed'
          );
          if (isCollapsed) header.click();
          parent?.children[1]
            ?.getElementsByClassName('add_button')[0]
            ?.getElementsByTagName('button')[0]
            ?.click();
        }
      }
    : undefined;
};

export default useShareLinkParams;
