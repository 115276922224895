import T from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { updateProjectPointerraId } from '../../../redux/actions';
import text from '../../../text';
import PointerraIDForm from '../../form/pointerraIDForm/PointerraIDForm';

export const POINTERRA_ID_MODAL = 'POINTERRA_ID_MODAL';

const PointerraIDModal = (props) => {
  const { show, job, onHide, ...restProps } = props;
  const dispatch = useDispatch();

  const [updateState, updatePointerraId] = useAsyncFn(
    async (projectId, pointerraId) => {
      try {
        await dispatch(
          updateProjectPointerraId({
            projectId,
            pointerraId,
          })
        );
        onHide();
      } catch (error) {
        throw error?.response?.data?.message;
      }
    }
  );

  return (
    <Modal centered show={show} onHide={onHide} {...restProps}>
      <Modal.Header className='align-end' closeButton>
        <h3 className='secondary'>{text('updatePointerraID')}</h3>
        <h6>{job?.project?.name}</h6>
      </Modal.Header>
      <PointerraIDForm
        job={job}
        submitState={updateState}
        onSubmit={updatePointerraId}
        onCancel={onHide}
      />
    </Modal>
  );
};

PointerraIDModal.propTypes = {
  project: T.shape(),
  show: T.bool,
};

export default PointerraIDModal;
