import React from 'react';
import Icon from '../../icon/Icon';

export const File = ({ file, onRemove, uploading }) => {
  const onClick = () => onRemove(file.id);

  return (
    <div className='file-added'>
      <span className='file-name-overlay'>
        <span className='file-name'>{file.name}</span>
        {!uploading && (
          <Icon className='file-remove' onClick={onClick} icon='cross' />
        )}
      </span>
      <div
        className='file-progress'
        style={{
          width: `${file.progress}%`,
        }}
      ></div>
    </div>
  );
};
