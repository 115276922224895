import { values } from 'lodash';
import _ from 'lodash/fp';
import { ADD_ONS } from '../../../constants/product';
import assert from '../../../utilities/assert';
import { Z_INDEX } from '../constants';

/** Returns path in counter-clockwise order. */
export const getPathFromBounds = (bounds) => {
  const point1Lat = bounds.getNorthEast().lat();
  const point1Lng = bounds.getNorthEast().lng();
  const point2Lat = bounds.getSouthWest().lat();
  const point2Lng = bounds.getSouthWest().lng();

  const locations = [];
  locations.push(
    new google.maps.LatLng({
      lat: point1Lat,
      lng: point1Lng,
    })
  );
  locations.push(
    new google.maps.LatLng({
      lat: point1Lat,
      lng: point2Lng,
    })
  );
  locations.push(
    new google.maps.LatLng({
      lat: point2Lat,
      lng: point2Lng,
    })
  );
  locations.push(
    new google.maps.LatLng({
      lat: point2Lat,
      lng: point1Lng,
    })
  );

  return locations;
};

const capitaliseString = (type) => {
  return type.charAt(0).toUpperCase() + type.slice(1);
};

export const convertGeometries = (geometries, toGeoJson) => {
  const geometryRegions = {};
  Object.keys(geometries).forEach((key) => {
    let type = capitaliseString(geometries[key].type);
    let path = [];
    if (type === 'Rectangle') {
      if (toGeoJson) {
        type = 'Polygon';
      }
      path = getPathFromBounds(geometries[key].getBounds());
    } else {
      path = geometries[key].getPath();
    }
    const latLngPath = [];
    path.forEach((point) => {
      latLngPath.push([point.lat(), point.lng()]);
    });

    if (type !== 'Boolean') {
      geometryRegions[geometries[key].id] = {
        region: {
          type,
          include: latLngPath,
          exclude: [],
        },
      };
    } else {
      if (geometryRegions[geometries[key].parentId]?.region) {
        geometryRegions[geometries[key].parentId] = {
          region: {
            ...geometryRegions[geometries[key].parentId].region,
            exclude: [
              ...(geometryRegions[geometries[key].parentId].region.exclude ||
                []),
              latLngPath,
            ],
          },
        };
      } else {
        geometryRegions[geometries[key].parentId] = {
          region: {
            exclude: [latLngPath],
          },
        };
      }
    }
  });
  return geometryRegions;
};

export const createMarker = (location, map) => {
  const markerIcon = {
    url: '/public/img/marker-point.svg',
    size: new google.maps.Size(15, 15),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(7, 7),
  };
  const markerShape = {
    coords: [0, 0, 0, 15, 15, 15, 15, 0],
    type: 'poly',
  };
  const marker = new google.maps.Marker({
    position: location,
    map,
    draggable: true,
    icon: markerIcon,
    shape: markerShape,
    zIndex: Z_INDEX.MARKER,
  });
  return marker;
};

export const createMidpointMarker = (location, map) => {
  const markerIcon = {
    url: '/public/img/marker-midpoint.svg',
    size: new google.maps.Size(7, 7),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(3, 3),
  };
  const markerShape = {
    coords: [0, 0, 0, 10, 10, 10, 10, 0],
    type: 'poly',
  };
  const marker = new google.maps.Marker({
    position: location,
    map,
    draggable: true,
    icon: markerIcon,
    shape: markerShape,
    zIndex: 2,
  });
  return marker;
};

export const isPathClockwise = (path) => {
  let area = 0;

  for (let i = 0; i < path.length; i++) {
    let j = (i + 1) % path.length;
    area += path[i].lat() * path[j].lng();
    area -= path[j].lat() * path[i].lng();
  }

  return area > 0;
};

export const getDistanceInPixels = (position1, position2, map, zoom) => {
  if (map && map.getProjection()) {
    const point1 = map.getProjection().fromLatLngToPoint(position1);
    const point2 = map.getProjection().fromLatLngToPoint(position2);
    const pixelSize = Math.pow(2, -zoom);
    const distance =
      Math.sqrt(
        (point1.x - point2.x) * (point1.x - point2.x) +
          (point1.y - point2.y) * (point1.y - point2.y)
      ) / pixelSize;
    return distance;
  }
  console.warn('map or map projection missing');
  return 0;
};

export const getSegmentLengths = (geometry, map, zoom) => {
  let lengths = [];
  let isClockwise = false;
  let totalLength = 0;

  if (geometry) {
    let geometryPath = [];
    if (geometry.type === 'rectangle') {
      geometryPath = getPathFromBounds(geometry.getBounds());
    } else {
      geometry.getPath().forEach((point) => {
        geometryPath.push(point);
      });
    }

    isClockwise = isPathClockwise(geometryPath);

    if (
      geometry.type === 'polygon' ||
      geometry.type === 'boolean' ||
      geometry.type === 'rectangle'
    ) {
      geometryPath.push(geometryPath[0]);
    }

    for (let i = 0; i < geometryPath.length - 1; i++) {
      // Google spherical uses geodesic("+a=6378137") by default
      const length = google.maps.geometry.spherical.computeDistanceBetween(
        geometryPath[i],
        geometryPath[i + 1]
      );
      const heading = google.maps.geometry.spherical.computeHeading(
        geometryPath[i],
        geometryPath[i + 1]
      );
      const midpoint = google.maps.geometry.spherical.computeOffset(
        geometryPath[i],
        length / 2,
        heading
      );
      const endpoint = google.maps.geometry.spherical.computeOffset(
        geometryPath[i],
        length,
        heading
      );
      const distanceInPixels = getDistanceInPixels(
        geometryPath[i],
        geometryPath[i + 1],
        map,
        zoom
      );

      lengths.push({ length, heading, midpoint, endpoint, distanceInPixels });
      totalLength += length;
    }
  }

  return { lengths, isClockwise, totalLength };
};

export const calculateMidpoints = (geometry) => {
  const midpoints = [];

  if (geometry) {
    const geometryPath = [];
    geometry.getPath().forEach((point) => {
      geometryPath.push(point);
    });

    for (let i = 0; i < geometryPath.length - 1; i++) {
      const length = google.maps.geometry.spherical.computeDistanceBetween(
        geometryPath[i],
        geometryPath[i + 1]
      );
      const heading = google.maps.geometry.spherical.computeHeading(
        geometryPath[i],
        geometryPath[i + 1]
      );
      const midpoint = google.maps.geometry.spherical.computeOffset(
        geometryPath[i],
        length / 2,
        heading
      );
      midpoints.push(midpoint);
    }

    if (geometry.type === 'polygon' || geometry.type === 'boolean') {
      const length = google.maps.geometry.spherical.computeDistanceBetween(
        geometryPath[geometryPath.length - 1],
        geometryPath[0]
      );
      const heading = google.maps.geometry.spherical.computeHeading(
        geometryPath[geometryPath.length - 1],
        geometryPath[0]
      );
      const midpoint = google.maps.geometry.spherical.computeOffset(
        geometryPath[geometryPath.length - 1],
        length / 2,
        heading
      );
      midpoints.push(midpoint);
    }
  }

  return midpoints;
};

export const getScreenPositionFromLatLng = (latLng, map, zoom) => {
  if (map && map.getProjection()) {
    const scale = Math.pow(2, zoom); // = numTiles
    const projection = map.getProjection();

    const positionWorldCoordinate = projection.fromLatLngToPoint(latLng);
    const pixelCoordinate = new google.maps.Point(
      positionWorldCoordinate.x * scale,
      positionWorldCoordinate.y * scale
    );

    const topLeft = new google.maps.LatLng(
      map.getBounds().getNorthEast().lat(),
      map.getBounds().getSouthWest().lng()
    );
    const topLeftWorldCoordinate = projection.fromLatLngToPoint(topLeft);
    const topLeftPixelCoordinate = new google.maps.Point(
      topLeftWorldCoordinate.x * scale,
      topLeftWorldCoordinate.y * scale
    );

    return {
      x: Math.floor(pixelCoordinate.x - topLeftPixelCoordinate.x),
      y: Math.floor(pixelCoordinate.y - topLeftPixelCoordinate.y),
    };
  }
  return { x: 0, y: 0 };
};

export const createRectangle = (bounds, id, map, clickable) => {
  return new google.maps.Rectangle({
    bounds,
    map,
    clickable: clickable,
    draggable: false,
    editable: false,
    geodesic: true,
    visible: true,
    fillColor: '#000000',
    fillOpacity: 0,
    strokeColor: '#000000',
    strokeOpacity: 1.0,
    strokeWeight: 3,
    zIndex: Z_INDEX.REGION,
    type: 'rectangle',
    id,
  });
};

export const createPolygon = (path, id, map, isBoolean, clickable) => {
  return new google.maps.Polygon({
    path,
    map,
    clickable: clickable,
    draggable: false,
    editable: false,
    geodesic: true,
    visible: true,
    fillColor: '#000000',
    fillOpacity: 0,
    strokeColor: '#000000',
    strokeOpacity: 1.0,
    strokeWeight: 3,
    zIndex: Z_INDEX.REGION,
    type: isBoolean ? 'boolean' : 'polygon',
    id,
  });
};

export const createPolygonWithHoles = (
  paths,
  id,
  map,
  isBoolean,
  clickable
) => {
  return new google.maps.Polygon({
    paths,
    map,
    clickable: clickable,
    draggable: false,
    editable: false,
    geodesic: true,
    visible: true,
    fillColor: '#000000',
    fillOpacity: 0,
    strokeColor: '#000000',
    strokeOpacity: 1.0,
    strokeWeight: 3,
    zIndex: Z_INDEX.REGION,
    type: isBoolean ? 'boolean' : 'polygon',
    id,
  });
};

export const createPolyline = (path, id, map) => {
  return new google.maps.Polyline({
    path,
    map,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: true,
    visible: true,
    icons: null,
    strokeColor: '#000000',
    strokeOpacity: 1.0,
    strokeWeight: 3,
    zIndex: Z_INDEX.REGION,
    type: 'polyline',
    id,
  });
};

export const transformGeometryToState = (geometries) => {
  const newState = {};
  Object.keys(geometries).forEach((geometryId) => {
    const array = [];
    newState[geometryId] = {
      type: geometries[geometryId].type,
      locations: (() => {
        switch (geometries[geometryId].type) {
          case 'rectangle':
            return getPathFromBounds(geometries[geometryId].getBounds());
          default:
            geometries[geometryId]
              .getPath()
              .forEach((point) => array.push(point));
            return array;
        }
      })(),
      parentId: geometries[geometryId].parentId || null,
    };
  });
  return newState;
};

export const isPopupMarkerType = (popup, type) =>
  popup?.markerType && type && popup.markerType === type;

export const isPopupStandard = (popup) => isPopupMarkerType(popup, 'standard');

export const isPopupMidpoint = (popup) => isPopupMarkerType(popup, 'midpoint');

export const getAddOnConfig = (product) =>
  values(ADD_ONS).find(({ PRODUCT_NAME }) => PRODUCT_NAME === product);

export const getIncludePath = (region) =>
  region?.include.map(
    (point) =>
      new google.maps.LatLng({
        lat: point[0],
        lng: point[1],
      })
  ) ?? [];

export const getExclusionPaths = (region) =>
  region?.exclude.map((path) =>
    path.map(
      (point) =>
        new google.maps.LatLng({
          lat: point[0],
          lng: point[1],
        })
    )
  ) ?? [];

export const getBoundsFromPath = (path) => {
  const bounds = new google.maps.LatLngBounds();
  path.forEach((location) => {
    bounds.extend(location);
  });
  return bounds;
};

export const isMarkerValid = (marker) => marker.id && !_.isNaN(marker.index);

export const isPathValid = (path) => path?.length > 0;

export const isPathsValid = (paths) => paths.every(isPathValid);

export const isRegionValid = (region) =>
  region &&
  ['Rectangle', 'Polygon', 'Boolean', 'Polyline'].includes(region.type) &&
  region.include.length > 0;

export const isGeometryValidPolygon = (geometry) =>
  typeof geometry?.setPath === 'function';

export const assertRegionIsValid = (region) =>
  assert(isRegionValid(region), 'Region not valid', region);

export const assertMarkerIsValid = (marker) =>
  assert(isMarkerValid(marker), 'Marker not valid', marker);

export const assertPathIsValid = (path) =>
  assert(isPathValid(path), 'Path not valid', path);

// assumes path has length > 0
export const assertPathIsClockwise = (path) =>
  assert(isPathClockwise(path), 'Path is not clockwise', path);

// assumes path has length > 0
export const assertPathIsCounterClockwise = (path) =>
  assert(!isPathClockwise(path), 'Path is not counter clockwise', path);

export const assertGeometryIsValidPolygon = (geometry) =>
  assert(
    isGeometryValidPolygon(geometry),
    'Geometry is not valid polygon',
    geometry
  );
