import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useBoolean } from 'react-use';
import { useWizard } from 'react-use-wizard';
import { Button } from '../../../../components/button';
import Checkbox from '../../../../components/form/Checkbox';
import text from '../../../../text';
import { onboardingConstants } from './constants';
import OnboardingTile from './onboardingTile';
import { useOnboarding } from './reducer';

const Profession = ({ onSubmit }) => {
  const [{ profession, fullName, phoneNumber, isMarketingEnabled }, dispatch] =
    useOnboarding();
  const { nextStep } = useWizard();
  const [hasOther, setHasOther] = useBoolean(false);
  const [loading, setLoading] = useBoolean(false);
  const disabled = (!profession && !hasOther) || loading;

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit({ profession, fullName, phoneNumber, isMarketingEnabled });
    setLoading(false);
  };

  const onClick = () => {
    if (hasOther) {
      nextStep();
    } else {
      handleSubmit();
    }
  };

  const onToggleProfession = (payload, other) => {
    setHasOther(other);

    dispatch({
      type: onboardingConstants.TOGGLE_PROFESSION,
      payload,
    });
  };

  const onToggleMarketing = (event) =>
    dispatch({
      type: onboardingConstants.TOGGLE_MARKETING,
      payload: event.target.checked,
    });

  const onClickArchitect = () => onToggleProfession('Architect', false);

  const onClickOwner = () => onToggleProfession('Property owner', false);

  const onClickOther = () => onToggleProfession('');

  return (
    <>
      <Modal.Header>
        <h2 className='text-center w-100'>
          {text('thanks')} {fullName}!
        </h2>
      </Modal.Header>
      <Modal.Body>
        <p className='text-center'>{text('tellUsAboutYourself')}</p>
        <p className='text-center secondary-heading'>
          {text('describeYourOccupation')}
        </p>
        <Row>
          <Col xs={4}>
            <div className='d-flex flex-column align-items-center'>
              <OnboardingTile
                icon='/public/img/icon1.png'
                selectedIcon='/public/img/icon1-hover.png'
                active={profession === 'Architect'}
                onClick={onClickArchitect}
              />
              <p className='text-center mt-3'>
                {text('architect')} {text('or')}
                <br />
                {text('buildingDesigner')}
              </p>
            </div>
          </Col>
          <Col xs={4}>
            <div className='d-flex flex-column align-items-center'>
              <OnboardingTile
                icon='/public/img/icon2.png'
                selectedIcon='/public/img/icon2-hover.png'
                active={profession === 'Property owner'}
                onClick={onClickOwner}
              />
              <p className='text-center mt-3'>
                {text('propertyOwner')}
                <br />
                {text('or')} {text('developer')}
              </p>
            </div>
          </Col>
          <Col xs={4}>
            <div className='d-flex flex-column align-items-center'>
              <OnboardingTile
                icon='/public/img/icon3.png'
                selectedIcon='/public/img/icon3-hover.png'
                active={hasOther}
                onClick={onClickOther}
              />
              <p className='text-center mt-3 mb-1'>{text('other')}</p>
            </div>
          </Col>
        </Row>
        <Checkbox
          defaultChecked={isMarketingEnabled}
          value={isMarketingEnabled}
          onChange={onToggleMarketing}
          label={text('receiveMarketing')}
        />
        <Button
          disabled={disabled}
          onClick={onClick}
          className='btn-block mt-4 w-100'
          loading={loading}
        >
          {text(hasOther ? 'nextStep' : 'goToMyDashboard')}
        </Button>
      </Modal.Body>
    </>
  );
};

export default Profession;
