import cn from 'classnames';
import T from 'prop-types';
import React from 'react';

export default function SiteAddressOverlay({ show }) {
  return (
    <div
      id='site-address-overlay'
      className={cn('site-address-overlay', show && 'show')}
    />
  );
}

SiteAddressOverlay.propTypes = {
  show: T.bool.isRequired,
};
