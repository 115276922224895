import * as turf from '@turf/turf';
import axios from 'axios';
import _ from 'lodash/fp';

import { PRODUCT_DATA } from '../components/mapView/constants';
import {
  computeBuffered,
  computeIntersection,
  computeUnionMulti,
} from '../components/mapView/geometry';
import { drawProductLayers } from '../workers/drawSelectionProducts.worker';
import { getCoverageLabels } from '../workers/getCoverageLabels.worker';
import { computeSurroundingPolygonFromPolylineNoGM } from '../workers/utilities';
import assert from './assert';
import { formatMonth } from './date';
import * as mapUtils from './map';

const MIN_BUFFER_M = 5;

const getBottomLeft = (xy) => [
  Math.min(...xy.map((c) => c[0])),
  Math.min(...xy.map((c) => c[1])),
];

const shift = (xy, dx, dy) => xy.map(([x, y]) => [x + dx, y + dy]);

const isClockwise = (xy) => {
  let area = 0;
  for (let i = 0; i < xy.length; i++) {
    let j = (i + 1) % xy.length;
    area += xy[i][1] * xy[j][0];
    area -= xy[j][1] * xy[i][0];
  }
  return area > 0;
};

// converts lat lng coordinates to pixel values
const latLngToXy = (projection, scale, ne, sw, coords, isLatFirst = true) => {
  return coords.map((coord) => {
    const [lat, lng] = isLatFirst ? coord : [coord[1], coord[0]];
    const point = projection.fromLatLngToPoint(
      new google.maps.LatLng({ lat, lng })
    );
    return [(point.x - sw.x) * scale, (point.y - ne.y) * scale];
  });
};

const polygonLatLngToGeojson = (coords, isLatFirst) => ({
  type: 'Polygon',
  coordinates: [
    [...coords, coords[0]].map((c) => (isLatFirst ? [c[1], c[0]] : c)),
  ], // geojson expects lng, lat order
});

const layerToPaths = (projection, scale, ne, sw, layer, dx, dy) => {
  const _process_polygon = (polygonCoords) =>
    polygonCoords.map((coords) => {
      const xy = latLngToXy(projection, scale, ne, sw, coords, false);
      return shift(xy, dx, dy);
    });

  const geom = layer.geometry;
  assert(['Polygon', 'MultiPolygon'].includes(geom.type));
  const paths =
    geom.type === 'MultiPolygon'
      ? _.flatMap(_process_polygon, geom.coordinates)
      : _process_polygon(geom.coordinates);
  return paths;
};

const translateBufferOffset = (coords, isLatFirst, buffer) => {
  const poly = polygonLatLngToGeojson(coords, isLatFirst);
  const distance = buffer * Math.SQRT2;
  const translated = turf.transformTranslate(poly, distance, 135, {
    units: 'meters',
  });
  return translated.coordinates[0];
};

const regionToPaths = (projection, scale, ne, sw, region, buffer) => {
  // convert `coords` to pixels, shifted such that bottom-left pixel coincides with 0,0
  const _calcIncludeXy = (coords, isLatFirst = true) => {
    const base = latLngToXy(projection, scale, ne, sw, coords, isLatFirst);
    let translated = latLngToXy(
      projection,
      scale,
      ne,
      sw,
      translateBufferOffset(coords, isLatFirst, buffer).slice(0, -1),
      false
    );
    const bl = getBottomLeft(base);
    const blT = getBottomLeft(translated);
    const tx = blT[0] - bl[0];
    const ty = blT[1] - bl[1];
    // line up bottom left with 0, 0
    const [dx, dy] = [-bl[0] + tx, -bl[1] + ty];
    translated = shift(base, dx, dy);
    return [translated, [dx, dy]];
  };

  let includeXy;
  let dx;
  let dy;
  let excludeXys = [];

  if (region.type === 'Polyline') {
    const surroundingPolygonCoords = computeSurroundingPolygonFromPolylineNoGM(
      region.include
    )[0];
    [includeXy, [dx, dy]] = _calcIncludeXy(surroundingPolygonCoords, false);
  } else if (region.type === 'Polygon' || region.type === 'Rectangle') {
    [includeXy, [dx, dy]] = _calcIncludeXy(region.include, true);

    // return value always a MultiPolygon
    const excludeUnion =
      region.exclude?.length > 0
        ? computeIntersection(
            // don't double up on same area
            computeUnionMulti(
              region.exclude.map((coords) => polygonLatLngToGeojson(coords))
            ),
            // make sure exclusions don't leave include geometry
            polygonLatLngToGeojson(region.include)
          )
        : null;

    const isIncludeClockwise = isClockwise(includeXy);
    excludeXys = excludeUnion
      ? excludeUnion.coordinates.map(([exclude]) => {
          let xy = shift(
            latLngToXy(projection, scale, ne, sw, exclude, false),
            dx,
            dy
          );
          // exclusions must be opposite order, source: https://stackoverflow.com/a/11878784
          if (isClockwise(xy) === isIncludeClockwise) {
            xy = xy.reverse();
          }
          return xy;
        })
      : [];
  } else {
    throw new Error(`unknown region type: ${region.type}`);
  }

  return { paths: [includeXy, excludeXys], displacement: [dx, dy] };
};

const computeBoundsWidthAndHeight = (bounds) => {
  const _ne = bounds.getNorthEast();
  const _sw = bounds.getSouthWest();
  const ne = turf.point([_ne.lng(), _ne.lat()]);
  const sw = turf.point([_sw.lng(), _sw.lat()]);
  const nw = turf.point([_sw.lng(), _ne.lat()]);
  const width = turf.distance(nw, ne, { units: 'meters' });
  const height = turf.distance(sw, nw, { units: 'meters' });
  return { width, height };
};

const computeMapScreenshot = async (map, mapDim, selection, coverage) => {
  const projection = map.getProjection();
  const bounds = map.getBounds();
  const ne = projection.fromLatLngToPoint(bounds.getNorthEast());
  const sw = projection.fromLatLngToPoint(bounds.getSouthWest());

  /* ---------- SHAPES ---------- */

  const regionBounds = mapUtils.getRegionBounds(selection.region);
  // we'll set the buffer to the min of 5 and (0.05)*x
  //  where x is the larger of the width and height of the region
  const regionSize = computeBoundsWidthAndHeight(regionBounds);
  const buffer = Math.max(
    MIN_BUFFER_M,
    0.05 * Math.max(regionSize.width, regionSize.height)
  );

  const zoom = Math.floor(
    mapUtils.getGMBoundsZoomLevel(regionBounds, mapDim, buffer)
  );
  const scale = Math.pow(2, zoom);

  /* ---------- DEBUGGING ---------- */
  // const TEST_COORD = [-37.810272, 144.962646]; // CBD
  // const [testXy] = latLngToXy(projection, scale, ne, sw, [TEST_COORD], true);
  // const [testLatLng] = xyToLatLng(projection, scale, ne, sw, [testXy]);
  // console.log('SHOULD = TEST_COORD', testXy, testLatLng);

  // const TEST_SCALE = Math.pow(2, 19);
  // const [TEST_TILE_X, TEST_TILE_Y] = [473305, 321717];
  // const testLatLng = tileXyToLatLng(TEST_SCALE, TEST_TILE_X, TEST_TILE_Y);
  // const testTile = latLngToTile(TEST_SCALE, testLatLng.lat, testLatLng.lng);
  /* -------------------- */

  // get coverage layers
  const productCoverage = _.find(
    ['category_name', selection.category_name],
    coverage
  );
  const {
    features: [{ geometry: drawRegion }],
  } = mapUtils.convertToGeoJson([selection]);
  const layers = drawProductLayers(
    productCoverage,
    drawRegion,
    false,
    true,
    true
  );
  const labels = getCoverageLabels({
    coverage,
    selections: [selection],
  });

  // get paths (for display) and displacement for region
  const {
    paths: regionPaths,
    displacement: [dx, dy],
  } = regionToPaths(projection, scale, ne, sw, selection.region, buffer);

  // get paths (for display) for layers
  const layerPaths = _.flatMap(
    (layer) => layerToPaths(projection, scale, ne, sw, layer, dx, dy),
    layers
  );

  const regionBuffered = computeBuffered(drawRegion, buffer);
  const bbox = getBbox(regionBuffered.coordinates[0]);
  const regionBufferedXy = latLngToXy(
    projection,
    scale,
    ne,
    sw,
    regionBuffered.coordinates[0],
    false
  );
  const bboxXy = getBbox(regionBufferedXy);

  const labelsXy = labels.map((label) => {
    let xy = latLngToXy(projection, scale, ne, sw, [label.position], false);
    xy = shift(xy, dx, dy);
    const [x, y] = xy[0];
    return { ...label, x, y };
  });

  /* -------------------- */

  /* ---------- TILES ---------- */

  const _mapTileToXy = (mapTile) => {
    let [[x, y]] = latLngToXy(
      projection,
      scale,
      ne,
      sw,
      [[mapTile.tile.lng, mapTile.tile.lat]],
      false
    );
    [[x, y]] = shift([[x, y]], dx, dy);
    x = Math.floor(x);
    y = Math.floor(y);
    return _.flow(_.set(['tile', 'x'], x), _.set(['tile', 'y'], y))(mapTile);
  };

  const mapTiles = await getMapTilesInBbox(projection, zoom, ne, sw, bbox);
  const mapTilesXy = mapTiles.map(_mapTileToXy);

  /* -------------------- */

  return {
    regionPaths,
    layerPaths,
    bbox,
    bboxXy,
    zoom,
    mapTilesXy,
    labelsXy,
  };
};

const xyToPathString = (xy) => {
  const [[x0, y0], ...rest] = xy;
  return [`M${x0} ${y0}`, ...rest.map(([x, y]) => `L${x} ${y}`)].join(' ');
};

const createSvgPath = (group, paths, category_name = null) => {
  const color = category_name
    ? PRODUCT_DATA.entities[category_name].display_color
    : '#9b9b9b';
  return group
    .path(paths.map((path) => xyToPathString(path)).join(' '))
    .fill({ color, opacity: 0.5 })
    .attr('stroke-width', 5.5)
    .stroke({ color, opacity: 1.0 })
    .attr('fill-rule', 'evenodd'); // use fill-rule=evenodd for exclusions, see https://codepen.io/SitePoint/pen/yLEgqv
};

// we need this cos canvas.toDataURL() not available with OffscreenCanvas
const toDataURL = async (data) =>
  new Promise((ok) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => ok(reader.result));
    reader.readAsDataURL(data);
  });

const waitImageLoad = (img) =>
  new Promise((ok, reject) => {
    img.onload = () => ok(img);
    img.onerror = (err) => {
      console.error(err);
      reject(err);
    };
  });

const LABEL_STYLES = {
  padding: [3, 3],
};

// returns a data URL that can be passed into downloadFile()
//  takes in a SVG() instance using svg.js
const drawMapScreenshot = async (draw, mapTilesXy, labelsXy) => {
  labelsXy.forEach((label) => {
    const _createText = (g) => {
      const text = g
        .text(formatMonth(label.dateAcquired * 1000))
        .font({
          family: 'sans-serif',
          size: 14,
          weight: 'bold',
          verticalAlign: 'middle',
        })
        .fill('#ffffff')
        .attr('alignment-baseline', 'top');
      return text;
    };
    const g = draw.group();
    _createText(g);
    const [w, h] = [g.width(), g.height()];
    const [px, py] = LABEL_STYLES.padding;
    const [rw, rh] = [w + 2 * px, h + 2 * py];
    g.rect(rw, rh) // top-left is 0,0 move
      .fill(label.displayColor)
      // .move(label.x - w*0.5, label.y);
      .move(label.x - rw * 0.5, label.y - rh * 0.5);
    const text = _createText(draw.group());
    text.move(label.x - w * 0.5, label.y - h * 0.5);
    // text.move(0, 0); // this should be top-left
    console.log(draw.svg());
  });

  const svgBlob = new Blob([draw.svg()], { type: 'image/svg+xml' });
  const url = URL.createObjectURL(svgBlob);

  const shapeImg = new Image();
  shapeImg.width = draw.width();
  shapeImg.height = draw.height();
  shapeImg.src = url;
  await waitImageLoad(shapeImg);
  const canvas = new OffscreenCanvas(shapeImg.width, shapeImg.height);
  const ctx = canvas.getContext('2d');

  const mapTileImages = await Promise.all(
    mapTilesXy.map(
      (mapTile) =>
        new Promise((ok, reject) => {
          const tileImg = new Image();
          tileImg.width = TILE_SIZE;
          tileImg.height = TILE_SIZE;
          tileImg.src = URL.createObjectURL(mapTile.data);
          waitImageLoad(tileImg)
            .then((img) => ok([mapTile, img]))
            .catch(reject);
        })
    )
  );
  mapTileImages.forEach(([{ tile }, tileImg]) => {
    ctx.drawImage(tileImg, tile.x, tile.y, TILE_SIZE, TILE_SIZE);
  });

  ctx.drawImage(shapeImg, 0, 0, shapeImg.width, shapeImg.height);
  const blob = await canvas.convertToBlob({ type: 'image/jpeg', quality: 1 });
  return await toDataURL(blob);
};

const getBbox = (path) => {
  let xMin = null;
  let yMin = null;
  let xMax = null;
  let yMax = null;
  path.forEach(([x, y]) => {
    if (xMin === null || x < xMin) xMin = x;
    if (yMin === null || y < yMin) yMin = y;
    if (xMax === null || x > xMax) xMax = x;
    if (yMax === null || y > yMax) yMax = y;
  });
  return { xMin, yMin, xMax, yMax };
};

const rad2deg = (x) => x * (180 / Math.PI);

const TILE_SIZE = 256;

const latLngToTile = (scale, lat, lng) => {
  // The mapping between latitude, longitude and pixels is defined by the web
  // mercator projection.
  //  source: https://developers.google.com/maps/documentation/javascript/examples/map-coordinates
  function project(latLng) {
    let sinY = Math.sin((latLng.lat() * Math.PI) / 180);

    // Truncating to 0.9999 effectively limits latitude to 89.189. This is
    // about a third of a tile past the edge of the world tile.
    sinY = Math.min(Math.max(sinY, -0.9999), 0.9999);

    return new google.maps.Point(
      TILE_SIZE * (0.5 + latLng.lng() / 360),
      TILE_SIZE * (0.5 - Math.log((1 + sinY) / (1 - sinY)) / (4 * Math.PI))
    );
  }

  const worldCoord = project(new google.maps.LatLng({ lat, lng }));
  const pixelCoord = new google.maps.Point(
    // it might be technically more accurate to remove Math.floor here but the difference is negligible
    Math.floor(worldCoord.x * scale),
    Math.floor(worldCoord.y * scale)
  );
  const px = pixelCoord.x;
  const py = pixelCoord.y;
  const xTile = Math.floor(px / TILE_SIZE);
  const yTile = Math.floor(py / TILE_SIZE);

  return { lat, lng, xTile, yTile };
};

const tileXyToLatLng = (scale, xTile, yTile) => {
  function invProject(worldCoord) {
    const latRad =
      2 * Math.atan(Math.exp(2 * Math.PI * (0.5 - worldCoord.y / TILE_SIZE))) -
      Math.PI / 2;
    return {
      lng: 360.0 * (worldCoord.x / TILE_SIZE - 0.5),
      lat: rad2deg(latRad),
    };
  }

  const pixelCoord = new google.maps.Point(
    xTile * TILE_SIZE,
    yTile * TILE_SIZE
  );
  const worldCoord = new google.maps.Point(
    pixelCoord.x / scale,
    pixelCoord.y / scale
  );
  const { lat, lng } = invProject(worldCoord);

  return { lat, lng };
};

const bboxToTiles = (projection, scale, ne, sw, bbox) => {
  const swTile = latLngToTile(scale, bbox.yMin, bbox.xMin);
  const neTile = latLngToTile(scale, bbox.yMax, bbox.xMax);
  const tiles = [];
  for (let xTile = swTile.xTile; xTile <= neTile.xTile; xTile++) {
    for (let yTile = neTile.yTile; yTile <= swTile.yTile; yTile++) {
      const { lat, lng } = tileXyToLatLng(scale, xTile, yTile);
      tiles.push({ lat, lng, xTile, yTile });
    }
  }
  return tiles;
};

const getMapTileDataAt = async (xTile, yTile, zoom) => {
  const resp = await axios.get(
    `${process.env.AEROMETREX_TILES_URL}/${process.env.AEROMETREX_TILES_KEY}/service?SERVICE=WMTS&REQUEST=GetTile&LAYER=Australia_latest&FORMAT=png&VERSION=1.0.0&STYLE=default&TileMatrixSet=webmercator&TileMatrix=${zoom}&TileRow=${yTile}&TileCol=${xTile}`,
    {
      responseType: 'blob',
      headers: { 'Access-Control-Allow-Origin': '*' },
    }
  );
  return resp.data;
};

const getMapTilesInBbox = async (projection, zoom, ne, sw, bbox) => {
  const zoomFloored = Math.floor(zoom);
  const scale = Math.pow(2, zoomFloored);
  const mapTiles = await Promise.all(
    bboxToTiles(projection, scale, ne, sw, bbox).map(
      (tile) =>
        new Promise((ok, reject) =>
          getMapTileDataAt(tile.xTile, tile.yTile, zoomFloored)
            .then((data) =>
              ok({
                tile,
                data,
              })
            )
            .catch((err) => reject(err))
        )
    )
  );
  return mapTiles;
};

// source: POSIX "Fully portable filenames" in https://en.wikipedia.org/wiki/Filename
const toValidFileStem = (str) => {
  const DEFAULT = 'Shape';
  const r = /[^\w\-. ]/g;
  const proposed = str.replace(r, '').trim();
  if (proposed.length === 0) {
    // empty file name isn't valid
    return DEFAULT;
  }
  return proposed;
};

const mapScreenshotUtils = {
  computeMapScreenshot,
  createSvgPath,
  drawMapScreenshot,
  toValidFileStem,
};

export default mapScreenshotUtils;
