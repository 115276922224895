import React, { useMemo } from 'react';
import { Link as RRLink } from 'react-router-dom';
import { getRedirectUrl, isUrlExternal } from '../../hooks/useRedirect';

/**
 * Like `Link` in react-router-dom, but supports external URLs.
 */
const Link = ({ to, children, ...props }) => {
  const url = useMemo(() => getRedirectUrl(to), [to]);
  const isExternal = useMemo(
    () => isUrlExternal(url),
    [url, window.location.hostname]
  );
  return isExternal ? (
    <a href={url.href} {...props}>
      {children}
    </a>
  ) : (
    <RRLink to={url.pathname} {...props}>
      {children}
    </RRLink>
  );
};

export default Link;
