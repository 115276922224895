import {
  TOGGLE_SIDENAV_VISIBILITY,
  TOGGLE_LAYERS_PANEL_VISIBILITY,
  TOGGLE_RIGHT_MENU_VISIBILITY,
  COLLAPSE_AUTOMATIC_DATA_TABLE,
  TOGGLE_MANUAL_DATA_TABLE_VISIBILITY,
  TOGGLE_AUTOMATIC_DATA_TABLE_VISIBILITY,
} from '../constants/layout';

const defaultMapState = {
  sideNav: { isVisible: false },
  rightMenu: { isVisible: false },
  layersPanel: { isVisible: false },
  automaticDataTable: { isVisible: false, isCollapsed: true },
  manualDataTable: { isVisible: false, isCollapsed: true },
};

const layoutReducer = (state = defaultMapState, action) => {
  switch (action.type) {
    case TOGGLE_SIDENAV_VISIBILITY:
      return {
        ...state,
        sideNav: { isVisible: action.payload },
      };
    case TOGGLE_RIGHT_MENU_VISIBILITY:
      return {
        ...state,
        rightMenu: { isVisible: action.payload },
      };
    case TOGGLE_LAYERS_PANEL_VISIBILITY:
      return {
        ...state,
        layersPanel: { isVisible: action.payload },
      };
    case COLLAPSE_AUTOMATIC_DATA_TABLE:
      return {
        ...state,
        automaticDataTable: {
          ...state.automaticDataTable,
          isCollapsed: action.payload,
        },
      };
    case TOGGLE_AUTOMATIC_DATA_TABLE_VISIBILITY:
      return {
        ...state,
        automaticDataTable: {
          ...state.automaticDataTable,
          isVisible: action.payload,
        },
      };
    case TOGGLE_MANUAL_DATA_TABLE_VISIBILITY:
      return {
        ...state,
        manualDataTable: {
          ...state.manualDataTable,
          isVisible: action.payload,
        },
      };
    default:
      return state;
  }
};

export default layoutReducer;
