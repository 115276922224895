/**
 * It's unlikely you want to use this hook directly, most often it is used with the <Tooltip /> component.
 */

import {
  arrow,
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { useMemo, useRef, useState } from 'react';

const useTooltip = ({
  placement,
  keepOpenOnHover = false,
  open: controlledOpen,
  setOpen: setControlledOpen,
}) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(false);
  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const arrowRef = useRef(null);
  const floating = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(8),
      shift({ padding: 8 }),
      flip(),
      arrow({
        element: arrowRef,
      }),
    ],
  });
  const hover = useHover(floating.context, {
    move: false,
    ...(keepOpenOnHover && { handleClose: safePolygon() }),
  });
  const focus = useFocus(floating.context, {
    move: false,
  });
  const dismiss = useDismiss(floating.context);
  const role = useRole(floating.context, { role: 'tooltip' });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return useMemo(
    () => ({
      open,
      setOpen,
      arrowRef,
      ...interactions,
      ...floating,
    }),
    [open, setOpen, interactions, floating]
  );
};

export default useTooltip;
