import classnames from 'classnames';
import React, { useMemo } from 'react';
import { ProgressBar } from 'react-bootstrap';
import {
  calculateRemainderDays,
  calculateRemainderHours,
  calculateTrialLengthDays,
} from '../../utilities/membership';

const TrialMembershipBar = ({ membership, ...restProps }) => {
  const { variant, trialLengthDays, remainderDays } = useMemo(() => {
    let variant = 'success';
    const trialLengthDays = calculateTrialLengthDays(membership);
    const remainderHours = calculateRemainderHours(membership);
    let remainderDays = calculateRemainderDays(membership);

    if (remainderDays <= 4 && remainderHours > 24) {
      variant = 'warning';
    } else if (remainderHours <= 24) {
      variant = 'danger';
    }
    // Allow for hours to show on progress bar
    if (remainderDays === 0) {
      remainderDays = remainderHours / 24;
    }
    return { variant, remainderDays, trialLengthDays };
  }, [membership]);

  return (
    <ProgressBar
      className={classnames('inverted', { [variant]: variant })}
      now={((trialLengthDays - remainderDays) / trialLengthDays) * 100}
      variant={variant}
      {...restProps}
    />
  );
};

export default TrialMembershipBar;
