import {
  TOGGLE_SIDENAV_VISIBILITY,
  TOGGLE_RIGHT_MENU_VISIBILITY,
  COLLAPSE_AUTOMATIC_DATA_TABLE,
  TOGGLE_MANUAL_DATA_TABLE_VISIBILITY,
  TOGGLE_LAYERS_PANEL_VISIBILITY,
  TOGGLE_AUTOMATIC_DATA_TABLE_VISIBILITY,
} from '../constants/layout';

export const toggleComponentVisibility =
  (type) => (isVisible) => async (dispatch) => {
    dispatch({
      type,
      payload: isVisible,
    });
  };
export const toggleSidenavVisibility = toggleComponentVisibility(
  TOGGLE_SIDENAV_VISIBILITY
);
export const toggleRightMenuVisibility = toggleComponentVisibility(
  TOGGLE_RIGHT_MENU_VISIBILITY
);
export const toggleLayersPanelVisibility = toggleComponentVisibility(
  TOGGLE_LAYERS_PANEL_VISIBILITY
);
export const toggleManualDataTableVisibility = toggleComponentVisibility(
  TOGGLE_MANUAL_DATA_TABLE_VISIBILITY
);
export const toggleAutomaticDataTableVisibility = toggleComponentVisibility(
  TOGGLE_AUTOMATIC_DATA_TABLE_VISIBILITY
);

export const collapseAutomaticDataTable = (isVisible) => async (dispatch) => {
  dispatch({
    type: COLLAPSE_AUTOMATIC_DATA_TABLE,
    payload: isVisible,
  });
};
