/**
 * TODO: remove the 'secondary' variant as that is what ProductTooltip is for
 */

import cn from 'classnames';
import { isEmpty, values } from 'lodash';
import _, { noop } from 'lodash/fp';
import get from 'lodash/get';
import T from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { AMPLITUDE_EVENTS } from '../../../constants/analytics';
import {
  ADD_ONS,
  ADD_ONS_PROP_NAME,
  PRODUCT_PROS_CONS,
  PRODUCT_TYPE,
} from '../../../constants/product';
import { amplitudeTrack } from '../../../utilities';
import { selectionChildren } from '../../../utilities/map';
import Tooltip from '../../Tooltip';
import Icon from '../../icon/Icon';
import AddOnIcon from '../../icon/common/AddOnIcon';
import { convertGeometries, getAddOnConfig } from '../drawingManager/utilities';
import { MapViewContext } from '../mapViewContext';
import ProductCard from '../productCard/ProductCard';

const { ORDERED_DATA } = AMPLITUDE_EVENTS;

const IconWrapper = ({ isAddOn = false, ...rest }) => {
  if (!isAddOn) {
    return <Icon {...rest} />;
  }

  return <AddOnIcon {...rest} />;
};

const ProductControlsButton = ({
  productType,
  withExplanation = false,
  variant = 'default',
  placement = 'right-start',
  asPopover = false,
  disableSetHoveredProductType = false,
  deliveryMethod,
  title = '',
  description = '',
}) => {
  const pros = get(PRODUCT_PROS_CONS, [productType, 'pros']);
  const cons = get(PRODUCT_PROS_CONS, [productType, 'cons']);
  const { state, actions } = useContext(MapViewContext);

  const isAddOn = deliveryMethod === ADD_ONS_PROP_NAME;
  const isActiveSelectionAnAddOn = !!state.activeSelectionDataType;

  // Get available product data associated with the current ROI
  const availableProductData = useMemo(() => {
    return _.find(
      ['category_name', productType],
      state.coverageInRegionSelection
    );
  }, [state.coverageInRegionSelection, productType]);

  const availableProductDataDefault = useMemo(() => {
    return _.find(
      ['category_name', productType],
      state.coverageInDefaultRegion
    );
  }, [state.coverageInDefaultRegion, productType]);

  const isProductDeactivated = useMemo(() => {
    const { DATA_TYPE: productControlDataType } =
      getAddOnConfig(productType) || {};

    if (isAddOn) {
      // if Add-on determine if belongs to exterior or interior
      if (state.activeSelectionDataType) {
        return productControlDataType !== state.activeSelectionDataType;
      }

      // if active selection is interior disable exterior add-on icons
      // or vice versa
      if (
        state.activeSelection &&
        [PRODUCT_TYPE.EXTERIOR, PRODUCT_TYPE.INTERIOR].includes(
          state.activeSelection.category_name
        )
      ) {
        return state.activeSelection.category_name !== productControlDataType;
      }
    }

    return !state.productAvailability[productType];
  }, [state.productAvailability, state.activeSelection, productType]);

  const isViableSelection = useMemo(() => {
    return (
      !isProductDeactivated &&
      (!state.activeSelection ||
        state.activeSelection.category_name !== productType)
    );
  }, [isProductDeactivated, state.activeSelection, productType]);

  const isActiveSelectionThisProduct =
    state.activeSelection?.category_name === productType;

  const getActionDependingOnProduct = useMemo(() => {
    if (
      ORDERED_DATA.STREETSCAPE_AERIAL.toLowerCase()
        .replace(/ +/g, '')
        .split(',')
        .includes(productType)
    ) {
      return ORDERED_DATA.STREETSCAPE_AERIAL;
    } else {
      return ORDERED_DATA.EXTERIOR_INTERIOR_DRONE;
    }
  }, [productType]);

  const getAddOnSetting = (productType) => {
    const selectedAddOnConfig = getAddOnConfig(productType);

    if (!selectedAddOnConfig) {
      return {
        baseProduct: {},
        addOn: {},
      };
    }

    // get selected add-on base Data type e.g exterior or interior from coverage products
    const baseProduct = state.coverage.find(
      ({ category_name }) => category_name === selectedAddOnConfig.DATA_TYPE
    );

    // get selected add on product_id, category_name etc. from coverage products
    const addOn = state.coverage.find(
      ({ category_name }) => category_name === productType
    );

    return {
      baseProduct,
      addOn,
    };
  };

  const createAddOnWithSelection = (
    baseProduct,
    addOn,
    region = state.defaultRegion
  ) => {
    return actions.addSelectionWithAddOn(
      {
        ...availableProductDataDefault,
        product_id: baseProduct.product_id,
        display_name: baseProduct.display_name,
        category_name: baseProduct.category_name,
      },
      region,
      addOn,
      true
    );
  };

  const changeProductTypeContainingAddOns = (baseProduct, addOn) => {
    return actions.changeSelectionProductType(
      state.activeSelection.id,
      {
        ...availableProductData,
        product_id: baseProduct.product_id,
        display_name: baseProduct.display_name,
        category_name: baseProduct.category_name,
      },
      {
        product_id: addOn.product_id,
        display_name: addOn.display_name,
        category_name: addOn.category_name,
      }
    );
  };

  const handleAddSelection = async () => {
    amplitudeTrack(ORDERED_DATA.EVENT, { action: getActionDependingOnProduct });
    const { baseProduct, addOn } = getAddOnSetting(productType);

    if (!isViableSelection) {
      console.warn(`product ${productType} not viable for selection`);
      return;
    }

    let newSelection;

    const handleDoneChangeType = (newSelection) => {
      actions.setActiveSelectionId(newSelection?.id);
      actions.setHoveredData(null);
      if (productType === PRODUCT_TYPE.UNKNOWN) {
        actions.clearProductSelected();
      }
    };

    if (_.isEmpty(state.debouncedRegionSelection)) {
      console.assert(
        !!availableProductDataDefault,
        'no available product data found in default region'
      );

      if (!state.defaultRegion) {
        console.warn('no default region found');
        return;
      }

      if (!isEmpty(addOn) && !isEmpty(baseProduct)) {
        createAddOnWithSelection(baseProduct, addOn);
        return; // setSelections and etc. handled in actions.addSelectionWithAddOn
      } else {
        newSelection = actions.addSelection(
          availableProductDataDefault,
          state.defaultRegion,
          true
        );
      }
    } else {
      console.assert(
        !!availableProductData,
        'no available product data found in region selection'
      );

      if (state.activeSelection) {
        if (isAddOn && !isActiveSelectionAnAddOn) {
          const selectionHasAddOn = !!values(ADD_ONS).find(
            ({ DATA_TYPE }) => DATA_TYPE === state.activeSelection.category_name
          );

          if (selectionHasAddOn) {
            actions.createAddOnSelection(productType, state.activeSelection);
            return;
          } else {
            // Triggered when any of add-ons tool is clicked and changing Draft/Tripods(interior/exterior)
            newSelection = changeProductTypeContainingAddOns(
              baseProduct,
              addOn
            );
          }
        } else {
          const activeSelectionChildren = selectionChildren(
            state.selections,
            state.activeSelection.id
          );

          const updatedSelection = (selectionId = null) =>
            actions.changeSelectionProductType(
              state.activeSelection.id,
              availableProductData,
              {},
              selectionId
            );

          if (activeSelectionChildren.length > 0) {
            actions.showChangeProductTypeConfirmation(
              productType,
              activeSelectionChildren,
              () => {
                // callback
                const newSelection = updatedSelection();
                handleDoneChangeType(newSelection);
              },
              false,
              productType === PRODUCT_TYPE.UNKNOWN
            );
            return;
          }

          newSelection = updatedSelection(
            !isAddOn && !!state.activeSelection.parent_selection_id
              ? state.activeSelection.id
              : null
          );
        }
      } else {
        const newRegion = _.values(
          convertGeometries(state.debouncedRegionSelection, false)
        )[0].region;

        if (!isEmpty(addOn) && !isEmpty(baseProduct)) {
          createAddOnWithSelection(baseProduct, addOn, newRegion);
          return;
        } else {
          newSelection = actions.addSelection(
            availableProductData,
            newRegion,
            true
          );
        }
      }
    }

    console.assert(newSelection);
    handleDoneChangeType(newSelection);
  };

  const renderIcon = () => {
    if (!isViableSelection && isAddOn && !isActiveSelectionThisProduct) {
      return `${productType}_disabled`;
    }

    return variant === 'secondary'
      ? 'question'
      : isAddOn && isActiveSelectionThisProduct
      ? `${productType}_white`
      : productType;
  };

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      actions.hoverProduct(productType);
    } else {
      actions.unhoverProduct(productType);
    }
    // exhaustive-deps requires `actions` be a dependency when `actions.setHoveredProductType` is the only actual dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, actions.hoverProduct, actions.unhoverProduct]);

  return (
    <Tooltip.Provider
      open={open}
      setOpen={setOpen}
      placement='right-start'
      keepOpenOnHover
    >
      <Tooltip.Trigger onClick={() => setOpen((v) => !v)}>
        <button
          type='button'
          className={cn(productType, {
            ProductBadge: variant !== 'secondary',
            'btn-icon': variant === 'secondary',
            tertiary: variant === 'tertiary',
            focus: isActiveSelectionThisProduct,
            disabled: !isViableSelection,
            deactivated: isProductDeactivated,
          })}
          {...(variant === 'secondary'
            ? noop
            : { onClick: handleAddSelection })}
        >
          <IconWrapper
            isAddOn={isAddOn}
            className={cn({
              enabled: true,
            })}
            icon={renderIcon()}
          />
        </button>
      </Tooltip.Trigger>
      <Tooltip.Content>
        <ProductCard
          productType={productType}
          pros={pros}
          cons={cons}
          isDisabled={!isViableSelection}
          handleClick={handleAddSelection}
          variant={variant}
          withExplanation={withExplanation}
          title={title}
          description={description}
        />
      </Tooltip.Content>
    </Tooltip.Provider>
  );
};

ProductControlsButton.propTypes = {
  productType: T.string.isRequired,
  scanCategories: T.array,
  withExplanation: T.bool,
  variant: T.oneOf(['default', 'secondary', 'tertiary']),
  placement: T.string,
  asPopover: T.bool,
  disableSetHoveredProductType: T.bool,
  title: T.string,
  description: T.string,
};

export default ProductControlsButton;
