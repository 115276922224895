import isEmpty from 'lodash/isEmpty';
import React from 'react';
import List from '../../../components/list/List';
import text, { formatSqmArea, formatCurrency } from '../../../text';
import classNames from 'classnames';
import T from 'prop-types';

const getArea = (details) =>
  details?.area_in_sqm ? formatSqmArea(details.area_in_sqm.toFixed()) : null;

const getDescription = (displayName, details) =>
  details?.area_in_sqm
    ? text('productData', {
        product: displayName,
        area: getArea(details),
      })
    : displayName;

const ChargeItem = (props) => {
  const {
    displayName,
    details,
    price,
    quote,
    discounts,
    fees,
    isApproximate = false,
    areaIsAlignedMiddle = false,
    hideArea = false,
  } = props;

  return (
    <tbody className='ChargeItem'>
      <List.Item
        className={classNames('charge', {
          isApproximate: isApproximate && price.base,
        })}
        description={
          hideArea || areaIsAlignedMiddle
            ? displayName
            : getDescription(displayName, details)
        }
        value={
          price.base
            ? formatCurrency(
                price.base,
                quote.currency.name,
                quote.currency.scale_factor,
                {},
                true
              )
            : text('toBeConfirmed')
        }
        {...(areaIsAlignedMiddle && {
          midText: `Area: ${getArea(details)}`,
        })}
      />

      {!isEmpty(fees) &&
        fees.map((fee, i) => (
          <List.Item
            key={i}
            description={fee.display_name}
            value={`${formatCurrency(
              fee.price.total,
              quote.currency.name,
              quote.currency.scale_factor,
              {},
              true
            )}`}
            midText={areaIsAlignedMiddle ? <span /> : null}
            // add span to align tr > td if there's a middle text
          />
        ))}

      {!isEmpty(discounts) &&
        discounts.map((discount, i) => (
          <List.Item
            key={i}
            description={discount.display_name}
            value={`-${formatCurrency(
              discount.price.discount,
              quote.currency.name,
              quote.currency.scale_factor,
              {},
              true
            )}`}
            className='discount font-pink pl-3 font-italic'
            midText={areaIsAlignedMiddle ? <span /> : null}
            // add span to align tr > td if there's a middle text
          />
        ))}
    </tbody>
  );
};

ChargeItem.propTypes = {
  displayName: T.string,
  details: T.object,
  price: T.object,
  quote: T.object,
  discounts: T.array,
  fees: T.array,
  isApproximate: T.bool,
  areaIsAlignedMiddle: T.bool,
  hideArea: T.bool,
};

export default ChargeItem;
