import Cookies from 'universal-cookie';

const cookies = new Cookies(null, {
  sameSite: 'lax',
  domain: process.env.COOKIE_DOMAIN,
  secure: !!process.env.COOKIE_SECURE,
});

export function getUser() {
  try {
    const user = sessionStorage.getItem('user') || localStorage.getItem('user');
    return JSON.parse(user);
  } catch {
    return null;
  }
}

export function getUserCookie() {
  const user = cookies.get('user');
  if (!user) return null;
  return user;
}

export function setUser(user, session = false) {
  cookies.set('user', user);
  return session
    ? sessionStorage.setItem('user', JSON.stringify(user))
    : localStorage.setItem('user', JSON.stringify(user));
}

export function clearUser() {
  cookies.remove('user');
  localStorage.clear();
  sessionStorage.clear();
}

export function isImpersonating() {
  try {
    const user = sessionStorage.getItem('user');

    if (!user) {
      return false;
    }

    const parsedUser = JSON.parse(user);

    if (parsedUser && parsedUser.is_anonymous) {
      return false;
    }

    return user !== null;
  } catch {
    return false;
  }
}
