import React from 'react';
import { Navbar } from 'react-bootstrap';
import cn from 'classnames';
import T from 'prop-types';

import routes from '../../../../../routes/constants';
import text from '../../../../../text';

const MarketingNav = (props) => {
  const { className } = props;
  return (
    <Navbar.Collapse className={cn('MarketingNav', className)} >
        <ul className='navbar-nav navigation'>
          <li className='MarketingNav-Item'>
            <a href={process.env.LARKI_MARKETING_URL + '/products'}>
              <div className='MarketingNav-Link'>
                {text('products')}
              </div>
            </a>
          </li>
          <li className='MarketingNav-Item'>
            <a href={process.env.LARKI_MARKETING_URL + '/pricing'}>
              <div className='MarketingNav-Link'>{text('pricing')}</div>
            </a>
          </li>
          <li className='MarketingNav-Item'>
            <a href={process.env.LARKI_MARKETING_URL + '/about'}>
              <div className='MarketingNav-Link'>{text('aboutUs')}</div>
            </a>
          </li>
          <li className='MarketingNav-Item'>
            <a href={process.env.LARKI_MARKETING_URL + '/contact-us'}>
              <div className='MarketingNav-Link'>{text('contactUs')}</div>
            </a>
          </li>
          <li id='login-item' className='MarketingNav-Item'>
            <a href={routes.login}>
              <div className='MarketingNav-Link'>{text('login')}</div>
            </a>
          </li>
        </ul>
    </Navbar.Collapse>
  );
};

MarketingNav.propTypes = {
  className: T.string,
};

export default MarketingNav;
