const incrementLetterSequence = (str) => {
  const incrementChar = (char) => {
    if (char === 'z') return 'aa';
    if (char === 'Z') return 'a';

    const code = char.charCodeAt(0);
    return String.fromCharCode(code + 1);
  };

  // Convert string to an array of characters for easy manipulation
  let chars = str.split('');

  // Start from the end of the string
  for (let i = chars.length - 1; i >= 0; i--) {
    if (chars[i] === 'z') {
      chars[i] = 'a';
      if (i === 0) {
        chars.unshift('a');
      }
    } else if (chars[i] === 'Z') {
      chars[i] = 'A';
      if (i === 0) {
        chars.unshift('A');
      }
    } else {
      chars[i] = incrementChar(chars[i]);
      break;
    }
  }

  return chars.join('');
};

export const getNextSequenceItem = (array) => {

  const DEFAULT = 'Shape 1';
  if (array.length === 0) return DEFAULT;

  const lastItem = array[array.length - 1];
  
  if (!lastItem) {
    return DEFAULT;
  }
  let output = DEFAULT;
  let match = lastItem.trim().match(/^(\S+)\s?(\w*)$/);
  if (match) {
    let prefix = match[1];
 
    let sequencePart = match[2];

    if(sequencePart === ''){
      sequencePart = '0';
    }

    if (!isNaN(sequencePart)) {
      // If there is a numeric part, increment it
      let number = parseInt(sequencePart, 10);
      let nextNumber = number + 1;
      output = `${prefix} ${nextNumber}`;
    } else {
      output = `${prefix} ${incrementLetterSequence(sequencePart)}`;
    }

 
  } else {
    output = DEFAULT;
  }

  if(array.includes(output)){
    array.pop();
    return getNextSequenceItem(array);
  }
  
  return output;
};

