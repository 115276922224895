import classNames from 'classnames';
import { isEmpty, sumBy, upperCase } from 'lodash';
import T from 'prop-types';
import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import List from '../../../../components/list/List';
import { softwares } from '../../../../components/mapView/constants';
import Activity from '../../../../components/timeline/Activity';
import { DEFAULT_CURRENCY, GST } from '../../../../constants/price';
import {
  ADD_ONS_DATA_TYPE,
  BUY_NOW_DATA_TYPES,
  CAPTURE_SCAN_SERVICES_DATA_TYPE,
  PRODUCT_TYPE,
} from '../../../../constants/product';
import text, { formatCurrency } from '../../../../text';
import RfqCard from './Card';

const RequestForQuote = ({ title, job, rfqCharges }) => {
  const { quote } = job;
  const { currency, gst } = quote || {
    currency: DEFAULT_CURRENCY,
    gst: GST,
  };

  const chargesHasTbc = useMemo(
    () =>
      quote?.charges?.some(({ details }) =>
        [PRODUCT_TYPE.UNKNOWN].includes(details.delivery_method)
      ),
    [quote?.charges]
  );

  const siteDeploymentFee = useMemo(() => {
    const deploymentFee = quote?.fees.find(
      (fee) => fee.id === 'site_deployment_fee'
    );
    if (deploymentFee && deploymentFee.price.total === text('toBeConfirmed')) {
      return text('toBeConfirmed');
    }
    return deploymentFee?.price.total;
  }, [quote?.fees]);

  const totalDiscount = useMemo(
    () =>
      sumBy(rfqCharges, (charge) =>
        sumBy(charge.discounts, (discount) => discount.price.discount)
      ),
    [rfqCharges]
  );

  const totalPrice = useMemo(
    () => sumBy(rfqCharges, (charge) => charge.price.base),
    [rfqCharges]
  );

  const subTotal = useMemo(() => {
    const deploymentFee =
      siteDeploymentFee === text('toBeConfirmed') ? 0 : siteDeploymentFee;
    return totalPrice + deploymentFee - totalDiscount;
  }, [siteDeploymentFee, totalDiscount, totalPrice]);

  const totalGst = subTotal * (gst / 100);
  const grandTotal = subTotal + totalGst;
  const software = softwares.entities[job.customer_software]?.name || null;

  const rfqOrders = useMemo(() => {
    const filteredData = {
      dbData: rfqCharges.filter((charge) =>
        BUY_NOW_DATA_TYPES.includes(charge.details.category_name)
      ),
      tripodData: rfqCharges.filter((charge) =>
        CAPTURE_SCAN_SERVICES_DATA_TYPE.includes(charge.details.category_name)
      ),
      addOnsData: rfqCharges.filter((charge) =>
        ADD_ONS_DATA_TYPE.includes(charge.details.category_name)
      ),
    };

    return {
      lhs: [
        {
          header: text('dataInDatabase'),
          data: filteredData.dbData,
          isSiteDeploymentFeeShown: false,
        },
        {
          header: text('getQuote3dScanCaptureServices'),
          data: filteredData.tripodData,
          isSiteDeploymentFeeShown: true,
        },
      ].filter(({ data }) => data.length > 0),
      rhs: [
        {
          header: 'Add ons',
          data: filteredData.addOnsData,
          isSiteDeploymentFeeShown: false,
        },
      ].filter(({ data }) => data.length > 0),
    };
  }, [rfqCharges]);

  return (
    <div className='InvoiceActivity'>
      <div className='mb-2 font-weight--600 font-18'>
        <Activity.Card.Type type={title} />
      </div>
      <p className='mb-0 font-weight--400 font-16'>
        {text('buyItems', {
          title: upperCase(text('getQuote')),
        })}
      </p>
      <Row>
        {/* LHS, Data in the Database and 3D Scan Capture Service */}
        <Col lg={12} xl={6} className='quote-card mb-3 pr-xl-0'>
          {!isEmpty(rfqOrders) &&
            rfqOrders.lhs.map((order, index) => {
              if (isEmpty(order.data)) {
                return null;
              }

              return (
                <RfqCard
                  key={index}
                  index={index}
                  quote={quote}
                  order={order}
                  {...(order.isSiteDeploymentFeeShown && {
                    siteDeploymentFee,
                  })}
                />
              );
            })}
        </Col>
        {/* ADD ONS */}
        <Col lg={12} xl={6} className='quote-card mb-3'>
          {!isEmpty(rfqOrders) &&
            rfqOrders.rhs.map((order, index) => {
              if (isEmpty(order.data)) {
                return null;
              }

              return (
                <RfqCard
                  key={index}
                  index={rfqOrders.lhs.length}
                  quote={quote}
                  order={order}
                  {...(order.isSiteDeploymentFeeShown && {
                    siteDeploymentFee,
                  })}
                />
              );
            })}
        </Col>
      </Row>

      <hr className='totals-divider mb-0' />

      {/* TOTALS */}
      <Row>
        <Col lg={12} xl={6} className='pr-xl-0'>
          <List isPaddingless className='InvoiceTotal'>
            {totalDiscount ? (
              <List.Item
                description={text('discounts')}
                value={`-${formatCurrency(
                  totalDiscount,
                  currency.name,
                  currency.scale_factor,
                  {},
                  true
                )}`}
                key='invoice-discounts'
                className='discounts'
              />
            ) : (
              <div className='mb-2' />
            )}

            <tbody>
              <List.Item
                className={classNames({
                  isApproximate: !chargesHasTbc && !isNaN(subTotal),
                })}
                description={text('subtotal')}
                value={
                  chargesHasTbc
                    ? text('toBeConfirmed')
                    : isNaN(subTotal)
                    ? text('toBeConfirmed')
                    : formatCurrency(
                        subTotal,
                        currency.name,
                        currency.scale_factor,
                        {},
                        true
                      )
                }
                key='invoice-subtotal'
              />

              {/* GST */}
              <List.Item
                className={classNames({
                  isApproximate: !chargesHasTbc && !isNaN(totalGst),
                })}
                description={text('gst')}
                value={
                  chargesHasTbc
                    ? text('toBeConfirmed')
                    : isNaN(totalGst)
                    ? text('toBeConfirmed')
                    : formatCurrency(
                        totalGst,
                        currency.name,
                        currency.scale_factor,
                        {},
                        true
                      )
                }
                key='invoice-gst'
              />

              <List.Item
                className={classNames({
                  isApproximate: !chargesHasTbc && !isNaN(grandTotal),
                })}
                isHighlighted
                description={text('total')}
                key='invoice-total'
                value={
                  chargesHasTbc
                    ? text('toBeConfirmed')
                    : isNaN(grandTotal)
                    ? text('toBeConfirmed')
                    : formatCurrency(
                        grandTotal,
                        currency.name,
                        currency.scale_factor,
                        {},
                        true
                      )
                }
              />
            </tbody>
          </List>
        </Col>
      </Row>

      <p className='pt-1 mb-3 grey-3'>{text('rfqNote')}</p>

      <div className='mb-3 mt-2'>
        <span className='font-weight-bold'>{text('software')}: </span>
        <span>{software} </span>
      </div>

      <div className='terms-and-conditions'>
        {text('orderSubjectAgreement', {
          link: (
            <Link
              key='urlTerms'
              to={{ pathname: process.env.LARKI_URL_TERMS }}
              target='_blank'
            >
              Customer {text('termsAndConditions')}
            </Link>
          ),
        })}
      </div>
    </div>
  );
};

RequestForQuote.propTypes = {
  title: T.string,
  job: T.object,
  quote: T.object,
  rfqCharges: T.array,
};

export default RequestForQuote;
