import React from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { getUserMembership, isUserUnderTrial } from '../../utilities/user';
import Membership from './Membership';
import text from '../../text';
import FreeUserMembership from './FreeUserMembership';
import Loading from '../../components/loading/Loading';

const MembershipTab = () => {
  const { currentUser } = useSelector((state) => ({
    currentUser: state.profileReducer.userProfile,
  }));
  const render = (user) => {
    if (getUserMembership(user) && !isUserUnderTrial(user)) {
      return <Membership />;
    } else if (
      (!getUserMembership(user) && !isEmpty(user)) ||
      isUserUnderTrial(user)
    ) {
      return <FreeUserMembership />;
    } else {
      return <Loading />;
    }
  };
  return (
    <>
      <h3 className='mb-3 green-1'>{text('membership')}</h3>
      {render(currentUser)}
    </>
  );
};

export default MembershipTab;
