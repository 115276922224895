import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../components/button';
import WhiteContainer from '../../components/containers/WhiteContainer';
import Icon from '../../components/icon/Icon';
import Page from '../../components/page/Page';
import routes from '../../routes/constants';
import text from '../../text';

const DEFAULT_STATE = {
  title: text('requestForQuoteSuccessTitle'),
  description: text('requestForQuoteSuccessMessage'),
};
const StatusPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { title, description } = location.state || DEFAULT_STATE;

  return (
    <Page className='StatusPage PageCenter'>
      <WhiteContainer className='p-5'>
        <div className='d-flex flex-column align-items-center'>
          <div className='icon-container success d-flex flex-row rounded-circle justify-content-center align-items-center'>
            <Icon icon='tick' />
          </div>
          <p className='title text-center'>{title}</p>
          <p className='description text-center'>{description}</p>

          <div className='d-flex justify-content-center'>
            <Button onClick={() => history.push(routes.dashboard)}>
              {text('goToDashboard')}
            </Button>
          </div>
        </div>
      </WhiteContainer>
    </Page>
  );
};

export default StatusPage;
