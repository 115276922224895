import classNames from 'classnames';
import React, { useContext, useMemo } from 'react';
import {
  Accordion,
  AccordionContext,
  Button as BootstrapButton,
  Card,
  Dropdown,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { showModal, toggleLayersPanelVisibility } from '../../../redux/actions';
import text from '../../../text';
import CollapseButton from '../../button/CollapseButton';
import Icon from '../../icon/Icon';
import { DELETE_SHAPES_MODAL } from '../../modal/DeleteShapesModal';
import { MapViewContext } from '../mapViewContext';
import DownloadsMenu from './DownloadsMenu';
import Layer from './Layer';

/* eslint-disable react/prop-types */
const GuideToggle = ({ children, eventKey }) => {
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <>
      <div>{children}</div>
      <BootstrapButton variant='light' className='toggle-button'>
        <Icon icon={isCurrentEventKey ? 'caret-down' : 'caret-up'} />
      </BootstrapButton>
    </>
  );
};

const LayersPanel = ({ onMenuClick }) => {
  const { actions, state } = useContext(MapViewContext);
  const dispatch = useDispatch();

  const toggleAllSelections = (visible) => {
    actions.setSelections(
      state.selections?.map((selection) => ({
        ...selection,
        visible,
        focused: false,
      })) || []
    );
    actions.setActiveSelectionId(null);
    actions.setGeometriesToLoad({});
  };

  const isAllSelectionsVisible = state.selections
    ? state.selections.every((selection) => selection.visible)
    : false;

  const isAllSelectionsHidden = state.selections
    ? state.selections.every((selection) => !selection.visible)
    : false;

  const eyeOffIcon = useMemo(
    () => (isAllSelectionsHidden ? 'eye-slash' : 'eye-mixed-state'),
    [isAllSelectionsHidden]
  );

  const { layout } = useSelector((reduxState) => ({
    layout: reduxState.layout,
  }));

  return (
    <div
      className={classNames('layers-panel-container', {
        show: layout.layersPanel.isVisible,
      })}
    >
      <CollapseButton
        id='layersPanelCollapseButton'
        openDirection='right'
        isOpen={layout.layersPanel.isVisible}
        closeText={text('layers')}
        onClick={() =>
          dispatch(toggleLayersPanelVisibility(!layout.layersPanel.isVisible))
        }
      />
      <div className='layers-panel'>
        <div className='d-flex flex-column'>
          <div className='panel-header'>
            <div className='panel-heading'>Layers</div>
            <div className='panel-actions'>
              <BootstrapButton
                className='panel-action'
                variant='light'
                // If all selections are visible, hide them all
                // otherwise; show them
                onClick={() => toggleAllSelections(!isAllSelectionsVisible)}
              >
                <Icon
                  className={classNames({
                    ['original']: !isAllSelectionsVisible,
                  })}
                  icon={isAllSelectionsVisible ? 'eye' : eyeOffIcon}
                />
              </BootstrapButton>
              <Dropdown>
                <Dropdown.Toggle variant='light' className='panel-action'>
                  <Icon icon='download' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <DownloadsMenu selections={state.selections} />
                </Dropdown.Menu>
              </Dropdown>
              <BootstrapButton
                className='panel-action'
                variant='light'
                onClick={() =>
                  dispatch(
                    showModal(DELETE_SHAPES_MODAL, {
                      selectionsToDelete: state.selections,
                    })
                  )
                }
              >
                <Icon icon='trash' />
              </BootstrapButton>
            </div>
          </div>
        </div>

        <div className='d-flex flex-fill overflow-auto'>
          <ul className='layers'>
            {state.selections?.length > 0 &&
              state.selections.map((selection, i) => (
                <Layer
                  onMenuClick={onMenuClick}
                  key={selection.id}
                  data={selection}
                  index={i}
                />
              ))}
          </ul>
        </div>
        <Accordion defaultActiveKey='0'>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey='0'>
              <GuideToggle eventKey='0'>
                <h6>{text('guide')}</h6>
              </GuideToggle>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey='0'>
              <Card.Body>
                {text('guideInstructions', {
                  emphasizeText1: (
                    <span className='font-weight-bold'>Shapes</span>
                  ),
                  emphasizeText2: (
                    <span className='font-weight-bold'>Draft</span>
                  ),
                  emphasizeText3: (
                    <span className='font-weight-bold'>type</span>
                  ),
                  emphasizeText4: (
                    <span className='font-weight-bold'>Cart</span>
                  ),
                  emphasizeText5: (
                    <span className='font-weight-bold'>Check-out</span>
                  ),
                })}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </div>
  );
};

export default LayersPanel;
