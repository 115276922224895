import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import MembershipButton from '../../components/button/MembershipButton';
import ProjectListing from '../../components/orderListing/projectListing';
import ProjectListingControl from '../../components/orderListing/projectListingControl';
import Page from '../../components/page/Page';
import routes from '../../routes/constants';
import text from '../../text';
import Onboarding from './components/onboarding';

export const DASHBOARD_PAGE_ID = 'dashboard';

export default function Dashboard() {
  const history = useHistory();

  return (
    <Page
      title={text('dashboard')}
      id={DASHBOARD_PAGE_ID}
      className='d-flex flex-column'
    >
      <div id='PageTopwWrapper'>
        <Page.Top>
          <h2>{text('dashboard')}</h2>
          <Page.Top.Control>
            <MembershipButton variant='outline-primary' />
            <Button
              variant='primary'
              onClick={() => history.push(routes.order.root)}
            >
              {text('order3DData')}
            </Button>
          </Page.Top.Control>
        </Page.Top>
        <ProjectListingControl />
      </div>

      <ProjectListing />
      <Onboarding />
    </Page>
  );
}
