import React from 'react';
import classnames from 'classnames';
import MembershipCardInfo from './MembershipCardInfo';
import { isMembershipPriceId } from '../../../utilities/membership';

const MembershipCard = (props) => {
  const { priceId, info } = props;
  return (
    <div
      className={classnames('MembershipCard', {
        noMembership: !isMembershipPriceId(priceId),
      })}
    >
      {info?.map((infoData, i) => {
        return <MembershipCardInfo key={i} {...infoData} />;
      })}
    </div>
  );
};

export default MembershipCard;
