/* eslint-disable react/jsx-no-leaked-render */
import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import T from 'prop-types';
import React, { createContext, forwardRef, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { AREA_TYPE } from '../../../constants/product';
import { RIGHT_MENU_WIDTH_PX } from '../../RightMenu/RightMenu';
import ShapeMenu from '../../shapeMenu/ShapeMenu';

export const CTX_TRIGGERS = {
  shapeRightClicked: 'onShapeRightClicked',
  lhsDropdown: 'lhsDropdown',
};

const ContextMenuCtx = createContext(null);

export const useContextMenuCtx = () => {
  const ctx = useContext(ContextMenuCtx);
  if (ctx === null) {
    throw new Error(
      'ContextMenu must be wrapped in <ContextMenuCtxProvider />'
    );
  }
  return ctx;
};

export const ContextMenuCtxProvider = ({ children }) => {
  const isRightMenuVisible = useSelector(
    (reduxState) => reduxState.layout.rightMenu.isVisible
  );
  const [isOpen, setIsOpen] = useState(false);
  const [floatingOverlayStyle, setFloatingOverlayStyle] = useState(null);
  const [customOffset, setCustomOffset] = useState(null);
  const floatingReturn = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      flip(
        {
          crossAxis: false,
          // all of detectOverflow's options can be passed
          //  so we'll add right-padding for the cart
          padding: { right: isRightMenuVisible ? RIGHT_MENU_WIDTH_PX : 0 },
        },
        // stateful values inside functions aren't fresh or reactive: https://floating-ui.com/docs/useFloating#option-reactivity
        //  we need to specify dependencies to make it so
        [isRightMenuVisible]
      ),
      shift(),
      customOffset && offset(customOffset),
    ],
    placement: 'right-start', // main-axis = x
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
  });

  return (
    <ContextMenuCtx.Provider
      value={{
        ...floatingReturn,
        isOpen,
        setIsOpen,
        floatingOverlayStyle,
        setFloatingOverlayStyle,
        setCustomOffset,
      }}
    >
      {children}
    </ContextMenuCtx.Provider>
  );
};

ContextMenuCtxProvider.propTypes = {
  children: T.node,
};

const ShapeMenuWrapper = forwardRef(({ children, className }, ref) => (
  <div ref={ref} className={className}>
    {children}
  </div>
));

ShapeMenuWrapper.displayName = 'ShapeMenuWrapper';

ShapeMenuWrapper.propTypes = {
  children: T.node.isRequired,
  className: T.string,
};

export const ContextMenu = forwardRef(({ data }, rootRef) => {
  const {
    isOpen,
    setIsOpen,
    floatingStyles,
    refs,
    context,
    floatingOverlayStyle,
  } = useContextMenuCtx();
  const role = useRole(context, { role: 'menu' });
  const dismiss = useDismiss(context);
  const { getFloatingProps } = useInteractions([role, dismiss]);
  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  return (
    <FloatingPortal root={rootRef}>
      {isOpen && (
        <FloatingOverlay
          lockScroll
          {...(floatingOverlayStyle && { style: floatingOverlayStyle })}
        >
          <FloatingFocusManager context={context} initialFocus={refs.floating}>
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
              className='context-menu-floating'
            >
              {data && (
                <ShapeMenu
                  data={data}
                  DropdownMenuComponent={ShapeMenuWrapper}
                  className='context-menu'
                  areaType={AREA_TYPE.SHAPE_AREA}
                  closeMenu={handleCloseMenu}
                  showShapeName
                  closeDropdownOnSelect={false}
                />
              )}
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      )}
    </FloatingPortal>
  );
});

ContextMenu.displayName = 'ContextMenu';

ContextMenu.propTypes = {
  data: T.object,
};
