import { useEffect, useRef } from 'react';

const useEventListener = (eventName, handler, element, options) => {
  const savedHandler = useRef(handler);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const targetElement = element?.current ?? window;
    if (!(targetElement && targetElement.addEventListener)) {
      return;
    }
    const listener = (event) => {
      savedHandler.current(event);
    };
    targetElement.addEventListener(eventName, listener, options);
    return () => {
      targetElement.removeEventListener(eventName, listener, options);
    };
  }, [element, options]);
};

export default useEventListener;
