import { useContext, useEffect } from 'react';
import { MapViewContext } from './mapViewContext';

// Loads the job data from the store and sets the state in MapViewContext
const useLoadJob = (onLoad = () => {}) => {
  const { state, actions } = useContext(MapViewContext);

  useEffect(() => {
    if (!state.hasLoadedJob && state.job) {
      actions.loadJob();
    }
  }, [state.job]);

  useEffect(() => {
    if (
      !state.hasMapFitToJobBounds &&
      state.job &&
      state.hasLoadedJob &&
      state.googleMapsScriptStatus === 'ready' &&
      state.map &&
      state.mapHasProjection
    ) {
      (async () => {
        const result = await actions.fitMapBoundsToJob();
        if (result) {
          onLoad(result);
        }
        actions.setHasMapFitToJobBounds(true);
      })();
    }
  }, [
    state.job,
    state.hasLoadedJob,
    state.hasMapFitToJobBounds,
    state.googleMapsScriptStatus,
    state.map,
    state.mapHasProjection,
  ]);
};

export default useLoadJob;
