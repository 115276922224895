import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { uniq } from 'lodash';
import { hideModal } from '../../redux/actions';
import text from '../../text';
import { Button } from '../button';
import Icon from '../icon/Icon';

export const UPDATE_SHAPES_MODAL = 'UPDATE_SHAPES_MODAL';

/* eslint-disable react/prop-types */
const UpdateShapesModal = ({
  onOk,
  show,
  associatedAddOns = [],
  isNonTripodTarget = false,
  removeFromCart = false,
  toDraft = false,
}) => {
  const dispatch = useDispatch();

  const handleHide = () => dispatch(hideModal(UPDATE_SHAPES_MODAL));

  const handleOk = () => {
    onOk();
    handleHide();
  };

  const associatedAddOnDisplayNames = uniq([
    ...associatedAddOns.map(({ display_name }) => display_name),
  ]).join(', ');

  const dialogHeader = useMemo(() => {
    if (!removeFromCart && !toDraft) {
      return text('updateShapesWarning');
    }

    if (removeFromCart) {
      return text('removeFromCartWarning');
    }

    if (toDraft) {
      return text('toDraftWarning');
    }
  }, [removeFromCart, toDraft]);

  return (
    <Modal
      centered
      className='UpdateShapeModal'
      onHide={handleHide}
      show={show}
    >
      <Modal.Header className='centered' closeButton>
        <Icon icon='circled-exclamation' />
      </Modal.Header>
      <Modal.Body className='centered'>
        <h3 className='mb-4'>{dialogHeader}</h3>

        {associatedAddOnDisplayNames && (
          <p className='mb-4 font-16 font-weight-bold'>
            {text('updateShapesWarningAddOns', {
              addOns: associatedAddOnDisplayNames,
              caption:
                isNonTripodTarget || removeFromCart
                  ? 'will be deleted'
                  : 'will also be updated',
            })}
          </p>
        )}
        <p className='m-0'>{text('updateShapesMessage')}</p>
      </Modal.Body>
      <Modal.Footer className='flex-column justify-content-center'>
        <Button onClick={handleOk} autoFocus>
          {text(removeFromCart ? 'yesRemove' : 'yesChangeShapeType')}
        </Button>
        <Button onClick={handleHide} variant='link'>
          {text('noCancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateShapesModal;
