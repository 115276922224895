import classNames from 'classnames';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { useAsync, useSearchParam } from 'react-use';
import * as yup from 'yup';
import Page from '../../components/page/Page';
import { alertConstants } from '../../redux/constants';
import routes from '../../routes/constants';
import { larkiApiNoAuthentication } from '../../utilities/api';
import { setUser } from '../../utilities/storage';
import { validateConfirmPassword, validatePassword } from '../../validation';
import ResetPasswordForm from './form';
import ResetPasswordSuccess from './success';

const resetPasswordSchema = yup.object({
  password: validatePassword,
  confirmPassword: validateConfirmPassword,
});

const initialValues = {
  password: '',
  confirmPassword: '',
};

const ResetPassword = () => {
  const dispatch = useDispatch();
  const token = useSearchParam('token');
  const [isSuccessful, setSuccess] = useState(false);

  if (!token) {
    return <Redirect to={routes.home} />;
  }

  const { loading, error, value } = useAsync(async () => {
    const { data } = await larkiApiNoAuthentication.post('/user/verify-token', {
      token,
    });

    return data.token;
  }, [token]);

  if (error) {
    return <Redirect to={routes.password.forgot} />;
  }

  const onSubmit = async ({ password }) => {
    try {
      const { data } = await larkiApiNoAuthentication.post(
        '/user/change-password',
        {
          password,
        },
        {
          headers: {
            Authorization: `Bearer ${value}`,
          },
        }
      );

      sessionStorage.clear();
      setUser(data);

      setSuccess(true);
    } catch (error) {
      dispatch({
        type: alertConstants.ALERT_ERROR,
        payload: { message: error.response.data.message },
      });
    }
  };

  return (
    <Page
      className={classNames({
        PageCenter: loading,
      })}
    >
      {loading ? (
        <PulseLoader size={12} color='#31a283' />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={resetPasswordSchema}
          onSubmit={onSubmit}
        >
          {isSuccessful ? <ResetPasswordSuccess /> : <ResetPasswordForm />}
        </Formik>
      )}
    </Page>
  );
};

export default ResetPassword;
