import cn from 'classnames';
import { formatDistance, differenceInMilliseconds } from 'date-fns';
import T from 'prop-types';
import React, { useCallback } from 'react';
import { Badge } from 'react-bootstrap';
import text from '../../text';
import { statuses, statusLight } from './consts';

const calculateETA = (leftTime, rightTime) => {
  const eta = differenceInMilliseconds(leftTime, rightTime);
  if (eta > 0) {
    return formatDistance(leftTime, rightTime);
  } else {
    return text('numberOfDays', { daysNumber: 0 });
  }
};

const Status = ({ className, job }) => {
  const getStatus = useCallback(() => {
    if (!job.status) {
      return;
    }
    if (['pending', 'inprogress'].includes(job.status) && job.expected_at) {
      return (
        statuses[job.status].name +
        '\n' +
        text('expectedAt', {
          eta: calculateETA(new Date(job.expected_at), new Date()),
        })
      );
    }

    return statuses[job.status].name;
  }, [job]);

  return (
    <span className={cn('tblstatus', { [className]: className })}>
      {job.status === 'demo' ? (
        <Badge variant='info'>SAMPLE</Badge>
      ) : (
        <>
          <span className={`status-circle ${statusLight[job.status]}`} />
          <span>
            {getStatus()}
            <br />
            <span className='very-small'>
              <span>
                {job.completed_date
                  ? new Date(job.completed_date).getDate() +
                    '/' +
                    (new Date(job.completed_date).getMonth() + 1) +
                    '/' +
                    new Date(job.completed_date).getFullYear()
                  : ''}
              </span>
            </span>
          </span>
        </>
      )}
    </span>
  );
};

Status.propTypes = {
  job: T.shape(),
  className: T.string,
};

export default Status;
