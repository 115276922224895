import { createBreakpoint } from 'react-use';
import { BREAKPOINTS } from '../pages/constants';

const useBreakpoint = (breakpoints) => {
  const getBreakpoint = createBreakpoint(BREAKPOINTS);
  const currentBreakpoint = getBreakpoint();
  return breakpoints.includes(currentBreakpoint);
};

export default useBreakpoint;
