import React from 'react';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import WhiteContainer from '../../../../components/containers/WhiteContainer';
import List from '../../../../components/list/List';
import text, { formatCurrency } from '../../../../text';
import { isMembershipPriceId } from '../../../../utilities/membership';
import { userHasActiveMembership } from '../../../../utilities/user';
import ChargeItem from '../../../billing/Invoice/ChargeItem';
import T from 'prop-types';

const RfqCard = ({ index, order, quote, siteDeploymentFee = null }) => {
  const { currency } = quote;
  const { currentUser, membershipPriceId } = useSelector((state) => ({
    currentUser: state.profileReducer.userProfile,
    membershipPriceId: state.order.membershipPriceId,
  }));

  return (
    <Col className='px-0 mt-2 quote-card' key={index}>
      <WhiteContainer className='p-3 charge-item'>
        <p className='title mb-2'>
          {index + 1}. {order.header}
        </p>
        <div className='px-3'>
          <List isPaddingless className='is-borderless'>
            {order.data.map(
              (
                { discounts, details, price, fees, member_discounts_preview },
                index
              ) => {
                return (
                  <ChargeItem
                    key={index}
                    displayName={text(`${details.category_name}3D`)}
                    discounts={
                      isMembershipPriceId(membershipPriceId)
                        ? member_discounts_preview
                        : discounts
                    }
                    details={details}
                    price={price}
                    fees={fees}
                    quote={quote}
                    isIndividualDiscountVisible={
                      !userHasActiveMembership(currentUser) &&
                      !isMembershipPriceId(membershipPriceId)
                    }
                    isApproximate={true}
                    areaIsAlignedMiddle={true}
                  />
                );
              }
            )}

            {siteDeploymentFee ? (
              <List.Item
                className='charge'
                description={text('siteDeploymentFee')}
                value={`~${
                  isNaN(siteDeploymentFee)
                    ? siteDeploymentFee
                    : formatCurrency(
                        siteDeploymentFee,
                        currency.name,
                        currency.scale_factor,
                        {},
                        true
                      )
                }`}
                midText={<span />}
                // add span to align tr > td
              />
            ) : null}
          </List>
        </div>
      </WhiteContainer>
    </Col>
  );
};

RfqCard.propTypes = {
  index: T.number,
  order: T.object,
  quote: T.object,
  siteDeploymentFee: T.oneOfType([T.string, T.number, null]),
};

export default RfqCard;
