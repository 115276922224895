import text from '../text';
import { USER_TYPE } from './user';

export const PRODUCT_TYPE = {
  AERIAL: 'aerial',
  STREETSCAPE: 'streetscape',
  DRONE: 'drone',
  EXTERIOR: 'exterior',
  INTERIOR: 'interior',
  UNKNOWN: 'unknown',
  BIM_INTERIOR: 'bim_interior',
  TWOD_PLAN_INTERIOR: 'twod_plan_interior',
  BIM_EXTERIOR: 'bim_exterior',
  TWOD_PLAN_EXTERIOR: 'twod_plan_exterior',
  ADDON_SCAN: 'addOnScan', // GET QUOTE BUTTONS
  ADDON_DATABASE: 'addOnDatabase', // GET QUOTE BUTTONS
};

export const ASSET_ICON_MAP = {
  Aerial: 'aerial',
  Drone: 'drone',
  Exterior: 'exterior',
  Interior: 'interior',
  Streetscape: 'streetscape',
  unknown: 'unknown',
  BIM_Interior: 'bim_interior',
  BIM_Exterior: 'bim_exterior',
  Twod_Plan_Interior: 'twod_plan_interior',
  Twod_Plan_Exterior: 'twod_plan_exterior',
};

export const STATUS_COLOR_MAP = {
  Completed: 'completed',
  'In progress': 'in-progress',
  Commenced: 'in-progress',
  Pending: 'pending',
  Quoted: 'pending',
  Paid: 'paid',
  Cancelled: 'cancelled',
  Draft: 'draft',
};

export const STATUS_TEXT_MAP = {
  Completed: 'Completed',
  'In progress': 'Commenced',
  Commenced: 'Commenced',
  Pending: 'Quoted',
  Quoted: 'Quoted',
  Paid: 'Paid',
  Cancelled: 'Cancelled',
  Draft: 'Draft',
};

export const PRODUCT_CAPTURED_MAP = {
  aerial: 'plane',
  streetscape: 'car',
};

export const ADD_ONS_PROP_NAME = 'addOns';

export const PRODUCT_DELIVERY_METHOD = {
  database: 'Database',
  capture: 'Capture',
  draft: 'Draft',
  [ADD_ONS_PROP_NAME]: 'Add-ons',
};

export const PRODUCT_PROS_CONS = {
  [PRODUCT_TYPE.AERIAL]: {
    pros: [
      text('lowCost'),
      text('quickTurnaroundTime'),
      text('greatLargeAreas'),
    ],
    cons: [text('lowerDetail'), text('lowerAccuracy'), text('older')],
  },
  [PRODUCT_TYPE.STREETSCAPE]: {
    pros: [
      text('lowCost'),
      text('quickTurnaroundTime'),
      text('greatFrontFacadesStreetscapes'),
    ],
    cons: [text('mediumDetail'), text('mediumAccuracy'), text('older')],
  },
  [PRODUCT_TYPE.DRONE]: {
    pros: [
      text('highDetail'),
      text('highAccuracy'),
      text('greatRoofsLargeGrounds'),
    ],
    cons: [text('higherCost'), text('slowerTurnaroundTime')],
  },
  [PRODUCT_TYPE.EXTERIOR]: {
    pros: [
      text('highestDetail'),
      text('highestAccuracy'),
      text('forPreciseExteriors'),
    ],
    cons: [text('highestCost'), text('slowestTurnaroundTime')],
  },
  [PRODUCT_TYPE.INTERIOR]: {
    pros: [
      text('highestDetail'),
      text('highestAccuracy'),
      text('forPreciseInteriors'),
    ],
    cons: [text('highestCost'), text('slowestTurnaroundTime')],
  },
  // TODO: should be another const DELIVERY_METHOD_PROS_CONS
  [PRODUCT_TYPE.ADDON_SCAN]: {
    pros: [
      text('highestDetail'),
      text('highestAccuracy'),
      text('forPrecision'),
    ],
    cons: [text('higherCostThanDatabase'), text('slowerTurnaroundTime')],
  },
  [PRODUCT_TYPE.ADDON_DATABASE]: {
    pros: [
      text('lowCost'),
      text('quickTurnaroundTime'),
      text('forConceptsAndContext'),
    ],
    cons: [text('mediumDetail'), text('mediumAccuracy'), text('older')],
  },
  [PRODUCT_TYPE.BIM_EXTERIOR]: {
    pros: [text('architectural3DModel'), text('bimFile'), text('forAEC')],
    cons: [text('basicLOD2'), text('excFrames'), text('takesTime')],
  },
  [PRODUCT_TYPE.BIM_INTERIOR]: {
    pros: [text('architectural3DModel'), text('bimFile'), text('forAEC')],
    cons: [text('basicLOD2'), text('excFrames'), text('takesTime')],
  },
  [PRODUCT_TYPE.TWOD_PLAN_EXTERIOR]: {
    pros: [text('lines2dText'), text('pdfAndDwg'), text('forApprovals')],
    cons: [text('basicBestFit'), text('singleCut'), text('takesTime')],
  },
  [PRODUCT_TYPE.TWOD_PLAN_INTERIOR]: {
    pros: [text('lines2dText'), text('pdfAndDwg'), text('forApprovals')],
    cons: [text('basicBestFit'), text('singleCut'), text('takesTime')],
  },
};

export const PRODUCT_EXPLANATION = {
  [PRODUCT_TYPE.AERIAL]: text('aerialPointCloudExplanation'),
  [PRODUCT_TYPE.STREETSCAPE]: text('streetscapePointCloudExplanation'),
  [PRODUCT_TYPE.INTERIOR]: text('interiorPointCloudExplanation'),
  [PRODUCT_TYPE.EXTERIOR]: text('exteriorPointCloudExplanation'),
  [PRODUCT_TYPE.DRONE]: text('dronePointCloudExplanation'),
  [PRODUCT_TYPE.UNKNOWN]: text('unknownPointCloudExplanation'),
  [PRODUCT_TYPE.ADDON_SCAN]: text('addOnScanExplanation'),
  [PRODUCT_TYPE.ADDON_DATABASE]: text('addOnDatabaseExplanation'),
  [PRODUCT_TYPE.BIM_EXTERIOR]: text('exteriorBimTooltip'),
  [PRODUCT_TYPE.BIM_INTERIOR]: text('interiorBimTooltip'),
  [PRODUCT_TYPE.TWOD_PLAN_EXTERIOR]: text('exterior2dPlanTooltip'),
  [PRODUCT_TYPE.TWOD_PLAN_INTERIOR]: text('interior2dPlanTooltip'),
};

export const SALES_HELP_DISCOUNT = {
  [PRODUCT_TYPE.AERIAL]: {
    [USER_TYPE.FREE]: 10,
    [USER_TYPE.ESSENTIALS]: 10,
  },
  [PRODUCT_TYPE.STREETSCAPE]: {
    [USER_TYPE.FREE]: 10,
    [USER_TYPE.ESSENTIALS]: 10,
  },
  [PRODUCT_TYPE.INTERIOR]: {
    [USER_TYPE.FREE]: 0,
    [USER_TYPE.ESSENTIALS]: 0,
  },
  [PRODUCT_TYPE.EXTERIOR]: {
    [USER_TYPE.FREE]: 0,
    [USER_TYPE.ESSENTIALS]: 0,
  },
  [PRODUCT_TYPE.DRONE]: {
    [USER_TYPE.FREE]: 0,
    [USER_TYPE.ESSENTIALS]: 0,
  },
  [PRODUCT_TYPE.BIM_INTERIOR]: {
    [USER_TYPE.FREE]: 0,
    [USER_TYPE.ESSENTIALS]: 0,
  },
  [PRODUCT_TYPE.BIM_EXTERIOR]: {
    [USER_TYPE.FREE]: 0,
    [USER_TYPE.ESSENTIALS]: 0,
  },
  [PRODUCT_TYPE.TWOD_PLAN_INTERIOR]: {
    [USER_TYPE.FREE]: 0,
    [USER_TYPE.ESSENTIALS]: 0,
  },
  [PRODUCT_TYPE.TWOD_PLAN_EXTERIOR]: {
    [USER_TYPE.FREE]: 0,
    [USER_TYPE.ESSENTIALS]: 0,
  },
};

export const AREA_TYPE = {
  SHAPE_AREA: 'SHAPE_AREA',
  PRODUCT_AREA: 'PRODUCT_AREA',
};

export const AREA_TYPE_CONFIG = {
  [AREA_TYPE.PRODUCT_AREA]: {
    key: 'area_in_sqm',
    label: text('productArea', {}),
  },
  [AREA_TYPE.SHAPE_AREA]: {
    key: 'selection_area_in_sqm',
    label: text('shapeArea'),
  },
};

export const ADD_ONS = {
  [PRODUCT_TYPE.BIM_INTERIOR]: {
    PRODUCT_NAME: PRODUCT_TYPE.BIM_INTERIOR,
    DATA_TYPE: PRODUCT_TYPE.INTERIOR,
  },
  [PRODUCT_TYPE.TWOD_PLAN_INTERIOR]: {
    PRODUCT_NAME: PRODUCT_TYPE.TWOD_PLAN_INTERIOR,
    DATA_TYPE: PRODUCT_TYPE.INTERIOR,
  },
  [PRODUCT_TYPE.BIM_EXTERIOR]: {
    PRODUCT_NAME: PRODUCT_TYPE.BIM_EXTERIOR,
    DATA_TYPE: PRODUCT_TYPE.EXTERIOR,
  },
  [PRODUCT_TYPE.TWOD_PLAN_EXTERIOR]: {
    PRODUCT_NAME: PRODUCT_TYPE.TWOD_PLAN_EXTERIOR,
    DATA_TYPE: PRODUCT_TYPE.EXTERIOR,
  },
};

export const NON_TRIPOD_DATA_TYPE = [
  PRODUCT_TYPE.UNKNOWN,
  PRODUCT_TYPE.DRONE,
  PRODUCT_TYPE.AERIAL,
  PRODUCT_TYPE.STREETSCAPE,
];

export const SMART_SELECTION_DELIVERY_METHOD = {
  AUTOMATIC: 'automatic',
  MANUAL: 'manual',
};

export const BUY_NOW_DATA_TYPES = [
  PRODUCT_TYPE.AERIAL,
  PRODUCT_TYPE.STREETSCAPE,
];

export const CAPTURE_SCAN_SERVICES_DATA_TYPE = [
  PRODUCT_TYPE.DRONE,
  PRODUCT_TYPE.INTERIOR,
  PRODUCT_TYPE.EXTERIOR,
];

export const ADD_ONS_DATA_TYPE = [
  PRODUCT_TYPE.BIM_INTERIOR,
  PRODUCT_TYPE.BIM_EXTERIOR,
  PRODUCT_TYPE.TWOD_PLAN_INTERIOR,
  PRODUCT_TYPE.TWOD_PLAN_EXTERIOR,
];

export const RFQ_DATA_TYPES = [
  ...CAPTURE_SCAN_SERVICES_DATA_TYPE,
  ...ADD_ONS_DATA_TYPE,
];

export const UNMERGED_OUTLINES_DATA_TYPES = [PRODUCT_TYPE.INTERIOR];
