import { matchPath } from 'react-router-dom';

export const isRouteIncluded = (routesToMatch) => (route) => {
  const matches = routesToMatch.map((path) => matchPath(route, { path }));
  return matches.some(Boolean);
};

export const isRouteMatching = (currentPath) => (path) => {
  const match = matchPath(currentPath, { path });
  return match;
};
