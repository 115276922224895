import React from 'react';
import PropTypes from 'prop-types';

const DrawingControlsTooltip = ({ text }) => {
  return <div className="draw-tooltip">{text}</div>;
};
DrawingControlsTooltip.propTypes = {
  text: PropTypes.string.isRequired,
};


export default DrawingControlsTooltip;
