import { get } from 'lodash';
import { alertConstants } from '../constants';

const defaultAlertsState = {
  alert: {
    message: '',
    variant: '',
    dismissible: true,
  },
  outdated: false,
};

const alerts = (state = defaultAlertsState, { type, payload }) => {
  switch (type) {
    case alertConstants.ALERT_SUCCESS:
      return {
        ...state,
        alert: {
          message: payload.message,
          icon: payload.icon,
          dismissible: get(
            payload,
            'dismissible',
            defaultAlertsState.alert.dismissible
          ),
          variant: 'success',
        },
      };
    case alertConstants.ALERT_ERROR:
      return {
        ...state,
        alert: {
          message: payload.message,
          dismissible: get(
            payload,
            'dismissible',
            defaultAlertsState.alert.dismissible
          ),
          variant: 'danger',
        },
      };
    case alertConstants.ALERT_WARNING:
      return {
        ...state,
        alert: {
          message: payload.message,
          dismissible: get(
            payload,
            'dismissible',
            defaultAlertsState.alert.dismissible
          ),
          variant: 'warning',
        },
      };
    case alertConstants.ALERT_INFO:
      return {
        ...state,
        alert: {
          message: payload.message,
          variant: 'info',
          icon: payload.icon,
          dismissible:
            payload.dismissible || defaultAlertsState.alert.dismissible,
        },
      };
    case alertConstants.ALERT_RESET:
      return {
        ...defaultAlertsState,
        outdated: state.outdated,
      };
    case alertConstants.ALERT_VERSION_OUTDATED:
      return {
        ...state,
        outdated: true,
      };
    default:
      return state;
  }
};

export default alerts;
