import React from 'react';
import { FormCheck } from 'react-bootstrap';

const Radio = (props) => {
  const { label, ...rest } = props;
  return (
    <FormCheck bsPrefix='Radio'>
      <FormCheck.Input type='radio' {...rest} />
      {label && <FormCheck.Label>{label}</FormCheck.Label>}
    </FormCheck>
  );
};

export default Radio;
