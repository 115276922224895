import React from 'react';
import { useSelector } from 'react-redux';
import CancelMembershipModal, {
  CANCEL_MEMBERSHIP_MODAL,
} from './CancelMembershipModal';
import ConfirmModal, { CONFIRM_MODAL } from './ConfirmModal';
import CouponModal, { COUPON_MODAL } from './CouponModal';
import DeleteProjectModal, { DELETE_PROJECT_MODAL } from './DeleteProjectModal';
import DeleteShapesModal, { DELETE_SHAPES_MODAL } from './DeleteShapesModal';
import DemoProjectActionsModal, {
  DEMO_PROJECT_ACTION_MODAL,
} from './DemoProjectActionsModal';
import GiveUserTrialModal, {
  GIVE_USER_TRIAL_MODAL,
} from './GiveUserTrialModal';
import InitiateTourModal, { INITIATE_TOUR_MODAL } from './InitiateTourModal';
import JobFailureModal, { JOB_FAILURE_MODAL } from './JobFailureModal';
import ManualOrderModal, {
  MANUAL_ORDER_MODAL,
} from './ManualOrderModal/ManualOrderModal';
import MergeProjectModal, { MERGE_PROJECT_MODAL } from './MergeProjectModal';
import NoDataModal, { NO_DATA_MODAL } from './NoDataModal';
import OrderFailureModal, { ORDER_FAILURE_MODAL } from './OrderFailureModal';
import PaymentFailureModal, {
  PAYMENT_FAILURE_MODAL,
} from './PaymentFailureModal';
import ProjectTrialMembersModal, {
  PROJECT_TRIAL_MEMBERS_MODAL,
} from './ProjectTrialMembers/ProjectTrialMembers';
import ProjectTrialMembersSuccessModal, {
  PROJECT_TRIAL_MEMBERS_SUCCESS_MODAL,
} from './ProjectTrialMembers/ProjectTrialMembersSuccess';
import QuotingFailureModal, {
  QUOTING_FAILURE_MODAL,
} from './QuotingFailureModal';
import StartTrialModal, {
  START_TRIAL_MODAL,
} from './StartTrialModal/StartTrialModal';
import TourDoneModal, { TOUR_DONE_MODAL } from './TourDoneModal';
import UpdateShapesModal, { UPDATE_SHAPES_MODAL } from './UpdateShapesModal';
import AddNoteModal, { ADD_NOTE_MODAL } from './addNoteModal/AddNoteModal';
import ForceJobUpdateModal, {
  FORCE_JOB_UPDATE_MODAL,
} from './forceJobUpdateModal';
import InviteProjectUsersModal, {
  INVITE_PROJECT_USERS_MODAL,
} from './inviteProjectUsersModal/InviteProjectUsersModal';
import PointerraIDModal, {
  POINTERRA_ID_MODAL,
} from './pointerraIDModal/PointerraIDModal';
import UpdateProductModal, {
  UPDATE_PRODUCT_MODAL,
} from './updateProductModal/UpdateProductModal';
import UploadAssetModal, { UPLOAD_ASSET_MODAL } from './uploadAssetModal';

const ModalManager = () => {
  const { id, modalProps } = useSelector((state) => state.modal);
  switch (id) {
    case NO_DATA_MODAL:
      return <NoDataModal {...modalProps} />;
    case ORDER_FAILURE_MODAL:
      return <OrderFailureModal {...modalProps} />;
    case JOB_FAILURE_MODAL:
      return <JobFailureModal {...modalProps} />;
    case QUOTING_FAILURE_MODAL:
      return <QuotingFailureModal {...modalProps} />;
    case INVITE_PROJECT_USERS_MODAL:
      return <InviteProjectUsersModal {...modalProps} />;
    case ADD_NOTE_MODAL:
      return <AddNoteModal {...modalProps} />;
    case POINTERRA_ID_MODAL:
      return <PointerraIDModal {...modalProps} />;
    case UPLOAD_ASSET_MODAL:
      return <UploadAssetModal {...modalProps} />;
    case UPDATE_PRODUCT_MODAL:
      return <UpdateProductModal {...modalProps} />;
    case FORCE_JOB_UPDATE_MODAL:
      return <ForceJobUpdateModal {...modalProps} />;
    case CONFIRM_MODAL:
      return <ConfirmModal {...modalProps} />;
    case PAYMENT_FAILURE_MODAL:
      return <PaymentFailureModal {...modalProps} />;
    case MANUAL_ORDER_MODAL:
      return <ManualOrderModal {...modalProps} />;
    case CANCEL_MEMBERSHIP_MODAL:
      return <CancelMembershipModal {...modalProps} />;
    case DELETE_PROJECT_MODAL: {
      return <DeleteProjectModal {...modalProps} />;
    }
    case MERGE_PROJECT_MODAL: {
      return <MergeProjectModal {...modalProps} />;
    }
    case START_TRIAL_MODAL: {
      return <StartTrialModal {...modalProps} />;
    }
    case PROJECT_TRIAL_MEMBERS_MODAL: {
      return <ProjectTrialMembersModal {...modalProps} />;
    }
    case PROJECT_TRIAL_MEMBERS_SUCCESS_MODAL: {
      return <ProjectTrialMembersSuccessModal {...modalProps} />;
    }
    case COUPON_MODAL: {
      return <CouponModal {...modalProps} />;
    }
    case DEMO_PROJECT_ACTION_MODAL: {
      return <DemoProjectActionsModal {...modalProps} />;
    }
    case DELETE_SHAPES_MODAL: {
      return <DeleteShapesModal {...modalProps} />;
    }
    case INITIATE_TOUR_MODAL: {
      return <InitiateTourModal {...modalProps} />;
    }
    case TOUR_DONE_MODAL: {
      return <TourDoneModal {...modalProps} />;
    }
    case UPDATE_SHAPES_MODAL: {
      return <UpdateShapesModal {...modalProps} />;
    }
    case GIVE_USER_TRIAL_MODAL: {
      return <GiveUserTrialModal {...modalProps} />;
    }
    default:
      return null;
  }
};

export default ModalManager;
