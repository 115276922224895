import { useState } from 'react';
import { MAX_MAP_ZOOM, MIN_MAP_ZOOM } from '../components/mapView/constants';
import { getDistanceInPixels } from '../components/mapView/drawingManager/utilities';
import { computeTranslate, toPoint } from '../components/mapView/geometry';
import { getGMBoundsZoomLevel } from '../utilities/map';
import * as numberUtils from '../utilities/number';

const useZoom = (
  mapRef,
  initialZoom,
  minZoom = MIN_MAP_ZOOM,
  maxZoom = MAX_MAP_ZOOM
) => {
  // todo: this zoom should be in MapViewContext since there should only be one zoom for the entire map and all instances of useZoom should share it
  const [_zoom, _setZoom] = useState(initialZoom);

  const setZoom = (map, value, updateMap = true) => {
    const clamped = numberUtils.clamp(value, minZoom, maxZoom);
    _setZoom(clamped);
    if (updateMap) {
      map.setZoom(clamped);
    }
  };

  const increaseZoom = (map, increaseBy) => {
    const newZoom = _zoom + increaseBy;
    setZoom(map, newZoom);
  };

  const decreaseZoom = (map, decreaseBy) => increaseZoom(map, -decreaseBy);

  const fitBounds = (map, bounds, zoom, padding = 0) => {
    const mapDim = {
      width: mapRef.current.offsetWidth,
      height: mapRef.current.offsetHeight,
    };

    // hacky way of determining the pixels per meter on the map
    // todo: this isn't exact, should be fixed
    const gmCenter = map.getCenter();
    const centerOffset = computeTranslate(
      toPoint({ lng: gmCenter.lng(), lat: gmCenter.lat() }),
      padding
    );
    const gmCenterOffset = new google.maps.LatLng(
      centerOffset.coordinates[1],
      centerOffset.coordinates[0]
    );
    const paddingInPixels = getDistanceInPixels(
      gmCenter,
      gmCenterOffset,
      map,
      zoom
    );
    map.fitBounds(bounds, paddingInPixels);
    const newZoom = getGMBoundsZoomLevel(bounds, mapDim, padding, MAX_MAP_ZOOM);
    setZoom(map, newZoom, false);

    // timeout here so that it isn't overridden by fitBounds
    // todo: find another way
    setTimeout(() => {
      map.setZoom(newZoom);
    }, 50);
  };

  return {
    state: { zoom: _zoom },
    actions: { setZoom, increaseZoom, decreaseZoom, fitBounds },
  };
};

export default useZoom;
