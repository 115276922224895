import T from 'prop-types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';

import { AxiosError } from 'axios';
import { alertError } from '../../redux/actions';
import { client } from '../../utilities/api';
import { captureError } from '../../utilities/error';

const updateRequestAccess = (projectId, userId, status) => async () => {
  try {
    const resp = await client.updateRequestAccess(projectId, userId, status);
    return resp.data;
  } catch (err) {
    if (err instanceof AxiosError && err.response.status === 404) {
      return null;
    }
    throw err;
  }
};

const UpdateBookmarkPage = ({ status }) => {
  const dispatch = useDispatch();
  const { projectId, userId: _userId } = useParams();
  const userId = _userId ? parseInt(_userId, 10) : null;
  const [state, update] = useAsyncFn(
    updateRequestAccess(projectId, userId, status),
    [projectId, userId, status]
  );

  useEffect(() => {
    (async () => {
      await update();
    })();
  }, [update]);

  useEffect(() => {
    const err = state.error;
    if (err) {
      captureError(err);
      dispatch(alertError(err.message));
    }
  }, [dispatch, state.error]);

  return null;
};

UpdateBookmarkPage.propTypes = {
  status: T.oneOf(['accepted', 'rejected']),
};

export default UpdateBookmarkPage;
