import React from 'react';
import { useSelector } from 'react-redux';

import MembershipButton from '../../components/button/MembershipButton';
import SubscribeButton from '../../components/button/SubscribeButton';
import Container from '../../components/containers/Container';
import {
  ESSENTIALS_MEMBERSHIP_BENEFITS,
  FREE_BENEFITS
} from '../../constants/membership';
import routes from '../../routes/constants';
import text from '../../text';
import {
  userHasActiveMembership
} from '../../utilities/user';

const FreeUserMembership = () => {
  const currentUser = useSelector((state) => state.profileReducer.userProfile);

  return (
    <div className='FreeUserMembership'>
      <Container className='p-4 d-flex flex-column mr-4'>
        <div className='d-flex flex-column'>
          <h4 className='mb-3'>{`${text('free')}*`}</h4>
          <p>{text('freeBenefitsMessage')}</p>
          <ul className='mb-4'>
            {FREE_BENEFITS.map((item, i) => {
              return (
                <li key={i} className='mb-2'>
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
        <div className='d-flex flex-column justify-content-between flex-fill'>
          <div>
            <p className='mb-0'>
              {text('findOutMoreOnPage', {
                page: '',
              })}
            </p>
            <a
              rel='noreferrer'
              target='_blank'
              href={routes.larkiWebsiteUrl.memberships}
            >
              {text('membershipPage')}
            </a>
          </div>

          <a
            className='mt-7'
            target='_blank'
            rel='noreferrer'
            href={routes.larkiWebsiteUrl.custTermsCond}
          >
            {`${text('termsAndCondApply')}*`}
          </a>
        </div>
      </Container>

      <Container
        className='d-flex flex-column essentials-upgrade-specs'
        isSecondary
      >
        <>
          <h4 className='mb-3'>{`${text('upgradeEssentialsMembership')}*`}</h4>
          <div className='mb-3'>
            <p className='mb-0'>{text('upgradeEssentialsBenefits')}</p>
          </div>
          <div className='mb-3'>
            <ul>
              {[...ESSENTIALS_MEMBERSHIP_BENEFITS].map((item, i) => {
                return (
                  <li key={i} className='mb-2'>
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className='mb-4'>
            <p className='mb-0'>
              {text('findOutMoreOnPage', {
                page: '',
              })}
            </p>
            <a
              rel='noreferrer'
              target='_blank'
              href={routes.larkiWebsiteUrl.memberships}
            >
              {text('membershipPage')}
            </a>
          </div>
        </>
        <div className='d-flex flex-row' style={{ gap: 16 }}>
          {!userHasActiveMembership(currentUser) &&
            !currentUser.has_used_trial && <MembershipButton />}
          <SubscribeButton />
        </div>
      </Container>
    </div>
  );
};

export default FreeUserMembership;
