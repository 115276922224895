import cn from 'classnames';
import T from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useEvent } from 'react-use';

import { TOOLTIP } from '../../pages/constants';
import { KEY } from '../../utilities/events';
import { isWidthTruncated } from '../../utilities/events/validate';
import Control from '../form/Control';

const Cell = (props, ref) => {
  const {
    className,
    children,
    id,
    initialValidateEnabled = false,
    isFailed,
    isOutsideClickEnabled = true,
    isSuccessful,
    onSubmit,
    validationSchema,
    value,
    maxLength,
    colSpan,
    ...rest
  } = props;
  const [cellValue, setCellValue] = useState(value);
  const [isCellValueValid, setIsCellValueValid] = useState(true);
  const [isValidateEnabled, setIsValidateEnabled] = useState(
    initialValidateEnabled
  );
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isCellTruncated, setIsCellTruncated] = useState(false);
  const [invalidMsg, setInvalidMsg] = useState('');

  useEffect(() => setCellValue(value), [value]);

  const onChange = (e) => setCellValue(e.target.value);

  const handleKeyPress = (evt) => {
    switch (evt.key) {
      case KEY.ENTER:
        {
          if (isCellValueValid) {
            if (cellValue.trim() !== value) {
              onSubmit(cellValue);
            }
            evt.target.blur();
          }
        }
        break;
      case KEY.ESC:
        {
          setCellValue(value);
          evt.target.blur();
        }
        break;
    }
  };

  const handleMouseEnter = (evt) => {
    if (isWidthTruncated(evt)) {
      setIsCellTruncated(true);
    }
  };

  const handleToggle = (isVisible) =>
    isCellTruncated && setIsTooltipVisible(isVisible);

  if (isOutsideClickEnabled) {
    useEvent('click', () => {
      if (cellValue.trim() !== value && isCellValueValid) {
        setTimeout(() => onSubmit(cellValue), 300);
      } else {
        setCellValue(value);
      }
    });
  }

  useEffect(() => {
    if (isValidateEnabled) {
      try {
        const isValid = validationSchema.trim().validateSync(cellValue);
        setIsCellValueValid(isValid);
        setInvalidMsg('');
      } catch (error) {
        setIsCellValueValid(false);
        setInvalidMsg(error.message);
      }
    } else {
      setIsValidateEnabled(true);
    }
  }, [cellValue]);
  return (
    <td
      className={cn('Cell', className)}
      ref={ref}
      {...(colSpan && { colSpan })}
    >
      <div className='cellContainer'>
        <OverlayTrigger
          show={isTooltipVisible}
          onToggle={handleToggle}
          placement='top'
          delay={{ show: TOOLTIP.SHOW_DELAY, hide: TOOLTIP.HIDE_DELAY }}
          overlay={<Tooltip id={`cell-${id}`}>{value}</Tooltip>}
        >
          <Control
            maxLength={maxLength ? maxLength + 1 : undefined}
            onMouseEnter={handleMouseEnter}
            isFailed={isFailed || !isCellValueValid}
            isSuccessful={isSuccessful}
            onKeyDown={handleKeyPress}
            onChange={onChange}
            value={cellValue}
            validationError={invalidMsg}
            {...rest}
          />
        </OverlayTrigger>
        {children}
      </div>
    </td>
  );
};

Cell.propTypes = {
  className: T.string,
  children: T.node,
  id: T.number,
  initialValidateEnabled: T.bool,
  isFailed: T.bool,
  isOutsideClickEnabled: T.bool,
  isSuccessful: T.bool,
  maxLength: T.number,
  onSubmit: T.func,
  validationSchema: T.shape(),
  value: T.string,
  colSpan: T.number,
};

export default forwardRef(Cell);
