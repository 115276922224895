import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { hideModal } from '../../redux/actions';
import Button from '../button/Button';
import Lottie from '../lottie/Lottie';

export const COUPON_MODAL = 'COUPON_MODAL';

const CouponModal = ({ coupon, show, title, onAccept, onAcceptLabel }) => {
  const dispatch = useDispatch();
  const onHide = () => {
    dispatch(hideModal(COUPON_MODAL));
  };

  return (
    <Modal className='CouponModal' show={show} onHide={onHide} centered>
      <Modal.Header className='centered' closeButton>
        <Lottie lottie='free-gift' />
      </Modal.Header>
      <Modal.Body className='centered'>
        <div>
          <h3>{title}</h3>
          <div className='form-footer'>
            <Button onClick={onAccept}>{onAcceptLabel}</Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CouponModal;
