import React from 'react';
import T from 'prop-types';
import cn from 'classnames';
import Icon from '../../icon/Icon';
import { ALL_STATUS } from '../consts';

const StatusFilterIcon = ({ status }) => {
  const iconColor = status === ALL_STATUS ? 'default' : [...status];
  return (
    <Icon
      className={cn('status-filter-icon', {
        [iconColor]: iconColor,
      })}
      icon='filter'
    />
  );
};

StatusFilterIcon.propTypes = {
  status: T.array,
};

export default StatusFilterIcon;
