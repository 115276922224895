import cn from 'classnames';
import React from 'react';
import ReactSelect from 'react-select';

const Select = (props) => {
  const { className, isBorderless = false, ...restProps } = props;
  return (
    <ReactSelect
      classNamePrefix='Select'
      className={cn('Select', {
        [className]: className,
        'is-borderless': isBorderless,
      })}
      {...restProps}
    />
  );
};

export default Select;
