import {
  SET_DISCOUNT_CODE,
  SET_MEMBERSHIP_PRICE_ID,
  SET_PAYMENT_METHOD_ID,
  SET_SELECTED_PAY_MODE,
} from '../constants/order';
import { MEMBERSHIP } from '../../constants/membership';
import { PAY_MODE } from '../../constants/order';

const defaultState = {
  membershipPriceId: MEMBERSHIP.YEARLY.PRICE_ID,
  paymentMethodId: '',
  selectedPayMode: PAY_MODE.PAYMENT_METHOD,
  discountCode: '',
};

const orderReducer = (state = defaultState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_MEMBERSHIP_PRICE_ID:
      return {
        ...state,
        membershipPriceId: payload.membershipPriceId,
      };
    case SET_PAYMENT_METHOD_ID:
      return { ...state, paymentMethodId: payload.paymentMethodId };
    case SET_DISCOUNT_CODE:
      return { ...state, discountCode: payload.discountCode };
    case SET_SELECTED_PAY_MODE:
      return { ...state, selectedPayMode: payload.selectedPayMode };
    default:
      return state;
  }
};

export default orderReducer;
