import { Formik } from 'formik';
import T from 'prop-types';
import React from 'react';
import { object, string, boolean } from 'yup';

import Control from '../Control';
import text from '../../../text';
import Checkbox from '../Checkbox';
import Button from '../../button/Button';

const addNoteSchema = object().shape({
  title: string().required(text('required')),
  description: string().required(text('required')),
  sendNotification: boolean(),
});

const AddNoteForm = (props) => {
  const { projectId, onSubmit, onCancel, addState } = props;

  return (
    <div className='AddNoteForm'>
      <Formik
        initialValues={{ title: '', description: '', sendNotification: false }}
        validationSchema={addNoteSchema}
      >
        {({ handleChange, handleBlur, isValid, values, errors, dirty, touched }) => {
          return (
            <>
              <Control
                required
                label={text('subject')}
                as='input'
                type='text'
                name='title'
                isFailed={touched.title && !!errors.title}
                value={values?.title || ''}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={text('enterSubject')}
                validationError={errors.title}
                autoComplete='off'
              />
              <Control
                required
                label={text('note')}
                as='textarea'
                type='text'
                name='description'
                isFailed={touched.description && !!errors.description}
                value={values?.description || ''}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={text('enterNote')}
                validationError={touched.description && errors.description}
                autoComplete='off'
              />
              <Checkbox
                onChange={handleChange}
                name='sendNotification'
                value={values?.sendNotification}
                label={text('sendNotificationToCustomer')}
              />
              <div className='form-footer'>
                <Button
                  loading={addState.loading}
                  onClick={() => onSubmit(projectId, values)}
                  disabled={!isValid || !dirty}
                >
                  {text('add')}
                </Button>
                <a name='cancel' href='#' onClick={onCancel}>
                  {text('cancel')}
                </a>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

AddNoteForm.propTypes = {
  onSubmit: T.func.isRequired,
  onCancel: T.func.isRequired,
  addState: T.shape(),
  projectId: T.number,
};

export default AddNoteForm;
