import { useContext } from 'react';
import { MapViewContext } from '../mapView/mapViewContext';

const useGeocoder = () => {
  const { state } = useContext(MapViewContext);

  const geocodeReady = state.googleMapsScriptStatus === 'ready';

  const geocode = async (fullAddress) => {
    if (!geocodeReady) {
      console.warn('geocode: Google Maps API not ready');
      return;
    }
    const geocoder = new google.maps.Geocoder();
    const place = await new Promise((resolve, reject) => {
      geocoder.geocode({ address: fullAddress }, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results.length === 0) {
            const error = `Geocode was not successful: no results`;
            return reject(error);
          }
          resolve(results[0]);
        } else {
          const error = `Geocode was not successful: ${status}`;
          reject(error);
        }
      });
    });
    return place;
  };

  return { geocode, geocodeReady };
};

export default useGeocoder;
