import T from 'prop-types';
import React, { createContext, useState } from 'react';

export const LayoutContext = createContext(null);

export const LayoutProvider = ({ children }) => {
  const [startNewProjectRef, setStartNewProjectRef] = useState(null);
  const [startNewProjectHighlight, setStartNewProjectHighlight] =
    useState(false);

  return (
    <LayoutContext.Provider
      value={{
        startNewProjectRef,
        setStartNewProjectRef,
        startNewProjectHighlight,
        setStartNewProjectHighlight,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

LayoutProvider.propTypes = {
  children: T.node.isRequired,
};
