import classnames from 'classnames';
import { isEmpty, map } from 'lodash';
import { flow as flowFP, groupBy as groupByFP } from 'lodash/fp';
import React from 'react';
import AssetIcon from '../../components/icon/common/AssetIcon';
import Activity from '../../components/timeline/Activity';
import { DETAILED_STATUS } from '../../constants/job';
import {
  ASSET_ICON_MAP,
  STATUS_COLOR_MAP,
  STATUS_TEXT_MAP,
} from '../../constants/product';
import text from '../../text';
import { formatDate, formatTime } from '../../utilities/date';

const JobStatusUpdate = (props) => {
  const {
    activityTitle,
    activityDescription,
    detailedStatuses,
    createdDate,
    jobId,
    projectId,
  } = props;

  // We asume that a status activity with not detailed status is by default a 'draft'
  const groupedUpdates = !isEmpty(detailedStatuses)
    ? flowFP([
        (result) => map(result, (value, product) => ({ ...value, product })),
        groupByFP('status'),
      ])(detailedStatuses)
    : ['Draft'];

  return map(groupedUpdates, (products, prodStatus) => {
    return (
      <Activity key={prodStatus} className='JobStatusUpdate'>
        <>
          <Activity.Icon
            iconProps={{
              icon: 'circle',
              className: !isEmpty(detailedStatuses)
                ? STATUS_COLOR_MAP[prodStatus]
                : STATUS_COLOR_MAP.Draft,
            }}
          />
          <div className='DetailedStatus w-100'>
            <Activity.Card.Header className='d-flex flex-column pb-1'>
              <div className='font-weight--500 font-12 d-flex flex-row justify-content-center'>
                <Activity.Card.Type type={activityTitle} />
                <Activity.Card.Date
                  date={formatDate(createdDate)}
                  time={formatTime(createdDate, 'h:mmaaa')}
                />
                {projectId ? (
                  <Activity.Card.HeaderElement
                    className={classnames(
                      {
                        hasPipeSeparator: !!jobId,
                      },
                      'projectId py-0'
                    )}
                  >
                    {text('projectId')}:{projectId}
                  </Activity.Card.HeaderElement>
                ) : null}

                {jobId ? (
                  <Activity.Card.HeaderElement className='jobId py-0'>
                    {text('orderId')}:{jobId}
                  </Activity.Card.HeaderElement>
                ) : null}
              </div>
            </Activity.Card.Header>
            <div className='DetailedStatusContent'>
              {!isEmpty(detailedStatuses)
                ? map(products, ({ product, status, expectedAt }, i) => (
                    <div key={i} className='DetailedStatusContent-Item'>
                      <AssetIcon
                        product={ASSET_ICON_MAP[product] || ''}
                        defaultIcon='cube'
                      />
                      <span>
                        {ASSET_ICON_MAP[product]
                          ? text('productPointCloud3DOrder', {
                              productType: product,
                            })
                          : product}
                        <strong>{` ${STATUS_TEXT_MAP[status]}`}</strong>
                        <span>
                          {' '}
                          {expectedAt && status !== DETAILED_STATUS.COMPLETED
                            ? text('expectedAt', {
                                eta: formatDate(expectedAt),
                              })
                            : null}
                        </span>
                      </span>
                    </div>
                  ))
                : activityDescription}
            </div>
          </div>
        </>
      </Activity>
    );
  });
};

export default JobStatusUpdate;
