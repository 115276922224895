export const DOWNLOAD_ALL_STATUS = {
  IDLE: 'idle',
  ZIPPING: 'zipping',
  DOWNLOADING: 'downloading',
};

export const DOWNLOAD_ALL_URL = 'DOWNLOAD_ALL_URL';
export const DOWNLOAD_ALL_URL_SUCCESS = 'DOWNLOAD_ALL_URL_SUCCESS';
export const DOWNLOAD_ALL_URL_FAILURE = 'DOWNLOAD_ALL_URL_FAILURE';
export const DOWNLOAD_ALL_UPDATE_STATUS = 'DOWNLOAD_ALL_UPDATE_STATUS';
export const DOWNLOAD_ALL_UPDATE_QUEUE_JOB_ID =
  'DOWNLOAD_ALL_UPDATE_QUEUE_JOB_ID';

export const REGISTER_PROJECT_TRIAL_MEMBERS = 'REGISTER_PROJECT_TRIAL_MEMBERS';
export const REGISTER_PROJECT_TRIAL_MEMBERS_SUCCESS =
  'REGISTER_PROJECT_TRIAL_MEMBERS_SUCCESS';

export const LIST_PROJECTS = 'LIST_PROJECTS';
export const LIST_PROJECTS_SUCCESS = 'LIST_PROJECTS_SUCCESS';
export const LIST_PROJECTS_FAILURE = 'LIST_PROJECTS_FAILURE';

export const LIST_PROJECT_USERS = 'LIST_PROJECT_USERS';
export const LIST_PROJECT_USERS_SUCCESS = 'LIST_PROJECT_USERS_SUCCESS';

export const UPDATE_USER_PROJECT_TRIAL = 'UPDATE_USER_PROJECT_TRIAL';
export const UPDATE_USER_PROJECT_TRIAL_SUCCESS =
  'UPDATE_USER_PROJECT_TRIAL_SUCCESS';

export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';

export const GET_CURRENT_PROJECT = 'GET_CURRENT_PROJECT';
export const GET_CURRENT_PROJECT_SUCCESS = 'GET_CURRENT_PROJECT_SUCCESS';
export const GET_CURRENT_PROJECT_FAILURE = 'GET_CURRENT_PROJECT_FAILURE';
