import React, { useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Icon from '../../../icon/Icon';
import text from '../../../../text';
import { useContext } from 'react';
import { MapViewContext } from '../../../mapView/mapViewContext';
import _ from 'lodash/fp';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { clearBoundaries, showModal } from '../../../../redux/actions';
import { DELETE_SHAPES_MODAL } from '../../DeleteShapesModal';

export default function Footer() {
  const dispatch = useDispatch();
  const { state, actions } = useContext(MapViewContext);
  const { data, product: productType } = state.productSelected;
  const product = data[1][0];

  const isAllSelectionsVisible = state.selections
    ? _.every(
        ['visible', true],
        _.filter(['category_name', productType])(state.selections)
      )
    : false;

  const isAllSelectionsHidden = state.selections
    ? _.every(
        ['visible', false],
        _.filter(['category_name', productType])(state.selections)
      )
    : false;

  const eyeIcon = useMemo(() => {
    return isAllSelectionsVisible
      ? 'eye'
      : isAllSelectionsHidden
      ? 'eye-slash'
      : 'eye-mixed-state';
  }, [isAllSelectionsVisible, isAllSelectionsHidden]);

  const handleDelete = async (event) => {
    const selectionsToDelete = state.selections.filter(
      (selection) => selection.product_id === product.product_id
    );

    const childrenSelections = state.selections.filter((selection) =>
      selectionsToDelete.some(
        (selectionToDelete) =>
          selectionToDelete.id === selection.parent_selection_id
      )
    );

    if (state.selections.length === selectionsToDelete.length) {
      dispatch(clearBoundaries());
    }

    dispatch(
      showModal(DELETE_SHAPES_MODAL, {
        selectionsToDelete: [...selectionsToDelete, ...childrenSelections],
        associatedAddOns: childrenSelections,
      })
    );
    actions.clearProductSelected();
    event.stopPropagation();
  };

  if (!state.isProductModalExpanded) {
    return null;
  }

  return (
    <Modal.Footer>
      <Button
        style={{ width: '72px' }}
        variant='light'
        className='row-action with-text icon-button'
        onClick={() => {
          actions.toggleProductVisibility(isAllSelectionsVisible, product);
        }}
      >
        <Icon
          className={classNames({
            original: !isAllSelectionsVisible,
          })}
          icon={eyeIcon}
        />
        <span>{isAllSelectionsVisible ? text('hide') : text('show')}</span>
      </Button>

      <Button
        variant='light'
        className='row-action with-text icon-button'
        onClick={handleDelete}
      >
        <Icon icon='trash' />
        <span>{text('delete')}</span>
      </Button>
    </Modal.Footer>
  );
}
