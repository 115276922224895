/* eslint-disable react/prop-types */
import cn from 'classnames';
import _ from 'lodash/fp';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { components } from 'react-select';

import { setCurrentProject } from '../../../redux/actions';
import { getProjectName } from '../../../utilities/project';
import { isUserAnonymous } from '../../../utilities/user';
import Select from '../Select';

const Label = ({ children, className }) => (
  <h6 className={cn('JobLabel', { [className]: className })}>{children}</h6>
);

const SingleValue = ({ children, ...rest }) => (
  <components.SingleValue className='JobSelectControl' {...rest}>
    <Label>{children}</Label>
  </components.SingleValue>
);

const NavProjectSelect = ({
  projects,
  isLoading,
  onProjectChange = _.noop,
  filterPredicate = _.stubTrue,
}) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const { currentProject } = useSelector((state) => state.project);
  const { userProfile } = useSelector((state) => state.profileReducer);

  useEffect(() => {
    setOptions(_.filter(filterPredicate, projects));
  }, [projects]);

  return isUserAnonymous(userProfile) ? (
    <Label className='JobSelect'>{getProjectName(currentProject)}</Label>
  ) : (
    <Select
      options={options}
      value={currentProject}
      onChange={(option) => {
        onProjectChange(option, currentProject);
        dispatch(setCurrentProject(option));
      }}
      getOptionLabel={(option) => getProjectName(option)}
      getOptionValue={(option) => option.id}
      formatOptionLabel={(option) => getProjectName(option)}
      className='JobSelect'
      components={{ SingleValue }}
      isDisabled={isLoading}
      isLoading={isLoading}
    />
  );
};

export default NavProjectSelect;
