const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      console.log('Registering worker');
      navigator.serviceWorker
        .register('./event-worker.js', { scope: '/' })
        .then(
          () => console.log('Service worker register success'),
          (error) => {
            console.error(`Service worker registration failed: ${error}`);
          }
        );
    });
  }
};

export default registerServiceWorker;
