import {
  SET_MEMBERSHIP_PRICE_ID,
  SET_PAYMENT_METHOD_ID,
  SET_SELECTED_PAY_MODE,
  SET_DISCOUNT_CODE,
} from '../constants/order';
import { alertConstants } from '../constants/alert';
import { client } from '../../utilities/api';
import { isMembershipPriceId } from '../../utilities/membership';

export const setDiscountCode = (discountCode) => ({
  type: SET_DISCOUNT_CODE,
  payload: { discountCode },
});

export const setPaymentMethodId = (paymentMethodId) => ({
  type: SET_PAYMENT_METHOD_ID,
  payload: { paymentMethodId },
});

export const setMembershipPriceId = (membershipPriceId) => ({
  type: SET_MEMBERSHIP_PRICE_ID,
  payload: { membershipPriceId },
});

export const setSelectedPayMode = (selectedPayMode) => ({
  type: SET_SELECTED_PAY_MODE,
  payload: { selectedPayMode },
});

export const assignPayment =
  (priceId, jobId, email, name, message) => async (dispatch) => {
    try {
      await client.sendQuote(
        isMembershipPriceId(priceId) ? priceId : undefined,
        email,
        name,
        message,
        jobId
      );
      return true;
    } catch (error) {
      dispatch({
        type: alertConstants.ALERT_ERROR,
        payload: { message: error.response.data.message },
      });
      return false;
    }
  };

export const createDiscountCode = (discountCode) => async (dispatch) => {
  try {
    dispatch({
      type: 'CREATE_DISCOUNT_CODE',
    });
    await client.createDiscountCode(discountCode);

    return true;
  } catch (error) {
    dispatch({
      type: alertConstants.ALERT_ERROR,
      payload: { message: error.response.data.message },
    });
    return false;
  }
};
