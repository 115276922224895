import React, { useEffect } from 'react';

import Table from '../../table/Table';
import text from '../../../text';
import ProductRow from './ProductRow';

const newProduct = { productKey: '' };

const UpdateProductTable = (props) => {
  const { products, productsHelpers, initialProducts } = props;

  useEffect(() => {
    if (products.length === 0) {
      productsHelpers.push(newProduct);
    }
  }, [products]);

  return (
    <Table className='UpdateProductTable'>
      <thead>
        <tr>
          <th>{text('product')}</th>
          <th>{text('status')}</th>
          <th style={{ whiteSpace: 'nowrap' }}>
            {text('estimatedTimeOfArrival')}
          </th>
        </tr>
      </thead>
      <tbody>
        {products &&
          products.map((product, i) => (
            <ProductRow
              name={`detailedStatus.${i}`}
              initialProducts={initialProducts}
              products={products}
              key={i}
              product={product}
            />
          ))}
      </tbody>
    </Table>
  );
};

export default UpdateProductTable;
