import map from 'lodash/map';
import React from 'react';
import text from '../../../text';
import { statuses } from '../../orderListing/consts';
import CreateTable from '../../select/CreateTable';

const { inprogress, pending, completed, cancelled } = statuses;

const getStatusOption = (status) =>
  status ? { status, label: status } : undefined;

const OPTIONS = map([inprogress, pending, completed, cancelled], (status) => ({
  status: status.name,
  label: status.name,
}));

const StatusSelect = (props) => {
  const { onChange, status, placeholder } = props;

  return (
    <>
      <CreateTable
        options={OPTIONS}
        onChange={option => {
          const value = option.status ? option : getStatusOption(option.value);
          onChange(value);
        }}
        placeholder={placeholder}
        createInputPlaceholder={text('enterCustomStatus')}
        getOptionValue={(o) => o.status}
        value={getStatusOption(status)}
      />
    </>
  );
};

export default StatusSelect;
