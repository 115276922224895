import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce, useSearchParam } from 'react-use';

import Control from '../../components/form/Control';
import { listProjectActivities } from '../../redux/actions/activity';
import text from '../../text';
import { AUTOSEARCH_DELAY } from '../constants';

const SearchActivitiesInput = ({ projectId }) => {
  const dispatch = useDispatch();
  const [queryValue, setQueryValue] = useState();
  const { projects } = useSelector((state) => ({
    projects: state.activity.projects,
  }));
  const queryParam = useSearchParam('query');

  const [isReady] = useDebounce(
    () =>
      projectId &&
      !projects[projectId]?.ui?.isLoading &&
      dispatch(listProjectActivities(projectId, queryValue)),
    AUTOSEARCH_DELAY,
    [queryValue]
  );

  useEffect(() => {
    if (queryParam && queryParam !== queryValue) {
      setQueryValue(queryParam);
    }
  }, [queryParam]);

  useEffect(() => {
    if (queryValue && projectId) {
      isReady();
    }
  }, [queryValue]);

  return (
    <Control
      as='input'
      type='text'
      name='search'
      value={queryValue}
      onChange={(event) => setQueryValue(event.target.value)}
      iconProps={{ icon: 'search' }}
      placeholder={text('searchHere')}
      autoComplete='off'
    />
  );
};

export default SearchActivitiesInput;
