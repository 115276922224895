import React from 'react';
import Icon from '../icon/Icon';
import WhiteContainer from './WhiteContainer';
import TextDivider from '../text/TextDivider';
import Loading from '../loading/Loading';

const ResultContainer = ({
  icon,
  firstHeader,
  resultStatus,
  isLoading,
  header,
  extraInfo,
}) => {
  return (
    <WhiteContainer className='ResultContainer'>
      {!isLoading ? (
        <>
          <Icon icon={icon} />
          <h3 className='pt-3'>{resultStatus}</h3>
          <TextDivider isComplete isSecondary/>
          <p className='mb-2 lightText'>
            <small>{header}</small>
          </p>
          <h3 className='pb-3 text-center'>{firstHeader}</h3>
          {extraInfo}
        </>
      ) : (
        <Loading />
      )}
    </WhiteContainer>
  );
};

export default ResultContainer;
