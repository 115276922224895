import get from 'lodash/get';
import { differenceInCalendarDays, differenceInHours } from 'date-fns';

import { MEMBERSHIP, STATUS } from '../constants/membership';

export const calculateRemainderHours = (membership) =>
  differenceInHours(new Date(membership.trial_end), new Date());

export const calculateRemainderDays = (membership) =>
  differenceInCalendarDays(new Date(membership.trial_end), new Date());

export const calculateTrialLengthDays = (membership) =>
  differenceInCalendarDays(
    new Date(membership.trial_end),
    new Date(membership.trial_start)
  );

export const isMembershipPriceId = (membershipPriceId) =>
  MEMBERSHIP.NO_MEMBERSHIP !== membershipPriceId;

export const isYearlyMembershipPriceId = (membershipPriceId) =>
  MEMBERSHIP.YEARLY.PRICE_ID === membershipPriceId;

export const isTrialMembership = (membership) =>
  membership?.status && [STATUS.TRIALING].includes(membership.status);

export const getPriceId = (memberships) => get(memberships, [0, 'price_id']);

export const getTrialResidualDays = (membership) => {
  return (
    membership &&
    membership.trial_end &&
    differenceInCalendarDays(new Date(membership.trial_end), new Date())
  );
};
