import React from 'react';
import cn from 'classnames';
import Icon from '../Icon';

export const AssetIcon = (props) => {
  const { product, defaultIcon, classNames, roundedCorners = true } = props;
  return (
    <Icon
      className={cn('AssetIcon', { inverted: product }, classNames)}
      isCornerRound={roundedCorners}
      icon={product || defaultIcon || 'clip'}
    />
  );
};

export default AssetIcon;
