import React from 'react';
import { useFormikContext } from 'formik';
import Control from '../Control';
import text from '../../../text';

const AssignedPaymentForm = () => {
  const {
    values: assignedPayment,
    touched,
    errors,
    handleBlur,
    handleChange,
  } = useFormikContext();
  return (
    <div className='AssignedPaymentForm w-100 mt-3'>
      <>
        <p>{text('assignedPaymentMessage')}</p>
        <Control
          as='input'
          type='text'
          name='payerName'
          isFailed={touched.payerName && !!errors.payerName}
          value={assignedPayment.payerName}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={text('enterName')}
          label={text('payerName')}
          validationError={touched.payerName && errors.payerName}
        />
        <Control
          as='input'
          onBlur={handleBlur}
          onChange={handleChange}
          name='payerEmail'
          label={text('payerEmail')}
          placeholder={text('enterEmail')}
          value={assignedPayment.payerEmail}
          isFailed={touched.payerEmail && !!errors.payerEmail}
          validationError={errors.payerEmail}
        />
        <Control
          onBlur={handleBlur}
          onChange={handleChange}
          as='textarea'
          name='message'
          label={text('message')}
          value={assignedPayment.message}
        />
      </>
    </div>
  );
};

export default AssignedPaymentForm;
