export const FILE_FORMAT = {
  pointCloud3D: ['rcs', 'e57', 'las', 'laz', 'ply', 'rcp', 'pts'],
  drawing2D: ['dwg'],
  bim3D: ['rvt', 'pln', 'skp', 'vwx', '3dm', 'ifc'],
  image: ['jpg', 'png'],
  document: ['pdf'],
  compressed: ['zip'],
};

export const BIM_FILE_EXT = ['rvt', 'pln', 'ifc'];
export const FEATURE_PLAN_EXT = ['pdf', 'dwg'];

export const DOWNLOAD_ALL_JOB_STATUS = {
  PROCESSING: 'processing',
  FAILED: 'failed',
  AVAILABLE: 'available',
  UNAVAILABLE: 'unavailable',
};
