import T from 'prop-types';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useAsyncFn, useList } from 'react-use';
import text from '../../../text';
import { client } from '../../../utilities/api';
import InviteUserForm from '../../form/inviteUserForm/InviteUserForm';
import InvitationTable from '../../table/invitationTable/InvitationTable';

export const INVITE_PROJECT_USERS_MODAL = 'INVITE_PROJECT_USERS_MODAL';

const InviteProjectUsersModal = (props) => {
  const { show, project, onHide, ...restProps } = props;
  const [projectInvitation, { set, insertAt, removeAt, clear, updateAt }] =
    useList();

  const isValidInvitee = (inviteeEmail) =>
    !projectInvitation.some(({ user }) => user.email === inviteeEmail);

  const [inviteState, inviteUser] = useAsyncFn(
    async (project, email, isResend = false) => {
      const { data } = await client.inviteUserToProject(project.id, email);
      if (!isResend) {
        insertAt(0, { ...data });
      }
    }
  );

  const invitationIndex = (userId) =>
    projectInvitation.findIndex((invitation) => invitation.user.id === userId);

  const removeProjectUser = async (projectToRemove, invite) => {
    const index = invitationIndex(invite.user.id);
    await client.removeUserFromProject(
      projectToRemove?.id,
      invite?.user?.email
    );
    removeAt(index);
  };

  const updateUserProjectVisibility = async (invite) => {
    const visibility = invite.visibility === 'public' ? 'staff' : 'public';
    const index = invitationIndex(invite.user.id);
    await client.updateUserProjectVisibility(
      project.id,
      invite.user.id,
      visibility
    );
    const updatedInvite = { ...invite, visibility };
    updateAt(index, updatedInvite);
  };

  useEffect(async () => {
    if (project && show) {
      const { data } = await client.listProjectUsers(project.id);
      set(data);
    }
  }, [show]);

  const onHandleHide = () => {
    clear();
    onHide();
  };

  return (
    <Modal centered scrollable show={show} onHide={onHandleHide} {...restProps}>
      <Modal.Header className='align-end' closeButton>
        <div>
          <h3 className='secondary'>{text('inviteProjectUser')}</h3>
          {project?.name && <h6 className='mt-2'>{project.name}</h6>}
        </div>
      </Modal.Header>
      <InviteUserForm
        isLoading={inviteState.loading}
        isValidUser={isValidInvitee}
        onSubmit={(email) => inviteUser(project, email)}
      />
      <InvitationTable
        callback={set}
        resendInvitation={(email) => inviteUser(project, email, true)}
        removeProjectUser={removeProjectUser}
        updateUserProjectVisibility={updateUserProjectVisibility}
        project={project}
        // job={job}
        invitations={projectInvitation}
      />
    </Modal>
  );
};

InviteProjectUsersModal.propTypes = {
  project: T.shape(),
  job: T.object,
  onHide: T.func,
  show: T.bool,
};

export default InviteProjectUsersModal;
