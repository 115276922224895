import { Formik } from 'formik';
import noop from 'lodash/noop';
import T from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useWizard } from 'react-use-wizard';
import { reach } from 'yup';

import text from '../../text';
import { projectValidationSchema } from '../../validation';
import Control from '../form/Control';

const ProjectNameForm = (props) => {
  const { onContinue, onCancel, project, onContinueText } = props;
  const { nextStep } = useWizard();

  const handleContinue = (values) => {
    onContinue(values);
    nextStep();
  };

  const handleCancel = (event) => {
    event?.preventDefault();
    onCancel();
  };

  return (
    <div className='ProjectNameForm'>
      <Formik
        initialValues={project}
        enableReinitialize
        validateOnMount
        validationSchema={reach(projectValidationSchema, 'project')}
      >
        {({ handleChange, handleBlur, isValid, values, errors, touched }) => {
          return (
            <>
              <Control
                as='input'
                type='text'
                name='name'
                isFailed={touched.name && !!errors.name}
                value={values?.name}
                onChange={(e) => handleChange(e)}
                onBlur={handleBlur}
                placeholder={text('enterProjectName')}
                validationError={touched.name && errors.name}
                autoComplete='off'
              />
              <div className='form-footer'>
                <Button
                  onClick={() => handleContinue(values)}
                  disabled={!isValid}
                >
                  {onContinueText}
                </Button>
                <a name='cancel' href='#' onClick={handleCancel}>
                  {text('cancel')}
                </a>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

ProjectNameForm.propTypes = {
  onCancel: T.func.isRequired,
  onContinue: T.func.isRequired,
};

ProjectNameForm.defaultProps = {
  onCancel: noop,
  onContinue: noop,
};

export default ProjectNameForm;
