import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '../../button/Button';
import { hideModal, listProjectUsers } from '../../../redux/actions';
import text from '../../../text';
import routes from '../../../routes/constants';
import Loading from '../../loading/Loading';
import List from '../../list/List';
import Icon from '../../icon/Icon';
import TextDivider from '../../text/TextDivider';
import { formatDate } from '../../../utilities/date';
import { getUserMembership } from '../../../utilities/user';

export const PROJECT_TRIAL_MEMBERS_SUCCESS_MODAL =
  'PROJECT_TRIAL_MEMBERS_SUCCESS_MODAL';

const ProjectTrialMembersSuccessModal = ({ show, projectId }) => {
  const dispatch = useDispatch();
  const { projectUsers, currentUser } = useSelector((state) => ({
    projectUsers: state.project.projectUsers,
    currentUser: state.profileReducer.userProfile,
  }));
  const history = useHistory();
  const onHide = () => dispatch(hideModal(PROJECT_TRIAL_MEMBERS_SUCCESS_MODAL));

  useEffect(() => {
    if (
      projectId &&
      !projectUsers[projectId] &&
      !projectUsers[projectId]?.isLoading
    ) {
      dispatch(listProjectUsers(projectId));
    }
  }, [projectId]);
  return (
    <Modal
      centered
      className='ProjectTrialMembersSuccessModal'
      show={show}
      onHide={onHide}
    >
      {!projectUsers[projectId]?.isLoading ? (
        <>
          <Modal.Header>
            <div className='w-100 center-content flex-column'>
              <Icon icon='circled-tick_filled' />
              <h3 className='m-4'>{text('projectTrialSuccessModalHeader')}</h3>

              <List className='m-auto' isPaddingless isBorderless>
                <List.Item
                  isColonAppended
                  description={text('endDateTrial')}
                  value={formatDate(getUserMembership(currentUser)?.end_date)}
                />
              </List>
            </div>
          </Modal.Header>
          <Modal.Body className='text-center'>
            <TextDivider isComplete isSecondary />
            <p className='text-center'>{text('usersHaveAccessToPlatform')}</p>
            {!projectUsers[projectId]?.isLoading ? (
              <List isPaddingless isBorderless className='px-4'>
                {projectUsers[projectId]?.users.map((item, i) => (
                  <List.Item
                    key={i}
                    description={text('projectUserNumber', {
                      number: i + 1,
                      email: null,
                    })}
                    value={item.user.email}
                  />
                ))}
              </List>
            ) : (
              <Loading />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className='full-width'
              onClick={() => {
                history.push(routes.fileManager.viewProject(projectId));
                onHide();
              }}
            >
              {text('goToFileManager')}
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <Loading />
      )}
    </Modal>
  );
};

export default ProjectTrialMembersSuccessModal;
