import _, { differenceBy } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { isEmpty, isNil } from 'lodash';
import { useBoolean } from 'react-use';
import {
  NON_TRIPOD_DATA_TYPE,
  PRODUCT_TYPE,
  SMART_SELECTION_DELIVERY_METHOD,
} from '../../constants/product';
import useDebounce from '../../hooks/useDebounce';
import useHoveredProduct from '../../hooks/useHoveredProduct';
import useScript from '../../hooks/useScript';
import useStateRef from '../../hooks/useStateRef';
import useWorkerCallback from '../../hooks/useWorkerCallback';
import useZoom from '../../hooks/useZoom';
import { clearBoundaries, showModal } from '../../redux/actions';
import { setCoverageCache } from '../../redux/actions/coverage';
import { mapConstants } from '../../redux/constants';
import { profileSelectors } from '../../redux/selectors/profile';
import { doesExistInArray } from '../../utilities/array';
import { getNowDate } from '../../utilities/date';
import { captureError } from '../../utilities/error';
import { transformUserOrderToSelections } from '../../utilities/job';
import {
  convertGMGeometryToGeoJson,
  convertToGeoJson,
  createId,
  selectionIsNonTripod,
} from '../../utilities/map';
import { smoothlyAnimatePanTo } from '../../utilities/panTo';
import { getNextSequenceItem } from '../../utilities/sequence';
import { UPDATE_SHAPES_MODAL } from '../modal/UpdateShapesModal';
import { STATUS } from '../orderListing/consts';
import {
  INITIAL_MAP_ZOOM,
  MAP_BOUNDS_PADDING,
  MAP_TYPE,
  MAX_MAP_ZOOM,
  MAX_MAP_ZOOM_HIGH_RES,
  MAX_MAP_ZOOM_SATELLITE,
  MIN_MAP_ZOOM,
  PRODUCT_DATA,
} from './constants';
import { MAP_STATES } from './drawingControls/drawingControls';
import { getAddOnConfig } from './drawingManager/utilities';
import { computeSquarePolygonAroundLocation } from './geometry';
import { quoteOrderRoute } from '../../utilities/api';

export const MapViewContext = createContext(null);

const pluck = (obj, keys) => {
  return keys
    .filter((key) => key in obj)
    .reduce((obj2, key) => ((obj2[key] = obj[key]), obj2), {});
};

export function MapViewProvider({ children }) {
  const dispatch = useDispatch();
  // STATE
  const { jobId } = useParams();
  const googleMapsScriptStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.GCP_MAPS_API_KEY}&libraries=drawing,places,geometry`
  );
  const [hasShadowOverlay, setHasShadowOverlay] = useState(!jobId);
  const [isAddressInfoVisible, setIsAddressInfoVisible] = useState(true);
  const [marqueeState, setMarqueeState] = useState('none');
  const [polygonState, setPolygonState] = useState('none');
  const [polylineState, setPolylineState] = useState('none');
  const [address, setAddress] = useState(null);
  const [inputAddress, setInputAddress] = useState('');
  const [project, setProject] = useState({});
  const [requestMessage, setRequestMessage] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [mapLoadCount, setMapLoadCount] = useState(0);
  const [map, setMap] = useState(null);
  const [mapHasProjection, setMapHasProjection] = useState(false);
  const [mapManager, setMapManager] = useState(null);
  const [mapPlace, setMapPlace] = useState(null);
  const [currentMapTypeId, setCurrentMapTypeId] = useState(null);
  const [isMapVisible, setIsMapVisible] = useState(false);
  const [mousePositionOnMap, setMousePositionOnMap] = useState(null);
  const [selections, setSelections] = useState([]);
  const [hoveredData, setHoveredData] = useState(null);
  // const [hoveredProductType, setHoveredProductType] = useState(null);
  const {
    currentlyHoveredProduct: hoveredProductType,
    hoverProduct,
    unhoverProduct,
  } = useHoveredProduct();
  // should be set to true if a draft should be created
  const [draftToCreate, setDraftToCreate] = useState(false);
  const [jobCenter, setJobCenter] = useState(null);
  const [viewportCenter, setViewportCenter] = useState(null);
  const debouncedViewportCenter = useDebounce(viewportCenter, 250);
  const [hasMapFitToJobBounds, setHasMapFitToJobBounds] = useBoolean(false); // flag to prevent the map from loading more than once

  const [currentMapState, setCurrentMapState, currentMapStateRef] = useStateRef(
    MAP_STATES.NONE
  );
  const [geometries, setGeometries, geometriesRef] = useStateRef({});
  const [geometriesToLoad, setGeometriesToLoad] = useState(null);
  const [rectangleToDraw, setRectangleToDraw] = useState(null);
  const [activeSelectionId, setActiveSelectionId] = useState(null);
  const mapRef = useRef(null);

  // the default region is used when the user has no geometries / selections
  const [defaultRegion, setDefaultRegion] = useState(null);

  // has the form { category_a: true/false, category_b: true/false, ... }
  // a product is available (in one of the product badges) iff
  // there is an active selection and the product is available in the selection's geometry... or
  // there is no active selection and the product is available in the default region
  const [productAvailability, setProductAvailability] = useState({});

  // { selectionId: { productType: true/false, ... }, ... }
  const [selectionProductAvailability, setSelectionProductAvailability] =
    useState({});

  // hasLoadedJob is true once the state has been updated with the job data
  // e.g. selections, address, etc.
  const [hasLoadedJob, setHasLoadedJob] = useState(false);

  const isHighResEnabled = useSelector(profileSelectors.getHighResEnabled);

  // Cart Product Modal & ShapeMenuModal states
  const shapeMenuModalRef = useRef(null);
  const [openedShapeMenu, setOpenedShapeMenu] = useState({
    data: null,
    prevCategoryName: null,
  });
  const [showProductModal, setShowProductModal] = useState(false);
  const [productSelected, setProductSelected] = useState({
    data: [],
    product: '',
    charges: {},
  });
  const closeShapeMenuModal = () => {
    shapeMenuModalRef.current = null;
    setOpenedShapeMenu({
      data: null,
      prevCategoryName: null,
    });
  };
  // state to leave Product modal expanded on select another product from cart
  const [isProductModalExpanded, setIsProductModalExpanded] = useState(false);

  const [currentHighResLayerName, setCurrentHighResLayerName] = useState(null);

  const {
    isJobCreatedModalOpen,
    job,
    coverageState,
    mapState,
    layout,
    mapType,
    isQuotingJob,
    pendingApiRequests,
  } = useSelector((state) => {
    return {
      isJobCreatedModalOpen: state.jobsReducer.isJobCreatedModalOpen,
      job: state.jobsReducer.job,
      coverageState: state.coverageReducer,
      mapState: state.mapReducer,
      layout: state.layout,
      mapType: state.profileReducer.userProfile.map_type,
      isQuotingJob: state.jobsReducer.isQuotingJob,
      pendingApiRequests: state.apiReducer.pendingApiRequests,
    };
  });

  const debouncedRegionSelection = useDebounce(geometries, 50);

  const selectionsWithFocused = useMemo(
    () =>
      selections.map((selection) =>
        _.set('focused', selection.id === activeSelectionId)(selection)
      ),
    [selections, activeSelectionId]
  );

  const activeSelection = useMemo(() => {
    return activeSelectionId
      ? _.find(['id', activeSelectionId], selections)
      : null;
  }, [selections, activeSelectionId]);

  const maxZoomForMapType = useMemo(() => {
    if (mapType === MAP_TYPE.ROAD_MAP) return MAX_MAP_ZOOM;
    if (mapType === MAP_TYPE.SATELLITE) return MAX_MAP_ZOOM_SATELLITE;
    if (mapType === MAP_TYPE.HIGH_RES || mapType === MAP_TYPE.HIGH_RES_NZ)
      return MAX_MAP_ZOOM_HIGH_RES;
    return MAX_MAP_ZOOM;
  }, [mapType]);

  const { state: zoomState, actions: zoomActions } = useZoom(
    mapRef,
    INITIAL_MAP_ZOOM,
    MIN_MAP_ZOOM,
    maxZoomForMapType
  );

  const isStillQuoting = useMemo(
    () =>
      (pendingApiRequests || []).some((item) => {
        return quoteOrderRoute().pattern.test(item.url);
      }),
    [pendingApiRequests]
  );

  const isCartLoading = useMemo(
    () =>
      isCartEmpty ||
      mapState.isLoadingSmartSelections ||
      (isQuotingJob && isEmpty(geometries)) ||
      isStillQuoting,
    [
      isCartEmpty,
      mapState.isLoadingSmartSelections,
      isQuotingJob,
      geometries,
      isStillQuoting,
    ]
  );

  const clearProductSelected = () => {
    setShowProductModal(false);
    setProductSelected({
      data: [],
      product: '',
      charges: {},
      isReadOnly: false,
    });
  };
  // end - Cart Simplification states

  /* ---------- Merge coverage ---------- */

  const mergeCoverageWorker = useMemo(
    () =>
      new Worker(
        new URL('../../workers/mergeCoverage.worker.js', import.meta.url)
      ),
    []
  );

  const [coverage, setCoverage] = useState({});

  const mergeCoverage = useWorkerCallback(mergeCoverageWorker, (data) => {
    if (data.hashes.length < coverageState.hashes.length) {
      return;
    }
    setCoverage(data.coverage);
    dispatch(setCoverageCache(data.coverage));
  });

  useEffect(() => {
    if (!window?.Worker) {
      return;
    }
    mergeCoverage({
      hashes: coverageState.hashes,
      coverages: coverageState.caches,
    });
  }, [window?.Worker, coverageState.hashes]);

  /* -------------------- */

  /* ---------- Coverage in regions ---------- */

  /**
   * Get coverage in region selection
   */
  const setCoverageInRegionSelectionWorker = useMemo(
    () =>
      new Worker(
        new URL('../../workers/getCoverageInRegion.worker.js', import.meta.url)
      ),
    []
  );
  const [coverageInRegionSelection, setCoverageInRegionSelection] =
    useState(null);
  const setCoverageInRegionSelection_ = useWorkerCallback(
    setCoverageInRegionSelectionWorker,
    (data) => {
      setCoverageInRegionSelection(data);
    }
  );
  useEffect(() => {
    if (!window?.Worker) {
      return;
    }
    if (_.isEmpty(debouncedRegionSelection)) {
      setCoverageInRegionSelection(null);
      return;
    }
    const region = _.values(debouncedRegionSelection)?.[0];
    if (_.isEmpty(region)) {
      setCoverageInRegionSelection(null);
      return;
    }
    const regionFeature = convertGMGeometryToGeoJson(region);
    setCoverageInRegionSelection_({
      coverage,
      region: regionFeature,
    });
  }, [window?.Worker, coverage, debouncedRegionSelection]);

  /**
   * Get coverage in default region
   */
  const setCoverageInDefaultRegionWorker = useMemo(
    () =>
      new Worker(
        new URL('../../workers/getCoverageInRegion.worker.js', import.meta.url)
      ),
    []
  );
  const [coverageInDefaultRegion, setCoverageInDefaultRegion] = useState(null);
  const setCoverageInDefaultRegion_ = useWorkerCallback(
    setCoverageInDefaultRegionWorker,
    (data) => {
      setCoverageInDefaultRegion(data);
    }
  );
  useEffect(() => {
    if (!window?.Worker) {
      return;
    }
    if (!defaultRegion) {
      setCoverageInDefaultRegion(null);
      return;
    }
    const geometry = convertToGeoJson([{ region: defaultRegion }]).features[0]
      .geometry;
    setCoverageInDefaultRegion_({
      coverage,
      region: geometry,
    });
  }, [window?.Worker, coverage, defaultRegion]);

  /* -------------------- */

  const isCartEmpty = !_.some(
    (selection) => selection.category_name !== 'unknown',
    selections
  );

  /**
   * Get coverage in selections, grouped by category.
   */
  const coveragePerProduct = useMemo(() => {
    const products = {};
    if (job?.coverage?.selections) {
      _.forEach((selection) => {
        if (selection.category_name in products) {
          products[selection.category_name].push(selection);
        } else {
          products[selection.category_name] = [selection];
        }
      }, job.coverage.selections);
    }
    return products;
  }, [job]);

  const canShowAddOns = !!job?.full_address;

  const showManual = canShowAddOns && !layout.manualDataTable.isVisible;

  const showAutomatic =
    canShowAddOns &&
    !layout.automaticDataTable.isVisible &&
    mapState.isAutomaticDataAvailable;

  const hasAddOns = showManual || showAutomatic;

  // ADD-ONs RHS button control - start
  const hasTripodShape = doesExistInArray(
    selections,
    [PRODUCT_TYPE.INTERIOR, PRODUCT_TYPE.EXTERIOR],
    'category_name'
  );

  /**
   * Checks if there is any Tripod shapes in map (interior/exterior) and
   * there is no at least one BIM(interior/exterior) shape exists in the map
   * then returns TRUE (the button will show e.g BIM (Building Information Model))
   *
   * works same with 2D Feature Plan
   */
  const showBimAddOnButton =
    canShowAddOns &&
    !doesExistInArray(
      selections,
      [PRODUCT_TYPE.BIM_INTERIOR, PRODUCT_TYPE.BIM_EXTERIOR],
      'category_name'
    ) &&
    hasTripodShape;

  const show2DFeaturePlanButton =
    canShowAddOns &&
    !doesExistInArray(
      selections,
      [PRODUCT_TYPE.TWOD_PLAN_EXTERIOR, PRODUCT_TYPE.TWOD_PLAN_INTERIOR],
      'category_name'
    ) &&
    hasTripodShape;
  // ADD-ONs RHS button control - end

  // FUNCTIONS
  const loadJob = useCallback(() => {
    setAddress({
      address: job.address,
      city: job.city,
      postcode: job.postcode,
      state: job.state,
    });
    setInputAddress(job.full_address);
    let selectionsToResume = null;
    if (job.user_order?.selections) {
      selectionsToResume = transformUserOrderToSelections(job);
      setSelections(selectionsToResume);
    }
    setIsAddressInfoVisible(false);
    setProject({ name: job.project?.name });
    setRequestMessage(job.message);
    dispatch({ type: mapConstants.CLEAR_MAP_DATA_QUERY });
    setHasLoadedJob(true);
  }, [job]);

  /**
   * (a) Fits the viewport to bounds of the selections if there are selections; else
   * (b) centers the viewport at the job's address if the job's address is valid; else
   * (c) centers the viewport at the default location.
   * Returns the location, null if map is not defined.
   */
  const fitMapBoundsToJob = useCallback(async () => {
    if (!map) {
      return null;
    }

    const selectionsToResume = transformUserOrderToSelections(job);
    if (
      selectionsToResume &&
      selectionsToResume.length > 0 &&
      googleMapsScriptStatus === 'ready' &&
      google
    ) {
      const selectionBounds = new google.maps.LatLngBounds();
      selectionsToResume.forEach((selection) => {
        selection.region.include.forEach((point) => {
          selectionBounds.extend(
            new google.maps.LatLng({
              lat: point[0],
              lng: point[1],
            })
          );
        });
      });

      // The padding is so the map doesn't fill the screen with the selection
      // The value can be reduced to zoom in to the selection or increased to zoom further out
      zoomActions.fitBounds(
        map,
        selectionBounds,
        zoomState.zoom,
        MAP_BOUNDS_PADDING
      );
      return selectionBounds.getCenter();
    } else if (
      job.full_address &&
      googleMapsScriptStatus === 'ready' &&
      google
    ) {
      const geocoder = new google.maps.Geocoder();
      const newCenter = await new Promise((resolve, reject) =>
        geocoder.geocode({ address: job.full_address }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            const place = results[0];
            resolve(place.geometry.location);
          } else {
            const error = `Geocode failed with status: ${status}`;
            captureError(new Error(error));
            reject(error);
          }
        })
      );

      try {
        map.setCenter(newCenter);
        return newCenter;
      } catch (error) {
        captureError(error);
      }
    } else if (googleMapsScriptStatus === 'ready' && google) {
      captureError(
        'No selections or valid address found, using default location'
      );
    } else {
      captureError('Google maps not loaded');
    }
    return null;
  }, [job, map, googleMapsScriptStatus]);

  const zoomToShapeExtents = (shapeSelected) => {
    const selectionBounds = new google.maps.LatLngBounds();
    shapeSelected.region.include.forEach((point) => {
      selectionBounds.extend(
        new google.maps.LatLng({
          lat: point[0],
          lng: point[1],
        })
      );
    });

    const boundsLatLng = selectionBounds.getCenter();
    const setCenter = () => map.setCenter(boundsLatLng);
    const fitBounds = () =>
      zoomActions.fitBounds(
        map,
        selectionBounds,
        zoomState.zoom,
        MAP_BOUNDS_PADDING
      );
    smoothlyAnimatePanTo(map, boundsLatLng, fitBounds, setCenter);
  };

  const sortByCategoryOrder = useCallback(
    (a, b) =>
      PRODUCT_DATA.entities[a.category_name].order -
      PRODUCT_DATA.entities[b.category_name].order
  );

  const sortByCreatedDate = useCallback(
    (a, b) => new Date(a.created_at) - new Date(b.created_at)
  );

  const createNewSelection = (data, region = null) => {
    const selectionRegion = data.region || region;
    console.assert(selectionRegion, 'region missing');
    console.assert(selectionRegion, 'data.region or region missing');
    const selectionNames = [...selections]
      .sort(sortByCreatedDate)
      .map((selection) => selection.name);

    return {
      ...data,
      created_at: getNowDate(),
      region: selectionRegion,
      regionQueryData: { ...data.geometry },
      name: getNextSequenceItem(selectionNames),
      visible: true,
      id: createId(),
    };
  };

  const addSelection = useCallback(
    (data, region = null, makeNewSelectionActive = false) => {
      console.assert(data, 'data missing');
      const newSelection = createNewSelection(data, region);
      setSelections([...selections, newSelection].sort(sortByCategoryOrder));
      if (makeNewSelectionActive) {
        setActiveSelectionId(newSelection.id);
      }
      return newSelection;
    },
    [selections]
  );

  const addSelections = (selectionInputs) => {
    const newSelections = selectionInputs.map((selectionInput) =>
      createNewSelection(selectionInput)
    );

    const selectionNames = [...selections]
      .sort(sortByCreatedDate)
      .map((selection) => selection.name);

    newSelections.sort(sortByCategoryOrder);

    renameSelections(selectionNames, newSelections);

    setSelections((prevSelections) =>
      [...prevSelections, ...newSelections].sort(sortByCategoryOrder)
    );
    return newSelections;
  };

  const renameSelections = async (selectionNames = [], newSelections = []) => {
    newSelections.forEach((_, index) => {
      const name = getNextSequenceItem(selectionNames);
      newSelections[index].name = name;
      selectionNames.push(name);
    });
  };

  const addSelectionWithAddOn = useCallback(
    (data, region, addOn = {}, makeAddOnSelectionActive = false) => {
      if (isEmpty(addOn)) {
        console.warn('Add-on data is empty');
        return;
      }
      const baseProductSelection = createNewSelection(data, region);
      const addOnSelection = createNewSelection(
        {
          ...data,
          product_id: addOn.product_id,
          display_name: addOn.display_name,
          category_name: addOn.category_name,
          parent_selection_id: baseProductSelection.id,
        },
        region
      );

      const selectionNames = [...selections]
        .sort(sortByCreatedDate)
        .map((selection) => selection.name);

      renameSelections(selectionNames, [baseProductSelection, addOnSelection]);

      setSelections(
        [...selections, baseProductSelection, addOnSelection].sort(
          sortByCategoryOrder
        )
      );

      if (makeAddOnSelectionActive) {
        setGeometriesToLoad({
          regionSelection: { region: addOnSelection.region },
        });
        setActiveSelectionId(addOnSelection.id);
        setProductSelectedOnMakeSelection(addOnSelection);
      }
      return addOnSelection;
    },
    [selections]
  );

  const getDefaultRegion = () => {
    const center = map?.getCenter();
    if (center) {
      const newRegion = computeSquarePolygonAroundLocation(center);
      return newRegion;
    }
    return null;
  };

  const makeSelectionActive = useCallback(
    (data, showClearWhenShapeSelected = true) => {
      if (_.isEmpty(data)) {
        setActiveSelectionId(null);
        return;
      }

      const selection = _.find(['id', data.id], selections);
      console.assert(selection, 'selection not found');
      setGeometriesToLoad({ regionSelection: { region: data.region } });
      setActiveSelectionId(data.id);
      if (!selection.visible) {
        toggleSelectionVisibility(data.id);
      }

      setProductSelectedOnMakeSelection(selection);
    },
    [selections]
  );

  // Set ProductSelected for Cart highlight and Popup incase its opened
  const setProductSelectedOnMakeSelection = useCallback(
    (selection) => {
      const isReadOnly = !!job && job?.status !== STATUS.DRAFT;
      setProductSelected((prevState) => ({
        ...prevState,
        data: [selection.category_name, [{ ...selection }]],
        isReadOnly,
        product: selection.category_name,
      }));
    },
    [selections]
  );

  const changeSelectionProductType = (
    selectionId,
    availableProduct,
    addOn = {},
    clearSelectionParent = null
  ) => {
    const currentSelection = selections.find((s) => s.id === selectionId);
    const newSelection = {
      ...currentSelection,
      ...pluck(availableProduct, [
        'category_name',
        'display_name',
        'product_id',
        'layers',
        'delivery_method',
      ]),
    };
    const index = _.findIndex(['id', selectionId], selections);
    let withNewSelections = [...selections].map((s, i) =>
      i === index ? newSelection : s
    );

    let addOnSelection = null;
    if (!isEmpty(addOn)) {
      addOnSelection = createNewSelection(
        { ...newSelection, ...addOn, parent_selection_id: newSelection.id },
        null
      );
      withNewSelections.push(addOnSelection);
    }

    // map and update add-on children
    const targetDataType = availableProduct.category_name;
    const isTargetStillAddOn = !!getAddOnConfig(targetDataType);

    if (!isTargetStillAddOn && NON_TRIPOD_DATA_TYPE.includes(targetDataType)) {
      withNewSelections = [...withNewSelections].filter(
        (selection) => selection.parent_selection_id !== selectionId
      );
    }

    const newSelections = withNewSelections.map((selection) => {
      let _selection = {
        ...selection,
        ...(clearSelectionParent === selection.id && {
          parent_selection_id: '',
        }),
      };

      if (_selection.parent_selection_id === selectionId) {
        // will trigger if changed product/shape/item is referenced by other shapes which is an add-on
        const add_on_category_name = selection.category_name.replace(
          currentSelection.category_name,
          targetDataType
        );

        const _coverage =
          coverage.find(
            ({ category_name }) => category_name === add_on_category_name
          ) || null;

        if (!_coverage) {
          return _selection;
        }

        const { product_id, category_name, display_name } = _coverage;
        return {
          ..._selection,
          product_id,
          category_name,
          display_name,
        };
      }

      return _selection;
    });

    setSelections(newSelections);

    const newestSelection = addOnSelection ?? newSelection;
    if (!isEmpty(newestSelection)) {
      setActiveSelectionId(newestSelection.id);
    }

    setProductSelectedOnMakeSelection(newestSelection);
    return newestSelection;
  };

  /**
   * Show/Hide a shape in the map
   * @param {string} selectionId
   * @param {boolean} closeDropdownOnSelect - passed from dropdown meatball(shape menu) to remain dropdown open on click
   */
  const toggleSelectionVisibility = useCallback(
    (selectionId) => {
      const data = _.find(['id', selectionId], selections);
      console.assert(data, 'selection not found');
      let isVisible;
      setSelections(
        selections.map((selection) => {
          const isCurrent = selection.id === selectionId;
          if (isCurrent) {
            isVisible = !selection.visible;
          }
          return {
            ...selection,
            visible: isCurrent ? isVisible : selection.visible,
          };
        })
      );
      if (!isVisible && selectionId === activeSelectionId) {
        makeSelectionActive(null);
        setGeometriesToLoad({});
      }
    },
    [activeSelectionId, makeSelectionActive, selections]
  );

  const toggleProductVisibility = useCallback(
    (isAllSelectionsVisible, product) => {
      let didActiveSelectionBecomeHidden = false;
      setSelections(
        selections.map((selection) => {
          if (selection.display_name !== product.display_name) {
            return { ...selection };
          }
          const newVisible = !isAllSelectionsVisible
            ? true
            : !selection.visible;
          if (activeSelection && activeSelection.id === selection.id) {
            didActiveSelectionBecomeHidden = !newVisible;
          }
          return {
            ...selection,
            visible: newVisible,
            focused: false,
          };
        }) || []
      );
      if (didActiveSelectionBecomeHidden) {
        setActiveSelectionId(null);
        setGeometriesToLoad({});
      }
    },
    [selections]
  );

  const deleteSelections = useCallback(
    (selectionsToDelete) => {
      if (selections.length === 1) {
        dispatch(clearBoundaries());
      }
      setSelections(differenceBy('id')(selections, selectionsToDelete));
      setHoveredData(null);
      setActiveSelectionId(null);
      setGeometries({});
      setGeometriesToLoad({});
    },
    [selections]
  );

  const numCartItems = selections.filter(
    (selection) => selection.category_name !== PRODUCT_TYPE.UNKNOWN
  ).length;

  /**
   * Duplicates a selection. Iff `makeDuplicatedSelectionActive` is true, make the duplicated selection active.
   */
  const duplicateSelection = (
    data,
    { makeDuplicatedSelectionActive = false }
  ) => {
    const duplicatedSelection = addSelection(
      { ...data, id: uuid(), name: undefined },
      null, // region
      true
    );
    if (makeDuplicatedSelectionActive) {
      setActiveSelectionId(duplicatedSelection.id);
    }
  };

  /**
   * @param {string} target
   * @param {object} sourceObject
   * @param {boolean} isForAll
   * @param {{target: string[]}} all e.g bim_exterior, bim_interior etc.
   */
  const createAddOnSelection = (
    target,
    sourceObject = {},
    isForAll = false,
    forAllConfig = {}
  ) => {
    const filtered = [];
    const getCoverage = (target) =>
      coverage.find(({ category_name }) => category_name === target);

    if (isForAll) {
      /**
       * Triggered from RHS - AddOnButton when state.showBimAddOnButton/state.show2DFeaturePlanButton is true
       * Create Add-on selections for all Tripod(interior/exterior) shapes in the map
       */
      forAllConfig.target.forEach((target) => {
        selections.forEach((selection) => {
          const { product_id, display_name } = getCoverage(target);
          const { DATA_TYPE } = getAddOnConfig(target);

          if (selection.category_name === DATA_TYPE) {
            filtered.push(
              createNewSelection({
                ...selection,
                category_name: target,
                display_name,
                product_id,
                parent_selection_id: selection.id,
              })
            );
          }
        });
      });
    } else {
      const { product_id, display_name } = getCoverage(target);
      const newSelection = addSelection(
        {
          ...sourceObject,
          category_name: target,
          display_name,
          product_id,
          parent_selection_id: sourceObject.id,
        },
        null,
        true
      );

      // make add on selected highlighted in Cart
      setProductSelectedOnMakeSelection(newSelection);
    }

    if (filtered.length) {
      const selectionNames = [...selections]
        .sort(sortByCreatedDate)
        .map((selection) => selection.name);
      renameSelections(selectionNames, filtered);

      const mergedWithNewSelections = [...selections, ...filtered].sort(
        sortByCategoryOrder
      );
      setSelections(mergedWithNewSelections);
    }
  };

  const incrementMapLoadCount = useCallback(() => {
    setMapLoadCount(mapLoadCount + 1);
  }, [mapLoadCount]);

  // fill in DATAT_TYPE e.g. exterior/interior when productType is an Add-on
  const activeSelectionDataType = useMemo(() => {
    if (!activeSelection) {
      return null;
    }
    const addOnConfig = getAddOnConfig(activeSelection.category_name);
    return addOnConfig?.DATA_TYPE || null;
  }, [activeSelection]);

  // Confirmation modal is shown with a product type that is changed has add-ons
  const showChangeProductTypeConfirmation = (
    productType,
    associatedAddOns,
    callback,
    removeFromCart = false,
    toDraft = false
  ) => {
    dispatch(
      showModal(UPDATE_SHAPES_MODAL, {
        onOk: callback,
        associatedAddOns,
        isNonTripodTarget: selectionIsNonTripod(productType),
        removeFromCart,
        toDraft,
      })
    );
  };

  const buyNowData = (job?.quote?.charges || []).filter(
    (charge) =>
      charge.details.delivery_method ===
      SMART_SELECTION_DELIVERY_METHOD.AUTOMATIC
  );

  return (
    <MapViewContext.Provider
      value={{
        state: {
          acceptTerms,
          activeSelectionId,
          activeSelection,
          address,
          currentMapState,
          currentMapStateRef,
          currentMapTypeId,
          geometries,
          geometriesRef,
          geometriesToLoad,
          googleMapsScriptStatus,
          hasLoadedJob,
          hasShadowOverlay,
          hoveredData,
          hoveredProductType,
          draftToCreate,
          inputAddress,
          isAddressInfoVisible,
          isHighResEnabled,
          isJobCreatedModalOpen,
          isMapVisible,
          defaultRegion,
          job,
          mapLoadCount,
          map,
          mapRef,
          mapManager,
          mapPlace,
          marqueeState,
          polygonState,
          polylineState,
          project,
          rectangleToDraw,
          requestMessage,
          mousePositionOnMap,
          selections: selectionsWithFocused,
          productAvailability,
          numCartItems,
          debouncedRegionSelection,
          coverage,
          coverageInRegionSelection,
          coverageInDefaultRegion,
          selectionProductAvailability,
          isCartEmpty,
          showProductModal,
          productSelected,
          coveragePerProduct,
          canShowAddOns,
          showAutomatic,
          showManual,
          hasAddOns,
          zoom: zoomState,
          mapHasProjection,
          isProductModalExpanded,
          showBimAddOnButton,
          show2DFeaturePlanButton,
          activeSelectionDataType,
          isCartLoading,
          buyNowData,
          jobCenter,
          viewportCenter,
          debouncedViewportCenter,
          hasMapFitToJobBounds,
          currentHighResLayerName,
          isStillQuoting,
          openedShapeMenu,
          shapeMenuModalRef,
        },
        actions: {
          setIsAddressInfoVisible,
          setMarqueeState,
          setPolygonState,
          setPolylineState,
          setAddress,
          setProject,
          setRequestMessage,
          setAcceptTerms,
          incrementMapLoadCount,
          setMap,
          setMapManager,
          setCurrentMapTypeId,
          setIsMapVisible,
          setDefaultRegion,
          setInputAddress,
          setSelections,
          setMousePositionOnMap,
          addSelection,
          addSelections,
          getDefaultRegion,
          makeSelectionActive,
          toggleSelectionVisibility,
          deleteSelections,
          changeSelectionProductType,
          setHoveredData,
          hoverProduct,
          unhoverProduct,
          setDraftToCreate,
          setHasShadowOverlay,
          setCurrentMapState,
          setGeometries,
          setGeometriesToLoad,
          setRectangleToDraw,
          setActiveSelectionId,
          setHasLoadedJob,
          setMapPlace,
          setProductAvailability,
          fitMapBoundsToJob,
          loadJob,
          duplicateSelection,
          setSelectionProductAvailability,
          setShowProductModal,
          setProductSelected,
          clearProductSelected,
          zoom: zoomActions,
          setMapHasProjection,
          setIsProductModalExpanded,
          setProductSelectedOnMakeSelection,
          setCoverageInRegionSelection,
          setCoverageInDefaultRegion,
          createAddOnSelection,
          addSelectionWithAddOn,
          showChangeProductTypeConfirmation,
          zoomToShapeExtents,
          toggleProductVisibility,
          setJobCenter,
          setViewportCenter,
          setHasMapFitToJobBounds,
          setCurrentHighResLayerName,
          setOpenedShapeMenu,
          closeShapeMenuModal,
        },
      }}
    >
      {children}
    </MapViewContext.Provider>
  );
}

MapViewProvider.propTypes = {
  children: PropTypes.element,
};
