import { format } from 'date-fns';

const DATE = 'dd MMM yyyy';
const TIME = 'h:mm aaaa';
const MONTH = 'MMM yyyy';

export const FORMAT = {
  DATE,
  TIME,
};

export const formatDate = (dateString) =>
  dateString && format(new Date(dateString), DATE);

export const formatTime = (dateString, customFormat = TIME) =>
  dateString && format(new Date(dateString), customFormat);

export const formatMonth = (dateString) =>
  dateString && format(new Date(dateString), MONTH);

export const getNowDate = () => new Date().toISOString();
