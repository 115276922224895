import React, { Fragment, useContext } from 'react';

import PropTypes from 'prop-types';
import { MapViewContext } from '../mapView/mapViewContext';
import Product from './Product';

export const ProductTableHeader = ({ headerText }) => {
  return (
    <div className='mb-2 mt-3'>
      <div className='d-flex justify-content-between center-content'>
        <h6>{headerText}</h6>
      </div>
    </div>
  );
};

ProductTableHeader.propTypes = {
  headerText: PropTypes.string,
};

const ProductTable = ({ product, chargesPerProduct, isReadOnly, index }) => {
  const { state } = useContext(MapViewContext);
  /**
   * productType - aerial, streetscape etc.
   * productCharges - contains all product shapes
   */
  const [productType, productCharges] = product;

  return (
    <Fragment key={`${productType}-${productCharges[0]?.id}`}>
      <Product
        product={productType}
        data={product}
        charges={{
          ...chargesPerProduct[product[1][0].display_name],
          currency: { ...state.job.quote.currency },
        }}
        isReadOnly={isReadOnly}
        index={index}
      />
    </Fragment>
  );
};

ProductTable.propTypes = {
  product: PropTypes.array,
  chargesPerProduct: PropTypes.object,
  isReadOnly: PropTypes.bool,
  index: PropTypes.number,
};

ProductTable.defaultProps = {
  isReadOnly: false,
};

export default ProductTable;
