import fp from 'lodash/fp';
import T from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown, Table } from 'react-bootstrap';
import { USER_TYPE } from '../../../constants/user';
import UserAvatar from '../../../pages/profile/UserAvatar';
import { hideModal, showModal } from '../../../redux/actions';
import text from '../../../text';
import { getProjectLatestJob } from '../../../utilities/project';
import { canGiveTrial, getUserType, isStaff } from '../../../utilities/user';
import MeatballDropdown from '../../dropdown/MeatballDropdown';
import { GIVE_USER_TRIAL_MODAL } from '../../modal/GiveUserTrialModal';
// import Table from '../Table';

const USER_TYPE_LABEL = {
  [USER_TYPE.FREE]: text('free'),
  [USER_TYPE.TRIAL]: text('trial'),
  [USER_TYPE.ESSENTIALS]: text('essentials'),
  [USER_TYPE.LARKI_SUPPORT]: text('larkiSupport'),
  [USER_TYPE.GOVERNMENT]: text('governmentFullWord'),
};

const InvitationTable = ({
  invitations,
  project,
  resendInvitation,
  removeProjectUser,
  updateUserProjectVisibility,
}) => {
  const dispatch = useDispatch();
  const job = getProjectLatestJob(project);
  const currentUser = useSelector((state) => state.profileReducer.userProfile);
  const groupedInvitations = fp.flow(
    fp.map((invite) => ({ ...invite, user_type: getUserType(invite.user) })),
    fp.groupBy('user_type')
  )(invitations);

  const showInvite = (invite) =>
    isStaff(currentUser.role) || !isStaff(invite.user.role);

  const isCurrentUser = (invite) => invite.user.id === currentUser.id;

  const showVisibilityMenu = (invite) =>
    isStaff(currentUser.role) && !isStaff(invite.user.role);

  const showHiddenIndicator = (invite) =>
    (isStaff(currentUser.role) && invite.visibility === 'staff') ||
    isStaff(invite.user.role);

  return (
    <Table className='Table InvitationTable' responsive>
      <thead>
        <tr>
          <th>{text('projectUsers')}</th>
          <th>{text('status')}</th>
          <th>{text('membership')}</th>
          <th className='ActionCell'>{text('actions')}</th>
        </tr>
      </thead>
      <tbody>
        {fp.flow(
          fp.mapValues((invites) => invites.filter(showInvite)),
          fp.pickBy((invites) => !fp.isEmpty(invites)), // `filter` for objects
          fp.map((invites) =>
            invites.map((invite, i) => (
              <tr key={invite.user.id}>
                <td className='UserNameCell'>
                  <div className='d-flex flex-row align-items-center'>
                    <UserAvatar profileImage={invite.user.profile_image} />
                    <p className='text-truncate m-0'>
                      {invite.user.full_name || invite.user.email}
                    </p>
                  </div>
                </td>
                <td className='StatusCell'>
                  {fp.capitalize(invite?.invite_status)}
                </td>
                <td className='MembershipCell'>
                  {USER_TYPE_LABEL[getUserType(invite.user)]}
                  {showHiddenIndicator(invite) && ` (${text('Hidden')})`}
                </td>
                <td className='ActionCell'>
                  <MeatballDropdown>
                    <Dropdown.Item
                      disabled={!(invite?.invite_status === 'pending')}
                      onClick={() => resendInvitation(invite?.user?.email)}
                    >
                      {text('resendInvitation')}
                    </Dropdown.Item>
                    {canGiveTrial(currentUser, invite.user) && (
                      <Dropdown.Item
                        onClick={() => {
                          dispatch(
                            showModal(GIVE_USER_TRIAL_MODAL, {
                              user: invite.user,
                              onHide: () => {
                                dispatch(hideModal(GIVE_USER_TRIAL_MODAL));
                              },
                            })
                          );
                        }}
                      >
                        {text('addTrial')}
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      disabled={job?.owner_user_id === invite.user.id}
                      onClick={() => removeProjectUser(project, invite)}
                    >
                      {isCurrentUser(invite, currentUser)
                        ? text('leaveProject')
                        : text('removeProjectUser')}
                    </Dropdown.Item>
                    {showVisibilityMenu(invite) && (
                      <Dropdown.Item
                        onClick={() => updateUserProjectVisibility(invite)}
                      >
                        {invite.visibility === 'public'
                          ? text('Hide')
                          : text('Show')}
                      </Dropdown.Item>
                    )}
                  </MeatballDropdown>
                </td>
              </tr>
            ))
          )
        )(groupedInvitations)}
      </tbody>
    </Table>
  );
};

InvitationTable.propTypes = {
  invitations: T.array.isRequired,
  project: T.object,
  resendInvitation: T.func.isRequired,
  removeProjectUser: T.func.isRequired,
};

export default InvitationTable;
