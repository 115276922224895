/* eslint-disable no-undef */
import * as amplitude from '@amplitude/analytics-browser';
import { isEmpty } from 'lodash/fp';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useLoadAmplitude = () => {
  const [hasLoaded, setHasLoaded] = useState(false);

  const { currentUser } = useSelector((state) => ({
    currentUser: state.profileReducer.userProfile,
  }));
  useEffect(() => {
    if (!isEmpty(currentUser) && process.env.AMPLITUDE_API_KEY && !hasLoaded) {
      amplitude.init(process.env.AMPLITUDE_API_KEY, currentUser.id, {
        defaultTracking: {
          sessions: true,
          pageViews: true,
          formInteractions: true,
          fileDownloads: true,
        },
        minIdLength: 1,
      });
      setHasLoaded(true);
    }
  }, [currentUser, hasLoaded]);
};

export default useLoadAmplitude;
