import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { AMPLITUDE_EVENTS } from '../../../constants/analytics';
import { amplitudeTrack } from '../../../utilities';
import HoverableOverlay from '../../hoverableOverlay';
import { DRAWING_ACTIONS, MAP_STATES } from './drawingControls';
import DrawingControlsTooltip from './drawingControlsTooltip';

const { SITE_ADDRESS_ENTERED } = AMPLITUDE_EVENTS;

const DrawingControlsButton = ({
  state,
  mapState,
  setMapState,
  sendDrawingState,
  icon,
  tooltip,
  isSelected,
  isDisabled,
  canDraw,
}) => {
  const [isHovered, setHovered] = useState(false);
  const [iconFilter, setIconFilter] = useState('');

  useEffect(() => {
    if (isSelected || (isHovered && !isDisabled)) {
      setIconFilter(
        'invert(48%) sepia(92%) saturate(669%) hue-rotate(122deg) brightness(95%) contrast(97%)'
      );
    } else {
      setIconFilter('');
    }
  }, [isSelected, isHovered, isDisabled]);

  const onMouseEnter = () => {
    if (!isDisabled) {
      setHovered(true);
    }
  };

  const onMouseLeave = () => {
    if (!isDisabled) {
      setHovered(false);
    }
  };

  return (
    <HoverableOverlay
      placement='right-start'
      overlay={!isDisabled ? <DrawingControlsTooltip text={tooltip} /> : <></>}
    >
      <div
        className={classnames('draw-button', { disabled: isDisabled })}
        onClick={() => {
          if (!isDisabled) {
            if (state === mapState) {
              setMapState(MAP_STATES.NONE);
              sendDrawingState({ type: DRAWING_ACTIONS.STOP_DRAWING });
            } else {
              setMapState(state);
              if (canDraw) {
                sendDrawingState({ type: DRAWING_ACTIONS.DRAW });
              }
            }
            amplitudeTrack(SITE_ADDRESS_ENTERED.EVENT, {
              action: SITE_ADDRESS_ENTERED.CLICK_ON_A_TOOL,
            });
          }
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <img
          className='draw-icon'
          style={{
            filter: iconFilter,
          }}
          src={icon}
        />
      </div>
    </HoverableOverlay>
  );
};

DrawingControlsButton.propTypes = {
  state: PropTypes.string.isRequired,
  mapState: PropTypes.string.isRequired,
  setMapState: PropTypes.func.isRequired,
  sendDrawingState: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  canDraw: PropTypes.bool,
};

export default DrawingControlsButton;
