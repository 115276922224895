import React from 'react';
import classnames from 'classnames';

const BottomContainer = (props) => {
  const { children, className } = props;
  return (
    <div className={classnames('BottomContainer', { [className]: className })}>
      {children}
    </div>
  );
};

export default BottomContainer;
