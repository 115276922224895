import React from 'react';
import cn from 'classnames';

import { components } from 'react-select';

import Icon from '../../icon/Icon';

const Option = (props) => {
  return props.data?.__isNew__ ? (
    <div
      className={cn('CreateOption center-content', {
        isFocused: props.isFocused,
      })}
      {...props.innerProps}
    >
      <Icon icon='plus' />
      {props.data.label}
    </div>
  ) : (
    <components.Option {...props} />
  );
};

export default Option;
