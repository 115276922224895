import React from 'react';
import cn from 'classnames';
import { FormControl, FormLabel } from 'react-bootstrap';
import T from 'prop-types';
import Icon from '../icon/Icon';

const Control = ({
  isSuccessful,
  isInputWidthAuto,
  label,
  isFailed,
  validationError,
  iconProps,
  required,
  onMouseEnter,
  className,
  ...rest
}) => {
  return (
    <div
      className={cn('Control', { [className]: className })}
      onMouseEnter={onMouseEnter}
    >
      {label && (
        <FormLabel className='Control-Label'>
          {label}
          {required && '*'}
        </FormLabel>
      )}
      <div className='Control-Container'>
        <FormControl
          className={cn('Control-Input', {
            isFailed,
            hasIcon: iconProps?.icon || isSuccessful,
            'w-auto': isInputWidthAuto,
          })}
          required={required}
          {...rest}
        />
        <Icon
          icon={cn({
            confirm: isSuccessful,
            [iconProps?.icon]: iconProps?.icon,
          })}
          {...iconProps}
        />
      </div>
      <p className={cn('Control-Feedback', { isFailed })}>{validationError}</p>
    </div>
  );
};

Control.propTypes = {
  isErroneous: T.bool,
  isSuccessful: T.bool,
  iconProps: T.shape(),
  isFailed: T.bool,
  label: T.string,
  validationError: T.string,
  validationSchema: T.shape(),
  required: T.bool,
  onMouseEnter: T.func,
};

export default Control;
