import React from 'react';
import { useBoolean } from 'react-use';
import Control from './Control';

const PasswordFormControl = (props) => {
  const [view, toggle] = useBoolean(false);
  return (
    <Control
    iconProps={{
      onClick: toggle,
      icon: view ? 'eye-slash' : 'eye',
    }}
    type={view ? 'text' : 'password'}
    {...props}
    />
  );
};

export default PasswordFormControl;
