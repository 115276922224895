import * as turf from '@turf/turf';

/**
 * path: [[lng, lat], [lng, lat], ...]
 */
export const isPathClockwiseNoGM = (path) => {
  let area = 0;

  for (let i = 0; i < path.length; i++) {
    let j = (i + 1) % path.length;
    area += path[i][1] * path[j][0];
    area -= path[j][1] * path[i][0];
  }

  return area > 0;
};

/**
 * path: [[lat, lng], [lat, lng], ...]
 */
export function computeSurroundingPolygonFromPolylineNoGM(path) {
  const LINE_WIDTH_IN_METERS = 20;

  let surroundingPolygonComponents = [];
  for (let i = 0; i < path.length - 1; i++) {
    const p1 = turf.point([path[i][1], path[i][0]]);
    const p2 = turf.point([path[i + 1][1], path[i + 1][0]]);

    const bearing = turf.bearing(p1, p2);
    const p0a = turf.destination(p1, LINE_WIDTH_IN_METERS, bearing + 90, {
      units: 'meters',
    });
    const p0b = turf.destination(p1, LINE_WIDTH_IN_METERS, bearing - 90, {
      units: 'meters',
    });
    const p1a = turf.destination(p2, LINE_WIDTH_IN_METERS, bearing + 90, {
      units: 'meters',
    });
    const p1b = turf.destination(p2, LINE_WIDTH_IN_METERS, bearing - 90, {
      units: 'meters',
    });

    const surroundingRectangle = turf.polygon([
      [
        turf.getCoord(p0a),
        turf.getCoord(p0b),
        turf.getCoord(p1b),
        turf.getCoord(p1a),
        turf.getCoord(p0a),
      ],
    ]);
    surroundingPolygonComponents.push(surroundingRectangle);

    if (i > 0 && i < path.length - 1) {
      const p0 = turf.point([path[i - 1][1], path[i - 1][0]]);
      const priorBearing = turf.bearing(p0, p1);

      const a0 = priorBearing - bearing < 0 ? priorBearing - 90 : bearing + 90;
      const a1 = priorBearing - bearing < 0 ? bearing - 90 : priorBearing + 90;

      const controlPointSector = turf.sector(p1, LINE_WIDTH_IN_METERS, a0, a1, {
        units: 'meters',
      });
      surroundingPolygonComponents.push(controlPointSector);
    }
  }

  let surroundingPolygon = turf.polygon([]);
  for (let i = 0; i < surroundingPolygonComponents.length; i++) {
    surroundingPolygon = turf.union(
      surroundingPolygon,
      surroundingPolygonComponents[i]
    );
  }

  // apply a fudge factor to make sure segments overlap and merge with rectangles
  surroundingPolygon = turf.buffer(surroundingPolygon, 0.001, {
    units: 'meters',
  });

  return surroundingPolygon.geometry.coordinates;
}

export const selectionToGeoJsonNoGM = (geometry) => {
  var coordinates = [];

  if (geometry.region.type === 'Polygon') {
    // the latitude & longitude pairs need to be reversed to be valid GeoJSON
    coordinates.push(
      geometry.region.include.map((point) => [point[1], point[0]])
    );
    geometry.region.exclude?.forEach((excludedRegion) => {
      coordinates.push(excludedRegion.map((point) => [point[1], point[0]]));
    });
  } else if (geometry.region.type === 'Rectangle') {
    if (geometry.region.include.length === 2) {
      const path = [];
      path.push([geometry.region.include[0][1], geometry.region.include[0][0]]);
      path.push([geometry.region.include[1][1], geometry.region.include[0][0]]);
      path.push([geometry.region.include[1][1], geometry.region.include[1][0]]);
      path.push([geometry.region.include[0][1], geometry.region.include[1][0]]);
      path.push([geometry.region.include[0][1], geometry.region.include[0][0]]);
      coordinates.push(path);
    } else {
      coordinates.push(
        geometry.region.include.map((point) => [point[1], point[0]])
      );
    }

    geometry.region.exclude?.forEach((excludedRegion) => {
      coordinates.push(excludedRegion.map((point) => [point[1], point[0]]));
    });
  } else if (geometry.region.type === 'Polyline') {
    const path = geometry.region.include;
    const polygon = computeSurroundingPolygonFromPolylineNoGM(
      path,
      geometry.region.width
    );
    coordinates = polygon;
  }

  // make sure the last pair equals the first pair to close the polygon
  coordinates.forEach((coordinatePoints, index, currentCoordinates) => {
    if (
      coordinatePoints.length > 1 &&
      (coordinatePoints[0][0] !==
        coordinatePoints[coordinatePoints.length - 1][0] ||
        coordinatePoints[0][1] !==
          coordinatePoints[coordinatePoints.length - 1][1])
    ) {
      currentCoordinates[index].push(coordinatePoints[0]);
    }
  });

  // check winding order of path
  // first polygon should be clockwise
  // subsequent ones should be anticlockwise
  coordinates.forEach((polygonPath, index, currentCoordinates) => {
    if (
      (index === 0 && isPathClockwiseNoGM(polygonPath)) ||
      (index > 0 && !isPathClockwiseNoGM(polygonPath))
    ) {
      currentCoordinates[index] = polygonPath.reverse();
    }
  });

  return {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Polygon',
      coordinates,
    },
  };
};
