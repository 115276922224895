import React from 'react';
import { Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import text from '../../text';

const VersioningAlert = () => {
  const outdated = useSelector((state) => state.alerts.outdated);

  if (!outdated) {
    return null;
  }

  const onReload = (event) => {
    event.preventDefault();
    window.location.reload();
  };

  return (
    <Alert show variant='warning' dismissible={false}>
      {text('versionOutdated', {
        link: (value) => (
          <Alert.Link key='versionOutdated' onClick={onReload}>
            {value}
          </Alert.Link>
        ),
      })}
    </Alert>
  );
};

export default VersioningAlert;
