import React from 'react';
import { useSelector } from 'react-redux';
import SaveDraftModal, { SAVE_DRAFT_MODAL } from './SaveDraftModal';

/**
 * This modal manager includes state needed by some of the components
 * which access the mapView context
 * this should be marged to the modal manager once we have one global state
 * instead of the multiple states mapViewContext and redux state
 */
const MapModalManager = () => {
  const { id, modalProps } = useSelector((state) => state.modal);

  switch (id) {
    case SAVE_DRAFT_MODAL:
      return <SaveDraftModal {...modalProps} />;
    default:
      return null;
  }
};

export default MapModalManager;
