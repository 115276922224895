import { larkiApi } from '../../utilities/api';
import { alertConstants } from '../constants';
import { productConstants } from '../constants/products';

export const getProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: productConstants.FETCH_PRODUCTS_LOADING,
    });

    const {
      data: { products },
    } = await larkiApi.get('/product');

    dispatch({
      type: productConstants.FETCH_PRODUCTS_SUCCESS,
      payload: products,
    });
  } catch (error) {
    dispatch({
      type: productConstants.FETCH_PRODUCTS_FAIL,
    });

    dispatch({
      type: alertConstants.ALERT_ERROR,
      payload: { message: error.response.data.message },
    });
  }
};
