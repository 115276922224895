import _ from 'lodash/fp';
import { useCallback, useMemo, useReducer } from 'react';

export const HOVERED_PRODUCT_ACTIONS = {
  HOVER_PRODUCT: 'HOVER_PRODUCT',
  UNHOVER_PRODUCT: 'UNHOVER_PRODUCT',
};

const hoveredProductReducer = (state, action) => {
  switch (action.type) {
    case HOVERED_PRODUCT_ACTIONS.HOVER_PRODUCT: {
      return _.set([action.product], true)(state);
    }
    case HOVERED_PRODUCT_ACTIONS.UNHOVER_PRODUCT: {
      return _.set([action.product], false)(state);
    }
    default:
      throw new Error('unknown type');
  }
};

const useHoveredProduct = () => {
  const [state, dispatch] = useReducer(hoveredProductReducer, {});
  const hoverProduct = useCallback(
    (product) =>
      dispatch({ type: HOVERED_PRODUCT_ACTIONS.HOVER_PRODUCT, product }),
    []
  );
  const unhoverProduct = useCallback(
    (product) =>
      dispatch({ type: HOVERED_PRODUCT_ACTIONS.UNHOVER_PRODUCT, product }),
    []
  );
  const currentlyHoveredProduct = useMemo(() => {
    return _.keys(state).find((k) => !!state[k]);
  }, [state]);
  return { hoverProduct, unhoverProduct, currentlyHoveredProduct };
};

export default useHoveredProduct;
