/* eslint-disable react/prop-types */

import React, { forwardRef, useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Icon from '../../icon/Icon';
import ProductIndicator from '../../productMenu/ProductIndicator';
import ProductMenu from '../../productMenu/ProductMenu';

const LayerProductMenu = forwardRef(({ data }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <Dropdown
      ref={ref}
      show={isOpen}
      onToggle={handleToggle}
      className='layer-product-menu'
    >
      <Dropdown.Toggle variant='outline-primary' bsPrefix='toggle'>
        <ProductIndicator
          categoryName={data.category_name}
          className={data.category_name}
        />
        <Icon icon='caret-down' className='toggle-icon' />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <ProductMenu data={data} />
      </Dropdown.Menu>
    </Dropdown>
  );
});

LayerProductMenu.displayName = 'LayerProductMenu';

export default LayerProductMenu;
