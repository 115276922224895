import { useTour } from '@reactour/tour';
import { isEmpty } from 'lodash';
import T from 'prop-types';
import React, { useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { TOUR_MODAL_START_CONFIG } from '../../pages/dashboard/components/onboarding/tour';
import { hideModal, toggleRightMenuVisibility } from '../../redux/actions';
import { clearTourSelected, putTourStatus } from '../../redux/actions/tour';
import text from '../../text';
import { AMPLITUDE_EVENTS } from '../../constants/analytics';
import { amplitudeTrack } from '../../utilities';
export const TOUR_DONE_MODAL = 'TOUR_DONE_MODAL';

const { SITE_TOUR_ACTIVITY } = AMPLITUDE_EVENTS;
export default function TourDoneModal({ show }) {
  const dispatch = useDispatch();
  const { setSteps, setIsOpen } = useTour();
  const tourSelected = useSelector((state) => state.tourReducer.tourSelected);

  const handleHide = () => {
    amplitudeTrack(SITE_TOUR_ACTIVITY.EVENT, {
      action: SITE_TOUR_ACTIVITY[tourSelected]['COMPLETED'],
    });
    dispatch(putTourStatus(tourSelected));
    dispatch(hideModal(TOUR_DONE_MODAL));
    dispatch(toggleRightMenuVisibility(false));
    dispatch(clearTourSelected());
    setSteps([]);
    setIsOpen(false);
  };

  const MODAL_CONFIG = useMemo(() => {
    return TOUR_MODAL_START_CONFIG[tourSelected];
  }, [tourSelected]);

  return (
    !isEmpty(MODAL_CONFIG) && (
      <Modal
        centered
        className='TourDoneModal'
        onHide={handleHide}
        show={show}
        backdrop='static'
      >
        <Modal.Header className='centered' closeButton>
          {/* set default width and height(149px) same w/ gif height
           * to avoid element movement/height adjustment when
           * gif is not loaded immediately */}
          <img
            style={{
              height: '149px',
              width: '149px',
            }}
            src='/public/img/Congratulations.gif'
            alt='Image not found'
          />
        </Modal.Header>
        <Modal.Body className='centered'>
          <h3 className='green-1'>{text('congratulations')}!</h3>
          <p className='mt-2 mb-0' style={{ fontSize: 16 }}>
            {MODAL_CONFIG.done}
          </p>
        </Modal.Body>
        <Modal.Footer className='flex-column justify-content-center'>
          <Button onClick={handleHide}>{text('letsPlay')}</Button>
        </Modal.Footer>
      </Modal>
    )
  );
}

TourDoneModal.propTypes = {
  show: T.bool,
};
