import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { listProjectActivities } from '../../../redux/actions/activity';
import text from '../../../text';
import { client } from '../../../utilities/api';
import UpdateProductForm from '../../form/updateProductForm/UpdateProductForm';

export const UPDATE_PRODUCT_MODAL = 'UPDATE_PRODUCT_MODAL';

const UpdateProductModal = (props) => {
  const { show, job, onHide, ...restProps } = props;
  const dispatch = useDispatch();
  const [currentJob, setCurrentJob] = useState(job);
  const [, getJob] = useAsyncFn(async (id) => {
    const { data } = await client.getJobById(id);
    return data;
  });

  const [updateJobDetailedStatusState, updateJobDetailedStatus] = useAsyncFn(
    async (job, detailedStatus, sendNotification) => {
      const { data } = await client.updateJobDetailedStatus(
        job.id,
        detailedStatus,
        sendNotification
      );

      dispatch(listProjectActivities(job?.project.id));
      setCurrentJob(data);
      onHide();
    }
  );

  useEffect(async () => {
    if (!job?.detailed_status && job?.id) {
      const data = await getJob(job?.id);
      setCurrentJob(data);
    }
  }, [job]);

  return (
    <Modal
      centered
      className='UpdateProductModal'
      show={show}
      onHide={onHide}
      {...restProps}
    >
      <Modal.Header className='align-end' closeButton>
        <h3 className='secondary'>{text('updateProductStatus')}</h3>
        <h6>{job?.project?.name}</h6>
        <h6>{`${text('order')}: ${job?.id}`}</h6>
      </Modal.Header>
      <UpdateProductForm
        submitState={updateJobDetailedStatusState}
        job={currentJob}
        onSubmit={(detailedStatus, sendNotification) =>
          updateJobDetailedStatus(job, detailedStatus, sendNotification)
        }
        onCancel={onHide}
      />
    </Modal>
  );
};

export default UpdateProductModal;
