import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Page from '../../components/page/Page';
import WhiteContainer from '../../components/containers/WhiteContainer';
import text from '../../text';
import Button from '../../components/button/Button';
import routes from '../../routes/constants';
import { alertConstants } from '../../redux/constants/alert';

const AssignedPaymentStatus = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.profileReducer.userProfile,
  }));
  const payerEmail = history.location.state.payerEmail;
  const payerName = history.location.state.payerName;
  const isMembership = history.location.state.isMembership;
  useEffect(() => {
    if (payerEmail && payerName) {
      dispatch({
        type: alertConstants.ALERT_INFO,
        payload: {
          message: text(
            isMembership ? 'requestSentSuccessfully' : 'quoteSentSuccessfully'
          ),
          icon: 'envelope-with-tick',
          dismissible: true,
        },
      });
    }
  }, []);

  return (
    <Page className='PaymentStatus PageCenter'>
      <WhiteContainer className='p-5 '>
        <WhiteContainer.Header
          title={text(isMembership ? 'requestEmailed' : 'quoteEmailed')}
        />
        <div className='text-center mb-5'>
          <div>
            {text(isMembership ? 'payerHasBeenSentRequest' : 'payerHasBeenSentQuote', {
              payerName,
              payerEmail: <p className='font-weight-bold m-0'>{payerEmail}</p>,
            })}
          </div>
          <div>
            {text('whenPaidNotified', {
              userEmail: (
                <p className='font-weight-bold m-0'>{currentUser.email}</p>
              ),
            })}
          </div>
        </div>
        <div className='d-flex justify-content-center'>
          <Button onClick={() => history.push(routes.dashboard)}>
            {text('goToDashboard')}
          </Button>
        </div>
      </WhiteContainer>
    </Page>
  );
};

export default AssignedPaymentStatus;
