import classNames from 'classnames';
import { capitalize, isObject } from 'lodash';
import _ from 'lodash/fp';
import T from 'prop-types';
import React, { useContext, useMemo, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import {
  BUY_NOW_DATA_TYPES,
  PRODUCT_TYPE,
} from '../../../../constants/product';
import text from '../../../../text';
import { IconWrapper } from '../../../RightMenu/Product';
import SimpleDataRow from '../../../RightMenu/SimpleDataRow';
import { MapViewContext } from '../../../mapView/mapViewContext';
import ProductTooltip from '../../../mapView/productTooltip/ProductTooltip';
import DataTable from '../../../table/dataTable/DataTable';
import ShapeMenuModal from '../../ShapeMenuModal';
import useProduct from '../hooks/useProduct';
import CloseButton from './CloseButton';
import WithLoader from './WithLoader';
import Preview, { showPreview } from './Preview';

const Body = ({ isHeaderShown }) => {
  const { state, actions } = useContext(MapViewContext);
  const { shapeMenuModalRef, openedShapeMenu } = state;
  const rowRefs = useRef([]);
  const {
    charges,
    age,
    totalArea: productTotalArea,
    shapesUnionArea,
    shapes,
    price,
    isEssentialUser,
    isFreeOrTrial,
    hasMemberDiscount,
    memberDiscount,
    priceWithSalesHelp,
    productType,
    isReadOnly,
    isStaff,
  } = useProduct();

  const isFreeUser = !isEssentialUser;
  const priceSalesHelpTemplate = isObject(priceWithSalesHelp) &&
    priceWithSalesHelp.value > 0 && (
      <div
        className={classNames('discount', {
          'mt-1': isEssentialUser,
          'mb-1': isFreeUser,
        })}
      >
        <span
          className={classNames({
            'text-strikethrough': isFreeUser,
          })}
        >
          {isFreeUser
            ? text('salesHelp', {
                amount: priceWithSalesHelp.total,
              })
            : capitalize(text('buyNow'))}
        </span>
        {', '}
        <span
          className={classNames({
            'text-strikethrough': isEssentialUser,
          })}
        >
          {isFreeUser
            ? text('savingAmount', {
                saving: priceWithSalesHelp.saved,
              })
            : `or ${text('salesHelp', {
                amount: priceWithSalesHelp.total,
              })}`}
        </span>
      </div>
    );

  const shapeMenuIsOpened = useMemo(() => {
    return openedShapeMenu.data && shapeMenuModalRef;
  }, [openedShapeMenu, shapeMenuModalRef]);

  const handleSetOpenedShapeMenu = (data, index) => {
    if (rowRefs.current[index]) {
      actions.setOpenedShapeMenu((prev) => ({
        ...prev,
        data: { ...data, index },
        prevCategoryName: data.category_name,
      }));
      shapeMenuModalRef.current = rowRefs.current[index];
    }
  };
  return (
    <Modal.Body
      className={classNames('pt-0 px-0', {
        'pb-4': !state.isProductModalExpanded,
      })}
    >
      <div
        className={classNames(
          'd-flex flex-row product align-items-center p-3',
          productType
        )}
      >
        <IconWrapper icon={productType} className='mr-1' />
        <div className='text-truncate' style={{ width: '100%' }}>
          {text(`${productType}3D`)}
        </div>
        <div className='d-flex flex-row'>
          <ProductTooltip productType={productType} placement='top' />
          {!isHeaderShown && <CloseButton />}
        </div>
      </div>

      <div className='px-3 pt-3 overflow-auto' style={{ maxHeight: '60vh' }}>
        <div
          className={classNames('d-flex flex-row', {
            'pb-1': isObject(priceWithSalesHelp),
          })}
        >
          <WithLoader hasCharges={!!charges}>
            <div className={classNames('price')}>{price.formatted}</div>
          </WithLoader>

          <div
            className={classNames(
              'grey-1 font-weight-bold font-12 d-flex flex-row flex-fill align-items-center justify-content-end'
            )}
          >
            {text('productAreaTotal', {
              delimiter: ':',
              productTotalArea: (
                <WithLoader
                  key={productType}
                  hasCharges={!!charges}
                  showLoaderText={false}
                  className='ml-3'
                  fullWidth={false}
                >
                  <span
                    key={new Date().getTime()}
                    className={`${productType}-product-area mb-0 ml-1 px-1 font-14`}
                  >
                    {productTotalArea.formatted}
                  </span>
                </WithLoader>
              ),
            })}
          </div>
        </div>

        <hr className='mx-0 emphasis' />

        {showPreview(productType) ? (
          <Preview.Thumbnail productType={productType} />
        ) : null}

        {isFreeUser ? priceSalesHelpTemplate : null}

        {/* SHOW MEMBERSHIP DISCOUNT IF NOT MEMBER */}
        <WithLoader hasCharges={!!charges}>
          {(isFreeOrTrial && hasMemberDiscount) || isStaff ? (
            <div
              className={classNames('discount', {
                'font-pink': !isEssentialUser || isStaff,
              })}
            >
              {memberDiscount}{' '}
            </div>
          ) : null}
        </WithLoader>

        {isEssentialUser ? priceSalesHelpTemplate : null}

        {state.isProductModalExpanded ? (
          <>
            {hasMemberDiscount ? <hr className='mx-0' /> : null}
            <DataTable className='product-selected-shapes mb-0'>
              <tbody>
                <tr>
                  <td className='pointCloudColumn' />
                  <td
                    colSpan={3}
                    className='areaColumn product-area text-left pl-3'
                  >
                    <span className='border-bottom'>
                      {`${text('productArea')}s`}
                    </span>
                  </td>
                </tr>
                {shapes?.map((selection, index) => {
                  const coverage = _.find(['selection_id', selection.id])(
                    state.coveragePerProduct[productType]
                  );
                  return (
                    <SimpleDataRow
                      key={selection.id}
                      data={selection}
                      coverage={coverage}
                      index={index}
                      isReadOnly={isReadOnly}
                      useMeatballDropdown={false}
                      showClearWhenShapeSelected={false}
                      ref={(el) => {
                        if (
                          openedShapeMenu.data &&
                          openedShapeMenu.data.id === selection.id
                        ) {
                          // if ShapeMenuModal is already opened and type is changed
                          // set the ref to the new element created
                          // and stay opened based on closeDropdownOnSelect value
                          shapeMenuModalRef.current = el;
                        }
                        rowRefs.current[index] = el;
                      }}
                      handleSetOpenedShapeMenu={handleSetOpenedShapeMenu}
                    />
                  );
                })}
              </tbody>
            </DataTable>

            {/* SHAPES UNION TOTAL */}
            {shapesUnionArea.value > 0 && (
              <>
                <div className='grey-3 font-12 mt-3'>
                  {text('shapesUnion', {
                    union: ![
                      PRODUCT_TYPE.INTERIOR,
                      PRODUCT_TYPE.BIM_INTERIOR,
                      PRODUCT_TYPE.TWOD_PLAN_INTERIOR,
                    ].includes(productType)
                      ? '(union) '
                      : '',
                    shapesUnionArea: shapesUnionArea.formatted,
                  })}
                </div>
                <hr className='mx-0' />
              </>
            )}

            <div
              className={classNames('d-flex flex-column', {
                'mt-3': shapesUnionArea.value <= 0,
              })}
            >
              <div className='grey-3 font-12'>{age}</div>
              <div
                className='grey-2'
                style={{
                  fontSize: 12,
                }}
              >
                {text('turnAroundTime', {
                  eta: BUY_NOW_DATA_TYPES.includes(productType)
                    ? '2 hours'
                    : '2 weeks',
                })}
              </div>
            </div>
          </>
        ) : null}
      </div>

      {shapeMenuIsOpened ? (
        <ShapeMenuModal
          ref={shapeMenuModalRef}
          shapeData={openedShapeMenu.data}
          shapeName={
            openedShapeMenu.data.name ??
            `Shape ${openedShapeMenu.data.index + 1}`
          }
        />
      ) : null}
    </Modal.Body>
  );
};

Body.propTypes = {
  isHeaderShown: T.bool,
};

export default Body;
