import { orderBy } from 'lodash';
import flowFP from 'lodash/fp/flow';
import setFP from 'lodash/fp/set';
import { profileConstants } from '../constants';
import { DEFAULT, LOADING, SUCCESS } from '../stateTools';

const defaultProfileState = {
  userProfile: {},
  isUserLoggedIn: false,
  userProfileState: 'idle',
  updateUserProfileState: DEFAULT,
  userCards: {
    isLoading: false,
    value: undefined,
  },
  companyProfile: {},
  updateCompanyProfileState: DEFAULT,
  membershipPrices: {
    isLoading: false,
    value: undefined,
  },
  membershipReceipt: {
    isLoading: false,
    value: undefined,
  },
  membershipDetails: {
    isLoading: false,
    value: undefined,
  },
  upsertMembership: {
    isLoading: false,
  },
  pretendType: null,
};

const profileReducer = (state = defaultProfileState, action) => {
  switch (action.type) {
    case profileConstants.FETCH_USER_DETAILS_LOADING:
      return { ...state, userProfileState: 'loading' };
    case profileConstants.FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userProfileState: 'success',
        userProfile: action.userProfile,
        isUserLoggedIn: true,
      };
    case profileConstants.FETCH_USER_DETAILS_FAILURE:
      return {
        ...state,
        userProfileState: 'error',
      };
    case profileConstants.UPDATE_USER_DETAILS_LOADING:
      return { ...state, updateUserProfileState: LOADING };
    case profileConstants.UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userProfile: action.userProfile,
        updateUserProfileState: SUCCESS,
      };
    case profileConstants.RESET_USER_FORM:
      return {
        ...state,
        updateUserProfileState: DEFAULT,
      };
    case profileConstants.UPDATE_USER_DETAILS_ERROR:
      return {
        ...state,
        userProfileState: 'error',
      };
    case profileConstants.SET_COMPANY_DETAILS:
      return { ...state, companyProfile: action.companyProfile };
    case profileConstants.UPDATE_COMPANY_DETAILS_LOADING:
      return { ...state, updateCompanyProfileState: LOADING };
    case profileConstants.UPDATE_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        companyProfile: action.companyProfile,
        updateCompanyProfileState: SUCCESS,
      };
    case profileConstants.UPDATE_COMPANY_DETAILS_ERROR:
      return {
        ...state,
        companyProfileState: 'error',
      };
    case profileConstants.RESET_COMPANY_FORM:
      return {
        ...state,
        updateCompanyProfileState: DEFAULT,
      };
    case profileConstants.UPDATE_MAP_TYPE:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          map_type: action.payload,
        },
      };
    case profileConstants.LIST_USER_CARDS: {
      return flowFP([setFP(['userCards', 'isLoading'], true)])(state);
    }
    case profileConstants.LIST_USER_CARDS_SUCCESS: {
      return flowFP([
        setFP(['userCards', 'isLoading'], false),
        setFP(
          ['userCards', 'value'],
          orderBy(
            action.payload,
            ['default_payment_method', 'name'],
            ['desc', 'asc']
          )
        ),
      ])(state);
    }
    case profileConstants.GET_MEMBERSHIP_PRICES_LOADING: {
      return flowFP([setFP(['membershipPrices', 'isLoading'], true)])(state);
    }
    case profileConstants.GET_MEMBERSHIP_PRICES_SUCCESS: {
      return flowFP([
        setFP(['membershipPrices', 'isLoading'], false),
        setFP(['membershipPrices', 'value'], action.payload),
      ])(state);
    }
    case profileConstants.GET_MEMBERSHIP_RECEIPT: {
      return flowFP([setFP(['membershipReceipt', 'isLoading'], true)])(state);
    }
    case profileConstants.GET_MEMBERSHIP_RECEIPT_SUCCESS: {
      return flowFP([
        setFP(['membershipReceipt', 'isLoading'], false),
        setFP(['membershipReceipt', 'value'], action.payload),
      ])(state);
    }
    case profileConstants.REFRESH_CUSTOMER_SUCCESS: {
      return flowFP([setFP(['userProfile', 'customer'], action.payload)])(
        state
      );
    }
    case profileConstants.GET_MEMBERSHIP_DETAILS: {
      return flowFP([setFP(['membershipDetails', 'isLoading'], true)])(state);
    }
    case profileConstants.GET_MEMBERSHIP_DETAILS_SUCCESS: {
      return flowFP([
        setFP(['membershipDetails', 'isLoading'], false),
        setFP(['membershipDetails', 'value'], action.payload),
      ])(state);
    }
    case profileConstants.UPSERT_USER_MEMBERSHIP: {
      return flowFP([setFP(['upsertMembership', 'isLoading'], true)])(state);
    }
    case profileConstants.UPSERT_USER_MEMBERSHIP_SUCCESS: {
      return flowFP([
        setFP(['upsertMembership', 'isLoading'], false),
        setFP(
          ['userProfile', 'customer', 'memberships', 0],
          action.payload.membership
        ),
      ])(state);
    }
    case profileConstants.UPSERT_USER_MEMBERSHIP_DONE: {
      return flowFP([setFP(['upsertMembership', 'isLoading'], false)])(state);
    }
    case profileConstants.SET_HAS_USED_TRIAL: {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          has_used_trial: action.payload,
        },
      };
    }
    case profileConstants.SET_PRETEND_TYPE:
      return setFP('pretendType', action.payload)(state);
    default:
      return state;
  }
};

export default profileReducer;
