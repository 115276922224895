import classNames from 'classnames';
import React from 'react';

const OnboardingTile = ({ icon, selectedIcon, active, onClick }) => (
  <div
    className={classNames('onboarding-tile', {
      active,
    })}
    onClick={onClick}
  >
    <img src={active ? selectedIcon : icon} />
  </div>
);

export default OnboardingTile;
