import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getJob } from '../redux/actions';

/**
 * Sets `job` in the Redux store to the job with the ID `jobId`, if `jobId` is not in the URL, this hook does nothing
 */
const useCurrentJob = () => {
  const { jobId } = useParams();
  const { job } = useSelector((state) => state.jobsReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!jobId) {
      return;
    }
    if (job && job.id === jobId) {
      return;
    }
    dispatch(getJob(jobId));
  }, [jobId]);
  return job;
};

export default useCurrentJob;
