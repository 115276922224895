import React from 'react';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CART_TABLE } from '../../constants/table';
import useBreakpoint from '../../hooks/useBreakpoint';
import Icon from '../icon/Icon';

const { COLUMN } = CART_TABLE;

const OtherChargeRow = ({ description, amount, infoMessage, isReadOnly }) => {
  const isEnabledForBreakpoint = useBreakpoint(['md', 'lg', 'xl']);

  return (
    <tr className='other-charges-row'>
      <td className='p-0 pb-2'>
        <Card className=''>
          <Card.Body>
            <div className='product-row d-flex flex-row other-charges-row justify-content-between align-items-center'>
              <div className='other-charge-text d-flex flex-row align-items-center font-12'>
                {description}
                {infoMessage && (
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement='top'
                    overlay={
                      <Tooltip id='other-charge-tooltip'>{infoMessage}</Tooltip>
                    }
                  >
                    <Icon
                      icon='question'
                      className='other-charge-icon'
                      style={{ pointerEvents: 'auto' }}
                    />
                  </OverlayTrigger>
                )}
              </div>

              <div className='other-charge-value font-12 font-weight-normal'>
                {amount}
              </div>
            </div>
          </Card.Body>
        </Card>
      </td>
    </tr>
  );
};

export default OtherChargeRow;
