import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Button from '../../../components/button/Button';
import Icon from '../../../components/icon/Icon';
import Page from '../../../components/page/Page';
import { MEMBERSHIP } from '../../../constants/membership';
import { alertWarning, setMembershipPriceId } from '../../../redux/actions';
import text from '../../../text';
import { getUserMembership } from '../../../utilities/user';
import MembershipInvoice from '../Invoice/MembershipInvoice';
import MembershipManagementWizard from '../MembershipWizard/MembershipManagementWizard';
import PaymentProvider from '../PaymentContext';

const MembershipManagement = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.profileReducer.userProfile,
  }));
  const [alertDispatched, setAlertDispatched] = useState(false);

  const handleDispatch = useCallback(
    (membershipPriceId) => {
      dispatch(setMembershipPriceId(membershipPriceId));

      if (location.state && location.state.alert && !alertDispatched) {
        setAlertDispatched(true);

        Promise.resolve().then(() => {
          // deferred dispatch to wait state updates
          dispatch(alertWarning(location.state.alert));
        });
      }
    },
    [dispatch, location.state, alertDispatched]
  );

  useEffect(() => {
    const membershipInfo = getUserMembership(currentUser);

    let membershipPriceId;
    if (!membershipInfo && !isEmpty(currentUser)) {
      membershipPriceId = MEMBERSHIP.MONTHLY.PRICE_ID;
    } else {
      membershipPriceId = membershipInfo?.price_id;
    }

    handleDispatch(membershipPriceId);
  }, [handleDispatch, currentUser, history]);

  return (
    <PaymentProvider>
      <Page className='MembershipManagement'>
        <div className='invoice-section'>
          <Button variant='link' onClick={() => history.goBack()}>
            <Icon icon='caret-left' />
            {text('back')}
          </Button>
          <MembershipInvoice />
        </div>
        <div className='section'>
          <MembershipManagementWizard />
        </div>
      </Page>
    </PaymentProvider>
  );
};

export default MembershipManagement;
