import { debounce } from 'lodash';
import TagManager from 'react-gtm-module';
import { RESET_FORM_DELAY } from '../../pages/constants';
import { client, larkiApi } from '../../utilities/api';
import { isStaff } from '../../utilities/user';
import { alertConstants, profileConstants } from '../constants';

const { RESET_COMPANY_FORM, RESET_USER_FORM } = profileConstants;

export const getUserProfile = () => async (dispatch) => {
  try {
    dispatch({
      type: profileConstants.FETCH_USER_DETAILS_LOADING,
    });
    const { data } = await larkiApi.get('/user/profile');

    dispatch({
      type: profileConstants.FETCH_USER_DETAILS_SUCCESS,
      userProfile: data,
    });

    if (process.env.GOOGLE_GTM_ID && !isStaff(data.role) && !window.dataLayer) {
      const tagManagerArgs = {
        gtmId: `${process.env.GOOGLE_GTM_ID}`,
        dataLayer: {
          event: 'login',
          userId: data.id,
        },
      };

      TagManager.initialize(tagManagerArgs);
    }
  } catch (error) {
    dispatch({
      type: alertConstants.ALERT_ERROR,
      payload: { message: error.response.data.message },
    });
  }
};

export const resetUserForm = () => (dispatch) =>
  dispatch({ type: RESET_USER_FORM });

export const debouncedResetUserForm = (dispatch) =>
  debounce(
    () =>
      dispatch({
        type: RESET_USER_FORM,
      }),
    RESET_FORM_DELAY
  );

export const updateUserProfile = (userProfile) => async (dispatch) => {
  try {
    dispatch({ type: profileConstants.UPDATE_USER_DETAILS_LOADING });

    const response = await larkiApi.put('/user/profile', {
      full_name: userProfile.full_name,
      email: userProfile.email,
      phone: userProfile.phone,
      profession: userProfile.profession,
    });

    if (response.status === 200) {
      dispatch({
        type: profileConstants.UPDATE_USER_DETAILS_SUCCESS,
        userProfile,
      });

      debouncedResetUserForm(dispatch)();
    }
  } catch (error) {
    dispatch({ type: profileConstants.UPDATE_USER_DETAILS_ERROR });

    dispatch({
      type: alertConstants.ALERT_ERROR,
      payload: { message: error.response.data.message },
    });
  }
};

export const getCompanyProfile = () => async (dispatch) => {
  try {
    const { data } = await larkiApi.get('/organisation/profile');

    dispatch({
      type: profileConstants.SET_COMPANY_DETAILS,
      companyProfile: data,
    });
  } catch (error) {
    dispatch({
      type: alertConstants.ALERT_ERROR,
      payload: { message: error.response.data.message },
    });
  }
};

export const resetCompanyForm = () => (dispatch) =>
  dispatch({ type: RESET_COMPANY_FORM });

export const debouncedResetCompanyForm = (dispatch) =>
  debounce(
    () =>
      dispatch({
        type: RESET_COMPANY_FORM,
      }),
    RESET_FORM_DELAY
  );

export const updateCompanyProfile = (companyProfile) => async (dispatch) => {
  try {
    dispatch({ type: profileConstants.UPDATE_COMPANY_DETAILS_LOADING });

    const response = await larkiApi.put(
      '/organisation/profile',
      companyProfile
    );

    if (response.status === 200) {
      dispatch({
        type: profileConstants.UPDATE_COMPANY_DETAILS_SUCCESS,
        companyProfile,
      });
      debouncedResetCompanyForm(dispatch)();
    }
  } catch (error) {
    dispatch({ type: profileConstants.UPDATE_COMPANY_DETAILS_ERROR });

    dispatch({
      type: alertConstants.ALERT_ERROR,
      payload: { message: error.response.data.message },
    });
  }
};

export const listUserCards = () => async (dispatch) => {
  try {
    dispatch({ type: profileConstants.LIST_USER_CARDS });
    const { data } = await client.listUserCards();
    dispatch({
      type: profileConstants.LIST_USER_CARDS_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({ type: profileConstants.LIST_USER_CARDS_FAILED });
  }
};

export const setPretendType = (payload) => (dispatch) =>
  dispatch({ type: profileConstants.SET_PRETEND_TYPE, payload });
