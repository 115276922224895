import { eventClient } from '../utilities/api';

export const eventHandler = async (event) => {
  try {
    if (event.data && event.data.type === 'pointerra-event') {
      const { eventType, pointCloudId, detail } = event.data.eventData;
      await eventClient.createPointerraEvent(
        eventType.type,
        pointCloudId,
        detail
      );
    }
  } catch (error) {
    console.log('Event could not be processed');
  }
};
export const startListener = () => {
  navigator.serviceWorker.addEventListener('message', eventHandler);
};
