import { useEffect, useRef, useState } from 'react';
import { usePageVisibility } from './usePageVisibility';

export const usePoll = ({
  callback,
  startEnabled = false,
  delay = 5000,
  dependencies = [],
}) => {
  // `callback` is called every `delay` ms
  // only when the page is visible
  // and when `isPollingEnabled` is true.
  // Return a function that can be called to enable or disable polling.

  const isPageVisible = usePageVisibility();
  const timerIdRef = useRef(null);
  const [isPollingEnabled, setIsPollingEnabled] = useState(startEnabled);

  useEffect(() => {
    const pollingCallback = () => {
      callback(() => setIsPollingEnabled(false));
    };

    const startPolling = () => {
      pollingCallback();
      timerIdRef.current = setInterval(pollingCallback, delay);
    };

    const stopPolling = () => {
      clearInterval(timerIdRef.current);
    };

    if (isPageVisible && isPollingEnabled) {
      startPolling();
    } else {
      stopPolling();
    }

    return () => {
      stopPolling();
    };
  }, [isPageVisible, isPollingEnabled, ...dependencies]);

  return { poll: setIsPollingEnabled, pollOnce: callback };
};
