import { AxiosError } from 'axios';
import _ from 'lodash/fp';
import { useCallback } from 'react';
import { useAsyncFn } from 'react-use';

import routes from '../routes/constants';
import { client } from '../utilities/api';
import objectUtilities from '../utilities/object';

const _addMetadata = (urlStr, metadataFlat) => {
  if (!urlStr) return null;
  const url = new URL(urlStr);
  if (metadataFlat) {
    _.forEach((key) => {
      const value = metadataFlat[key];
      if (!_.isNil(value)) url.searchParams.set(key, value);
    }, _.keys(metadataFlat));
  }
  return url.toString();
};

export class OpenBookmarkRejectedError extends Error {}

export const METADATA_PREFIX = 'm';

const useOpenBookmark = (shareToken, redirectToRequestAccess = true) => {
  const [state, openBookmarkNoMemo] = useAsyncFn(async () => {
    try {
      const { data } = await client.openBookmark(shareToken);
      const metadata = data.bookmark?.metadata;
      const metadataFlat = metadata
        ? objectUtilities.flatten(metadata, METADATA_PREFIX)
        : null;

      console.log('data', data);
      return {
        data,
        redirectUrl: _addMetadata(data.bookmark?.redirect_url, metadataFlat),
      };
    } catch (err) {
      if (err instanceof AxiosError && err.response.status === 403) {
        const shareToken = err.response.data?.bookmark?.share_token;
        if (shareToken) {
          if (redirectToRequestAccess) {
            return {
              data: err.response.data,
              redirectUrl: routes.requestAccess.withShareToken(shareToken),
            };
          }
        }
        if (err.response.data?.userProject?.invite_status === 'rejected') {
          throw new OpenBookmarkRejectedError('Rejected');
        }
        return { data: err.response.data };
      }
      throw err;
    }
  }, [shareToken]);
  const openBookmark = useCallback(openBookmarkNoMemo, [
    openBookmarkNoMemo,
    shareToken,
  ]);
  return [state, openBookmark];
};

export default useOpenBookmark;
