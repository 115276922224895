import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Wizard } from 'react-use-wizard';
import { hideModal } from '../../redux/actions/modal';
import routes from '../../routes/constants';
import text from '../../text';
import { MapViewContext } from '../mapView/mapViewContext';
import ProjectNameForm from './ProjectNameForm';
import { isUserAnonymous } from '../../utilities/user';
import { ANONYMOUS_DASHBOARD } from '../../constants/alert';

export const SAVE_DRAFT_MODAL = 'SAVE_DRAFT';

const SaveDraftModal = (props) => {
  const { onSubmit, show, currentUser } = props;
  const { state, actions } = useContext(MapViewContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleHide = () => dispatch(hideModal(SAVE_DRAFT_MODAL));

  const handleContinue = async (latestJob, values) => {
    actions.setProject(values);

    dispatch(hideModal(SAVE_DRAFT_MODAL));

    const job = await onSubmit(latestJob, {
      projectName: values.name,
    });

    if (job && !isUserAnonymous(currentUser)) {
      history.push(routes.dashboard);
    } else {
      history.push(`${routes.login}?alertType=${ANONYMOUS_DASHBOARD}`);
    }
  };

  return (
    <Modal centered show={show} onHide={handleHide}>
      <Modal.Body>
        <Wizard>
          <>
            <h2 className='text-center w-100'>{text('enterProjectName')}</h2>
            <p className='text-center mb-1'>
              {text('yourProjectSavedAsDraft')}
            </p>
            <p className='text-center'>{text('accessDraftsAnytime')}</p>
            <ProjectNameForm
              project={state.project}
              onContinue={(values) => handleContinue(state.job, values)}
              onCancel={handleHide}
              onContinueText={text('goToDashboard')}
            />
          </>
        </Wizard>
      </Modal.Body>
    </Modal>
  );
};

export default SaveDraftModal;
