import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import text from '../../text';
import routes from '../../routes/constants';

const Step = ({ title, children, className, extraText }) => (
  <div className={classnames('Step', { [className]: className })}>
    {title && (
      <div className='stepHeader'>
        {title && <h4 className='stepTitle'>{title} </h4>}
        {extraText}
      </div>
    )}
    {children}
    <p className='text-center'>
      {text('needHelpContactUs', {
        contactUs: (
          <Link
            key='urlTerms'
            to={{ pathname: routes.larkiWebsiteUrl.contactUs }}
            target={'_blank'}
          >
            {text('contactUs')}
          </Link>
        ),
      })}
    </p>
  </div>
);

const StepContent = ({ children, className }) => (
  <div className={classnames('StepContent', { [className]: className })}>
    {children}
  </div>
);

Step.Content = StepContent;

export default Step;
