import routes from '../routes/constants';
import { isRouteIncluded, isRouteMatching } from './routes';

const SHARE_LINK_ROUTES = [
  routes.fileManager.viewProject(),
  routes.order.job(),
  routes.view3D.project(),
];
const SHARE_LINK_ROUTES_WITH_ORDER_ID = [routes.order.job()];
const SHARE_LINK_ROUTES_WITH_HOME_ICON = [routes.view3D.project()];

const isShareLinkVisible = (pathname) =>
  isRouteIncluded(SHARE_LINK_ROUTES)(pathname);

const extractRouteParam = (pathname) => {
  let _param;
  for (const route of SHARE_LINK_ROUTES) {
    const match = isRouteMatching(pathname)(route);
    if (match) {
      if (isRouteIncluded(SHARE_LINK_ROUTES_WITH_ORDER_ID)(route)) {
        _param = match.params.jobId;
      } else {
        _param = match.params.projectId;
      }
    }
  }
  return _param ? parseInt(_param, 10) : null;
};

const doesPathHaveOrderId = (pathname) => {
  return SHARE_LINK_ROUTES_WITH_ORDER_ID.some((route) =>
    isRouteMatching(pathname)(route)
  );
};

const shouldShowHomeIcon = (pathname) =>
  isRouteIncluded(SHARE_LINK_ROUTES_WITH_HOME_ICON)(pathname);

const isRoute2dMap = (pathname) =>
  isRouteIncluded([routes.order.job()])(pathname);

const isRoute3dViewer = (pathname) =>
  isRouteIncluded([routes.view3D.project()])(pathname);

const shareLinkUtilities = {
  isShareLinkVisible,
  extractRouteParam,
  doesPathHaveOrderId,
  shouldShowHomeIcon,
  isRoute2dMap,
  isRoute3dViewer,
};
export default shareLinkUtilities;
