import React from 'react';
import T from 'prop-types';
import cn from 'classnames';

import Icon from '../icon/Icon';
import ActivityCard from './ActivityCard';

const ActivityIcon = ({ iconProps }) => {
  return (
    <div className='ActivityIconContainer'>
      <div className='ActivityLine' />
      <div className='ActivityIcon'>
        <Icon {...iconProps} />
      </div>
    </div>
  );
};

ActivityIcon.propTypes = {
  icon: T.string,
};

const Activity = ({ children, className }) => {
  return (
    <div className={cn('Activity', { [className]: className })}>
      {children}
    </div>
  );
};

Activity.propTypes = {
  children: T.node,
  className: T.string,
};

Activity.Icon = ActivityIcon;
Activity.Card = ActivityCard;

export default Activity;
