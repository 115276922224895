import get from 'lodash/get';
import { USER_TYPE } from '../constants/user';
import { getPriceId } from './membership';

export const isSuperAdministrator = (role) => role === 'superadministrator';
export const isAdministrator = (role) =>
  role === 'administrator' || isSuperAdministrator(role);
export const isStaff = (role) => role === 'staff' || isAdministrator(role);

// NOTE: Please try to make the first arg to be an user object
export const isUserStaff = (user) =>
  user?.role === 'staff' || isAdministrator(user?.role);

// We assume the first element is the user's current membership
export const getUserMembership = (user) =>
  get(user, ['customer', 'memberships', 0]);

export const isUserAnonymous = (user) => user && user.is_anonymous;

export const userHasMembershipWithStatus = (user, status) =>
  user?.customer?.memberships.some((membership) =>
    status.includes(membership?.status)
  );

export const userHasActiveMembership = (user) =>
  userHasMembershipWithStatus(user, ['active']);

export const userHasValidMembership = (user) =>
  userHasMembershipWithStatus(user, ['active', 'trialing']);

export const isUserUnderTrial = (user) =>
  !isStaff(user.role) && userHasMembershipWithStatus(user, ['trialing']);

export const getUserPriceId = (user) =>
  user?.customer && getPriceId(user.customer?.memberships);

export const isUserGovernment = (user) => user?.role === 'government';

export const getUserType = (user) =>
  isUserStaff(user)
    ? USER_TYPE.LARKI_SUPPORT
    : isUserGovernment(user)
    ? USER_TYPE.GOVERNMENT
    : userHasValidMembership(user)
    ? isUserUnderTrial(user)
      ? USER_TYPE.TRIAL
      : USER_TYPE.ESSENTIALS
    : USER_TYPE.FREE;

export const canUserUpgrade = (user) =>
  user.has_used_trial || isUserUnderTrial(user);

export const canGiveTrial = (currentUser, targetUser) => {
  if (
    !currentUser ||
    !targetUser ||
    !isStaff(currentUser.role) ||
    isStaff(targetUser.role)
  ) {
    return false;
  }
  const membership = getUserMembership(targetUser);
  if (!membership) return true;
  return membership.cancel_date || membership.status === 'trialing';
};
