import { useEffect, useRef } from 'react';

const useStateAsRef = (state) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = state;
  }, [state]);
  return ref;
};

export default useStateAsRef;
