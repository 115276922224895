import {
  TOURS,
  TOUR_MODAL_START_CONFIG,
  hightlightedAreaStyling,
  popoverCustomDefaultStyling,
  MODAL_TOUR_NAMES,
} from './config';
import { TOUR_2D_MAP } from './tour2dMap';
import { TOUR_3D_VIEWER } from './tour3dViewer';
import { TOUR_FILE_MANAGER } from './tourFileManager';

export const GET_STARTED_TOURS = {
  [TOURS.TOUR_2D_MAP]: TOUR_2D_MAP,
  [TOURS.TOUR_FILE_MANAGER]: TOUR_FILE_MANAGER,
  [TOURS.TOUR_3D_VIEWER]: TOUR_3D_VIEWER,
};

export {
  TOURS,
  hightlightedAreaStyling,
  popoverCustomDefaultStyling,
  TOUR_MODAL_START_CONFIG,
  MODAL_TOUR_NAMES,
};
