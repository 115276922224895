import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../redux/reducers';

let store;
if (process.env.ENABLE_REDUX_LOG) {
  const { logger } = require('redux-logger');
  store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunkMiddleware, logger))
  );
} else {
  store = createStore(rootReducer, applyMiddleware(thunkMiddleware));
}

export { store };
