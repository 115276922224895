/* eslint-disable react/prop-types */
import { Form, useFormikContext } from 'formik';
import React from 'react';
import { ToggleButton } from 'react-bootstrap';
import classnames from 'classnames';

import Control from '../../../components/form/Control';
import ToggleButtonGroup from '../../../components/form/ToggleButtonGroup';
import { softwares } from '../../../components/mapView/constants';
import text from '../../../text';
import ProjectSelectV2 from '../../../components/select/ProjectSelect/ProjectSelectV2';
import _ from 'lodash/fp';
import { getProjectName } from '../../../utilities/project';

const ProjectDetailsForm = () => {
  const {
    errors,
    values,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  return (
    <Form className='ProjectDetailsForm'>
      <h5 className='required'>{text('project')}</h5>
      <ProjectSelectV2
        value={{
          id: values.projectId,
          name: values.projectName,
        }}
        onChange={(option, { newProject = false }) => {
          setFieldTouched('projectName');
          if (newProject) {
            setFieldValue('projectId', undefined);
            setFieldValue('projectName', getProjectName(option));
          } else {
            if (option) {
              setFieldValue('projectId', option.id);
              setFieldValue('projectName', getProjectName(option));
            } else {
              // `option` is null if clearing the input field
              setFieldValue('projectId', undefined);
              setFieldValue('projectName', undefined);
            }
          }
        }}
        hasError={touched.projectName && errors.projectName}
      />
      <p
        className={classnames('Control-Feedback', {
          isFailed: touched.projectName && errors.projectName,
        })}
      >
        {touched.projectName && errors.projectName}
      </p>

      <h5 className='required'>{text('whatSoftwareWillYouUse')}</h5>
      <div>{text('selectSoftwareFormat')}</div>
      <ToggleButtonGroup
        className='CustomerSoftware'
        type='radio'
        name='customer_software'
      >
        {softwares.result.map((key) => (
          <ToggleButton variant='outline-secondary' key={key} value={key}>
            {softwares.entities[key].name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <h5>{text('comments')}</h5>
      <Control
        className='Message'
        as='textarea'
        type='text'
        name='message'
        rows={3}
        isFailed={touched.message && !!errors.message}
        value={values?.message}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={text('addMessageOptional')}
        validationError={touched.message && errors.message}
      />
    </Form>
  );
};

export default ProjectDetailsForm;
