import React from 'react';
import classnames from 'classnames';

const Container = ({ children, className, isSecondary, isTertiary, noBorder = false }) => {
  return (
    <div
      className={classnames('Container', {
        secondary: isSecondary,
        tertiary: isTertiary,
        [className]: className,
        noBorder,
      })}
    >
      {children}
    </div>
  );
};

export default Container;
