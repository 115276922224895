// Common states
export const DEFAULT = 'DEFAULT';
export const LOADING = 'LOADING';
export const SUCCESS = 'SUCCESS';
export const FAILED = 'FAILED';

export const isDefault = state => state && state === DEFAULT;
export const isLoading = state => state && state === LOADING;
export const isSuccess = state => state && state === SUCCESS;
export const isFailed = state => state && state === FAILED;
