import { useEffect } from 'react';

export function useClickOutside(
  ref,
  callback,
  excludedSelectors = [],
  condition = false
) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (!condition) {
        if (ref?.current && !ref.current.contains(event.target)) {
          const excludedElementDetectedInDOM = excludedSelectors.some(
            (selector) => document.querySelector(selector)
          );

          if (excludedSelectors.length && excludedElementDetectedInDOM) {
            const excludededElementsContainsTarget = excludedSelectors.some(
              (selector) => {
                const el = document.querySelectorAll(selector);
                if (el.length) {
                  return Array.from(el).some((element) =>
                    element?.contains(event.target)
                  );
                }
                return false;
              }
            );
            !excludededElementsContainsTarget && callback(event);
          } else {
            callback(event);
          }
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, condition]);
}
