import React from 'react';
import Page from '../components/page/Page';

const AccessDenied = () => (
  <Page>
    <p className='main-font'>403</p>
    <p className='normal-font'>Access Denied</p>
  </Page>
);

export default AccessDenied;
