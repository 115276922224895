import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { hideModal } from '../../redux/actions';
import text from '../../text';
import Icon from '../icon/Icon';
import routes from '../../routes/constants';

export const PAYMENT_FAILURE_MODAL = 'PAYMENT_FAILURE_MODAL';

const PaymentFailureModal = (props) => {
  const dispatch = useDispatch();

  const handleHide = () => dispatch(hideModal(PAYMENT_FAILURE_MODAL));

  return (
    <Modal centered className='PaymentFailureModal' onHide={handleHide} {...props}>
      <Modal.Header className='centered' closeButton>
        <Icon icon='circled-exclamation' />
      </Modal.Header>
      <Modal.Body className='centered'>
        <h2>{props.message}</h2>
        <div>
          {text('paymentHelp', {
            contactUs: (
              <Link
                key='urlTerms'
                to={{ pathname: routes.larkiWebsiteUrl.contactUs }}
                target={'_blank'}
              >
                {text('contactUs')}
              </Link>
            ),
          })}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentFailureModal;
