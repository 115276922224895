import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Wizard } from 'react-use-wizard';
import { hideModal } from '../../../redux/actions/modal';
import text from '../../../text';

export const MANUAL_ORDER_MODAL = 'MANUAL_ORDER_MODAL';

const ManualOrderModal = (props) => {
  const { show } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const handleHide = () => dispatch(hideModal(MANUAL_ORDER_MODAL));

  const handleCancel = () => {
    handleHide();
  };

  const handleReturn = () => {
    handleCancel();
    history.push('/');
  };

  return (
    <Modal centered show={show} onHide={handleHide}>
      <Modal.Body>
        <Wizard>
          <div>
            <h2 className='metromap-modal-heading text-center w-100'>
              {text('thanksForYourOrder')}
            </h2>

            <div className='text-center text-grey-1'>
              {text('orderSubmitSucces')}
            </div>
            <div className='text-center text-grey-1 software-success-body'>
              {text('salesPersonWillContactYou')}
            </div>
            <div className='form-footer'>
              <Button onClick={handleReturn}>{text('goToDashboard')}</Button>
            </div>
          </div>
        </Wizard>
      </Modal.Body>
    </Modal>
  );
};

export default ManualOrderModal;
