import T from 'prop-types';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

// With ChatGPT's help
const FloatingScrollbar = forwardRef((props, ref) => {
  const {
    elementId,
    children,
    scollOffsetHeight = 0,
    thumbHeightSize = 100, // percentage
    ...rest
  } = props;
  const contentRef = useRef(null);
  const thumbRef = useRef(null);
  const [thumbTop, setThumbTop] = useState(0);
  const [thumbHeight, setThumbHeight] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);

  // check if content is scrollable
  const updateScrollbar = useCallback(() => {
    const content = contentRef.current;
    const contentHeight = content?.scrollHeight;
    const containerHeight = content?.clientHeight;

    if (contentHeight > containerHeight) {
      setIsScrollable(true);

      // calculate the thumb height as a percentage of the content height
      const newThumbHeight =
        (containerHeight / contentHeight) *
        containerHeight *
        (thumbHeightSize / 100);
      setThumbHeight(newThumbHeight);
    } else {
      setIsScrollable(false);
    }
  }, [thumbHeightSize]);

  // Observe if the content changes (e.g., window resize or content size change)
  useEffect(() => {
    const content = contentRef.current;
    const resizeObserver = new ResizeObserver(() => {
      updateScrollbar();
    });
    resizeObserver.observe(content);
    // Initial check to see if scrollbar is needed
    updateScrollbar();

    return () => {
      if (content) {
        resizeObserver.unobserve(content);
      }
    };
  }, [updateScrollbar]);

  // Handle scrolling to move the thumb
  useEffect(() => {
    const content = contentRef.current;
    const handleScroll = () => {
      const containerHeight = content.clientHeight;
      const contentHeight = content.scrollHeight;
      const scrollFraction =
        content.scrollTop / (contentHeight - containerHeight);

      // Set thumb position based on scroll fraction
      setThumbTop(scrollFraction * (containerHeight - thumbHeight));
    };
    content.addEventListener('scroll', handleScroll);
    content.addEventListener('wheel', handleScroll);
    updateScrollbar();

    return () => {
      content.removeEventListener('scroll', handleScroll);
      content.removeEventListener('wheel', handleScroll);
    };
  }, [thumbHeight, updateScrollbar]);

  // Handle dragging of the scrollbar thumb
  const handleMouseDown = (e) => {
    const startY = e.clientY;
    const startTop = thumbTop;
    document.body.classList.add('disable-selection');

    const onMouseMove = (eMove) => {
      const deltaY = eMove.clientY - startY;
      const newTop = startTop + deltaY;

      const containerHeight = contentRef.current.clientHeight;
      const maxThumbTop = containerHeight - thumbHeight;
      const constrainedTop = Math.max(0, Math.min(newTop, maxThumbTop));

      setThumbTop(constrainedTop);

      const scrollFraction = constrainedTop / (containerHeight - thumbHeight);
      contentRef.current.scrollTop =
        scrollFraction *
        (contentRef.current.scrollHeight - contentRef.current.clientHeight);
    };

    const onMouseUp = () => {
      document.body.classList.remove('disable-selection');
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  return (
    <div
      className='floating-scroll-container'
      id={elementId}
      ref={ref}
      {...rest}
    >
      <div className='content' ref={contentRef}>
        {children}
      </div>
      {isScrollable ? (
        <div className='floating-scrollbar'>
          <div
            className='floating-scroll-thumb'
            ref={thumbRef}
            style={{
              height: `${thumbHeight - scollOffsetHeight}px`,
              top: `${thumbTop}px`,
            }}
            onMouseDown={handleMouseDown}
          />
        </div>
      ) : null}
    </div>
  );
});

FloatingScrollbar.displayName = 'FloatingScrollbar';

export default FloatingScrollbar;

FloatingScrollbar.propTypes = {
  elementId: T.string,
  children: T.node,
  scollOffsetHeight: T.number,
  thumbHeightSize: T.number,
};
