import React, { forwardRef } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

/*
This component is mainly to be used with custom overlays
to avoid injecting refs. For other scenarios we should use the 
included Tooltip, Popover from react-bootstrap
*/
const HoverableOverlay = (props) => {
  const { overlay, children, onMouseEnter, onMouseLeave, asPopover, ...rest } =
    props;

  const OverlayContainer = (
    {
      placement,
      scheduleUpdate,
      arrowProps,
      outOfBoundaries,
      show,
      ...props
    } = props,
    ref
  ) => (
    <div
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...props}
    >
      {React.cloneElement(overlay, props)}
    </div>
  );

  const OverlayContainerWithRef = forwardRef(OverlayContainer);

  return (
    <OverlayTrigger
      overlay={asPopover ? overlay : <OverlayContainerWithRef />}
      {...rest}
    >
      {children}
    </OverlayTrigger>
  );
};

export default HoverableOverlay;
