export const AERIAL = 'aerial';
export const DRONE = 'drone';
export const EXTERIOR = 'exterior';
export const INTERIOR = 'interior';
export const STREETSCAPE = 'streetscape';
export const UNKNOWN = 'unknown';

export const STATUS = {
  COMPLETED: 'completed',
  DRAFT: 'draft',
  IN_PROGRESS: 'inprogress',
  PENDING: 'pending',
  DEMO: 'demo',
};

export const ACTIVE_STATUS = [STATUS.IN_PROGRESS, STATUS.PENDING];

export const DETAILED_STATUS = {
  COMPLETED: 'Completed',
  DRAFT: 'Draft',
  IN_PROGRESS: 'In progress',
  PENDING: 'Pending',
};

export const EXCLUDED_COLUMNS_ON_COPY_JOB = [
  'user_project',
  'has_customer',
  'point_clouds',
  'project',
  'coverage',
  'detailed_status',
  'quote',
  'user_order',
];
export const LEAD_CHECKOUT = 'checkout';
export const LEAD_PAYMENT_PAGE = 'payment_page';
export const LEAD_CONFIRMATION_PAGE = 'confirmation_page';
export const LEAD_START_ESSENTIAL = 'start_essential';
