import { PRODUCT_TYPE } from '../../constants/product';

export const defaultSelectionColor = '#000000';

export const aerialIconEnabled = '/public/img/aerial-point-cloud-enabled.svg';
export const droneIconEnabled = '/public/img/drone-point-cloud-enabled.svg';
export const exteriorIconEnabled =
  '/public/img/exterior-point-cloud-enabled.svg';
export const interiorIconEnabled =
  '/public/img/interior-point-cloud-enabled.svg';
export const streetscapeIconEnabled =
  '/public/img/streetscape-point-cloud-enabled.svg';
export const unknownIconEnabled = '/public/img/unknown-point-cloud-enabled.svg';

export const aerialIconDisabled = '/public/img/aerial-point-cloud-disabled.svg';
export const droneIconDisabled = '/public/img/drone-point-cloud-disabled.svg';
export const exteriorIconDisabled =
  '/public/img/exterior-point-cloud-disabled.svg';
export const interiorIconDisabled =
  '/public/img/interior-point-cloud-disabled.svg';
export const streetscapeIconDisabled =
  '/public/img/streetscape-point-cloud-disabled.svg';
export const unknownIconDisabled =
  '/public/img/unknown-point-cloud-disabled.svg';

export const aerialIconWhite = '/public/img/aerial-point-cloud-white.svg';
export const droneIconWhite = '/public/img/drone-point-cloud-white.svg';
export const exteriorIconWhite = '/public/img/exterior-point-cloud-white.svg';
export const interiorIconWhite = '/public/img/interior-point-cloud-white.svg';
export const streetscapeIconWhite =
  '/public/img/streetscape-point-cloud-white.svg';
export const unknownIconWhite = '/public/img/unknown-point-cloud-white.svg';

export const PRODUCT_DATA = {
  entities: {
    unknown: {
      order: 0,
      display_name: 'Unspecified',
      display_color: '#9b9b9b',
    },
    aerial: {
      order: 1,
      display_name: 'Aerial',
      display_color: '#157DAA',
    },
    streetscape: {
      order: 2,
      display_name: 'Streetscape',
      display_color: '#3048C9',
    },
    drone: {
      order: 3,
      display_name: 'Drone',
      display_color: '#B087F3',
    },
    exterior: {
      order: 4,
      display_name: 'Exterior',
      display_color: '#E55384',
    },
    interior: {
      order: 5,
      display_name: 'Interior',
      display_color: '#F47601',
    },
    bim_exterior: {
      order: 6,
      display_name: 'BIM Exterior',
      display_color: '#E55384',
    },
    bim_interior: {
      order: 7,
      display_name: 'BIM Interior',
      display_color: '#F47601',
    },
    twod_plan_exterior: {
      order: 8,
      display_name: '2D Plan Exterior',
      display_color: '#E55384',
    },
    twod_plan_interior: {
      order: 9,
      display_name: '2D Plan Interior',
      display_color: '#F47601',
    },
  },
  result: [
    'unknown',
    'aerial',
    'streetscape',
    'drone',
    'exterior',
    'interior',
    'bim_exterior',
    'bim_interior',
    'twod_plan_exterior',
    'twod_plan_interior',
  ],
};

export const GEOSPATIAL_FILES = {
  SHAPEFILE: {
    fileExtension: 'shp',
    displayName: '.shp (WGS84, m)',
  },
  GEOJSON: {
    fileExtension: 'geojson',
    displayName: '.geojson (WGS84, m)',
  },
  KEYHOLE: {
    fileExtension: 'kml',
    displayName: '.kml (WGS84, m)',
  },
  DXF: {
    fileExtension: 'dxf',
    displayName: '.dxf (MGA2020, m)',
  },
  DXF_TRUNCATED: {
    fileExtension: 'dxf-truncated',
    displayName: '.dxf (MGA2020, mm, <1km truncated)',
  },
};

export const MAP_LOAD_DELAY = 500;

export const MAP_TYPE = {
  HIGH_RES: 'highres',
  HIGH_RES_NZ: 'highresnz',
  SATELLITE: 'satellite',
  ROAD_MAP: 'roadmap',
};

export const softwares = {
  entities: {
    autodesk_revit: {
      id: 'autodesk_revit',
      name: 'Revit',
      format: 'rcs',
    },
    autodesk_autocad: {
      id: 'autodesk_autocad',
      name: 'AutoCAD',
      format: 'rcs',
    },
    graphisoft_archicad: {
      id: 'graphisoft_archicad',
      name: 'ArchiCAD',
      format: 'e57',
    },
    bentley: {
      id: 'bentley',
      name: 'Bentley',
      format: 'e57',
    },
    vectorworks: {
      id: 'vectorworks',
      name: 'Vectorworks',
      format: 'e57',
    },
    rhinoceros_rhino: {
      id: 'rhinoceros_rhino',
      name: 'Rhinoceros',
      format: 'e57',
    },
    sketchup_scan_plugin: {
      id: 'sketchup_scan_plugin',
      name: 'SketchUp + scan plugin',
      format: 'e57',
    },
  },
  result: [
    'autodesk_revit',
    'autodesk_autocad',
    'graphisoft_archicad',
    'bentley',
    'vectorworks',
    'rhinoceros_rhino',
    'sketchup_scan_plugin',
  ],
};

export const MAP_ZOOM_LEVEL_TO_HIDE_ELEMENTS = 16;
export const MAP_ZOOM_LEVEL_TO_HIDE_LABELS = 17;

export const MAP_BOUNDS_PADDING = 20; // m

export const PREFERRED_MAP_ZOOM = 18;

export const NZ_STATES = [
  'Auckland',
  'New Plymouth',
  'Wellington',
  'Nelson',
  'Canterbury',
  'Otago',
];

export const INITIAL_MAP_LOCATION = '-37.81229312184036,144.96279632621173'; // Melbourne CBD

export const INITIAL_MAP_ZOOM = 18;
export const MIN_MAP_ZOOM = 12;
export const MAX_MAP_ZOOM = 22;
export const MAX_MAP_ZOOM_SATELLITE = 20;
export const MAX_MAP_ZOOM_HIGH_RES = 22;

export const INITIAL_RECTANGLE_SIZE = {
  dx: 0.025 /* km */,
  dy: 0.025 /* km */,
};

export const AUTO_MAP_ADDRESS = {
  address: '90 Bridge Road',
  city: 'Richmond',
  state: 'Victoria',
  postcode: '3121',
};

/**
 * Whether to merge layers of the same type.
 * Display only.
 */
export const DRAW_LAYERS_MERGED = {
  unknown: true,
  aerial: true,
  streetscape: false,
  drone: true,
  exterior: true,
  interior: true,
  [PRODUCT_TYPE.BIM_EXTERIOR]: true,
  [PRODUCT_TYPE.BIM_INTERIOR]: true,
  [PRODUCT_TYPE.TWOD_PLAN_EXTERIOR]: true,
  [PRODUCT_TYPE.TWOD_PLAN_INTERIOR]: true,
};

export const Z_INDEX = {
  SELECTION: 1,
  COVERAGE: 2,
  CLICKABLE: 3,
  BOOLEAN: 4,
  MARKER: 5,
  REGION: 6, // currently selected region
};

export const LNG_MAP = [1, 0, 3, 2];
export const LAT_MAP = [3, 2, 1, 0];

/**
 * Actions to take upon revisiting the map after logging in from previously being an authenticated user.
 */
export const ON_REVISIT = {
  _2D_HIGH_RES: '2d-high-res',
};
