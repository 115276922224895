import T from 'prop-types';
import React from 'react';

import text from '../../text';
import Img from '../img/Img';
import { useSelector } from 'react-redux';

const CartEmpty = ({ hasAddOns }) => {
  const undefinedAddress = useSelector(
    (state) => state.jobsReducer.undefinedAddress
  );

  return (
    <div className='cart-empty pb-0'>
      <div className='content'>
        <h4>{text('cartIsEmpty')}</h4>
        <Img
          img={
            hasAddOns && !undefinedAddress
              ? 'cart-empty-illustration'
              : 'cart-empty-illustration-no-addons'
          }
          alt='cart empty'
          className='illustration'
        />
        {hasAddOns && !undefinedAddress ? (
          <>
            <p style={{ fontSize: 14 }} className='wide'>
              {text('cartIsEmptyDescription1')}
            </p>
            <p style={{ fontSize: 14 }} className='mb-2 wide'>
              {text('cartIsEmptyDescription2')}
            </p>
          </>
        ) : (
          <p style={{ fontSize: 14 }}>
            {text('cartIsEmptyNoAddOnsDescription')}
          </p>
        )}
      </div>
    </div>
  );
};

CartEmpty.propTypes = {
  hasAddOns: T.bool.isRequired,
};

export default CartEmpty;
