import { Formik } from 'formik';
import noop from 'lodash/noop';
import T from 'prop-types';
import React from 'react';
import { object } from 'yup';

import text from '../../../text';
import { validateEmail } from '../../../validation';
import Button from '../../button/Button';
import Control from '../Control';

const emailSchema = ({ isValidUser }) =>
  object().shape({
    email: validateEmail.test(
      'valid-invitee',
      text('userAlreadyInvited'),
      function (value) {
        return isValidUser(value);
      }
    ),
  });

const InviteUsersForm = (props) => {
  const { onSubmit, isLoading, isValidUser } = props;
  return (
    <div className='InviteUserForm'>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={emailSchema({ isValidUser })}
      >
        {({
          handleChange,
          handleBlur,
          isValid,
          values,
          errors,
          touched,
          dirty,
          resetForm,
        }) => {
          return (
            <>
              <Control
                label={text('email')}
                as='input'
                type='text'
                name='email'
                isFailed={touched.email && !!errors.email}
                value={values?.email || ''}
                onBlur={handleBlur}
                onChange={(e) => handleChange(e)}
                placeholder={text('enterEmail')}
                validationError={touched.email && errors.email}
                autoComplete='off'
              />
              <Button
                loading={isLoading}
                onClick={() => {
                  onSubmit(values.email);
                  resetForm();
                }}
                disabled={!isValid || !dirty || !isValidUser(values.email)}
              >
                {text('sendInvitation')}
              </Button>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

InviteUsersForm.propTypes = {
  onSubmit: T.func.isRequired,
  isLoading: T.bool.isRequired,
  isValidUser: T.func.isRequired,
};

InviteUsersForm.defaultProps = {
  onSubmit: noop,
};

export default InviteUsersForm;
