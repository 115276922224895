import React, { useState, useEffect } from 'react';
import { components } from 'react-select';
import Creatable from 'react-select/creatable';
import { useAsyncFn } from 'react-use';
import isEmpty from 'lodash/isEmpty';
import toLower from 'lodash/toLower';
import noop from 'lodash/noop';
import classnames from 'classnames';

import text from '../../../text';
import { client } from '../../../utilities/api';
import { getProjectName } from '../../../utilities/project';
import { isNull } from 'lodash/fp';
import Icon from '../../icon/Icon';
import { Badge } from 'react-bootstrap';

const Option = (props) => {
  return props.data?.__isNew__ ? (
    <div
      className={classnames('CreateOption center-content', {
        isFocused: props.isFocused,
      })}
      {...props.innerProps}
    >
      <Icon icon='plus' />
      {props.data.label}
    </div>
  ) : (
    <components.Option {...props} />
  );
};

const IndicatorSeparator = ({ inputValue, ...props }) => {
  const existingOption = props.options.find(
    (option) =>
      option.name &&
      toLower(option.name) === toLower(inputValue) &&
      !option.__isNew__
  );
  return (
    <>
      {inputValue && (
        <Badge
          className='inverted center-content mr-2'
          variant={existingOption ? 'warning' : 'info'}
        >
          <Icon
            className='mr-1'
            icon={existingOption ? 'triangular-exclamation' : 'information'}
          />
          {text(existingOption ? 'similar' : 'new')}
        </Badge>
      )}
      |
    </>
  );
};


// This one allows creation of new projects
const ProjectSelect = ({
  className,
  isAdmin,
  currentJob,
  onChange = noop,
  onBlur,
  hasError,
  filterPredicate,
  isCreateOptionEnabled = true,
}) => {
  const [currentProject, setCurrentProject] = useState();
  const [options, setOptions] = useState();
  const [inputValue, setInputValue] = useState();

  const [listProjectsState, listProjects] = useAsyncFn(async () => {
    const { data } = await client.listProjects({
      isAdmin,
      includeDemo: false,
      inviteStatus: ['accepted'],
    });
    setOptions(data.projects);
  });

  const [upsertProjectState, upsertProject] = useAsyncFn(async (inputName) => {
    let project;
    if (!isEmpty(currentJob) && isNull(currentJob.project.name)) {
      const { data } = await client.updateProjectName(
        currentJob.project?.id,
        inputName
      );
      project = data.project;
    } else {
      const { data } = await client.createProject(inputName);
      project = data;
    }

    setOptions((previousOptions) => [...previousOptions, project]);
    setCurrentProject(project);
    onChange(project);
  });

  const handleCreateOption = upsertProject;

  useEffect(() => {
    if (!listProjectsState.loading && isEmpty(listProjectsState.value)) {
      listProjects();
    }
  }, []);

  return (
    <>
      <Creatable
        inputValue={inputValue}
        onInputChange={setInputValue}
        components={{
          IndicatorSeparator: (props) => (
            <IndicatorSeparator inputValue={inputValue} {...props} />
          ),
          Option,
        }}
        classNamePrefix='Select'
        createOptionPosition='first'
        className={classnames('Select', { hasError, [className]: className })}
        onBlur={onBlur}
        defaultValue={currentJob?.project}
        isClearable
        getOptionValue={(item) => item.id}
        getOptionLabel={(item) => item.name}
        isDisabled={listProjectsState.loading || upsertProjectState.loading}
        isLoading={listProjectsState.loading || upsertProjectState.loading}
        placeholder={text('searchProject')}
        value={currentProject}
        options={filterPredicate ? options?.filter(filterPredicate) : options}
        onChange={(option) => {
          onChange(option);
          setCurrentProject(option);
        }}
        onCreateOption={handleCreateOption}
        formatOptionLabel={(item) => item?.label ?? getProjectName(item)}
        isValidNewOption={() => isCreateOptionEnabled}
      />
    </>
  );
};

export default ProjectSelect;
