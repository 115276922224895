export const TOGGLE_SIDENAV_VISIBILITY = 'TOGGLE_SIDENAV_VISIBILITY';

export const TOGGLE_RIGHT_MENU_VISIBILITY = 'TOGGLE_RIGHT_MENU_VISIBILITY';
export const TOGGLE_LAYERS_PANEL_VISIBILITY = 'TOGGLE_LAYERS_PANEL_VISIBILITY';

export const COLLAPSE_AUTOMATIC_DATA_TABLE = 'COLLAPSE_AUTOMATIC_DATA_TABLE';

export const TOGGLE_AUTOMATIC_DATA_TABLE_VISIBILITY =
  'TOGGLE_AUTOMATIC_DATA_TABLE_VISIBILITY';
export const TOGGLE_MANUAL_DATA_TABLE_VISIBILITY =
  'TOGGLE_MANUAL_DATA_TABLE_VISIBILITY';
