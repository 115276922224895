import { alertConstants } from '../constants/alert';

export const alertSuccess =
  (message, options = {}) =>
  async (dispatch) => {
    dispatch({
      type: alertConstants.ALERT_SUCCESS,
      payload: { message, ...options },
    });
  };

export const alertWarning =
  (message, options = {}) =>
  async (dispatch) => {
    dispatch({
      type: alertConstants.ALERT_WARNING,
      payload: { message, ...options },
    });
  };

export const alertError =
  (message, options = {}) =>
  async (dispatch) => {
    dispatch({
      type: alertConstants.ALERT_ERROR,
      payload: { message, ...options },
    });
  };
