import React from 'react';
import T from 'prop-types';
import Icon from '../icon/Icon';
import routes from '../../routes/constants';

export const BRANDS = {
  INSTAGRAM: 'instagram',
  YOUTUBE: 'youtube',
  TWITTER: 'twitter',
  FACEBOOK: 'facebook',
  LINKEDIN: 'linkedin',
};

const SocialMedia = (props) => {
  const { brand } = props;
  return (
    <a href={routes.larkiSocialMedia[brand]} target='_blank' rel='noreferrer'>
      <Icon icon={brand} />
    </a>
  );
};

SocialMedia.propTypes = {
    brand: T.string,
};

export default SocialMedia;
