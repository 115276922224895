import React from 'react';
import cn from 'classnames';

const Table = (props) => {
  const { children, className } = props;
  return (
    <table className={cn('Table', { [className]: className })}>
      {children}
    </table>
  );
};

export default Table;
