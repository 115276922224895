import React from 'react';
import classnames from 'classnames';

const TextDivider = ({ children, isSecondary, isComplete, isMarginLess }) => (
  <div
    className={classnames('TextDivider', {
      secondary: isSecondary,
      complete: isComplete,
      'is-marginless': isMarginLess,
    })}
  >
    {children}
  </div>
);

export default TextDivider;
