import classNames from 'classnames';
import { formatDistanceToNowStrict } from 'date-fns';
import { Form, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Checkbox from '../../../components/form/Checkbox';
import Control from '../../../components/form/Control';
import { softwares } from '../../../components/mapView/constants';
import { MapViewContext } from '../../../components/mapView/mapViewContext';
import TextDivider from '../../../components/text/TextDivider';
import { DEFAULT_CURRENCY } from '../../../constants/price';
import { profileSelectors } from '../../../redux/selectors/profile';
import text, { formatCurrency } from '../../../text';
import { isJobPayable } from '../../../utilities/job';
import { isMembershipPriceId } from '../../../utilities/membership';
import { isStaff } from '../../../utilities/user';
import MembershipInvoice from '../Invoice/MembershipInvoice';
import ProductInvoice from '../Invoice/ProductInvoice';

const ConfirmationForm = () => {
  const { jobId } = useParams();
  const { job, membershipPriceId, membershipPrices, currentUser } = useSelector(
    (state) => ({
      job: state.jobsReducer.job,
      membershipPriceId: state.order.membershipPriceId,
      membershipPrices: state.profileReducer.membershipPrices,
      currentUser: state.profileReducer.userProfile,
    })
  );
  const currency =
    job && job.quote ? { ...job.quote.currency } : DEFAULT_CURRENCY;
  const { values, handleChange } = useFormikContext();
  const hasValidMembership = useSelector(profileSelectors.hasValidMembership);
  const {
    state: { buyNowData },
  } = useContext(MapViewContext);

  const selectedMembership = useMemo(() => {
    if (membershipPrices.value) {
      return membershipPrices.value.find(
        (membership) => membership.id === membershipPriceId
      );
    }

    return null;
  }, [membershipPriceId, membershipPrices]);

  const estimatedDelivery = useMemo(() => {
    if (!jobId) {
      // return TBC if no jobId immediately
      // this only means origin of the flow is not from Order since there's no jobId from params
      // e.g. probably from membership upgrade flow
      return null;
    }

    if (!job.quote.expected_at) {
      return text('toBeConfirmed');
    }

    return formatDistanceToNowStrict(new Date(job.quote.expected_at), {
      roundingMethod: 'round',
    });
  }, [job, jobId]);

  const totalDataPrice = jobId // refer to comment above in `estimatedDelivery`
    ? isMembershipPriceId(membershipPriceId)
      ? job.quote.price.member_grand_total
      : job.quote.price.grand_total
    : 0;

  const hasPurchasedMembership = !isEmpty(selectedMembership);
  const numOrder =
    [buyNowData.length > 0, hasPurchasedMembership].filter((v) => !!v).length ||
    1;
  const canShowTotalDataPrice = useMemo(
    () => buyNowData.length > 0,
    [buyNowData.length]
  );

  /**
   * NOTE:
   * !jobId = no jobId - so it only means the flow is not from order
   * adding some classNames to match styling from requirement
   * and not to change the Step in order flow since
   * it's re-using the component
   */

  return (
    <Form className='ConfirmationForm'>
      <div className='orderSummary'>
        <h4
          className={classNames({
            'mt-2 mb-5 text-center': !jobId,
          })}
        >
          {text('confirmOrder')}
        </h4>
        {jobId ? (
          <>
            <div className='d-flex'>
              <p className='pr-3 font-weight-bold'>{text('projectName')}:</p>
              <p>{job.project.name}</p>
            </div>
            <div className='d-flex'>
              <p className='pr-3 font-weight-bold'>{text('yourSoftware')}:</p>
              <p>{softwares.entities[job.customer_software].name}</p>
            </div>
            {job.message ? (
              <>
                <p>{text('comments')}:</p>
                <Control
                  className='Message'
                  as='textarea'
                  rows={3}
                  value={job.message}
                  readOnly
                />
              </>
            ) : null}
          </>
        ) : null}
      </div>
      <div className='totalSummary'>
        <TextDivider isComplete />
        {canShowTotalDataPrice ? (
          <Row className='Price'>
            <Col xs={12} md={6}>
              <div className='PriceHeader' style={{ fontWeight: 500 }}>
                {numOrder - (numOrder > 1)}.{' '}
                {text(isJobPayable(job) ? 'dataInDatabase' : 'dataPayLater')}
              </div>
            </Col>
            <Col>
              <h4 className='text-right'>
                {totalDataPrice
                  ? formatCurrency(
                      totalDataPrice,
                      currency.name,
                      currency.scale_factor,
                      {},
                      true
                    )
                  : text('toBeConfirmed')}
              </h4>
            </Col>
          </Row>
        ) : null}
        {hasPurchasedMembership ? (
          <Row
            className={classNames('Price', {
              'pt-1': !jobId,
            })}
          >
            <Col xs={12} md={6}>
              <div style={{ fontWeight: 500 }} className='PriceHeader'>
                {numOrder}. {!jobId ? `${text('invoice')} - ` : null}{' '}
                {text('membership')}
              </div>
            </Col>
            <Col>
              <h4 className='text-right'>
                {formatCurrency(
                  selectedMembership.grand_total,
                  currency.name,
                  currency.scale_factor,
                  {},
                  true
                )}
              </h4>
            </Col>
          </Row>
        ) : null}
        {/* Shown when not null - e.g TBC or ~2Hrs etc */}
        {estimatedDelivery ? (
          <Row className='Price mt-3'>
            <Col xs={12} md={6}>
              <div className='PriceHeader'>{text('deliveryTime')}:</div>
              <h4>~{estimatedDelivery}</h4>
            </Col>
            <Col xs={12} md={6} />
          </Row>
        ) : null}
      </div>
      <hr />

      {/* Start: Components below are visible on small screens (Mobile).
       *  Will revisit on mobile view tickets */}
      {jobId ? (
        <div className='invoiceSummary'>
          {!hasValidMembership && !isStaff(currentUser.role) && (
            <MembershipInvoice jobId={jobId} />
          )}
          <ProductInvoice jobId={jobId} />
        </div>
      ) : null}
      {/* End comment */}

      <div
        className={classNames('Terms', {
          'mt-4 mb-4 d-flex flex-row justify-content-center': !jobId,
        })}
      >
        <Checkbox
          onChange={handleChange}
          name='terms'
          id='terms'
          value={values.terms}
          labelClass='ml-3'
        >
          {text('acceptTermsAndConditions', {
            termsConditions: (
              <Link
                key='urlTerms'
                to={{ pathname: process.env.LARKI_URL_TERMS }}
                target='_blank'
              >
                {text('termsAndConditions')}
              </Link>
            ),
          })}
        </Checkbox>
      </div>
    </Form>
  );
};

export default ConfirmationForm;
