/* eslint-disable react/jsx-max-depth */
import {
  FloatingArrow,
  FloatingPortal,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import T from 'prop-types';
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useClickOutside } from '../../hooks/useClickOutside';
import useEventListener from '../../hooks/useEventListener';
import { getMapQueryData } from '../../redux/actions';
import { convertToGeoJson } from '../../utilities/map';
import Icon from '../icon/Icon';
import { PRODUCT_DATA } from '../mapView/constants';
import { MapViewContext } from '../mapView/mapViewContext';
import ShapeMenu from '../shapeMenu/ShapeMenu';

const ARROW_WIDTH = 24;
const ARROW_HEIGHT = 12;
const ShapeMenuModal = forwardRef(
  ({ shapeData, shapeName, placement = 'left' }, ref) => {
    const { actions } = useContext(MapViewContext);
    const dispatch = useDispatch();
    const dialogRef = useRef(null);
    const arrowRef = useRef(null);
    const {
      actions: { closeShapeMenuModal },
    } = useContext(MapViewContext);

    useClickOutside(dialogRef, () => closeShapeMenuModal(), [
      '.click-outside-excluded',
    ]);

    const { refs, floatingStyles, context, update } = useFloating({
      placement,
      middleware: [
        flip(),
        offset({
          mainAxis: 26,
        }),
        shift({
          padding: 16,
        }),
        arrow({
          element: arrowRef,
        }),
      ],
      whileElementsMounted: autoUpdate,
    });
    const click = useClick(context);
    const { getFloatingProps } = useInteractions([click]);

    useEffect(() => {
      if (!ref.current) {
        actions.closeShapeMenuModal();
      }
    }, [actions, ref]);

    const handleEscapePress = (event) => {
      if (event.key === 'Escape') {
        closeShapeMenuModal();
      }
    };

    useEventListener('keydown', handleEscapePress, document);
    useEventListener('resize', update);

    useEffect(() => {
      if (ref.current && shapeData) {
        refs.setReference(ref.current);
        onProductMenuOpen();
        update();
      }
    }, [onProductMenuOpen, ref, refs, shapeData, update]);

    const onProductMenuOpen = useCallback(() => {
      if (shapeData) {
        dispatch(
          getMapQueryData({
            region: convertToGeoJson([{ region: shapeData.region }]).features[0]
              .geometry,
            category_names: PRODUCT_DATA.result,
          })
        );
      }
    }, [dispatch, shapeData]);

    return (
      <FloatingPortal>
        <div
          ref={refs.setFloating}
          style={{ ...floatingStyles, zIndex: 1000 }}
          {...getFloatingProps()}
        >
          <div
            className='position-relative'
            style={{
              border: '1px solid #BDBDBD',
              borderRadius: 2,
            }}
          >
            <FloatingArrow
              context={context}
              ref={arrowRef}
              width={ARROW_WIDTH}
              height={ARROW_HEIGHT}
              fill='#fff'
              stroke='#BDBDBD'
              strokeWidth={0.8}
              style={{
                zIndex: 1,
              }}
            />

            <Modal.Dialog id='product-dropdown-menu-container' ref={dialogRef}>
              <Modal.Header className='align-align-items-center'>
                <div className='d-flex flex-column'>
                  <div style={{ fontSize: 16, fontWeight: '600' }}>
                    {shapeName}
                  </div>
                </div>

                <Button
                  onClick={(ev) => {
                    ev.stopPropagation();
                    closeShapeMenuModal();
                  }}
                  variant='light'
                  className='row-action icon-button'
                >
                  <Icon icon='close' />
                </Button>
              </Modal.Header>
              <Modal.Body>
                <ShapeMenu
                  data={shapeData}
                  DropdownMenuComponent={Dropdown.Menu}
                  show
                  className='product-dropdown'
                  closeMenu={closeShapeMenuModal}
                  closeDropdownOnSelect={false}
                />
              </Modal.Body>
            </Modal.Dialog>
          </div>
        </div>
      </FloatingPortal>
    );
  }
);

ShapeMenuModal.propTypes = {
  shapeData: T.object,
  shapeName: T.string,
  placement: T.string,
};
ShapeMenuModal.displayName = 'ShapeMenuModal';
export default ShapeMenuModal;
