import { useTour } from '@reactour/tour';
import { isEmpty } from 'lodash';
import T from 'prop-types';
import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AMPLITUDE_EVENTS } from '../../constants/analytics';
import { TOUR_MODAL_START_CONFIG } from '../../pages/dashboard/components/onboarding/tour';
import { hideModal } from '../../redux/actions';
import { clearTourSelected, putTourStatus } from '../../redux/actions/tour';
import text from '../../text';
import { amplitudeTrack } from '../../utilities';
import { Button } from '../button';

const { DID_SITE_TOUR, SITE_TOUR_ACTIVITY } = AMPLITUDE_EVENTS;

export const INITIATE_TOUR_MODAL = 'INITIATE_TOUR_MODAL';

export default function InitiateTourModal({ show }) {
  const dispatch = useDispatch();
  const { setIsOpen, setSteps } = useTour();

  const { tourSelected } = useSelector((state) => ({
    tourSelected: state.tourReducer.tourSelected,
  }));

  const handleHide = () => {
    amplitudeTrack(SITE_TOUR_ACTIVITY.EVENT, {
      action: SITE_TOUR_ACTIVITY[tourSelected]['INC'],
    });
    dispatch(putTourStatus(tourSelected));
    setIsOpen(false);
    setSteps([]);
    dispatch(clearTourSelected());
    dispatch(hideModal(INITIATE_TOUR_MODAL));
  };

  const handleStart = () => {
    amplitudeTrack(DID_SITE_TOUR.EVENT, {
      action: DID_SITE_TOUR[tourSelected],
    });
    setIsOpen(true);
    dispatch(hideModal(INITIATE_TOUR_MODAL));
  };

  const MODAL_CONFIG = useMemo(() => {
    return TOUR_MODAL_START_CONFIG[tourSelected];
  }, [tourSelected]);

  return (
    !isEmpty(MODAL_CONFIG) && (
      <Modal
        centered
        className='InitiateTourModal'
        onHide={handleHide}
        show={show}
        backdrop='static'
      >
        <Modal.Header className='centered' closeButton>
          <img
            style={{
              height: '150px',
              width: '150px',
            }}
            src={MODAL_CONFIG.gif}
            alt='Image not found'
          />
        </Modal.Header>
        <Modal.Body className='centered'>
          <h3 className='green-1'>{MODAL_CONFIG.title}</h3>
          <p className='mt-2 mb-0' style={{ fontSize: 16 }}>
            {MODAL_CONFIG.description}
          </p>
        </Modal.Body>
        <Modal.Footer className='flex-column justify-content-center'>
          <Button autoFocus onClick={handleStart}>{text('letsStart')}</Button>
        </Modal.Footer>
      </Modal>
    )
  );
}

InitiateTourModal.propTypes = {
  show: T.bool,
};
