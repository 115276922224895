import { Formik } from 'formik';
import React, { useContext, useImperativeHandle, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useWizard } from 'react-use-wizard';
import * as yup from 'yup';

import FormFooter from '../../../components/form/FormikFormFooter';
import { softwares } from '../../../components/mapView/constants';
import { FORCE_JOB_UPDATE_MODAL } from '../../../components/modal/forceJobUpdateModal';
import Step from '../../../components/wizard/Step';
import useBreakpoint from '../../../hooks/useBreakpoint';
import {
  commitJob,
  commitOrder,
  getJob,
  showModal,
  updateJob,
} from '../../../redux/actions';
import text from '../../../text';
import { client } from '../../../utilities/api';
import { getNowDate } from '../../../utilities/date';
import { isJobPayable } from '../../../utilities/job';
import { isMembershipPriceId } from '../../../utilities/membership';
import { getProjectName } from '../../../utilities/project';
import { isStaff } from '../../../utilities/user';
import ProjectDetailsForm from '../ProjectDetailsForm/Form';
import { PaymentContext } from '../PaymentContext';
import { MapViewContext } from '../../../components/mapView/mapViewContext';
import routes from '../../../routes/constants';

const validationSchema = yup.object({
  customer_software: yup
    .string()
    .oneOf([
      'autodesk_revit',
      'autodesk_autocad',
      'graphisoft_archicad',
      'bentley',
      'vectorworks',
      'rhinoceros_rhino',
      'sketchup_scan_plugin',
    ])
    .required(text('required')),
  message: yup.string().notRequired(),
  projectId: yup.number(),
  projectName: yup
    .string()
    .required(text('pleaseSelectOrCreateProject'))
    .typeError(text('pleaseTypeProjectName')),
});

const ProjectDetailsStep = () => {
  const history = useHistory();
  const isBreakpointEnabled = useBreakpoint(['xs', 'sm']);
  const { jobId } = useParams();
  const { job, currentUser, membershipPriceId } = useSelector((state) => ({
    job: state.jobsReducer.job,
    membershipPriceId: state.order.membershipPriceId,
    currentUser: state.profileReducer.userProfile,
  }));
  const dispatch = useDispatch();
  const { nextStep, previousStep } = useWizard();
  const {
    refs: { wizzardFunctions },
  } = useContext(PaymentContext);
  const {
    state: { buyNowData },
  } = useContext(MapViewContext);

  useImperativeHandle(wizzardFunctions, () => ({
    previousStep,
  }));

  const isRequestQuote = useMemo(
    () => buyNowData.length === 0 && !isMembershipPriceId(membershipPriceId),
    [buyNowData.length, membershipPriceId]
  );

  const footerButtonLabel = useMemo(() => {
    if (isRequestQuote) {
      return text('requestQuote');
    }

    if (isBreakpointEnabled) {
      return text('continue ');
    }

    if (
      isStaff(currentUser.role) ||
      (!isJobPayable(job) && !isMembershipPriceId(membershipPriceId))
    ) {
      return text('continueToConfirm');
    } else {
      return text('continueToPaymentMethod');
    }
  }, [
    currentUser.role,
    isBreakpointEnabled,
    isRequestQuote,
    job,
    membershipPriceId,
  ]);

  const onUpdateJob = async (values) => {
    const { customer_software, message, force, projectId } = values;
    const updatedJob = await updateJob({
      customer_software,
      point_cloud_format: softwares.entities[customer_software].format,
      mergeProjectId: projectId,
      message,
      jobId,
      updatedAt: getNowDate(),
      force,
      onConflict: () => {
        onShowForceUpdateModal(values);
      },
    })(dispatch);

    if (updatedJob && !isRequestQuote) {
      nextStep();
    }
  };

  const onShowForceUpdateModal = (values) => {
    dispatch(
      showModal(FORCE_JOB_UPDATE_MODAL, {
        onSubmit: async () => {
          await onUpdateJob({ ...values, force: true });
        },
      })
    );
  };

  const commit = async () => {
    const order = await commitOrder(jobId)(dispatch);
    if (order) {
      await commitJob(jobId, null)(dispatch);
    }
  };

  const handleSubmit = async (values) => {
    const { projectName } = values;
    let projectId;
    if (values.projectId) {
      projectId = values.projectId;
    } else if (job.project?.id) {
      const { data } = await client.updateProjectName(
        job.project.id,
        projectName
      );
      projectId = data.project.id;
    } else {
      console.warn('no associated project found, creating new project...');
      const { data } = await client.createProject(projectName);
      projectId = data.project.id;
    }
    console.assert(projectId);
    await onUpdateJob({
      force: false,
      projectId,
      ...values,
    });

    // unecessary getJob???
    await getJob(jobId);

    if (isRequestQuote) {
      await commit();
      history.push({
        pathname: routes.statusPage.url('success'),
      });
    }
  };

  return (
    <Formik
      validateOnMount
      validateOnChange
      initialValues={{
        projectId: job.project.id,
        projectName: getProjectName(job.project),
        customer_software: job.customer_software || '', // TODO: use camelCase
        message: job.message,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid }) => {
        return (
          <>
            <Step>
              <ProjectDetailsForm />
            </Step>
            <FormFooter disabled={!isValid} label={footerButtonLabel} />
          </>
        );
      }}
    </Formik>
  );
};

export default ProjectDetailsStep;
