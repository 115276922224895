import React from 'react';
import classnames from 'classnames';

const MembershipCardInfo = (props) => {
  const {
    firstHeader,
    secondHeader,
    supplementalText,
    primaryText,
    secondText,
    noMembership,
  } = props;
  return (
    <div className={classnames('MembershipCardInfo', { noMembership })}>
      {firstHeader && <h5 className='firstHeader'>{firstHeader}</h5>}
      <div className='secondContainer'>
        {secondHeader && <h2 className='secondHeader'>{secondHeader}</h2>}
        {secondText && <p className='secondText'>{secondText}</p>}
      </div>
      {primaryText && <p className='primaryText'>{primaryText}</p>}
      {supplementalText && (
        <p className='supplementalText'>{`(${supplementalText})`}</p>
      )}
    </div>
  );
};

export default MembershipCardInfo;
