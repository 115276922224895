import React from 'react';
import { Modal } from 'react-bootstrap';
import { useAsyncFn } from 'react-use';
import { useDispatch } from 'react-redux';
import T from 'prop-types';

import AddNoteForm from '../../form/addNoteForm/AddNoteForm';
import text from '../../../text';
import { client } from '../../../utilities/api';
import { listProjectActivities } from '../../../redux/actions/activity';

export const ADD_NOTE_MODAL = 'ADD_NOTE_MODAL';

const AddNoteModal = (props) => {
  const { show, job, onHide, ...restProps } = props;
  const dispatch = useDispatch();
  const [createActivityState, addNote] = useAsyncFn(async (id, note) => {
    await client.createActivity(id, { ...note, type: 'note' });
    dispatch(listProjectActivities(job.project.id));
    onHide();
  });

  return (
    show && (
      <Modal centered show={show} onHide={onHide} {...restProps}>
        <Modal.Header className='align-end' closeButton>
          <h3 className='secondary'>{text('addNote')}</h3>
          <h6>{job.project.name}</h6>
        </Modal.Header>
        <AddNoteForm
          addState={createActivityState}
          projectId={job.project.id}
          onSubmit={addNote}
          onCancel={onHide}
        />
      </Modal>
    )
  );
};

AddNoteModal.propTypes = {
  show: T.bool,
  job: T.shape(),
  onHide: T.func,
};

export default AddNoteModal;
