import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { useAsyncFn, useMount, useSearchParam } from 'react-use';
import Page from '../../components/page/Page';
import { ANONYMOUS_ALERT } from '../../constants/alert';
import { alertConstants } from '../../redux/constants';
import routes from '../../routes/constants';
import text from '../../text';
import { larkiApiNoAuthentication } from '../../utilities/api';
import { setUser } from '../../utilities/storage';
import LoginForm from './loginForm';
import SideContent from './SideContent';
import SignupWizard from './signupWizard';

const Signup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const code = useSearchParam('code');
  const state = useSearchParam('state');
  const alertType = useSearchParam('alertType');
  const [email, setEmail] = useState('');

  const [{ loading }, googleLogin] = useAsyncFn(
    async () => {
      try {
        const stateParams = new URLSearchParams(state);
        const headers = {};
        if (state && stateParams.has('token')) {
          headers['Authorization'] = `Bearer ${stateParams.get('token')}`;
        }
        const { data } = await larkiApiNoAuthentication.post(
          '/user/google-login',
          {
            code,
          },
          { headers }
        );

        sessionStorage.clear();
        setUser(data);

        history.push(stateParams.get('redirectUrl') || routes.dashboard);
      } catch (error) {
        dispatch({
          type: alertConstants.ALERT_ERROR,
          payload: { message: error.response.data.message },
        });
      }
    },
    [code],
    {
      loading: code !== null,
    }
  );

  useEffect(() => {
    if (alertType && ANONYMOUS_ALERT[alertType]) {
      dispatch({
        type: ANONYMOUS_ALERT[alertType].type,
        payload: {
          message: text(ANONYMOUS_ALERT[alertType].message),
          dismissible: false,
        },
      });
    }
  }, [alertType]);

  useMount(() => {
    if (code) {
      googleLogin();
    }
  });

  if (loading) {
    return (
      <Page className='PageCenter'>
        <PulseLoader size={12} color='#31a283' />
      </Page>
    );
  }

  const onGoToLogin = (email) => setEmail(email);

  return (
    <Page className='Signup' noPadding>
      <div className='signUpContainer'>
        <SideContent />

        {email ? (
          <LoginForm email={email} />
        ) : (
          <SignupWizard onGoToLogin={onGoToLogin} />
        )}
      </div>
    </Page>
  );
};

export default Signup;
