/* eslint-disable react/jsx-max-depth */
import classnames from 'classnames';
import { capitalize, isEmpty, isNil, upperCase } from 'lodash';
import T from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import remarkGfm from 'remark-gfm';
import MeatballDropdown from '../../components/dropdown/MeatballDropdown';
import Icon from '../../components/icon/Icon';
import { CONFIRM_MODAL } from '../../components/modal/ConfirmModal';
import useGeocoder from '../../components/RightMenu/useGeocoder';
import Activity from '../../components/timeline/Activity';
import { ACTIVITY_TYPES } from '../../constants/activity';
import { deleteActivity, deleteAsset, showModal } from '../../redux/actions';
import text from '../../text';
import {
  isActivityFileDownload,
  isActivityType,
} from '../../utilities/activity';
import { formatDate, formatTime } from '../../utilities/date';
import { getShortenedAddressFromGeocoder } from '../../utilities/map';
import { isAdministrator, isStaff } from '../../utilities/user';
import ActivityAsset from './ActivityAsset';
import Invoice from './ActivityTemplates/Invoice';
import RequestForQuote from './ActivityTemplates/RFQ';
import JobStatusUpdate from './JobStatusUpdate';

const TypeIconMap = {
  file: 'clip',
  order: 'shopping-cart',
  note: 'speech',
  user: 'user-outline',
  alert: 'larki-icon',
  status: '?',
};

const ActivityTemplate = ({ metadata, job, rfqCharges }) => {
  if (!job || !job.quote) {
    return null;
  }

  if (metadata && metadata.action === 'quote') {
    if (job.quote && job.quote.charges && rfqCharges) {
      return (
        <RequestForQuote
          title={upperCase(text('requestForQuote'))}
          job={job}
          rfqCharges={rfqCharges}
        />
      );
    } else {
      return <Invoice job={job} />;
    }
  } else {
    return (
      <>
        <p className='font-weight--600 mb-3 font-18'>
          {upperCase(text('paymentReceived'))}
        </p>
        <Link
          className='font-weight-bold'
          style={{ textDecoration: 'underline' }}
          key='viewPaymentReceipt'
          to={{ pathname: metadata.receipt_url }}
          target='_blank'
        >
          {text('viewPaymentReceipt')}
        </Link>
      </>
    );
  }
};

ActivityTemplate.propTypes = {
  metadata: T.shape({
    action: T.string,
    receipt_url: T.string,
  }),
  rfqCharges: T.array,
  job: T.object,
};

// TODO: Refactor this to project activity
const JobActivity = (props) => {
  const {
    jobActivity: {
      id,
      created_at,
      created_assets,
      title,
      description,
      type,
      job_id,
      project_id,
      detailed_status_update,
      is_notification_sent,
      visibility,
      created_user,
      rfqCharges,
      metadata,
    },
    currentProject,
    currentUser,
  } = props;

  const dispatch = useDispatch();
  const { geocode } = useGeocoder();
  const [shortenedAddress, setShortenedAddress] = useState(null);
  const [addressStatus, setAddressStatus] = useState('checking');

  const onDeleteActivity = () => {
    dispatch(
      showModal(CONFIRM_MODAL, {
        onConfirm: () => dispatch(deleteActivity(project_id, id)),
        title:
          type === ACTIVITY_TYPES.note
            ? text('deleteNote')
            : text('deleteFileUploadActivity'),
        body:
          type === ACTIVITY_TYPES.note
            ? text('deleteNoteBody')
            : text('deleteFileUploadActivityBody'),
        okLabel: text('delete'),
      })
    );
  };

  const onDeleteAsset = (assetId) => {
    dispatch(
      showModal(CONFIRM_MODAL, {
        onConfirm: () => dispatch(deleteAsset(project_id, job_id, id, assetId)), // id = activityId
        title: text('deleteFile'),
        body: text('deleteFileBody'),
        okLabel: text('delete'),
      })
    );
  };

  const getActivityIcon = () => {
    // handle old File Downloaded activities w/c are still under assets
    if (isActivityFileDownload({ title, description }) && type !== 'larki') {
      return TypeIconMap.alert;
    }    
    return TypeIconMap[type] || 'speech';
  };

  const getActivityType = (type) => {
    // handle old File Downloaded activites that were saved as notes
    if (
      [ACTIVITY_TYPES.note, ACTIVITY_TYPES.alert].includes(type) &&
      isActivityFileDownload({ title, description })
    ) {
      return 'download';
    }
    return type;
  };

  useEffect(() => {
    let isMounted = true;
    const fetchGeocodedAddress = async () => {
      if (
        job_id &&
        !isEmpty(currentProject?.jobs) &&
        addressStatus === 'checking'
      ) {
        const fullAddress = currentProject.jobs.find(
          (job) => job.id === job_id
        )?.full_address;
        if (fullAddress) {
          try {
            const place = await geocode(fullAddress);
            if (isMounted) {
              const shortenedAddress = getShortenedAddressFromGeocoder(place);
              setShortenedAddress(shortenedAddress);
            }
          } catch (error) {
            console.error('Geocode error:', error);
          }
        }
      }

      if (isMounted) {
        setAddressStatus('done');
      }
    };
    fetchGeocodedAddress();
    return () => {
      isMounted = false;
    };
  }, [job_id, currentProject?.jobs, addressStatus, geocode]);

  const job = useMemo(
    () => currentProject.jobs.find(({ id }) => id === job_id),
    [currentProject.jobs, job_id]
  );

  const activityMetadata = useMemo(() => {
    if (job && type === ACTIVITY_TYPES.order && metadata) {
      return metadata;
    }
    return null;
  }, [job, type, metadata]);

  const { jobActivity } = props;

  return !isActivityType(jobActivity, 'status') ? (
    <Activity
      className={classnames('JobActivity', { isStaff: isStaff(visibility) })}
    >
      <>
        <Activity.Icon iconProps={{ icon: getActivityIcon() }} />
        <Activity.Card>
          <Activity.Card.Header>
            <Row className='m-0 w-100'>
              <div className='main-content font-weight--500 font-12'>
                <Activity.Card.Type
                  type={type ? capitalize(getActivityType(type)) : null}
                />
                <Activity.Card.Date
                  hasPipeSeparator={job_id || project_id}
                  date={formatDate(created_at)}
                  time={formatTime(created_at, 'h:mmaaa')}
                />

                {project_id ? (
                  <Activity.Card.HeaderElement
                    className={classnames(
                      {
                        hasPipeSeparator: !!job_id,
                      },
                      'projectId'
                    )}
                  >
                    {text('projectId')}:{project_id}
                  </Activity.Card.HeaderElement>
                ) : null}

                {job_id ? (
                  <Activity.Card.HeaderElement className='jobId'>
                    {text('orderId')}:{job_id}
                  </Activity.Card.HeaderElement>
                ) : null}

                {is_notification_sent ? (
                  <OverlayTrigger
                    delay={{ show: 250 }}
                    placement='auto'
                    overlay={
                      <Tooltip className='notification-sent'>
                        {text('notificationSent')}
                      </Tooltip>
                    }
                  >
                    <Icon icon='envelope' />
                  </OverlayTrigger>
                ) : null}
              </div>
              {isStaff(visibility) && (
                <span className='larki-tag'>{`[${text('larkiOnly')}]`} </span>
              )}
              <Activity.Card.HeaderRight>
                {(!created_user?.full_name || isStaff(created_user?.role)) &&
                !isStaff(currentUser?.role)
                  ? 'LARKI'
                  : created_user?.full_name}
                {isAdministrator(currentUser?.role) && !isNil(project_id) ? (
                  <MeatballDropdown
                    btnClassName='icon-button ActivityMeatballMenuButton'
                    tooltipProps={{
                      text: text('more'),
                    }}
                  >
                    <Dropdown.Item onClick={onDeleteActivity}>
                      {text('delete')}
                    </Dropdown.Item>
                  </MeatballDropdown>
                ) : null}
              </Activity.Card.HeaderRight>
            </Row>
            {addressStatus === 'checking' ? (
              <div className='position-relative w-100'>
                <PulseLoader size={8} />
              </div>
            ) : shortenedAddress ? (
              <Row className='w-100 d-flex font-weight--400 font-12'>
                {shortenedAddress}
              </Row>
            ) : null}
          </Activity.Card.Header>
          <div className='ActivityContent'>
            {!isEmpty(metadata) ? (
              <ActivityTemplate
                metadata={activityMetadata}
                job={job}
                rfqCharges={rfqCharges}
                description={description}
              />
            ) : (
              <>
                <div className='ActivityTitle font-16 font-weight--600 text-uppercase'>
                  {title}
                </div>
                {created_assets ? (
                  <ActivityAsset
                    onDelete={onDeleteAsset}
                    projectId={project_id}
                    assets={created_assets}
                    currentUser={currentUser}
                  />
                ) : null}
                <ReactMarkdown linkTarget='_blank' remarkPlugins={[remarkGfm]}>
                  {description}
                </ReactMarkdown>
              </>
            )}
          </div>
        </Activity.Card>
      </>
    </Activity>
  ) : (
    <JobStatusUpdate
      detailedStatuses={detailed_status_update}
      createdDate={created_at}
      activityTitle={title}
      activityDescription={description}
      jobId={job_id}
      projectId={project_id}
    />
  );
};

JobActivity.propTypes = {
  jobActivity: T.shape(),
  currentUser: T.shape(),
  currentProject: T.shape(),
};

export default JobActivity;
