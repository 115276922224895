import classnames from 'classnames';
import React from 'react';
import { useWizard } from 'react-use-wizard';

import Icon from '../icon/Icon';
import { noop } from 'lodash';

const BreadcrumbStep = ({ stepIndex, stepLabel, name, disabled }) => {
  const { goToStep, activeStep } = useWizard();

  const handleClick = () => {
    goToStep(stepIndex);
  };

  const isVisited = activeStep > stepIndex;

  return (
    <div
      className={classnames('BreadcrumbStep', {
        visited: isVisited,
        isCurrentStep: activeStep === stepIndex,
      })}
    >
      <div className='thread' />
      <div className='globe' onClick={!disabled ? handleClick : noop}>
        {isVisited && activeStep !== stepIndex ? (
          <Icon icon='tick' />
        ) : (
          stepLabel
        )}
      </div>
      <p className='name mb-0'>{name}</p>
    </div>
  );
};

export default BreadcrumbStep;
