import { tourActionConstants } from '../constants/tour';

const defaultState = {
  tourSelected: null,
};

const tourReducer = (state = defaultState, action) => {
  switch (action.type) {
    case tourActionConstants.TOUR_SELECTED:
      return { ...state, tourSelected: action.payload };
    case tourActionConstants.CLEAR_TOUR_SELECTED:
      return { ...state, tourSelected: null };
    default:
      return state;
  }
};

export default tourReducer;
