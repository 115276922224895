import React, { useEffect, useState } from 'react';
import BootstrapAlert from 'react-bootstrap/Alert';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMount } from 'react-use';
import classnames from 'classnames';

import Icon from '../icon/Icon';

const getAlertIcon = (variant) => {
  switch (variant) {
    case 'info':
      return 'information';
    default:
      return '';
  }
};

const Alert = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [alertDetails, setAlertDetails] = useState({
    message: '',
    variant: '',
  });
  const alertReducer = useSelector((state) => state.alerts.alert);

  const handleClose = () => {
    setOpen(false);
  };

  useMount(() =>
    history.listen((location, action) => {
      if (action === 'PUSH') {
        handleClose();
      }
    })
  );

  useEffect(() => {
    if (alertReducer.message && alertReducer.variant) {
      setAlertDetails(alertReducer);
      setOpen(true);
      if (alertReducer.dismissible) {
        setTimeout(handleClose, 6000);
      }
    } else {
      handleClose();
    }
  }, [alertReducer]);

  return (
    <BootstrapAlert
      className={classnames({
        hasIcon: alertDetails.icon ?? getAlertIcon(alertDetails.variant),
      })}
      show={open}
      variant={alertDetails.variant}
      onClose={handleClose}
      onClick={handleClose}
      dismissible={alertReducer.dismissible}
    >
      {(getAlertIcon(alertDetails.variant) || alertDetails.icon) && (
        <Icon icon={alertDetails.icon ?? getAlertIcon(alertDetails.variant)} />
      )}
      {alertDetails.message}
    </BootstrapAlert>
  );
};

export default Alert;
