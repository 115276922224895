import React from 'react';
import { useWizard } from 'react-use-wizard';
import classnames from 'classnames';

const Breadcrumbs = ({ children }) => {
  const { activeStep } = useWizard();
  return (
    <div className='Breadcrumbs'>
      <div className='BreadcrumbsGuide'>
        {activeStep > 0 && activeStep < children.length && (
          <h6
            className={classnames('stepLabel', { isCurrentStep: activeStep })}
          >{`${activeStep + 1}.${children[activeStep].props.name}`}</h6>
        )}
        {activeStep + 1 < children?.length && (
          <h6 className='stepLabel'>{`${activeStep + 2}.${
            children[activeStep + 1].props.name
          }`}</h6>
        )}
      </div>
      <div className='BreadcrumbsSteps'>{children}</div>
    </div>
  );
};

export default Breadcrumbs;
