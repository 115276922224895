import _ from 'lodash/fp';

import { jobsConstants, mapConstants } from '../constants';

const defaultMapState = {
  mapDataQuery: {},
  mapDefaultDataQuery: {},
  smartSelections: undefined,
  boundaries: undefined,
  lotArea: undefined,
  isLoadingSmartSelections: false,
  smartSelectionsDeliveryMethod: undefined,
  isZoomToggle: false,
  hasSmartSelectionLoaded: false,
  highRes: {
    currentDate: null,
    layers: {
      data: null,
      isLoading: false,
      error: null,
    },
    collections: {
      data: null,
      isLoading: false,
      error: null,
    },
  },
  isAutomaticDataAvailable: false,
  loadingRhsButtonIds: [],
};

const mapReducer = (state = defaultMapState, action) => {
  switch (action.type) {
    case mapConstants.SET_MAP_DATA_QUERY:
      return { ...state, mapDataQuery: action.payload };
    case mapConstants.SET_MAP_DEFAULT_DATA_QUERY:
      return { ...state, mapDefaultDataQuery: action.payload };
    case mapConstants.CLEAR_MAP_DATA_QUERY:
      return { ...state, mapDataQuery: {} };
    case mapConstants.GET_PLACE_SMART_SELECTIONS:
      return {
        ...state,
        isLoadingSmartSelections: true,
        smartSelectionsDeliveryMethod: action.payload.deliveryMethod,
        loadingRhsButtonIds: action.payload.buttonId
          ? [...state.loadingRhsButtonIds, action.payload.buttonId]
          : state.loadingRhsButtonIds,
      };
    case mapConstants.GET_PLACE_SMART_SELECTIONS_SUCCESS:
      return {
        ...state,
        smartSelections: action.payload.smartSelections,
        boundaries: action.payload.boundaries,
        lotArea: action.payload.lotArea,
        isLoadingSmartSelections: false,
        smartSelectionsDeliveryMethod: undefined,
        hasSmartSelectionLoaded: true,
        loadingRhsButtonIds: action.payload.buttonId
          ? state.loadingRhsButtonIds.filter(
              (buttonId) => buttonId !== action.payload.buttonId
            )
          : state.loadingRhsButtonIds,
      };
    case mapConstants.SET_AUTOMATIC_SMART_SELECTIONS_AVAILABILITY:
      return {
        ...state,
        isAutomaticDataAvailable: action.payload.isAutomaticDataAvailable,
        boundaries: action.payload.boundaries,
        lotArea: action.payload.lotArea,
        isLoadingSmartSelections: false,
      };
    case jobsConstants.QUOTE_ORDER_SUCCESS: {
      const property = action.payload.user_order?.property;
      return {
        ...state,
        ...(property && {
          boundaries: property,
          lotArea: property.area_in_sqm,
        }),
      };
    }
    case mapConstants.CLEAR_BOUNDARIES: {
      return {
        ...state,
        boundaries: undefined,
      };
    }
    case jobsConstants.SET_JOB: {
      return {
        ...state,
        boundaries: action.payload?.user_order?.property,
      };
    }
    case mapConstants.CLEAR_SMART_SELECTIONS: {
      return {
        ...state,
        smartSelections: undefined,
        lotArea: undefined,
      };
    }
    case mapConstants.SET_ZOOM_TOGGLE: {
      return {
        ...state,
        isZoomToggle: action.payload.isZoomToggle,
      };
    }
    case mapConstants.SET_LOT_AREA: {
      return {
        ...state,
        lotArea: action.payload.lotArea,
      };
    }
    case mapConstants.RESET_ZOOM_TOGGLE_BOUNDARY_VISIBILITY: {
      return {
        ...state,
        isZoomToggle: false,
      };
    }
    case mapConstants.RESET_HAS_SMART_SELECTIONS_LOADED: {
      return {
        ...state,
        hasSmartSelectionLoaded: false,
      };
    }

    case mapConstants.LOAD_HIGH_RES_COLLECTIONS:
      return _.set('highRes.collections.isLoading', true)(state);
    case mapConstants.LOAD_HIGH_RES_COLLECTIONS_SUCCESS:
      return _.set('highRes.collections', {
        data: action.payload,
        isLoading: false,
        error: null,
      })(state);
    case mapConstants.LOAD_HIGH_RES_COLLECTIONS_FAILURE:
      return _.set('highRes.collections', {
        data: null,
        isLoading: false,
        error: action.payload,
      })(state);

    case mapConstants.LOAD_HIGH_RES_LAYERS:
      return _.set('highRes.layers.isLoading', true)(state);
    case mapConstants.LOAD_HIGH_RES_LAYERS_SUCCESS:
      return _.set('highRes.layers', {
        data: action.payload,
        isLoading: false,
        error: null,
      })(state);
    case mapConstants.SET_HIGH_RES_CURRENT_DATE:
      return _.set('highRes.currentDate', action.payload)(state);
    case mapConstants.LOAD_HIGH_RES_LAYERS_FAILURE:
      return _.set('highRes.layers', {
        data: null,
        isLoading: false,
        error: action.payload,
      })(state);

    case mapConstants.NEXT_HIGH_RES_LAYER: {
      const { currentDate, layers } = state.highRes;
      const dates = _.keys(layers.data);
      if (!dates) {
        console.warn('cannot change date');
        return state;
      }
      const currentIndex = dates.indexOf(currentDate);
      console.assert(currentIndex !== -1, 'currentDate not found in layers');
      if (currentIndex === dates.length - 1) {
        console.warn('currentDate is already the latest date');
        return state;
      }
      const nextDate = dates[currentIndex + 1];
      return _.set('highRes.currentDate', nextDate)(state);
    }
    case mapConstants.PREV_HIGH_RES_LAYER: {
      const { currentDate, layers } = state.highRes;
      const dates = _.keys(layers.data);
      if (!dates) {
        console.warn('cannot change date');
        return state;
      }
      const currentIndex = dates.indexOf(currentDate);
      console.assert(currentIndex !== -1, 'currentDate not found in layers');
      if (currentIndex === 0) {
        console.warn('currentDate is already the earliest date');
        return state;
      }
      const prevDate = dates[currentIndex - 1];
      return _.set('highRes.currentDate', prevDate)(state);
    }
    case mapConstants.SET_BOUNDARIES: {
      return {
        ...state,
        boundaries: action.payload.boundaries,
        lotArea: action.payload.lotArea,
      };
    }

    default:
      return state;
  }
};

export default mapReducer;
