import React from 'react';
import { Link } from 'react-router-dom';
import { useSearchParam } from 'react-use';

import text from '../../text';
import FormContainer from '../../components/containers/FormContainer';

const ResetPasswordSuccess = () => {
  const redirectUrlParam = useSearchParam('redirectUrl');
  return (
    <FormContainer>
      <FormContainer.SecondaryHeader>
        <h2>{text('success')}!</h2>
      </FormContainer.SecondaryHeader>
      <FormContainer.Body>
        <FormContainer.PrimaryHeader>
          <h2>{text('success')}!</h2>
        </FormContainer.PrimaryHeader>
        <p className='text-center'>{text('passwordReset')}</p>
        <Link
          to={redirectUrlParam ?? '/'}
          className='btn btn-primary full-width'
        >
          {text('login')}
        </Link>
      </FormContainer.Body>
    </FormContainer>
  );
};

export default ResetPasswordSuccess;
