import cn from 'classnames';
import T from 'prop-types';
import React, { useContext } from 'react';

import { useSelector } from 'react-redux';
import Img from '../../components/img/Img';
import useBreakpoint from '../../hooks/useBreakpoint';
import text from '../../text';
import { LayoutContext } from '../LayoutContext';
import FileManagerFallbackArrow from './FileManagerFallbackArrow';

export default function FileManagerFallbackOverlay({ show }) {
  const { sideNav } = useSelector((state) => ({
    sideNav: state.layout.sideNav,
  }));

  const isFallbackArrowVisibleForBreakpoint = useBreakpoint(['lg', 'xl']);

  const { startNewProjectRef } = useContext(LayoutContext);

  return (
    <div
      id='file-manager-fallback-overlay'
      className={cn('file-manager-fallback-overlay', show && 'show')}
    >
      <FileManagerFallbackArrow
        show={isFallbackArrowVisibleForBreakpoint && sideNav.isVisible && show}
        referenceElement={startNewProjectRef}
      />

      <div className='content'>
        <Img
          img='file-manager-fallback-illustration'
          alt={text('startNewProject')}
        />
        <h3>{text('startANewProject')}</h3>
        <p>{text('fileManagerInstructions')}</p>
      </div>
    </div>
  );
}

FileManagerFallbackOverlay.propTypes = {
  show: T.bool.isRequired,
};
