import text, { formatCurrency } from '../text';

export const BILLING_CYCLE = {
  day: 'daily',
  week: 'weekly',
  month: 'monthly',
  year: 'yearly',
};

export const ENTITLEMENTS = {
  HIGH_RES_MAP: 'high-res-map',
  _3D_VIEWER: '3d-viewer',
};

export const MEMBERSHIP = {
  MONTHLY: { PRICE_ID: process.env.STRIPE_SUBSCRIPTION_MONTHLY_PRICE_ID },
  YEARLY: { PRICE_ID: process.env.STRIPE_SUBSCRIPTION_YEARLY_PRICE_ID },
  NO_MEMBERSHIP: 'noMembership',
};

export const STATUS = {
  TRIALING: 'trialing',
};

export const TRIAL_MEMBERSHIP_BENEFITS = [
  text('trial2DMapBenefit'),
  text('trial3DViewerBenefit'),
  text('trialFileManagerBenefit'),
  text('trialSupportBenefit'),
  text('trialFreeTierBenefit'),
  text('andMoreBenefit'),
];

export const ESSENTIALS_MEMBERSHIP_BENEFITS = [
  text('free2DMapBenefit'),
  text('freeLotAreaBenefit'),
  text('essentialsFileManagerBenefit1'),
  text('essentialsFileManagerBenefit2'),
  text('essentialsDiscountBenefit'),
  text('essentialsScanServicesDiscountBenefit'),
  text('essentials3DViewerDiscountBenefit1'),
  text('essentials3DViewerDiscountBenefit2'),
  text('andMoreBenefit'),
];

export const FREE_BENEFITS = [
  text('free2DMapBenefit'),
  text('freeLotAreaBenefit'),
  text('freeDownloadFileBenefit'),
  text('freeFileBenefit'),
  text('freeDiscountBenefit'),
  text('andMoreBenefit'),
];

export const SUBSCRIPTION_PRICE = {
  monthly: formatCurrency(99, 'aud', 1, {}, true),
  yearly: formatCurrency(990, 'aud', 1, {}, true),
};
