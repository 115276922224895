import classnames from 'classnames';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useAsyncFn } from 'react-use';
import text from '../../text';
import { client } from '../../utilities/api';
import WhiteContainer from '../containers/WhiteContainer';
import MeatballDropdown from '../dropdown/MeatballDropdown';
import Radio from '../form/Radio';
import Loading from '../loading/Loading';
import { cardsBrand } from './constants';

const CardOption = (props) => {
  const { card, isSelected, onChange, name, listCards } = props;

  const [removeState, removeCard] = useAsyncFn(async (cardId) => {
    await client.removeCard(cardId);
    listCards();
    onChange();
  });

  return (
    <WhiteContainer className={classnames('CardOption', { isSelected })}>
      {removeState.loading ? (
        <Loading />
      ) : (
        <>
          <Radio
            id={card.id}
            onChange={onChange}
            name={name}
            value={card.id}
            checked={isSelected}
          />
          <div className='details'>
            <div>
              <span>
                {cardsBrand[card.brand] ? cardsBrand[card.brand] : card.brand}
              </span>
              <span>{`•••• •••• •••• ${card.last4}`}</span>
            </div>
            {card.name}
          </div>
          <MeatballDropdown btnClassName='icon-button'>
            <Dropdown.Item onClick={() => removeCard(card.id)}>
              {text('remove')}
            </Dropdown.Item>
          </MeatballDropdown>
        </>
      )}
    </WhiteContainer>
  );
};

export default CardOption;
