import React from 'react';
import { Modal } from 'react-bootstrap';
import { useWizard } from 'react-use-wizard';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { Button } from '../../../../components/button';
import Control from '../../../../components/form/Control';
import text from '../../../../text';
import { onboardingConstants } from './constants';
import { useOnboarding } from './reducer';
import FormContainer from '../../../../components/containers/FormContainer';

const onboardingSchema = yup.object({
  fullName: yup.string().required(),
  phoneNumber: yup.string().required(),
});

const Welcome = () => {
  const [{ fullName, phoneNumber }, dispatch] = useOnboarding();
  const { nextStep } = useWizard();

  const handleSubmit = ({ fullName, phoneNumber }) => {
    dispatch({
      type: onboardingConstants.SET_FULL_NAME,
      payload: fullName,
    });
    dispatch({
      type: onboardingConstants.SET_PHONE_NUMBER,
      payload: phoneNumber,
    });
    nextStep();
  };

  return (
    <>
      <Modal.Header>
        <h2 className='text-center w-100'>{text('welcomeToLarki')}!</h2>
      </Modal.Header>
      <Modal.Body>
        <h6 className='secondary-heading'>{text('tellUsAboutYourself')}</h6>
        <h6 className='secondary-heading'>
          {text('letsBeginWithFullNameAndPhoneNumber')}
        </h6>
        <Formik
          initialValues={{ fullName, phoneNumber }}
          validationSchema={onboardingSchema}
          onSubmit={handleSubmit}
          validateOnChange
          validateOnMount
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            dirty,
            isValid,
          }) => (
            <Form className='form'>
              <Control
                required
                type='text'
                name='fullName'
                label={text('fullName')}
                value={values?.fullName || ''}
                onChange={handleChange}
                autoComplete='off'
                isFailed={touched.fullName && !!errors.fullName}
                isSuccessful={!errors.fullName}
                validationError={errors.fullName}
              />
              <Control
                required
                type='text'
                name='phoneNumber'
                label={text('phoneNumber')}
                value={values?.phoneNumber || ''}
                onChange={handleChange}
                autoComplete='off'
                isFailed={touched.phoneNumber && !!errors.phoneNumber}
                isSuccessful={!errors.phoneNumber}
                validationError={errors.phoneNumber}
              />
              <Button
                type={!isSubmitting && 'submit'}
                disabled={!(dirty && isValid)}
                className='submit-button btn-block w-100'
              >
                {text('nextStep')}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </>
  );
};

export default Welcome;
