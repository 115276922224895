import _ from 'lodash/fp';
import React, { Fragment, useContext, useEffect, useMemo } from 'react';
import { Collapse } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import T from 'prop-types';
import {
  toggleAutomaticDataTableVisibility,
  toggleManualDataTableVisibility,
} from '../../redux/actions';
import text, { formatCurrency } from '../../text';
import {
  isSelectionAutomatic,
  isSelectionManual,
} from '../../utilities/selection';
import Icon from '../icon/Icon';
import { getAddOnConfig } from '../mapView/drawingManager/utilities';
import { MapViewContext } from '../mapView/mapViewContext';
import DataTable from '../table/dataTable/DataTable';
import CartEmpty from './CartEmpty';
import OtherChargeRow from './OtherChargeRow';
import ProductTable, { ProductTableHeader } from './ProductTable';

export default function CartTable(props) {
  const { isReadOnly } = props;
  const { state } = useContext(MapViewContext);
  const dispatch = useDispatch();
  const { layout } = useSelector((reduxState) => ({
    layout: reduxState.layout,
  }));

  const selectionsPerProduct = useMemo(() => {
    const products = new Map();
    if (state.selections) {
      state.selections.forEach((selection) => {
        if (products?.[selection.category_name]) {
          products[selection.category_name] = [
            ...products[selection.category_name],
            selection,
          ];
        } else {
          products[selection.category_name] = [selection];
        }
      });
    }
    return products;
  }, [state.selections]);

  const automaticProducts = useMemo(() => {
    return Object.entries(selectionsPerProduct).filter((product) =>
      isSelectionAutomatic(product[1][0])
    );
  }, [selectionsPerProduct]);

  const manualProducts = useMemo(() => {
    return Object.entries(selectionsPerProduct).filter((product) => {
      const isAddOn = !!getAddOnConfig(product[1][0]?.category_name);
      return isSelectionManual(product[1][0]) && !isAddOn;
    });
  }, [selectionsPerProduct]);

  const addOnProducts = useMemo(() => {
    return Object.entries(selectionsPerProduct).filter((product) => {
      const isAddOn = !!getAddOnConfig(product[1][0]?.category_name);
      return isSelectionManual(product[1][0]) && isAddOn;
    });
  }, [selectionsPerProduct]);

  const chargesPerProduct = useMemo(() => {
    const products = new Map();
    if (state.job?.quote?.charges) {
      state.job.quote.charges.forEach((charge) => {
        products[charge.display_name] = { ...charge };
      });
    }
    return products;
  }, [state.job]);

  // TODO: use context
  const coveragePerProduct = useMemo(() => {
    const products = {};
    if (state.job?.coverage?.selections) {
      _.forEach((selection) => {
        if (selection.category_name in products) {
          products[selection.category_name].push(selection);
        } else {
          products[selection.category_name] = [selection];
        }
      })(state.job.coverage.selections);
    }
    return products;
  }, [state.job]);

  const siteDeploymentFee = useMemo(() => {
    const fee = state.job?.quote?.fees.find(
      (fee) => fee.id === 'site_deployment_fee'
    );

    return fee
      ? !isNaN(fee.price.total)
        ? formatCurrency(
            fee.price.total,
            state.job?.quote?.currency.name,
            state.job?.quote?.currency.scale_factor,
            {},
            true
          )
        : text('toBeConfirmed')
      : '';
  }, [state.job]);

  useEffect(() => {
    // note that whenever the automatic/manual data table is visible
    // the add-on button is not visible, and vice versa

    const isAutomaticDataAdded = state.selections.some(
      (selection) => selection.delivery_method === 'automatic'
    );
    dispatch(toggleAutomaticDataTableVisibility(isAutomaticDataAdded));

    const isManualDataAdded = state.selections.some(
      (selection) => selection.delivery_method === 'manual'
    );
    dispatch(toggleManualDataTableVisibility(isManualDataAdded));
  }, [dispatch, state.selections]);

  const hasAddOns = state.hasAddOns && !isReadOnly;
  return (
    <div className='CartTableV2'>
      {state.isCartEmpty ? (
        <CartEmpty hasAddOns={hasAddOns} />
      ) : (
        <>
          <hr className='my-2' />
          <h3 className='title green-1'>
            <Icon icon='shopping-cart' />
            {text('cart')}
          </h3>
          <hr className='my-2' />
        </>
      )}

      <ProductGroup
        headerText={text('buyNowDataInDatabase')}
        isVisible={layout.automaticDataTable.isVisible}
        products={automaticProducts}
        selectionsPerProduct={selectionsPerProduct}
        chargesPerProduct={chargesPerProduct}
        coveragePerProduct={coveragePerProduct}
        isReadOnly={isReadOnly}
        quote={state.job?.quote}
      />

      <ProductGroup
        headerText={text('getQuoteData')}
        isVisible={layout.manualDataTable.isVisible}
        products={manualProducts}
        selectionsPerProduct={selectionsPerProduct}
        chargesPerProduct={chargesPerProduct}
        coveragePerProduct={coveragePerProduct}
        isReadOnly={isReadOnly}
        quote={state.job?.quote}
        siteDeploymentFee={siteDeploymentFee}
      />

      <ProductGroup
        headerText={text('getQuoteAddOns')}
        isVisible={addOnProducts.length > 0}
        products={addOnProducts}
        selectionsPerProduct={selectionsPerProduct}
        chargesPerProduct={chargesPerProduct}
        coveragePerProduct={coveragePerProduct}
        isReadOnly={isReadOnly}
        quote={state.job?.quote}
      />
    </div>
  );
}

CartTable.propTypes = {
  isReadOnly: T.bool,
};

const ProductGroup = ({
  headerText,
  isVisible,
  products,
  selectionsPerProduct,
  chargesPerProduct,
  coveragePerProduct,
  isReadOnly,
  quote,
  siteDeploymentFee,
}) => {
  if (!isVisible) {
    return null;
  }

  return (
    <>
      <ProductTableHeader headerText={headerText} />
      <Collapse in={isVisible}>
        <div>
          <DataTable className='DataTableV2'>
            {Object.keys(selectionsPerProduct).length > 0 && quote ? (
              <Fragment key='wrapper'>
                <tbody>
                  {products.map((product, index) => {
                    return (
                      <ProductTable
                        key={index}
                        product={product}
                        chargesPerProduct={chargesPerProduct}
                        coveragePerProduct={coveragePerProduct}
                        isReadOnly={isReadOnly}
                        index={index}
                      />
                    );
                  })}

                  {siteDeploymentFee ? (
                    <OtherChargeRow
                      isReadOnly={isReadOnly}
                      description={text('siteDeploymentFee')}
                      amount={siteDeploymentFee}
                      infoMessage={text('siteDeploymentFeeInfo')}
                    />
                  ) : null}
                </tbody>
              </Fragment>
            ) : null}
          </DataTable>
        </div>
      </Collapse>
    </>
  );
};

ProductGroup.propTypes = {
  headerText: T.string,
  isVisible: T.bool,
  products: T.oneOfType([T.object, T.array]),
  selectionsPerProduct: T.object,
  chargesPerProduct: T.object,
  coveragePerProduct: T.object,
  isReadOnly: T.bool,
  quote: T.object,
  siteDeploymentFee: T.oneOfType([T.string, T.number]),
};
