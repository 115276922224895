export const CART_TABLE = {
  COLUMN: {
    POINT_CLOUD: 'pointCloudColumn',
    CAPTURED: 'capturedColumn',
    VIEW: 'viewColumn',
    MEATBALL: 'meatballColumn',
    AREA: 'areaColumn',
    DELIVERY: 'deliveryColumn',
    PRICE: 'priceColumn',
    DELETE: 'deleteColumn',
    VISIBILITY: 'visibility',
  },
};
