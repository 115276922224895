import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { MEMBERSHIP } from '../../../constants/membership';
import routes from '../../../routes/constants';
import text, { formatCurrency } from '../../../text';
import {
  isMembershipPriceId,
  isYearlyMembershipPriceId,
} from '../../../utilities/membership';
import { getUserPriceId, isUserUnderTrial } from '../../../utilities/user';
import MembershipCard from './MembershipCard';
import MembershipOption from './MembershipOption';

const memberships = {
  [MEMBERSHIP.MONTHLY.PRICE_ID]: {
    priceId: [MEMBERSHIP.MONTHLY.PRICE_ID],
    info: [
      {
        firstHeader: text('essentials'),
        primaryText: text('monthlyMembershipRenews'),
      },
      {
        secondText: `/${text('month')}`,
        secondHeader: formatCurrency(90, 'aud', 1, {}, true),
        supplementalText: `*${text('minPriceAmount', {
          amount: formatCurrency(
            1080,
            'aud',
            1,
            { maximumFractionDigits: 0 },
            true
          ),
        })}`,
      },
    ],
  },

  [MEMBERSHIP.YEARLY.PRICE_ID]: {
    priceId: MEMBERSHIP.YEARLY.PRICE_ID,
    info: [
      {
        firstHeader: text('essentials'),
        primaryText: text('annualMembershipRenews'),
      },
      {
        secondHeader: formatCurrency(75, 'aud', 1, {}, true),
        secondText: `/${text('monthEquivalent')}*`,
        supplementalText: `*${text('paidUpfrontAmount', {
          months: 12,
          amount: formatCurrency(
            900,
            'aud',
            1,
            { maximumFractionDigits: 0 },
            true
          ),
        })}`,
      },
    ],
  },
  [MEMBERSHIP.NO_MEMBERSHIP]: {
    priceId: MEMBERSHIP.NO_MEMBERSHIP,
    info: [
      {
        firstHeader: text('noMembership'),
        primaryText: text('noMembershipFeatures'),
      },
    ],
  },
};

const membershipOptions = {
  [MEMBERSHIP.YEARLY.PRICE_ID]: {
    label: text('yearlySaveDiscount', { discount: 17 }),
    priceId: MEMBERSHIP.YEARLY.PRICE_ID,
  },
  [MEMBERSHIP.MONTHLY.PRICE_ID]: {
    label: text('monthly'),
    priceId: MEMBERSHIP.MONTHLY.PRICE_ID,
  },
  [MEMBERSHIP.NO_MEMBERSHIP]: {
    label: text('noThanks'),
    priceId: MEMBERSHIP.NO_MEMBERSHIP,
  },
};

const MembershipForm = ({
  membershipPriceId,
  onMembershipChange,
  includeNoMembership = true,
}) => {
  const { currentUser } = useSelector((state) => ({
    currentUser: state.profileReducer.userProfile,
  }));
  const noMembershipOption = membershipOptions[MEMBERSHIP.NO_MEMBERSHIP];
  useEffect(() => {
    if (getUserPriceId(currentUser)) {
      onMembershipChange(
        isYearlyMembershipPriceId(getUserPriceId(currentUser))
          ? MEMBERSHIP.MONTHLY.PRICE_ID
          : MEMBERSHIP.YEARLY.PRICE_ID
      );
    }
  }, []);

  const Membership = () => (
    <div className='MembershipForm-card'>
      <MembershipCard {...memberships[membershipPriceId]} />
      {isMembershipPriceId(membershipPriceId) && (
        <div className='termsAndCond'>
          <div>{`*${text('pricingTax')}`}</div>
          <a
            href={routes.larkiWebsiteUrl.custTermsCond}
            target='_blank'
            rel='noreferrer'
          >
            {text('larkiTermsAndCond')}
          </a>
        </div>
      )}
    </div>
  );
  return (
    <div className='MembershipForm'>
      <div className='MembershipForm-options'>
        <div>
          <div className='options-label'>{`${text('billed')}:`}</div>
          {[
            membershipOptions[MEMBERSHIP.YEARLY.PRICE_ID],
            membershipOptions[MEMBERSHIP.MONTHLY.PRICE_ID],
          ]
            .filter(
              ({ priceId }) =>
                priceId !== getUserPriceId(currentUser) ||
                isUserUnderTrial(currentUser)
            )
            .map(({ label, priceId, extraText }, index) => (
              <MembershipOption
                onChange={onMembershipChange}
                key={index}
                label={label}
                priceId={priceId}
                isSelected={membershipPriceId === priceId}
                noMembership={!isMembershipPriceId(priceId)}
                extraText={extraText}
                tooltipText={
                  isYearlyMembershipPriceId(priceId) &&
                  membershipPriceId !== priceId &&
                  isMembershipPriceId(membershipPriceId)
                    ? text('percentageSaving', {
                        percentage: 17,
                      })
                    : undefined
                }
              />
            ))}
          {includeNoMembership && (
            <MembershipOption
              onChange={onMembershipChange}
              label={noMembershipOption.label}
              priceId={noMembershipOption.priceId}
              isSelected={membershipPriceId === noMembershipOption.priceId}
              noMembership={!isMembershipPriceId(noMembershipOption.priceId)}
            />
          )}
        </div>
      </div>
      <Membership />
    </div>
  );
};

export default MembershipForm;
