import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  files: {
    entities: {},
    ids: [],
  },
  uploading: false,
  error: null,
};

const slice = createSlice({
  name: 'uploadAsset',
  initialState,
  reducers: {
    addFile(state, action) {
      state.files.ids.push(action.payload.id);
      state.files.entities[action.payload.id] = {
        ...action.payload,
        progress: 0,
      };
    },
    removeFile(state, action) {
      delete state.files.entities[action.payload];
      state.files.ids = state.files.ids.filter((id) => id !== action.payload);
    },
    updateProgress(state, action) {
      state.files.entities[action.payload.id].progress =
        action.payload.progress;
    },
    setUploading(state, action) {
      state.uploading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { reducer: uploadAssetReducer, actions: uploadAssetActions } =
  slice;
