import PropTypes from 'prop-types';
import React from 'react';
import text from '../../text';
import { IconButton } from '../button';
import Container from '../containers/Container';
import classNames from 'classnames';

// Call to Action Button
const CtaButton = ({
  show,
  header,
  description,
  tooltip = null,
  handleClick,
  titleClassNames = '',
  subTitleClassNames = '',
  className = '',
  isSecondary = true,
  isTertiary,
}) => {
  if (!show) {
    return null;
  }

  return (
    <button
      type='button'
      onClick={handleClick}
      className={classNames('cta-button', className)}
    >
      <Container
        noBorder
        {...(isSecondary && { isSecondary })}
        {...(isTertiary && { isTertiary })}
        className='d-flex justify-content-between p-3 align-items-center'
      >
        <div className='text-left'>
          <div className='d-flex flex-row align-items-center'>
            <p
              className={classNames(
                'font-weight-bold m-0 mr-2',
                titleClassNames
              )}
            >
              {header}
            </p>
            {tooltip}
          </div>
          <p className={classNames('m-0 font-12', subTitleClassNames)}>
            {description}
          </p>
        </div>
        <div className='d-flex flex-column add-button-container'>
          <IconButton iconProps={{ isOriginal: true }} icon='plus-filled' />
          <p className='m-0 font-weight-bold'>{text('add')}</p>
        </div>
      </Container>
    </button>
  );
};

CtaButton.propTypes = {
  show: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  tooltip: PropTypes.oneOfType([PropTypes.string, null]),
  description: PropTypes.string,
  handleClick: PropTypes.func,
  className: PropTypes.string,
  titleClassNames: PropTypes.string,
  subTitleClassNames: PropTypes.string,
  isSecondary: PropTypes.bool,
  isTertiary: PropTypes.bool,
};

CtaButton.defaultProps = {
  show: true,
  description: '',
};

export default CtaButton;
