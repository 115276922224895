import { useElements, useStripe } from '@stripe/react-stripe-js';
import { Formik } from 'formik';
import { first } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { listUserCards, setDiscountCode } from '../../../redux/actions';
import routes from '../../../routes/constants';
import text from '../../../text';
import Accordion from '../../accordion/Accordion';
import Button from '../../button/Button';
import CardOption from '../../card/CardOption';
import NewCard from '../../card/NewCard';
import Loading from '../../loading/Loading';
import TextDivider from '../../text/TextDivider';

import FormFooter from '../FormFooter';
import Control from '../Control';

const PaymentMethodForm = ({
  onAddCard = noop,
  onPaymentMethodChange = noop,
}) => {
  const { jobId } = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paymentError, setPaymentError] = useState();
  const [isNewCardDetailsComplete, setIsNewCardDetailsComplete] = useState();
  const [currentEventKey, setCurrentEventKey] = useState();
  const dispatch = useDispatch();

  const returnPage = routes.payment.job(jobId);
  const returnUrl = `${process.env.LARKI_APP_URL}${returnPage}`;

  const { userProfile, discountCode, userCards, defaultCardId } = useSelector(
    (state) => ({
      job: state.jobsReducer.job,
      discountCode: state.order.discountCode,
      userProfile: state.profileReducer.userProfile,
      userCards: state.profileReducer.userCards,
      defaultCardId:
        state.profileReducer.userCards.value?.find(
          (card) => card.default_payment_method
        )?.id || first(state.profileReducer.userCards.value)?.id,
    })
  );

  const isStripeLoading = !stripe || !elements;

  const handleSubmit = async (values) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    if (isStripeLoading) {
      return;
    }
    setIsSubmitting(true);

    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            address: {
              country: 'au',
            },
            name: values.name,
          },
        },
        return_url: returnUrl,
      },
      redirect: 'if_required',
    });

    if (error) {
      setPaymentError(error);
      setIsSubmitting(false);
    }
    onPaymentMethodChange(setupIntent.payment_method);
    onAddCard();
    setIsSubmitting(false);
  };

  useEffect(() => {
    dispatch(listUserCards());
  }, []);

  useEffect(() => {
    if (userCards.value && userCards.value[0]?.id) {
      onPaymentMethodChange(userCards.value[0]?.id);
    }
  }, [userCards]);

  return (
    <div className='PaymentMethodForm'>
      <Formik
        enableReinitialize
        initialValues={{
          savedCardId: defaultCardId,
          name: userProfile.full_name,
          saveCard: true,
        }}
      >
        {({ values, handleChange, setFieldValue }) => {
          const handleAccordionToggle = (eventKey) => {
            setCurrentEventKey(eventKey);
            setFieldValue('savedCardId', null);
            onPaymentMethodChange();
          };
          return (
            <>
              <h4 className='green-1'>{text('payByCard')}</h4>
              {!userCards.isLoading ? (
                !isEmpty(userCards.value) && (
                  <div>
                    {userCards.value?.map((card, i) => (
                      <CardOption
                        key={i}
                        name='savedCardId'
                        listCards={() => dispatch(listUserCards())}
                        onChange={(v) => {
                          setCurrentEventKey();
                          onPaymentMethodChange(v?.target.value);
                          handleChange(v);
                        }}
                        card={card}
                        isSelected={card.id === values.savedCardId}
                      />
                    ))}
                    <TextDivider isSecondary>{text('or')}</TextDivider>
                  </div>
                )
              ) : (
                <Loading />
              )}
              {!userCards.isLoading && (
                <Accordion
                  defaultActiveKey={isEmpty(userCards.value) ? 'newCard' : null}
                  activeKey={currentEventKey}
                >
                  <Accordion.Card
                    toggleLabel={text('addNewCard')}
                    eventKey='newCard'
                    onToggle={handleAccordionToggle}
                  >
                    <NewCard
                      onChange={handleChange}
                      onPaymentElementChange={setIsNewCardDetailsComplete}
                      name='name'
                      value={values.name}
                      label={text('nameOnCard')}
                      isFailed={paymentError?.code === 'incomplete_name'}
                      error={
                        paymentError?.code === 'incomplete_name'
                          ? paymentError.message
                          : ''
                      }
                    />
                    <FormFooter>
                      <Button
                        onClick={() => handleSubmit(values)}
                        variant='outline-primary'
                        loading={isSubmitting}
                        disabled={isStripeLoading || !isNewCardDetailsComplete}
                      >
                        {text('addCard')}
                      </Button>
                    </FormFooter>
                  </Accordion.Card>
                </Accordion>
              )}
              <div className='paymentError'>{paymentError?.message}</div>
              <div>
                <Control
                  placeholder='PROMO_CODE'
                  isInputWidthAuto
                  as='input'
                  type='text'
                  place
                  value={discountCode}
                  className='w-auto'
                  onChange={(event) =>
                    dispatch(setDiscountCode(event.target.value))
                  }
                  label={text('enterYourPromotionCode')}
                />
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default PaymentMethodForm;
