import classnames from 'classnames';
import T from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import useScript from '../../hooks/useScript';
import Loading from '../loading/Loading';

import './searchAddressInput.scss';

const autoCompleteOptions = {
  componentRestrictions: {
    country: ['aus', 'nz'],
  },
  types: ['address'],
};

const SearchAddress = (props) => {
  const { onChange } = props;
  const inputRef = useRef();
  const [value, setValue] = useState();

  const initSearch = () => {
    const autocomplete = new google.maps.places.Autocomplete(
      inputRef.current,
      autoCompleteOptions
    );

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();

      if (!place.address_components) {
        return;
      }

      onChange(place);
    });

    return autocomplete;
  };

  const googleMapsScriptStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.GCP_MAPS_API_KEY}&libraries=drawing,places,geometry&rankby=distance`
  );

  useEffect(async () => {
    if (googleMapsScriptStatus === 'ready') {
      initSearch();
    }
  }, [googleMapsScriptStatus]);

  const onAddContainerClass = () => {
    const container = document.querySelector('.pac-container');

    if (container) {
      container.classList.add('pac-limited');
    }
  };

  const onKeyDown = (event) => {
    if (event.key !== 'Enter') {
      return;
    }

    const item = document.querySelector('.pac-container .pac-item:first-child');

    if (!item) {
      return;
    }

    const matched = item.querySelector('.pac-item-query')?.textContent;
    const rest = item.lastChild?.textContent;
    const address = `${matched} ${rest}`;

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        const place = results[0];
        inputRef.current.value = place.formatted_address;
        onChange(place);
      }
    });
  };

  return googleMapsScriptStatus !== 'loading' ? (
    <input
      type='text'
      ref={inputRef}
      onChange={(event) => setValue(event.target.value)}
      id='pac-input'
      className={classnames('SearchAddress pac-target-input', {
        noSearchIcon: value,
      })}
      placeholder='Enter your site address'
      autoComplete='off'
      onKeyDown={onKeyDown}
      onFocus={onAddContainerClass}
    />
  ) : (
    <Loading color='white' />
  );
};

SearchAddress.propTypes = {
  onChange: T.func,
};

export default SearchAddress;
