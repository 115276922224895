import cn from 'classnames';
import isBoolean from 'lodash/isBoolean';
import T from 'prop-types';
import React from 'react';
import { Nav } from 'react-bootstrap';
import text from '../../text';
import Icon from '../icon/Icon';

const NavLink = ({
  isCaretHighlighted = true,
  isBorderHighlighted = true,
  className,
  name,
  onClick,
  href = '#',
  isCollapsed,
  icon,
  ...rest
}) => (
  <Nav.Link
    className={cn(
      {
        'is-caret-highlighted': isCaretHighlighted,
        'is-border-highlighted': isBorderHighlighted,
      },
      className
    )}
    href={href}
    eventKey={name}
    onClick={onClick}
    {...rest}
  >
    <div className='nav-left'>
      {icon && <Icon className='nav-icon' icon={icon} />}
      {text(name)}
    </div>
    <div className='caret-icon'>
      <Icon
        icon={cn({
          'caret-right': !isBoolean(isCollapsed),
          'caret-up': isBoolean(isCollapsed) && isCollapsed,
          'caret-down': isBoolean(isCollapsed) && !isCollapsed,
        })}
      />
    </div>
  </Nav.Link>
);

NavLink.propTypes = {
  href: T.string,
  name: T.string.isRequired,
  onClick: T.func,
  isCollapsed: T.bool,
};

export default NavLink;
