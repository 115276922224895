import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';

import FooterBody from './FooterBody';
import FooterBottom from './FooterBottom';
import routes from '../../routes/constants';
import { isRouteIncluded } from '../../utilities/routes';
import useBreakpoint from '../../hooks/useBreakpoint';


const ROUTES_ALLOWED = [routes.dashboard, routes.user.profile];
const isVisibleForRoute = (r) => isRouteIncluded(ROUTES_ALLOWED)(r);

const Footer = () => {
  const location = useLocation();
  const isEnabledForBkpt = useBreakpoint(['xs', 'sm', 'md']);
  const userDetailsState = useSelector(
    (state) => state.profileReducer.userProfile
  );
  const isVisible = isVisibleForRoute(location.pathname) && isEnabledForBkpt;
  return (
    (isEmpty(userDetailsState) || isVisible) && (
      <footer id='Footer' className={cn({ 'only-bottom': isVisible })}>
        <FooterBody />
        <FooterBottom />
      </footer>
    )
  );
};

export default Footer;
