import _ from 'lodash/fp';
import T from 'prop-types';
import React from 'react';

import { PRODUCT_PROS_CONS } from '../../../constants/product';
import Tooltip from '../../Tooltip';
import { IconButton } from '../../button';
import ProductCard from '../productCard/ProductCard';

const ProductTooltip = ({
  productType,
  title,
  description,
  placement,
  keepOpenOnHover,
}) => {
  const pros = _.get([productType, 'pros'], PRODUCT_PROS_CONS);
  const cons = _.get([productType, 'cons'], PRODUCT_PROS_CONS);

  return (
    <Tooltip.Provider placement={placement} keepOpenOnHover={keepOpenOnHover}>
      <Tooltip.Trigger>
        <IconButton className='product-tooltip' icon='question' />
      </Tooltip.Trigger>
      <Tooltip.Content withArrow arrowProps={{ fill: '#2a2a2a' }}>
        <ProductCard
          productType={productType}
          pros={pros}
          cons={cons}
          isDisabled={false}
          handleClick={_.noop}
          variant='secondary'
          withExplanation
          title={title}
          description={description}
        />
      </Tooltip.Content>
    </Tooltip.Provider>
  );
};
ProductTooltip.propTypes = {
  productType: T.string.isRequired,
  title: T.string,
  description: T.string,
  placement: T.string,
  keepOpenOnHover: T.bool,
};

export default ProductTooltip;
