import _ from 'lodash/fp';
import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAdminJobsList, getJobsList } from '../redux/actions';

const useProjectsList = ({
  isAdmin,
  filterPredicate = _.stubTrue,
  deps = [],
}) => {
  const dispatch = useDispatch();
  const { jobs, adminJobs, isJobsListLoading, isAdminJobListLoading } =
    useSelector((state) => ({
      jobs: state.jobsReducer.jobsList,
      isJobsListLoading: state.jobsReducer.isJobsListLoading,
      adminJobs: state.jobsReducer.adminJobList,
      isAdminJobListLoading: state.jobsReducer.isAdminJobListLoading,
    }));
  const projects = useMemo(() => {
    return _.flow(
      _.map((job) => job.project),
      _.orderBy('updated_at', ['desc']),
      _.filter(filterPredicate)
    )(isAdmin ? adminJobs : jobs);
  }, [isAdmin, adminJobs, jobs]);
  const isLoading = useMemo(
    () => (isAdmin ? isAdminJobListLoading : isJobsListLoading),
    [isAdmin, isAdminJobListLoading, isJobsListLoading]
  );
  const hasFetchedJobsList = useRef(false);
  const hasFetchedAdminJobList = useRef(false);
  useEffect(() => {
    if (!deps.every(Boolean)) {
      return;
    }
    if (isAdmin && !hasFetchedAdminJobList.current) {
      hasFetchedAdminJobList.current = true;
      dispatch(getAdminJobsList());
    } else if (!isAdmin && !hasFetchedJobsList.current) {
      hasFetchedJobsList.current = true;
      dispatch(getJobsList());
    }
  }, [isAdmin, ...deps]);
  return { projects, isLoading };
};

export default useProjectsList;
