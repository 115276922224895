import classNames from 'classnames';
import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { useAsync, useSearchParam } from 'react-use';
import * as yup from 'yup';
import { Button } from '../components/button';
import FormContainer from '../components/containers/FormContainer';
import { PasswordFormControl } from '../components/form';
import Page from '../components/page/Page';
import { alertConstants } from '../redux/constants';
import routes from '../routes/constants';
import text from '../text';
import { larkiApiNoAuthentication } from '../utilities/api';
import { setUser } from '../utilities/storage';
import { validatePassword } from '../validation';

const createPasswordSchema = yup.object({
  password: validatePassword,
});

const initialValues = {
  password: '',
};

const CreatePassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSearchParam('token');
  const coupon = useSearchParam('coupon');
  const redirectUrl = useSearchParam('redirectUrl');
  const onRevisit = useSearchParam('onRevisit');

  if (!token) {
    return <Redirect to={routes.home} />;
  }

  const { loading, error, value } = useAsync(async () => {
    const { data } = await larkiApiNoAuthentication.post('/user/verify-token', {
      token,
    });

    return data.token;
  }, [token]);

  if (error) {
    return <Redirect to={routes.login} />;
  }

  const onSubmit = async ({ password }) => {
    try {
      const { data } = await larkiApiNoAuthentication.post(
        '/user/set-initial-password',
        {
          password,
        },
        {
          headers: {
            Authorization: `Bearer ${value}`,
          },
          params: {
            coupon,
          },
        }
      );

      sessionStorage.clear();
      setUser(data);

      const search = new URLSearchParams({
        ...(coupon && { coupon }),
        ...(onRevisit && { onRevisit }),
      });

      history.push({
        pathname: redirectUrl || routes.home,
        search: search.toString(),
        state: {
          validated: true,
        },
      });
    } catch (error) {
      dispatch({
        type: alertConstants.ALERT_ERROR,
        payload: { message: error.response.data.message },
      });
    }
  };

  return (
    <Page
      className={classNames('Signup', {
        PageCenter: loading,
      })}
    >
      {loading ? (
        <PulseLoader size={12} color='#31a283' />
      ) : (
        <FormContainer>
          <FormContainer.SecondaryHeader>
            <h2>{text('createPassword')}</h2>
          </FormContainer.SecondaryHeader>
          <FormContainer.Body>
            <FormContainer.PrimaryHeader>
              <div>
                <h2>{text('createPassword')}</h2>
              </div>
              <p>{text('createPasswordDescription')}</p>
            </FormContainer.PrimaryHeader>
            <Formik
              initialValues={initialValues}
              validationSchema={createPasswordSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                handleChange,
                dirty,
              }) => (
                <Form>
                  <PasswordFormControl
                    required
                    as='input'
                    name='password'
                    value={values?.password || ''}
                    onChange={handleChange}
                    placeholder={text('enterPassword')}
                    label={text('password')}
                    autoComplete='off'
                    isFailed={touched.password && Boolean(errors.password)}
                    validationError={errors.password}
                  />
                  <div className='text-center'>
                    <div className='btns-group'>
                      <Button
                        type={!isSubmitting && 'submit'}
                        className='full-width'
                        loading={isSubmitting}
                        disabled={!dirty}
                      >
                        {text('continue')}
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </FormContainer.Body>
        </FormContainer>
      )}
    </Page>
  );
};

export default CreatePassword;
