import { useGesture } from '@use-gesture/react';
import { __ } from 'lodash/fp';
import { useContext, useEffect, useState } from 'react';
import useDebounceCollect from '../../hooks/useDebounceCollect';
import * as numberUtils from '../../utilities/number';
import { MapViewContext } from './mapViewContext';

const SCROLL_WHEEL_SENS = 8e-4;
const TRACKPAD_SENS = 1e-4;

function useSmoothMapZoom() {
  const { state, actions } = useContext(MapViewContext);

  // custom scrollwheel/pinch events for controlling zoom
  // from experience: the map appears to debounce setZoom calls;
  //  so we can't send setZoom events at too high a frequency or they will all be debounced
  //  until the user stops scrolling (this is most noticeable on a trackpad)
  // to fix this, we useDebounceCollect
  const { callFunc: updateWheelState, debounced: collectedWheelState } =
    useDebounceCollect((wheelState) => wheelState, 50);
  const [isTrackpad, setIsTrackpad] = useState(false);
  const mapBind = useGesture({
    onWheel: (wheelState) => {
      if (!wheelState.active) {
        return;
      }
      const wheelDelta = wheelState.event?.nativeEvent.wheelDelta;
      const _isTrackpad = wheelDelta && Math.abs(wheelDelta) <= 50;
      if (_isTrackpad) {
        setIsTrackpad(true);
      } else {
        setIsTrackpad(false);
        const zoomChange = -SCROLL_WHEEL_SENS * wheelState.movement[1];
        actions.zoom.increaseZoom(state.map, zoomChange);
      }
      updateWheelState(wheelState);
    },
    onPinch: (pinchState) => {
      console.log('pinch', pinchState);
    },
  });
  useEffect(() => {
    if (!isTrackpad) {
      return;
    }
    if (!state.map) {
      return;
    }
    let zoomChange =
      -TRACKPAD_SENS *
      __.sum(collectedWheelState.map((wheelState) => wheelState.movement[1]));
    zoomChange = numberUtils.clamp(zoomChange, -1, 1);
    actions.zoom.increaseZoom(state.map, zoomChange);
  }, [collectedWheelState]);
  return mapBind;
}

export default useSmoothMapZoom;
