import { captureError } from './error';

const assert = (condition, message, ...args) => {
  if (!condition) {
    console.error(args);
    const err = new Error(message || 'Assertion failed');
    if (process.env.NODE_ENV === 'development') {
      throw err;
    } else {
      captureError(err);
    }
  }
};

export default assert;
