import { AxiosError } from 'axios';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useAsyncFn, useSearchParam } from 'react-use';
import { alertError, alertWarning } from '../redux/actions';
import text from '../text';
import { client } from '../utilities/api';
import { captureError } from '../utilities/error';

const updateBookmarkRequest = (bookmarkId, data) => async () => {
  try {
    const resp = await client.updateBookmarkRequest(bookmarkId, data);
    return resp.data;
  } catch (err) {
    if (err instanceof AxiosError && err.response.status === 404) {
      return null;
    }
    throw err;
  }
};

const useUpdateBookmarkRequest = () => {
  const bookmarkId = useSearchParam('bookmarkId');
  const userId = useSearchParam('userId');
  const requestAccessStatus =
    useSearchParam('status') === 'approve' ? 'accepted' : 'rejected';

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [state, update] = useAsyncFn(
    updateBookmarkRequest(bookmarkId, {
      status: requestAccessStatus,
      userId,
    }),
    [bookmarkId, userId, requestAccessStatus]
  );

  const removeBookmarkQueryParamsOnly = useCallback(
    (keysToRemove = []) => {
      const params = new URLSearchParams(location.search);
      keysToRemove.forEach((key) => params.delete(key));

      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      });
    },
    [history, location.pathname, location.search]
  );

  const handleAfterUpdate = useCallback(
    (value) => {
      if (value) {
        const { user } = value;
        dispatch(
          alertWarning(
            text('rejectOrAcceptAlert', {
              status: requestAccessStatus,
              email: user.email,
            })
          )
        );
      }
      removeBookmarkQueryParamsOnly(['bookmarkId', 'userId', 'status']);
    },
    [dispatch, removeBookmarkQueryParamsOnly, requestAccessStatus]
  );

  useEffect(() => {
    if (bookmarkId && userId && requestAccessStatus) {
      (async () => {
        await update();
      })();
    }
  }, [bookmarkId, requestAccessStatus, userId, update]);

  useEffect(() => {
    const { error, value } = state;

    if (error) {
      captureError(error);
      dispatch(alertError(error.message));
      return;
    }

    handleAfterUpdate(value);
    state.value = null;
  }, [dispatch, handleAfterUpdate, state]);

  return {
    isUpdatingBookmarkRequest: state.loading,
  };
};

export default useUpdateBookmarkRequest;
