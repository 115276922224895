import { useEffect, useState } from 'react';

/**
 * Given a func, return a list of the return values of that func that updates at a max frequency given by minTimeBetweenCalls.
 * @param func function to call
 * @param minTimeBetweenCalls time between calls [ms]
 */
function useDebounceCollect(func, minTimeBetweenCalls) {
  const [lastCalled, setLastCalled] = useState(null);
  const [collected, setCollected] = useState([]);
  const [debounced, setDebounced] = useState([]);
  const callFunc = (...args) => {
    // console.log('call func', args);
    setCollected([...collected, func(...args)]);
  };
  useEffect(() => {
    if (collected.length === 0) {
      return;
    }

    const now = new Date().getTime();

    const update = () => {
      setLastCalled(now);
      setDebounced(collected);
      setCollected([]);
    };

    if (!lastCalled) {
      return update();
    }

    if (now - lastCalled >= minTimeBetweenCalls) {
      return update();
    }

    const handler = setTimeout(() => {
      return update();
    }, minTimeBetweenCalls - (now - lastCalled));

    return () => {
      clearTimeout(handler);
    };
  }, [collected]);
  return { callFunc, debounced };
}

export default useDebounceCollect;
