import classNames from 'classnames';
import React from 'react';

const Overlay = ({ id, className, children }) => (
  <div
    id={id}
    className={classNames('larki-overlay', {
      [className]: className !== null,
    })}
  >
    {children}
  </div>
);

export default Overlay;
