import flowFP from 'lodash/fp/flow';
import setFP from 'lodash/fp/set';
import {
  CLEAR_PENDING_REQUESTS,
  REMOVE_FROM_PENDING_REQUESTS,
  SET_PENDING_REQUESTS,
} from '../constants/api';

const defaultState = {
  pendingApiRequests: [],
};

const apiReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_PENDING_REQUESTS: {
      return flowFP([
        setFP(
          ['pendingApiRequests'],
          [...state.pendingApiRequests, action.payload]
        ),
      ])(state);
    }
    case REMOVE_FROM_PENDING_REQUESTS: {
      return flowFP([
        setFP(
          ['pendingApiRequests'],
          state.pendingApiRequests.filter((item) => item.id !== action.payload)
        ),
      ])(state);
    }
    case CLEAR_PENDING_REQUESTS: {
      return flowFP([setFP(['pendingApiRequests'], [])])(state);
    }
    default:
      return state;
  }
};

export default apiReducer;
