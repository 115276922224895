/**
 * Facilitating the transition from `user` in local storage to `user` as a cookie.
 * The user may be logged out and not have the `user` cookie in the external 3D viewer.
 * In this situation, we redirect back here so the `user` cookie can be set, and then go back to the external 3D viewer.
 */

import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import Loading from '../components/loading/Loading';
import { useRedirect } from '../hooks/useRedirect';
import routes from '../routes/constants';
import { getUser, setUser } from '../utilities/storage';

const ViewerRedirect = () => {
  const redirect = useRedirect();
  const { projectId } = useParams();
  useEffect(() => {
    const user = getUser();
    if (!user) {
      redirect.push('/logout');
      return;
    }
    setUser(user);
    const queryParams = {};
    if (process.env.DISABLE_COOKIE) {
      queryParams.user = encodeURIComponent(JSON.stringify(user));
    } else {
      if (location.hostname === 'localhost') {
        throw new Error('You should set DISABLE_COOKIE=1 on localhost');
      }
    }
    redirect.push(
      projectId
        ? routes.view3DExternal.project(projectId)
        : routes.view3DExternal.root,
      {
        preserveQueryParams: true,
        queryParams,
      }
    );
  }, [projectId, redirect]);
  return <Loading />;
};

export default ViewerRedirect;
