import AwsS3Multipart from '@uppy/aws-s3-multipart';
import { Uppy } from '@uppy/core';
import { DragDrop, useUppy } from '@uppy/react';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import React, { useReducer } from 'react';
import { FormLabel } from 'react-bootstrap';
import { useMount } from 'react-use';
import { boolean, object, string } from 'yup';
import text from '../../../text';
import { getUser } from '../../../utilities/storage';
import { Button } from '../../button';
import Checkbox from '../../form/Checkbox';
import Control from '../../form/Control';
import { File } from './file';
import {
  initialState,
  uploadAssetActions,
  uploadAssetReducer,
} from './reducer';
import { client } from '../../../utilities/api';
import { ACTIVITY_TYPES } from '../../../constants/activity';

const uploadFilesSchema = object().shape({
  description: string(),
  sendNotification: boolean(),
});

const initialValues = {
  description: '',
  sendNotification: false,
};

export const UploadAssetForm = ({ projectId, onSubmit, onCancel }) => {
  const [{ files, uploading, error }, dispatch] = useReducer(
    uploadAssetReducer,
    initialState
  );

  const uppy = useUppy(() => {
    const newUppy = new Uppy({
      debug: process.env.NODE_ENV === 'development',
    }).use(AwsS3Multipart, {
      limit: 5,
      companionUrl: process.env.UPPY_COMPANION_URL,
      companionHeaders: {
        Authorization: `Bearer ${getUser().token}`,
      },
      createMultipartUpload: async function (file) {
        const { data } = await client.createProjectAsset(
          projectId,
          file.name,
          file.type
        );

        file.meta['assetId'] = data.asset_id;

        return this.createMultipartUpload(file);
      },
    });

    newUppy
      .on('file-added', (file) => {
        dispatch(
          uploadAssetActions.addFile({
            id: file.id,
            name: file.name,
            type: file.type,
          })
        );
      })
      .on('file-removed', (file) => {
        dispatch(uploadAssetActions.removeFile(file.id));
      })
      .on('upload-progress', (file, progress) => {
        dispatch(
          uploadAssetActions.updateProgress({
            id: file.id,
            progress: (progress.bytesUploaded / progress.bytesTotal) * 100,
          })
        );
      })
      .on('upload', () => {
        dispatch(uploadAssetActions.setUploading(true));
        dispatch(uploadAssetActions.setError(null));
      })
      .on('error', (error) => {
        dispatch(uploadAssetActions.setError(error));
        dispatch(uploadAssetActions.setUploading(false));
      })
      .on('upload-error', (file, error, response) => {
        dispatch(uploadAssetActions.setError(error));
        dispatch(uploadAssetActions.setUploading(false));
        newUppy.reset();
      });

    return newUppy;
  });

  useMount(() => () => {
    uppy.cancelAll();
  });

  const onHandleCancel = (event) => {
    event.preventDefault();
    uppy.cancelAll();
    onCancel();
  };

  const onHandleSubmit = async (values) => {
    try {
      const uploadResult = await uppy.upload();

      if (uploadResult?.successful.length > 0) {
        const promises = uploadResult.successful.map((file) =>
          client.commitProjectAsset(projectId, file.meta.assetId)
        );

        await Promise.all(promises);

        await client.createActivity(projectId, {
          type: ACTIVITY_TYPES.file,
          title: 'Files to download',
          createdAssetIds: uploadResult.successful.map(
            (file) => file.meta.assetId
          ),
          ...values,
        });

        onSubmit();
      }
    } catch (error) {
      dispatch(uploadAssetActions.setError(error));
    }
  };

  const onRemoveFile = (id) => {
    if (!uploading) {
      uppy.removeFile(id);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={uploadFilesSchema}
      onSubmit={onHandleSubmit}
    >
      {({ handleChange, handleBlur, isValid, values, errors, touched }) => (
        <Form noValidate>
          <Control
            label={text('note')}
            as='textarea'
            type='text'
            name='description'
            isFailed={touched.description && !!errors.description}
            value={values?.description || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={text('enterNote')}
            validationError={touched.description && errors.description}
            autoComplete='off'
            disabled={uploading}
          />
          <FormLabel className='Control-Label'>{text('files')}</FormLabel>
          <div
            className={classNames('asset-uploader', {
              disabled: uploading,
              error,
            })}
          >
            <DragDrop
              uppy={uppy}
              note={text('dropFilesToUpload')}
              locale={{
                strings: {
                  dropHereOr: text('dropHereOr'),
                  browse: text('browseToUpload'),
                },
              }}
            />
            {error && (
              <p className='Control-Feedback isFailed'>{error.message}</p>
            )}
          </div>
          {files.ids.map((id) => (
            <File
              key={id}
              file={files.entities[id]}
              onRemove={onRemoveFile}
              uploading={uploading}
            />
          ))}
          <Checkbox
            id='sendNotification'
            onChange={handleChange}
            name='sendNotification'
            value={values?.sendNotification}
            label={text('sendNotificationToCustomer')}
            disabled={uploading}
          />
          <div className='form-footer'>
            <Button
              type='submit'
              disabled={!isValid || uploading || files.ids.length === 0}
            >
              {text('upload')}
            </Button>
            <a name='cancel' href='#' onClick={onHandleCancel}>
              {text('cancel')}
            </a>
          </div>
        </Form>
      )}
    </Formik>
  );
};
