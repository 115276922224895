import React from 'react';
import Icon from '../icon/Icon';
import classNames from 'classnames';

const DropdownItemLabel = ({ icon, label, className }) => {
  return (
    <span className={classNames('DropdownItemLabel', className)}>
      {icon && <Icon icon={icon} />}
      {label}
    </span>
  );
};

export default DropdownItemLabel;
