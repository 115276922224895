import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useAsyncFn } from 'react-use';

import PaymentMethodForm from '../form/paymentMethodForm/PaymentMethodForm';
import Loading from '../loading/Loading';
import StripeElements from '../stripe/StripeElements';
import Step from '../wizard/Step';
import text from '../../text';
import { client } from '../../utilities/api';
import { isStaff } from '../../utilities/user';
import { ToggleButtonGroup } from 'react-bootstrap';
import Button from '../button/Button';
import AssignedPaymentForm from '../form/assignedPaymentForm/AssignedPaymentForm';
import { PAY_MODE } from '../../constants/order';
import { Formik } from 'formik';
import { assignedPaymentSchema } from '../../validation';
import FormikFormFooter from '../form/FormikFormFooter';
import { isJobPayable } from '../../utilities/job';
import List from '../list/List';
import { formatDate } from '../../utilities/date';

const PaymentMethodStep = ({
  paymentMethodId,
  onPaymentMethodChange,
  job,
  currentUser,
  handleSubmit,
  handleSelectedPayMode,
  userProfileState,
  selectedPayMode,
  isAssignedPayment,
  onAddCard,
  nextButtonProps,
  isTrialDetailsVisible,
}) => {
  const availablePayModes = [
    { label: 'myPaymentDetails', mode: PAY_MODE.PAYMENT_METHOD },
    {
      label: 'sendToPayer',
      mode: PAY_MODE.ASSIGNED_PAYMENT,
      isEnabled: () => isJobPayable(job),
    },
  ];

  const [{ value, loading }, createPaymentMethodSetup] =
    useAsyncFn(async () => {
      const { data } = await client.createPaymentMethodSetup();
      return data;
    }, []);

  const [trialMembershipDetailsState, getTrialMembershipDetails] = useAsyncFn(
    async () => {
      const { data } = await client.getTrialMembershipDetails();
      return data;
    }
  );

  useEffect(() => {
    if (!trialMembershipDetailsState.value && isTrialDetailsVisible) {
      getTrialMembershipDetails();
    }
  }, []);

  useEffect(() => {
    if (
      !isEmpty(currentUser) &&
      !isStaff(currentUser.role) &&
      userProfileState !== 'loading' &&
      !loading
    ) {
      createPaymentMethodSetup();
    }
  }, [currentUser]);

  const renderPaymentMethodType = (type) => {
    switch (type) {
      case PAY_MODE.ASSIGNED_PAYMENT: {
        return <AssignedPaymentForm />;
      }
      case PAY_MODE.PAYMENT_METHOD: {
        return (
          <PaymentMethodForm
            onAddCard={onAddCard}
            onPaymentMethodChange={onPaymentMethodChange}
          />
        );
      }
      default:
        return <div />;
    }
  };

  const membershipDetails = trialMembershipDetailsState.value;

  return !loading && userProfileState !== 'loading' ? (
    <>
      <Formik
        validateOnMount
        isInitialValid={false}
        validationSchema={isAssignedPayment && assignedPaymentSchema}
        initialValues={{ payerEmail: '', payerName: '', message: '' }}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ isValid }) => {
          return (
            <StripeElements clientSecret={value}>
              <Step>
                <Step.Content className='flex-column'>
                  {isTrialDetailsVisible && (
                    <div className='align-self-start'>
                      {!trialMembershipDetailsState.loading &&
                      membershipDetails ? (
                        <>
                          <h4>{text('essentialsMembershipTrial')}</h4>
                          <List isBorderless>
                            <tbody>
                              <List.Item
                                isColonAppended
                                description={text('trialStartDate')}
                                value={formatDate(
                                  membershipDetails?.trial_start_at
                                )}
                              />
                              <List.Item
                                isColonAppended
                                description={text('nextChargeDate')}
                                value={formatDate(
                                  membershipDetails?.next_charge_at
                                )}
                              />
                              <List.Item
                                isColonAppended
                                description={text('contractRenewalDate')}
                                value={formatDate(
                                  membershipDetails?.contract_renewal_at
                                )}
                              />
                            </tbody>
                          </List>
                          <p>{text('essentialsMembershipMessage')}</p>
                          <p>{text('receiveEmailBeforeTrialExpires')}</p>
                        </>
                      ) : (
                        <Loading />
                      )}
                    </div>
                  )}
                  <ToggleButtonGroup
                    className='PaymentMethodButtonGroup'
                    name='paymentType'
                  >
                    {availablePayModes.map(({ mode, label }, i) => {
                      return (
                        <Button
                          key={i}
                          variant={
                            selectedPayMode === mode
                              ? 'primary'
                              : 'outline-primary'
                          }
                          checked={selectedPayMode === mode}
                          value={mode}
                          onClick={handleSelectedPayMode}
                        >
                          {text(label)}
                        </Button>
                      );
                    })}
                  </ToggleButtonGroup>
                  {renderPaymentMethodType(selectedPayMode)}
                </Step.Content>
              </Step>
              <FormikFormFooter
                disabled={
                  nextButtonProps.disabled ?? isAssignedPayment
                    ? !isValid
                    : !paymentMethodId
                }
                label={nextButtonProps.label}
              />
            </StripeElements>
          );
        }}
      </Formik>
    </>
  ) : (
    <Loading />
  );
};

export default PaymentMethodStep;
