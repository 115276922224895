import _ from 'lodash/fp';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { getPlaceSmartSelection } from '../../redux/actions';
import { convertSelectionToRegionSelection } from '../../utilities/map';
import { INITIAL_RECTANGLE_SIZE } from '../mapView/constants';
import { MapViewContext } from '../mapView/mapViewContext';

const useSmartSelections = () => {
  const { state, actions } = useContext(MapViewContext);
  const dispatch = useDispatch();

  const addFallbackSmartSelections = () => {
    if (!state.mapPlace) {
      console.error('tried to add smart selections without a map place');
      return;
    }
    actions.setRectangleToDraw({
      centre: {
        lat: state.mapPlace.geometry.location.lat(),
        lng: state.mapPlace.geometry.location.lng(),
      },
      size: INITIAL_RECTANGLE_SIZE,
    });
  };

  const addSmartSelections = (smartSelections) => {
    if (_.isEmpty(smartSelections)) {
      addFallbackSmartSelections();
      return;
    }
    const newSelections = smartSelections.map((selection) => ({
      ...selection,
      region: convertSelectionToRegionSelection(selection),
    }));
    actions.addSelections(newSelections);
    return newSelections;
  };

  const addSmartSelectionsInPlace = async (place, deliveryMethod, buttonId = null) => {
    const smartSelections = await dispatch(
      getPlaceSmartSelection(place, deliveryMethod, buttonId)
    );
    const newSelections = addSmartSelections(smartSelections);
    return newSelections;
  };

  return addSmartSelectionsInPlace;
};

export default useSmartSelections;
