import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import * as yup from 'yup';
import { Button } from '../../components/button';
import FormContainer from '../../components/containers/FormContainer';
import { PasswordFormControl } from '../../components/form';
import { getUserProfile } from '../../redux/actions';
import { alertConstants } from '../../redux/constants';
import routes from '../../routes/constants';
import text from '../../text';
import { larkiApiNoAuthentication } from '../../utilities/api';
import { getUser, setUser } from '../../utilities/storage';
import { isUserAnonymous } from '../../utilities/user';

const loginSchema = yup.object({
  password: yup.string().required(text('required')),
});

const initialValues = {
  password: '',
};

const LoginForm = ({ email }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const redirectUrl = useSearchParam('redirectUrl');
  const onRevisit = useSearchParam('onRevisit');

  const onLogin = async (values, { setFieldError }) => {
    try {
      let headers = {};

      if (isUserAnonymous(getUser())) {
        headers['Authorization'] = `Bearer ${getUser().token}`;
      }
      const { data } = await larkiApiNoAuthentication.post(
        '/user/login',
        {
          email,
          ...values,
        },
        { headers }
      );

      sessionStorage.clear();
      setUser(data);
      dispatch(getUserProfile());
      history.push({
        pathname: redirectUrl || routes.home,
        search: new URLSearchParams([['onRevisit', onRevisit]]).toString(),
      });
    } catch (error) {
      if (error.response.status === 401) {
        setFieldError('password', error.response.data.message);
      } else {
        dispatch({
          type: alertConstants.ALERT_ERROR,
          payload: { message: error.response.data.message },
        });
      }
    }
  };

  return (
    <FormContainer>
      <FormContainer.SecondaryHeader>
        <h2>{text('login')}</h2>
      </FormContainer.SecondaryHeader>
      <FormContainer.Body>
        <FormContainer.PrimaryHeader>
          <div>
            <h2>{text('loginToYourAccount')}</h2>
          </div>
          <p>{text('pleaseEnterPassword')}</p>
        </FormContainer.PrimaryHeader>
        <Formik
          initialValues={initialValues}
          validationSchema={loginSchema}
          onSubmit={onLogin}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            isSubmitting,
            isValid,
            dirty,
          }) => (
            <Form noValidate>
              <PasswordFormControl
                required
                as='input'
                name='password'
                value={values?.password || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={text('enterPassword')}
                label={text('password')}
                autoComplete='off'
                isFailed={touched.password && Boolean(errors.password)}
                validationError={errors.password}
              />
              <div className='text-center'>
                <div className='btns-group'>
                  <Button
                    type={!isSubmitting && 'submit'}
                    className='full-width'
                    loading={isSubmitting}
                    disabled={!(dirty && isValid)}
                  >
                    {text('login')}
                  </Button>
                </div>
                <p className='small-text'>
                  <Link
                    to={{
                      pathname: '/forgot-password',
                      search: redirectUrl
                        ? `?redirectUrl=${redirectUrl}`
                        : undefined,
                    }}
                  >
                    {' '}
                    {text('forgotPassword')}
                  </Link>
                </p>
              </div>
            </Form>
          )}
        </Formik>
      </FormContainer.Body>
    </FormContainer>
  );
};

export default LoginForm;
