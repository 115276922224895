import { useEffect } from 'react';

const useWorkerCallback = (worker, callback) => {
  useEffect(() => {
    if (!window?.Worker) {
      return;
    }

    worker.onmessage = (e) => {
      callback(e.data);
    };

    return () => {
      worker.terminate();
    };
  }, [window?.Worker, worker]);

  return (data) => {
    worker.postMessage(data);
  };
};

export default useWorkerCallback;
