import React from 'react';
import { setListJobSearchText } from '../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Control from '../../form/Control';
import text from '../../../text';
import Icon from '../../icon/Icon';

const SearchControl = () => {
  const dispatch = useDispatch();
  const { searchText } = useSelector((state) => ({
    searchText: state.jobsReducer.listJobFilter.searchText,
  }));

  const handleChange = (e) => {
    dispatch(setListJobSearchText(e.target.value));
  };
  const handleClear = () => {
    dispatch(setListJobSearchText(''));
  };

  return (
    <div id='SearchControl'>
      <Control
        as='input'
        type='text'
        name='search'
        value={searchText}
        onChange={handleChange}
        placeholder={text('searchHere')}
        autoComplete='off'
      />
      <Icon className='clear-search' onClick={handleClear} icon='cross' />
    </div>
  );
};

export default SearchControl;
