import React from 'react';
import ConnectSection from './ConnectSection';
import SocialMediaSection from './SocialMediaSection';

const FooterBody = () => {
  return (
    <div id='FooterBody'>
      <ConnectSection />
      <SocialMediaSection />
    </div>
  );
};

export default FooterBody;
