import classnames from 'classnames';
import upperCase from 'lodash/upperCase';
import React from 'react';
import { useSelector } from 'react-redux';

import useMembershipButton from '../../hooks/useMembershipButton';
import { profileSelectors } from '../../redux/selectors/profile';
import routes from '../../routes/constants';
import text from '../../text';
import Button from './Button';

const MembershipButton = ({ className, variant = 'primary', isUppercase }) => {
  const canUserStartTrial = useSelector(profileSelectors.getCanUserStartTrial);
  const canUserUpgrade = useSelector(profileSelectors.getCanUserUpgrade);
  const { handleMembershipClick } = useMembershipButton();

  const label = isUppercase
    ? upperCase(text(canUserStartTrial ? 'startTrial' : 'upgrade'))
    : text(canUserStartTrial ? 'startTrial' : 'upgrade');

  if (!canUserStartTrial && !canUserUpgrade) return null;

  return (
    <Button
      id='MembershipButton'
      className={classnames(className)}
      variant={variant}
      onClick={() =>
        handleMembershipClick({
          redirectTo: {
            path: `${routes.user.profile}?focusedTab=membership`,
            label: text('navigateToRoute', {
              navigation: 'Back',
              routeName: text('account'),
            }),
          },
        })
      }
    >
      {label}
    </Button>
  );
};

export default MembershipButton;
