import { omit } from 'lodash';
import _ from 'lodash/fp';
import flowFP from 'lodash/fp/flow';
import setFP from 'lodash/fp/set';
import set from 'lodash/set';
import {
  ACTIVE_STATUS,
  EXCLUDED_COLUMNS_ON_COPY_JOB,
} from '../../constants/job';
import { jobsConstants } from '../constants';
import { DEFAULT, FAILED, LOADING, SUCCESS } from '../stateTools';

const defaultJobsState = {
  jobsList: {}, // TODO: Refactor jobsList to project list
  isJobsListLoading: false,
  listJobFilter: {
    status: ACTIVE_STATUS,
    searchText: '',
  },
  statusCounts: {},
  adminStatusCounts: {},
  receipt: {
    isLoading: false,
    value: undefined,
  },
  adminJobList: {},
  isAdminJobListLoading: false,
  pointCloud: undefined,
  isJobCreatedModalOpen: false,
  project: {},
  isQuotingJob: false,
  isGettingJob: false,
  getJobError: null,
  job: null,
  undefinedAddress: false,
};

const jobsReducer = (state = defaultJobsState, action) => {
  switch (action.type) {
    case jobsConstants.GET_JOB: {
      return { ...state, isGettingJob: true };
    }
    case jobsConstants.GET_JOB_FAILED: {
      return _.flow(
        _.set('isGettingJob', false),
        _.set('getJobError', action.payload)
      )(state);
    }
    case jobsConstants.GET_JOB_SUCCESS: {
      return _.flow(
        _.set('isGettingJob', false),
        _.set('getJobError', null),
        _.set('job', action.payload)
      )(state);
    }
    case jobsConstants.SET_JOB: {
      return { ...state, job: action.payload, isGettingJob: false };
    }
    case jobsConstants.CLEAR_JOB:
      return { ...defaultJobsState };
    case jobsConstants.UPDATE_JOB_PROJECT_NAME: {
      const { id, isAdmin } = action.payload;
      const property = isAdmin ? 'adminJobList' : 'jobsList';
      return flowFP([setFP([property, id, 'ui', 'updateState'], LOADING)])(
        state
      );
    }
    case jobsConstants.UPDATE_JOB_PROJECT_NAME_SUCCESS: {
      const { project, isAdmin } = action.payload;
      const property = isAdmin ? 'adminJobList' : 'jobsList';
      return flowFP([
        setFP([property, project.id, 'ui'], { updateState: SUCCESS }),
        setFP([property, project.id, 'project', 'name'], project.name),
        setFP(
          [property, project.id, 'updated_at'],
          project.updated_at
        ),
        setFP([property, project.id, 'updater'], project.updater),
      ])(state);
    }
    case jobsConstants.UPDATE_JOB_PROJECT_NAME_FAILED: {
      const { projectId, isAdmin } = action.payload;
      const property = isAdmin ? 'adminJobList' : 'jobsList';
      return {
        ...state,
        [property]: {
          ...state.jobsList,
          [projectId]: {
            ...state.jobsList[projectId],
            ui: { updateState: FAILED },
          },
        },
      };
    }
    case jobsConstants.RESET_UPDATE_JOB_PROJECT_NAME: {
      const { isAdmin } = action.payload;
      const property = isAdmin ? 'adminJobList' : 'jobsList';
      return flowFP([
        setFP([property, action.payload.id, 'ui', 'updateState'], DEFAULT),
      ])(state);
    }
    case jobsConstants.SET_PROJECT:
      return { ...state, project: action.payload };

    case jobsConstants.GET_JOBS_LIST:
      return { ...state, isJobsListLoading: true };
    case jobsConstants.SET_JOBS_LIST: {
      return flowFP([
        setFP('jobsList', action.jobsList),
        setFP('isJobsListLoading', false),
      ])(state);
    }
    case jobsConstants.GET_JOBS_LIST_DONE:
      return { ...state, isJobsListLoading: false };

    case jobsConstants.GET_ADMIN_JOB_LIST:
      return { ...state, isAdminJobListLoading: true };
    case jobsConstants.SET_ADMIN_JOB_LIST: {
      return flowFP([
        setFP('adminJobList', action.jobsList),
        setFP('isAdminJobListLoading', false),
      ])(state);
    }
    case jobsConstants.GET_ADMIN_JOB_LIST_DONE:
      return { ...state, isAdminJobListLoading: false };

    case jobsConstants.SET_JOB_STATUS_COUNTS:
      return { ...state, statusCounts: action.statusCounts };
    case jobsConstants.SET_POINT_CLOUD:
      return { ...state, pointCloud: { ...action.pointCloud } };
    case jobsConstants.OPEN_JOB_CREATED_DIALOG:
      return { ...state, isJobCreatedModalOpen: true };
    case jobsConstants.CLOSE_JOB_CREATED_DIALOG:
      return { ...state, isJobCreatedModalOpen: false };
    case jobsConstants.DELETE_PROJECT_SUCCESS: {
      const { [action.payload]: value, ...rest } = state.jobsList;
      const { [action.payload]: adminValue, ...adminRest } = state.adminJobList;

      return {
        ...state,
        jobsList: rest,
        adminJobList: adminRest,
      };
    }
    case jobsConstants.UPDATE_INVITE: {
      const job = state.jobsList[action.payload.id];

      return {
        ...state,
        jobsList: {
          ...state.jobsList,
          [action.payload.id]: {
            ...job,
            user_project: {
              ...job.user_project,
              invite_status: action.payload.status,
            },
          },
        },
      };
    }
    case jobsConstants.POINTERRA_ID_UPDATED: {
      const job = set(
        state.jobsList[action.payload.id],
        ['point_clouds', '0', 'pointerra_id'],
        action.payload.pointerraId
      );

      return {
        ...state,
        jobsList: {
          ...state.jobsList,
          [action.payload.id]: {
            ...job,
          },
        },
      };
    }
    case jobsConstants.SET_LIST_JOB_STATUS: {
      return flowFP([setFP(['listJobFilter', 'status'], action.payload)])(
        state
      );
    }
    case jobsConstants.SET_LIST_JOB_SEARCHTEXT: {
      return flowFP([setFP(['listJobFilter', 'searchText'], action.payload)])(
        state
      );
    }
    case jobsConstants.SET_ADMIN_STATUS_COUNT:
      return { ...state, adminStatusCounts: action.statusCounts };
    case jobsConstants.GET_JOB_RECEIPT: {
      return flowFP([setFP(['receipt', 'isLoading'], true)])(state);
    }
    case jobsConstants.GET_JOB_RECEIPT_SUCCESS: {
      return flowFP([
        setFP(['receipt', 'isLoading'], false),
        setFP(['receipt', 'value'], action.payload),
      ])(state);
    }
    case jobsConstants.ADD_COPIED_JOB: {
      const { job, isAdmin } = action.payload;

      const duplicatedProject = {
        ...job,
        jobs: [getJobColumnsOnly(job)],
      };

      const list = isAdmin
        ? {
            adminJobList: {
              ...state.adminJobList,
              [job.project.id]: duplicatedProject,
            },
            adminStatusCounts: {
              ...state.adminStatusCounts,
              draft: ++state.adminStatusCounts.draft,
            },
          }
        : {
            jobsList: {
              ...state.jobsList,
              [job.project.id]: duplicatedProject,
            },
            statusCounts: {
              ...state.statusCounts,
              draft: ++state.statusCounts.draft,
            },
          };

      const updatedState = {
        ...state,
        ...list,
      };

      return updatedState;
    }
    case jobsConstants.QUOTE_ORDER: {
      return {
        ...state,
        isQuotingJob: true,
      };
    }
    case jobsConstants.QUOTE_ORDER_SUCCESS: {
      return {
        ...state,
        isQuotingJob: false,
        job: action.payload,
      };
    }
    case jobsConstants.TOGGLE_JOB_BOUNDARY_VISIBILITY: {
      return flowFP([
        setFP(['job', 'lot_area_state', 'visibility'], action.payload),
      ])(state);
    }
    case jobsConstants.TOGGLE_JOB_BOUNDARY_HIDDEN: {
      return flowFP([
        setFP(['job', 'lot_area_state', 'hidden'], action.payload),
      ])(state);
    }
    case jobsConstants.SET_UNDEFINED_ADDRESS: {
      return flowFP([setFP(['undefinedAddress'], action.payload)])(state);
    }
    default:
      return state;
  }
};

const getJobColumnsOnly = (job) => omit(job, EXCLUDED_COLUMNS_ON_COPY_JOB);

export default jobsReducer;
