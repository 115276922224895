import { Form, useFormikContext } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../components/button';
import text from '../../text';
import FormContainer from '../../components/containers/FormContainer';
import Control from '../../components/form/Control';

const ForgotPasswordForm = () => {
  const {
    values,
    touched,
    errors,
    handleChange,
    isSubmitting,
    handleBlur,
    dirty,
    isValid,
  } = useFormikContext();

  return (
    <FormContainer>
      <FormContainer.SecondaryHeader>
        <h2>{text('forgotYourPassword')}</h2>
      </FormContainer.SecondaryHeader>
      <FormContainer.Body>
        <FormContainer.PrimaryHeader>
          <h2>{text('forgotYourPassword')}</h2>
        </FormContainer.PrimaryHeader>
        <Form>
          <Control
            as='input'
            type='text'
            name='email'
            value={values?.email || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={text('email')}
            isFailed={touched.email && Boolean(errors.email)}
            validationError={errors.email}
          />
          <div className='text-center'>
            <div className='btns-group'>
              <Button
                type={!isSubmitting && 'submit'}
                className='full-width'
                loading={isSubmitting}
                disabled={!(dirty && isValid)}
              >
                {text('submit')}
              </Button>
            </div>
            <p className='small-text'>
              <Link to='/'>{text('cancel')}</Link>
            </p>
          </div>
        </Form>
      </FormContainer.Body>
    </FormContainer>
  );
};

export default ForgotPasswordForm;
