import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from '../../utilities/api';
import appearance from './appearance';

const StripeElements = (props) => {
  const { clientSecret, children } = props;
  return (
    <Elements
      stripe={stripePromise}
      options={{
        appearance: appearance,
        clientSecret,
      }}
    >
      {children}
    </Elements>
  );
};

export default StripeElements;
