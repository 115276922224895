import { useFormikContext } from 'formik';
import React from 'react';
import StepFooter from '../wizard/StepFooter';

const FormikFormFooter = (props) => {
  const { label, disabled, topContent } = props;
  const { submitForm, isSubmitting } = useFormikContext();

  const onClick = async () => {
    if (!isSubmitting) {
      await submitForm();
    }
  };

  return (
    <StepFooter
      topContent={topContent}
      nextButtonProps={{
        label,
        onClick,
        loading: isSubmitting,
        disabled,
      }}
    />
  );
};

export default FormikFormFooter;
