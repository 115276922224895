import * as yup from 'yup';
import text from '../text';
export * from './company';
export * from './user';

export const isValidName = (name) => (name ? name.trim().length > 0 : false);

export const PROJ_NAME_MIN_LENGTH = 1;
export const PROJ_NAME_MAX_LENGTH = 50;

export const MIN_LENGTH = 1;

export const projectValidationSchema = yup.object().shape({
  project: yup.object().shape({
    name: yup
      .string()
      .required(text('required'))
      .min(
        PROJ_NAME_MIN_LENGTH,
        text('projectNameLength', {
          min: PROJ_NAME_MIN_LENGTH,
          max: PROJ_NAME_MAX_LENGTH,
        })
      )
      .max(
        PROJ_NAME_MAX_LENGTH,
        text('projectNameLength', {
          min: PROJ_NAME_MIN_LENGTH,
          max: PROJ_NAME_MAX_LENGTH,
        })
      )
      .nullable(),
  }),
});

export const validateEmail = yup
  .string()
  .required(text('required'))
  .email(text('enterValidEmail'));

export const validatePassword = yup
  .string()
  .min(8, text('passwordValidation'))
  .matches(
    /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
    text('passwordValidation')
  )
  .required(text('required'));

export const validateConfirmPassword = validatePassword.test(
  'passwords-match',
  text('passwordsMustMatch'),
  function (value) {
    return this.parent.password === value;
  }
);

export const assignedPaymentSchema = yup.object().shape({
  payerName: yup.string().required(text('required')),
  payerEmail: validateEmail,
  message: yup.string().notRequired(),
});
