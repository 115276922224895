import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';

const STATUS_TYPES = {
  initial: 'initial',
  pending: 'pending',
  fulfilled: 'fulfilled',
  rejected: 'rejected',
};
const ACTION_TYPES = {
  start: 'start',
  cancel: 'cancel',
  fulfill: 'fulfill',
  reject: 'reject',
};

const asyncReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.start:
      return { ...state, status: STATUS_TYPES.pending };
    case ACTION_TYPES.cancel: {
      return {
        ...state,
        status:
          state.error instanceof Error
            ? STATUS_TYPES.rejected
            : state.data !== undefined
            ? STATUS_TYPES.fulfilled
            : STATUS_TYPES.initial,
      };
    }
    case ACTION_TYPES.fulfill:
      return { ...state, data: action.payload, status: STATUS_TYPES.fulfilled };
    case ACTION_TYPES.reject:
      return { ...state, error: action.payload, status: STATUS_TYPES.rejected };
  }
};

// Why do we need our own useAsync function?
// The react-use implementation always calls the fn on mount, we may wish not to.
const usePromiseWrapper = (promiseFn, deps = []) => {
  const [state, dispatch] = useReducer(asyncReducer, {}, () => ({
    status: STATUS_TYPES.initial,
  }));
  const isMounted = useRef(true);

  const run = useCallback(
    async (...args) => {
      if (!isMounted.current) return;
      dispatch({
        type: ACTION_TYPES.start,
      });
      try {
        dispatch({
          type: ACTION_TYPES.fulfill,
          payload: await promiseFn(...args),
        });
      } catch (err) {
        dispatch({
          type: ACTION_TYPES.reject,
          payload: err,
        });
        throw err;
      }
    },
    [dispatch, promiseFn, ...deps]
  );

  useEffect(() => () => (isMounted.current = false), []);

  return useMemo(() => ({ ...state, run }), [state, run]);
};

export default usePromiseWrapper;
