/* eslint-disable react/jsx-max-depth */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import routes from '../../routes/constants';
import text from '../../text';

import { pickBy, values } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MapViewContext } from '../../components/mapView/mapViewContext';
import { profileSelectors } from '../../redux/selectors/profile';
import { isJobPayable } from '../../utilities/job';
import { isMembershipPriceId } from '../../utilities/membership';
import { isRouteIncluded } from '../../utilities/routes';
import {
  isStaff,
  isUserGovernment,
  isUserUnderTrial,
} from '../../utilities/user';

const WIZARD_KEYS = {
  order: 'order',
  membership: 'membership',
};

export const PaymentContext = createContext(null);

const PaymentProvider = ({ children }) => {
  const [activeWizardStep, setActiveWizardStep] = useState(null);
  const [cardHeading, setCardHeading] = useState(text('estimate'));
  const location = useLocation();
  const isInOrderPage = isRouteIncluded([routes.payment.job()]);
  const isInMembershipPage = isRouteIncluded([
    routes.user.membershipManagement,
  ]);
  const { state } = useContext(MapViewContext);
  const { currentUser, membershipPriceId } = useSelector((state) => ({
    currentUser: state.profileReducer.userProfile,
    membershipPriceId: state.order.membershipPriceId,
  }));
  const hasValidMembership = useSelector(profileSelectors.hasValidMembership);
  const wizardStepsHeading = useMemo(
    () => ({
      [WIZARD_KEYS.order]: [
        {
          text: text('estimate'),
          filter: () => {
            return (
              !process.env.DISABLE_STRIPE &&
              !isUserGovernment(currentUser) &&
              !isStaff(currentUser.role) &&
              (!hasValidMembership || isUserUnderTrial(currentUser))
            );
          },
        }, // membership
        {
          text: text('estimate'),
        }, // project
        {
          text: text('quote'),
          filter: () =>
            (!isStaff(currentUser.role) && isJobPayable(state.job)) ||
            isMembershipPriceId(membershipPriceId),
        }, // payment
        {
          text: text('invoice'),
        }, // confirmation
      ],
      [WIZARD_KEYS.membership]: [
        {
          text: text('estimate'),
        },
        {
          text: text('quote'),
        },
        {
          text: text('invoice'),
        },
      ],
    }),
    [currentUser, hasValidMembership, membershipPriceId, state.job]
  );

  const wizardKeys = useMemo(
    () => [
      {
        key: WIZARD_KEYS.order,
        isActive: isInOrderPage(location.pathname),
      },
      {
        key: WIZARD_KEYS.membership,
        isActive: isInMembershipPage(location.pathname),
      },
    ],
    [isInMembershipPage, isInOrderPage, location.pathname]
  );

  const getActiveRouteKey = useMemo(() => {
    if (!activeWizardStep) {
      return text('estimate');
    }

    return wizardKeys.find((wk) => !!wk.isActive);
  }, [activeWizardStep, wizardKeys]);

  const handleSetCardHeading = useCallback(() => {
    if (activeWizardStep) {
      const { step, wizard } = activeWizardStep;
      const filteredWizardStepsHeading = values(
        pickBy(wizardStepsHeading[wizard], (step) => {
          return !step.filter || step.filter();
        })
      );
      // (step - 1) if wizard = order coz `Cart` step is index 0 so step is starting at 1
      const stepIndex = wizard === WIZARD_KEYS.membership ? step : step - 1;
      setCardHeading(
        filteredWizardStepsHeading?.[`${stepIndex}`]?.text || text('estimate')
      );
    }
  }, [activeWizardStep, wizardStepsHeading]);

  const handleSetWizardStep = useCallback(
    (step) => {
      setActiveWizardStep({ step, wizard: getActiveRouteKey.key });
    },
    [getActiveRouteKey.key]
  );

  const memoizedState = useMemo(() => {
    const membershipStepper = [
      text('member'),
      text('paymentMethod'),
      text('confirmation'),
    ];
    return {
      activeWizardStep,
      cardHeading,
      membershipStepper,
    };
  }, [activeWizardStep, cardHeading]);

  const memoizedActions = useMemo(
    () => ({
      handleSetWizardStep,
    }),
    [handleSetWizardStep]
  );

  useEffect(() => {
    handleSetCardHeading();
  }, [handleSetCardHeading]);
  
  // ref to expose functions from Stepper component
  const wizzardFunctions = useRef({});
  return (
    <PaymentContext.Provider
      value={{
        states: memoizedState,
        actions: memoizedActions,
        refs: {
          wizzardFunctions,
        },
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

PaymentProvider.propTypes = {
  children: PropTypes.element,
};

export default PaymentProvider;
