export const alertConstants = {
  ALERT_SUCCESS: 'ALERT_SUCCESS',

  ALERT_ERROR: 'ALERT_ERROR',

  ALERT_WARNING: 'ALERT_WARNING',

  ALERT_INFO: 'ALERT_INFO',

  ALERT_RESET: 'ALERT_RESET',

  ALERT_VERSION_OUTDATED: 'ALERT_VERSION_OUTDATED',
};
