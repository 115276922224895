import React from 'react';
import cn from 'classnames';

import Activity from './Activity';

const Timeline = (props) => {
  const { children, className } = props;
  return (
    <div className={cn('Timeline', { [className]: className })}>{children}</div>
  );
};

Timeline.Activity = Activity;

export default Timeline;
