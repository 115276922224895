import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { profileSelectors } from '../redux/selectors/profile';
import { isDemoJob, jobHasPointCloud } from '../utilities/job';
import { getProjectLatestJob, isDemoProject } from '../utilities/project';

const useProjectPermissions = (project) => {
  const isAdmin = useSelector(profileSelectors.getIsAdmin);
  const isStaff = useSelector(profileSelectors.getIsStaff);
  const is3dViewerAccessibleToUser = useSelector(
    profileSelectors.get3dViewerEnabled
  );
  const isDeleteEnabled = useMemo(
    () => isAdmin || project?.jobs?.every((job) => job.status === 'draft'),
    [isAdmin, project]
  );
  const isPending = useMemo(
    () => project?.user_project?.invite_status === 'pending',
    [project]
  );
  const latestJob = useMemo(() => getProjectLatestJob(project), [project]);
  const is2dMapEnabled = useMemo(() => !!latestJob, [latestJob]);
  const is3dViewerLocked = useMemo(
    () =>
      jobHasPointCloud(latestJob) &&
      !is3dViewerAccessibleToUser &&
      !isDemoJob(latestJob),
    [is3dViewerAccessibleToUser, latestJob]
  );
  const is3dViewerEnabled = useMemo(
    () =>
      (jobHasPointCloud(project) && !isPending && is3dViewerAccessibleToUser) ||
      (jobHasPointCloud(project) && isDemoProject(project)),
    [project, isPending, is3dViewerAccessibleToUser]
  );
  const isAdministrationEnabled = useMemo(
    () => (latestJob && latestJob.status === 'demo' ? isAdmin : isStaff),
    [isAdmin, isStaff, latestJob]
  );
  return {
    is2dMapEnabled,
    is3dViewerLocked,
    is3dViewerEnabled,
    isDeleteEnabled,
    isAdministrationEnabled,
  };
};

export default useProjectPermissions;
