import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

/* eslint-disable react/prop-types */
const RightControlsWrapper = ({ children }) => {
  const { rightMenu } = useSelector((state) => state.layout);
  return (
    <div
      className={cn('right-controls-wrapper', rightMenu.isVisible && 'open')}
    >
      {children}
    </div>
  );
};

export default RightControlsWrapper;
