import { createReducerContext } from 'react-use';
import { onboardingConstants } from './constants';

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case onboardingConstants.SET_FULL_NAME:
      return {
        ...state,
        fullName: payload,
      };
    case onboardingConstants.SET_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: payload,
      };
    case onboardingConstants.TOGGLE_PROFESSION: {
      return {
        ...state,
        profession: state.profession === payload ? '' : payload,
      };
    }
    case onboardingConstants.TOGGLE_SOFTWARE: {
      const exists = state.designSoftware.includes(payload);
      return {
        ...state,
        designSoftware: exists
          ? state.designSoftware.filter((software) => software !== payload)
          : [...state.designSoftware, payload],
      };
    }
    case onboardingConstants.CLEAR_SOFTWARE: {
      return {
        ...state,
        designSoftware: [],
      };
    }
    case onboardingConstants.TOGGLE_MARKETING: {
      return {
        ...state,
        isMarketingEnabled: payload,
      };
    }
    default:
      return state;
  }
};

export const [useOnboarding, OnboardingProvider] = createReducerContext(
  reducer,
  {
    fullName: '',
    phoneNumber: '',
    profession: '',
    designSoftware: '',
    isMarketingEnabled: true,
  }
);
