import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { getUser } from './storage';
import { isUserAnonymous } from './user';

const LoggedOutRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      getUser() && !isUserAnonymous(getUser()) ? (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location },
            search: props.location.search,
          }}
        />
      ) : (
        <Component {...props} />
      )
    }
  />
);

export default LoggedOutRoute;
