import React from 'react';
import classnames from 'classnames';
import T from 'prop-types';

const oppositeDirection = {
  left: 'right',
  right: 'left',
  down: 'up',
  up: 'down',
};
const getIcon = (direction, isOpen) =>
  `caret-${isOpen ? oppositeDirection[direction] : direction}`;

const MapButton = (props) => {
  const { onClick, children, className, noPadding } = props;

  return (
    <button
      className={classnames('MapButton', {
        [className]: className,
        noPadding: noPadding,
      })}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

MapButton.propTypes = {
  onClick: T.func,
  className: T.string,
};

export default MapButton;
