import { useContext, useMemo } from 'react';
import { MapViewContext } from '../mapViewContext';
import { sumBy } from 'lodash';
import {
  ADD_ONS_DATA_TYPE,
  CAPTURE_SCAN_SERVICES_DATA_TYPE,
} from '../../../constants/product';
import {
  isUserGovernment,
  isUserUnderTrial,
  userHasValidMembership,
} from '../../../utilities/user';
import { useSelector } from 'react-redux';
import { profileSelectors } from '../../../redux/selectors/profile';
import { GST } from '../../../constants/price';

const useData = () => {
  const currentUser = useSelector(profileSelectors.getUserProfile);
  const { state } = useContext(MapViewContext);
  const quote = state.job?.quote;

  let orderTotals;
  if (quote?.cart_totals) {
    // for new orders Sprint 67
    orderTotals = quote.cart_totals;
  } else if (quote?.order) {
    // released Sprint 66
    orderTotals = quote.order;
  } else {
    // old implementation
    // used quoted and completed orders
    orderTotals = quote;
  }

  // Trialing users are not included as essential
  const isEssentialUser = useMemo(() => {
    if (isUserGovernment(currentUser)) return true;
    return (
      userHasValidMembership(currentUser) && !isUserUnderTrial(currentUser)
    );
  }, [currentUser]);

  const isUserOnTrial = useMemo(
    () => isUserUnderTrial(currentUser),
    [currentUser]
  );

  const gstPercentage = useMemo(
    () => 1 + (quote.gst || GST) / 100,
    [quote.gst]
  );

  // for FREE & TRIAL user for Advertisement or Promotion of Membership
  const discounts = useMemo(() => {
    const captureServicesDiscount = sumBy(quote.charges, (charge) => {
      const isScanCaptureService = CAPTURE_SCAN_SERVICES_DATA_TYPE.includes(
        charge.details.category_name
      );
      if (isScanCaptureService) {
        return sumBy(
          isEssentialUser ? charge.discounts : charge.member_discounts_preview,
          (preview) => preview.price.discount
        );
      }
      return 0;
    });

    const addOnsDiscount = sumBy(quote.charges, (charge) => {
      const isScanCaptureService = ADD_ONS_DATA_TYPE.includes(
        charge.details.category_name
      );
      if (isScanCaptureService) {
        return sumBy(
          isEssentialUser ? charge.discounts : charge.member_discounts_preview,
          (preview) => preview.price.discount
        );
      }
      return 0;
    });

    const selfServiceDiscount = sumBy(quote.charges, (charge) => {
      return sumBy(charge.discounts, (discount) => discount.price.discount);
    });

    return {
      members: {
        captureServicesDiscount,
        addOnsDiscount,
      },
      selfServiceDiscount,
      selfServiceDiscountIncGst: selfServiceDiscount * gstPercentage,
    };
  }, [gstPercentage, isEssentialUser, quote.charges]);

  const fullPriceWithSalesHelpIncGst = useMemo(
    () =>
      (orderTotals.price.base +
        orderTotals.price.fees +
        discounts.selfServiceDiscount) *
      gstPercentage,
    [
      orderTotals.price.base,
      orderTotals.price.fees,
      discounts.selfServiceDiscount,
      gstPercentage,
    ]
  );

  const memberTax = useMemo(
    () => sumBy(orderTotals?.member_taxes, (tax) => tax.price.extra),
    [orderTotals?.member_taxes]
  );

  const nonMemberTax = useMemo(
    () => sumBy(orderTotals?.taxes, (tax) => tax.price.extra),
    [orderTotals?.taxes]
  );

  const memberPrice = useMemo(
    () => orderTotals?.price?.member_subtotal + memberTax,
    [memberTax, orderTotals?.price?.member_subtotal]
  );

  /** AFAIK: orderTotals.discounts only includes New Customer Discount(165) and
   *  Membership Trial Discount(69.50) passed from Backend, associated w/ this comment
   *  in template check section --OTHER DISCOUNTS-- */
  const otherDiscountsTotal = useMemo(() => {
    const subtotal = sumBy(
      orderTotals?.discounts,
      (discount) => discount.price.subtotal
    );
    return {
      subtotal,
      gst: sumBy(orderTotals?.discounts, (discount) => discount.price.gst),
      subtotalIncGst: subtotal * gstPercentage,
    };
  }, [gstPercentage, orderTotals?.discounts]);

  return {
    quote,
    currentUser,
    isEssentialUser,
    isUserOnTrial,
    orderTotals,
    discounts,
    memberTax,
    nonMemberTax,
    memberPrice,
    otherDiscountsTotal,
    fullPriceWithSalesHelpIncGst,
    currency: {
      name: quote.currency.name,
      scale_factor: quote.currency.scale_factor,
    },
    gstPercentage,
  };
};

export default useData;
