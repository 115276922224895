import React from 'react';
import { useContext } from 'react';
import { MapViewContext } from '../../../mapView/mapViewContext';
import Icon from '../../../icon/Icon';
import { Button } from 'react-bootstrap';

export default function CloseButton() {
  const { state, actions } = useContext(MapViewContext);

  return (
    <Button
      onClick={() => {
        if (state.activeSelection) {
          actions.setShowProductModal(false);
        } else {
          actions.clearProductSelected();
        }
      }}
      variant='light'
      className='row-action icon-button bg-transparent'
    >
      <Icon icon='close' />
    </Button>
  );
}
