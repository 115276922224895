import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { useMount, useSearchParam } from 'react-use';
import Page from '../components/page/Page';
import { getUserProfile } from '../redux/actions';
import routes from '../routes/constants';
import { larkiApi, larkiApiNoAuthentication } from '../utilities/api';
import { setUser } from '../utilities/storage';

const AcceptInvite = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { projectId } = useParams();
  const token = useSearchParam('token');
  const userProfile = useSelector((state) => state.profileReducer.userProfile);

  if (!token && !location.state?.validated) {
    return <Redirect to={routes.home} />;
  }

  const onAcceptInvite = async () => {
    try {
      if (!location.state?.validated) {
        const { data } = await larkiApiNoAuthentication.post(
          '/user/verify-token',
          {
            token,
          }
        );

        sessionStorage.clear();
        setUser(data);
      }

      const { data } = await larkiApi.put(`/project/${projectId}/user`, {
        inviteStatus: 'accepted',
      });

      if (location.state?.validated) {
        history.push(routes.dashboard);
      } else {
        if (isEmpty(userProfile)) {
          await dispatch(getUserProfile());
        }
        history.push(`${routes.fileManager.root}/${data.project}`);
      }
    } catch {
      history.push(routes.login);
    }
  };

  useMount(() => {
    onAcceptInvite();
  });

  return (
    <Page className='PageCenter'>
      <PulseLoader size={12} color='#31a283' />
    </Page>
  );
};

export default AcceptInvite;
