import { FieldArray, Formik } from 'formik';
import flowFP from 'lodash/fp/flow';
import keyByFP from 'lodash/fp/keyBy';
import orderByFP from 'lodash/fp/orderBy';
import pullAllWithFP from 'lodash/fp/pullAllWith';
import setFP from 'lodash/fp/set';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import React from 'react';
import { array, boolean, object } from 'yup';

import text from '../../../text';
import { getNowDate } from '../../../utilities/date';
import {
  isDetailedStatusCompleted,
  isDetailedStatusInProgress,
} from '../../../utilities/job';
import { productDetailedStatusSchema } from '../../../validation/job';
import Button from '../../button/Button';
import Checkbox from '../Checkbox';
import UpdateProductTable from './UpdateProductTable';

const updateFormSchema = (compareFn) =>
  object().shape({
    detailedStatus: array()
      .of(productDetailedStatusSchema)
      .test(
        'has-changed',
        'no changes',
        async (value) => await compareFn(value)
      ),
    sendNotification: boolean().notRequired(),
  });

const setCompletedDate = (item) =>
  isDetailedStatusCompleted(item.status)
    ? setFP('completedAt', getNowDate(), item)
    : identity(item);

const setStartedDate = (item) =>
  isDetailedStatusInProgress(item.status) && !item.startedAt
    ? setFP('startedAt', getNowDate(), item)
    : identity(item);

const setCreatedDate = (detailedStatus) =>
  setFP('createdAt', getNowDate(), detailedStatus);

const formatValues = (initial, detailedStatus) =>
  flowFP([
    pullAllWithFP(isEqual, initial),
    (result) =>
      map(result, (item) =>
        flowFP([setCompletedDate, setStartedDate, setCreatedDate])(item)
      ),
    keyByFP('productKey'),
  ])(detailedStatus);

const UpdateProductForm = (props) => {
  const { job, submitState, onSubmit, onCancel } = props;

  const initialStatuses = flowFP([
    (result) =>
      map(result, (values, productKey) => ({
        productKey,
        ...values,
      })),
    orderByFP(['createdAt'], ['asc']),
  ])(job?.detailed_status);

  const hasFormChanged = flowFP([
    pullAllWithFP(isEqual, initialStatuses), // Get values that have changed
    (result) => !isEmpty(result),
  ]);

  return (
    <div className='UpdateProductForm'>
      <Formik
        initialValues={{
          detailedStatus: initialStatuses,
          sendNotification: false,
        }}
        validateOnMount
        validationSchema={updateFormSchema(hasFormChanged)}
        enableReinitialize
      >
        {({ values, handleChange, isValid, dirty }) => {
          return (
            <>
              <FieldArray name='detailedStatus'>
                {(helpers) => {
                  return (
                    <>
                      <UpdateProductTable
                        initialProducts={initialStatuses}
                        products={values.detailedStatus}
                        productsHelpers={helpers}
                      />
                      <a
                        href='#'
                        onClick={(event) => {
                          event.preventDefault();
                          helpers.push({ productKey: '' });
                        }}
                      >
                        {`+ ${text('addNewProduct')}`}
                      </a>
                    </>
                  );
                }}
              </FieldArray>
              <Checkbox
                id='sendNotification'
                onChange={handleChange}
                name='sendNotification'
                value={values?.sendNotification}
                label={text('sendNotificationToCustomer')}
              />
              <div className='form-footer'>
                <Button
                  loading={submitState.loading}
                  disabled={!isValid || !dirty}
                  onClick={() => {
                    onSubmit(
                      formatValues(initialStatuses, values.detailedStatus),
                      values.sendNotification
                    );
                  }}
                >
                  {text('update')}
                </Button>
                <a name='cancel' href='#' onClick={onCancel}>
                  {text('cancel')}
                </a>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default UpdateProductForm;
