import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAsyncFn, useSearchParam } from 'react-use';

import Page from '../../components/page/Page';
import { client } from '../../utilities/api';
import ResultContainer from '../../components/containers/ResultContainer';
import text, { formatCurrency } from '../../text';
import List from '../../components/list/List';

const AcceptQuote = () => {
  const { quoteId } = useParams();
  const token = useSearchParam('token');
  const [acceptQuoteState, acceptQuote] = useAsyncFn(async (id, quoteToken) => {
    const { data } = await client.acceptQuote(id, quoteToken);
    return data;
  });

  useEffect(() => {
    if (!acceptQuoteState.loading) {
      acceptQuote(quoteId, token);
    }
  }, [quoteId]);

  return (
    <Page className='PageCenter'>
      <div>
        <ResultContainer
          isLoading={acceptQuoteState.loading}
          header={
            acceptQuoteState.value?.id &&
            `${text('quoteId')}: ${acceptQuoteState.value?.id}`
          }
          icon={
            acceptQuoteState.error
              ? 'circled-exclamation'
              : 'circled-tick_filled'
          }
          firstHeader={
            acceptQuoteState.error
              ? acceptQuoteState.error?.response.data.message
              : text('quoteAccepted')
          }
          resultStatus={`${
            acceptQuoteState.error ? text('error') : text('success')
          }!`}
          extraInfo={
            acceptQuoteState.value && (
              <List isPaddingless className='py-3'>
                <tbody>
                  {acceptQuoteState.value.amount_total && (
                    <List.Item
                      isColonAppended
                      description={text('amount')}
                      value={formatCurrency(
                        acceptQuoteState.value.amount_total,
                        acceptQuoteState.value.currency.name,
                        acceptQuoteState.value.currency.scale_factor
                      )}
                    />
                  )}
                  {acceptQuoteState.value.message && (
                    <List.Item
                      isColonAppended
                      description={text('message')}
                      value={acceptQuoteState.value.message}
                    />
                  )}
                </tbody>
              </List>
            )
          }
        />
      </div>
    </Page>
  );
};

export default AcceptQuote;
